
import { environment } from '../../../environments/environment';

export const URLS = {
    login: `${environment.baseUrl}/auth/client/login`,
    logout: `${environment.baseUrl}/auth/client/logout`,
    home: `${environment.baseUrl}/employees`,
    refineList: `${environment.baseUrl}/refine`,
    registration: `${environment.baseUrl}/auth/register`,
    countryList: `${environment.baseUrl}/country`,
    currencyList: `${environment.baseUrl}/admin/countryCurrency`,
    stateList: `${environment.baseUrl}/state`,
    forgetPassword: `${environment.baseUrl}/auth/web-forgot`,
    resetPassword: `${environment.baseUrl}/auth/web-reset`,
    menuList: `${environment.baseUrl}/menuListing`,
    tokenVerify: `${environment.baseUrl}/auth/forgotPasswordTokenVerify`,



    userDeatils: `${environment.baseUrl}/user/getClientDetail`,
    userUpdate: `${environment.baseUrl}/user/clientDetailUpdate`,
    masterBusinessRole: `${environment.baseUrl}/getmasterBusinessRole`,
    changePassword: `${environment.baseUrl}/user/updateClientPassword`,


    getUsers: `${environment.baseUrl}/user/getInternalUserList`,
    getUsersData: `${environment.baseUrl}/user/internalUser`,
    userUpdateStatus: `${environment.baseUrl}/user/internalUserAction`,
    addUserData: `${environment.baseUrl}/user/internalUserCreate`,
    updateUserData: `${environment.baseUrl}/user/internalUserUpdate`,
    getRoleMaster: `${environment.baseUrl}/subUser/getSubUserRoleList`,
    getDefaultMasterValues: `${environment.baseUrl}/getDefaultMasterValue`,
    getCountryCurrency: `${environment.baseUrl}/admin/countryCurrency`,


    businessDeatils: `${environment.baseUrl}/user/getBusinessDetails`,
    businessUpdate: `${environment.baseUrl}/user/updateBusinessDetails`,

    createMarkup: `${environment.baseUrl}/user/addSellPriceMarkup`,
    getMarkupList: `${environment.baseUrl}/user/getMarkuplist`,

    getRoleManagementRole: `${environment.baseUrl}/subUser/getSubUserRoles`,
    addRoleData: `${environment.baseUrl}/subUser/createSubUserRole`,
    getRoleDetails: `${environment.baseUrl}/subUser/getSubUserRoleDetail`,
    updateRoleData: `${environment.baseUrl}/subUser/updateSubUserRole`,
    RoleupdateStatus: `${environment.baseUrl}/subUser/updateSubRoleStatus`,

    getFilterData: `${environment.baseUrl}/getMasterAttributes`,

    getDiamondList: `${environment.baseUrl}/diamond/getDiamondList`,
    getLocation: `${environment.baseUrl}/admin/lotLocation`,
    getDiamondDetail: `${environment.baseUrl}/diamond/getDiamondDetail`,
    getWishlist: `${environment.baseUrl}/diamond/wishList`,
    addToWishlist: `${environment.baseUrl}/diamond/addToWishlist`,
    wishListRemoveItem: `${environment.baseUrl}/diamond/wishListRemoveItem`,

    addToCart: `${environment.baseUrl}/order/addToCart`,
    getOrderList: `${environment.baseUrl}/order/orderCartListing`,
    removeFromCart: `${environment.baseUrl}/order/removeFromCart`,
    requestOrder: `${environment.baseUrl}/order/requestOrder`,
    getcreditFormShippingAddress: `${environment.baseUrl}/user/creditFormShippingAddress`,

    //credit form api
    getCreditForm: `${environment.baseUrl}/user/CreditFormList`,
    postCreditForm: `${environment.baseUrl}/user/CreditForm`,//step: 1 / 2 / 3 / 4, reference : Trade / Bank(only for step: 2)
    getImageData: `${environment.baseUrl}/fetch-file/image`,
    uploadCreditFormPdf: `${environment.baseUrl}/user/sendCreditPdfMail`,
    creditFromPdf: `${environment.baseUrl}/user/creditFormPDF`,
    getPDF: `${environment.baseUrl}/fetch-file/:type`,
    verifyCompany: `${environment.baseUrl}/user/blackCompanyVerify`,


    getOrderHistoryList: `${environment.baseUrl}/order/orderHistory`,
    getPlaceUserList: `${environment.baseUrl}/order/getPlaceUserList`,
    getOrderDetail: `${environment.baseUrl}/order/getOrderDetail`,
    putOrderApproval: `${environment.baseUrl}/order/orderApproval`,

    //buying group
    getBuyingGroup: `${environment.baseUrl}/getBuyingGroup`,
    getRole: `${environment.baseUrl}/order/getRoleListForOrder`,
    recentlyViewed: `${environment.baseUrl}/diamond/recentlyViewed`,

    shareToClient: `${environment.baseUrl}/diamond/shareToClient`,
    getShareDiamondDetail: `${environment.baseUrl}/diamond/shareDiamondDetail`,
    getSharePairedDiamondDetail: `${environment.baseUrl}/diamond/sharePairedDiamondDetail`,

    getPairedDiamondList: `${environment.baseUrl}/diamond/getPairedDiamondList`,
    getPairedDiamondDetail: `${environment.baseUrl}/diamond/getPairedDiamondDetail`,
    recentlyPairedViewed: `${environment.baseUrl}/diamond/pairedRecentlyViewed`,
    addRecentlyViewData: `${environment.baseUrl}/diamond/addRecentlyViewData`,

    getNotificationList: `${environment.baseUrl}/user/notificationListing`,
    getDeleteItemList: `${environment.baseUrl}/user/notificationRemove`,
    getViewAndUpdate: `${environment.baseUrl}/user/notificationViewAndUpdate`,

    emailVerify: `${environment.baseUrl}/admin/verifyEmail`,
    phoneVerify: `${environment.baseUrl}/admin/verifyMobileNumber`,
    companyVerify: `${environment.baseUrl}/admin/verifyCompany`,

    listChatUsers:`${environment.baseUrl}/chat/list-chat-user`,
}

export const SECRET_KEY: string = `${environment.rsa_public_key}`;
