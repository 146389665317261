<section class="section first-section">
    <div class="container-fluid">
        <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner>
        <div class="row diamondRow justify-content-center">
            <div class="detail-section mb-3">
                <div class="title-info">
                    <label>Stock ID.</label>
                    <span>{{diamondLotData?.altr_stone_id ? diamondLotData?.altr_stone_id : '-'}}</span>
                    <span>{{pairedDiamondLotData?.altr_stone_id ? pairedDiamondLotData?.altr_stone_id : '-'}}</span>
                </div>
                <ul>
                    <li>
                        <label>Shape :</label>
                        <span *ngIf="!isShareToClient">{{diamondLotData?.shape_name ? diamondLotData?.shape_name : '-'}}</span>
                        <span *ngIf="isShareToClient">{{diamondLotData?.master_shape.shape_name ? diamondLotData?.master_shape.shape_name : '-'}}</span>
                        <span *ngIf="!isShareToClient">{{pairedDiamondLotData?.shape_name ? pairedDiamondLotData?.shape_name : '-'}}</span>
                        <span *ngIf="isShareToClient">{{pairedDiamondLotData?.paired_shape.shape_name ? pairedDiamondLotData?.paired_shape.shape_name : '-'}}</span>
                    </li>
                    <li>
                        <label>Carat :</label>
                        <span>{{diamondLotData?.weight ? diamondLotData?.weight : '-'}}</span>
                        <span>{{pairedDiamondLotData?.weight ? pairedDiamondLotData?.weight : '-'}}</span>
                    </li>
                    <li>
                        <label>Color :</label>
                        <span *ngIf="!isShareToClient">{{ diamondLotData?.color_name ? diamondLotData?.color_name : '-'}}</span>
                        <span *ngIf="isShareToClient">{{ diamondLotData?.master_color.color_name ? diamondLotData?.master_color.color_name : '-'}}</span>
                        <span *ngIf="!isShareToClient">{{ pairedDiamondLotData?.color_name ? pairedDiamondLotData?.color_name : '-'}}</span>
                        <span *ngIf="isShareToClient">{{ pairedDiamondLotData?.paired_color.color_name ? pairedDiamondLotData?.paired_color.color_name : '-'}}</span>
                    </li>
                    <li>
                        <label>Clarity:</label>
                        <span *ngIf="!isShareToClient">{{diamondLotData?.clarity_name ? diamondLotData?.clarity_name : '-'}}</span>
                        <span *ngIf="isShareToClient">{{diamondLotData?.master_clarity.clarity_name ? diamondLotData?.master_clarity.clarity_name : '-'}}</span>
                        <span *ngIf="!isShareToClient">{{pairedDiamondLotData?.clarity_name ? pairedDiamondLotData?.clarity_name : '-'}}</span>
                        <span *ngIf="isShareToClient">{{pairedDiamondLotData?.paired_clarity.clarity_name ? pairedDiamondLotData?.paired_clarity.clarity_name : '-'}}</span>
                    </li>
                    <li>
                        <label>Lab :</label>
                        <span>{{diamondLotData?.lab_name ? diamondLotData?.lab_name : '-'}}</span>
                        <span>{{pairedDiamondLotData?.lab_name ? pairedDiamondLotData?.lab_name : '-'}}</span>
                    </li>
                    <hr>
                    <li>
                        <label>Certificate No. :</label>
                        <span>{{diamondLotData?.report_no ? diamondLotData?.report_no : '-'}}</span>
                        <span>{{pairedDiamondLotData?.report_no ? pairedDiamondLotData?.report_no : '-'}}</span>
                    </li>
                    <li>
                        <label>Measurements :</label>
                        <span class="measurementLabel">{{ measurement }}</span>
                        <span class="measurementLabel">{{ pairedMeasurement }}</span>
                    </li>
                    <li>
                        <label>Cut :</label>
                        <span *ngIf="!isShareToClient">{{diamondLotData?.cut_name ? diamondLotData?.cut_name : '-'}}</span>
                        <span *ngIf="isShareToClient">{{diamondLotData?.cut.cut_name ? diamondLotData?.cut.cut_name :
                            '-'}}</span>
                        <span *ngIf="!isShareToClient">{{pairedDiamondLotData?.cut_name ?
                            pairedDiamondLotData?.cut_name : '-'}}</span>
                        <span *ngIf="isShareToClient">{{pairedDiamondLotData?.paired_cut.cut_name ?
                            pairedDiamondLotData?.paired_cut.cut_name : '-'}}</span>
                    </li>
                    <li>
                        <label>Polish :</label>
                        <span *ngIf="!isShareToClient">{{diamondLotData?.polish_short_code ? diamondLotData?.polish_short_code : '-'}}</span>
                        <span *ngIf="isShareToClient">{{diamondLotData?.polish.short_code ? diamondLotData?.polish.short_code : '-'}}</span>
                        <span *ngIf="!isShareToClient">{{pairedDiamondLotData?.polish_short_code ? pairedDiamondLotData?.polish_short_code : '-'}}</span>
                        <span *ngIf="isShareToClient">{{pairedDiamondLotData?.paired_polish.short_code ? pairedDiamondLotData?.paired_polish.short_code : '-'}}</span>
                    </li>
                    <li>
                        <label>Symmetry :</label>
                        <span *ngIf="!isShareToClient">{{diamondLotData?.symmetry_short_code ? diamondLotData?.symmetry_short_code : '-'}}</span>
                        <span *ngIf="isShareToClient">{{diamondLotData?.symmetry.short_code ? diamondLotData?.symmetry.short_code : '-'}}</span>
                        <span *ngIf="!isShareToClient">{{pairedDiamondLotData?.symmetry_short_code ? pairedDiamondLotData?.symmetry_short_code : '-'}}</span>
                        <span *ngIf="isShareToClient">{{pairedDiamondLotData?.paired_symmetry.short_code ? pairedDiamondLotData?.paired_symmetry.short_code : '-'}}</span>
                    </li>
                    <li>
                        <label>Depth :</label>
                        <span>{{diamondLotData?.depth_percentage ? diamondLotData?.depth_percentage : '-'}}%</span>
                        <span>{{pairedDiamondLotData?.depth_percentage ? pairedDiamondLotData?.depth_percentage : '-'}}%</span>
                    </li>
                    <li>
                        <label>Table :</label>
                        <span>{{diamondLotData?.table_percentage ? diamondLotData?.table_percentage : '-'}}%</span>
                        <span>{{pairedDiamondLotData?.table_percentage ? pairedDiamondLotData?.table_percentage : '-'}}%</span>
                    </li>
                    <li>
                        <label>Ratio :</label>
                        <span>{{diamondLotData?.ratio ? diamondLotData?.ratio : '-'}}</span>
                        <span>{{pairedDiamondLotData?.ratio ? pairedDiamondLotData?.ratio : '-'}}</span>
                    </li>
                    <li>
                        <label>Hearts & Arrow :</label>
                        <span>{{diamondLotData?.heart_n_arrow === true ? 'Yes' : 'No'}}</span>
                        <span>{{pairedDiamondLotData?.heart_n_arrow === true ? 'Yes' : 'No'}}</span>
                    </li>
                    <hr>
                    <li>
                        <label class="totalLabel">Total Carat :</label>
                        <span class="price-span">{{diamondDetailData?.total_carat ? diamondDetailData?.total_carat : '-'}}</span>
                    </li>
                    <hr>
                    <li>
                        <label class="totalLabel">Total Price :</label>
                        <span class="price-span" *ngIf="!isShareToClient && displayPrice === 'b' || displayPrice === 's'">{{diamondDetailData?.currency_symbol}}{{diamondDetailData?.conversion_amount
                            | number}}
                        </span>
                        <span class="price-span" *ngIf="!isShareToClient && displayPrice === 'c'">{{diamondDetailData?.currency_symbol}}{{diamondDetailData?.cost_conversion_amount
                            | number}}
                        </span>
                        <span *ngIf="isShareToClient" class="price-span">{{diamondDetailData?.currency_symbol}}{{diamondDetailData?.retail_price
                            | number}}</span>
                    </li>
                    <li *ngIf="isShareToClient" class="msgLi">
                        <mat-form-field appearance="outline" class="w-100" *ngIf="diamondDetailData?.message">
                            <mat-label>Message</mat-label>
                            <textarea matInput placeholder="Message" readonly="true" value="{{diamondDetailData?.message}}"
                                rows="5"></textarea>
                        </mat-form-field>
                    </li>
                    <li>
                        <button type="button" class="btn" (click)="showClientModal(diamondDetailData)" *ngIf="!isShareToClient">Show
                            Client ></button>
                    </li>
                </ul>
                <div class="card" *ngIf="!isShareToClient">
                    <!-- <button type="button" class="btn btn-outline" (click)="AddToCart(diamondDetailData?.lot_id, diamondDetailData?.in_order_cart)">{{diamondDetailData?.in_order_cart ? 'Remove from Cart' : 'Add to Cart'}}</button> -->
                    <button type="button" class="btn btn-outline" (click)="AddToCart(diamondDetailData)">{{getLabelForCart(diamondDetailData)}}</button>

                </div>
            </div>
            <div class="diamond-section">
                <div class="row sectionRow" style="width: 845px;">
                    <div class="col-12 col-md-6 col-sm-6">
                        <div class="title">
                            <h1 *ngIf="isShareToClient">{{diamondLotData?.master_shape.shape_name}}
                                {{diamondLotData?.weight}} {{ diamondLotData?.master_color.color_name}}
                                {{diamondLotData?.master_clarity.clarity_name}} {{diamondLotData?.cut.cut_name ?
                                diamondLotData?.cut.cut_name
                                : '-'}}
                            </h1>
                            <h1 *ngIf="!isShareToClient">{{diamondLotData?.shape_name}} {{diamondLotData?.weight}} {{
                                diamondLotData?.color_name}} {{diamondLotData?.clarity_name}}
                                {{diamondLotData?.cut_name ? diamondLotData?.cut_name : '-'}}
                            </h1>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-sm-6">
                        <div class="title">
                            <h1 *ngIf="isShareToClient">{{pairedDiamondLotData?.paired_shape.shape_name}}
                                {{pairedDiamondLotData?.weight}} {{ pairedDiamondLotData?.paired_color.color_name}}
                                {{pairedDiamondLotData?.paired_clarity.clarity_name}}
                                {{pairedDiamondLotData?.paired_cut.cut_name
                                ? pairedDiamondLotData?.paired_cut.cut_name : '-'}}
                            </h1>
                            <h1 *ngIf="!isShareToClient">{{pairedDiamondLotData?.shape_name}}
                                {{pairedDiamondLotData?.weight}} {{ pairedDiamondLotData?.color_name}}
                                {{pairedDiamondLotData?.clarity_name}} {{pairedDiamondLotData?.cut_name ?
                                pairedDiamondLotData?.cut_name : '-'}}
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="slider-wrap">
                    <ngb-carousel [interval]="false">
                        <ng-template ngbSlide>
                            <div class="row">
                                <div class="col-12 col-md-6 col-sm-6">
                                    <div class="detail-img-div">
                                        <!-- <img src="./assets/img/360.png" alt="360"> -->
                                        <iframe id="diamondIframe" scrolling="no" width="401" height="350" frameborder="0"
                                            allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 col-sm-6">
                                    <div class="detail-img-div">
                                        <!-- <img src="./assets/img/360.png" alt="360"> -->
                                        <iframe id="diamondIframe2" scrolling="no" width="401" height="350" frameborder="0"
                                            allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="((diamondLotData && diamondLotData.image_path) && (pairedDiamondLotData && pairedDiamondLotData.image_path))">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="diamondLotData?.image_path" alt="Image Not Available">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="pairedDiamondLotData?.image_path" alt="Image Not Available">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="((diamondLotData && diamondLotData.top_image) && (pairedDiamondLotData && pairedDiamondLotData.top_image))">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="diamondLotData.top_image" alt="Image Not Available">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="pairedDiamondLotData.top_image" alt="Image Not Available">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="((diamondLotData && diamondLotData.bottom_image) && (pairedDiamondLotData && pairedDiamondLotData.bottom_image))">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="diamondLotData.bottom_image" alt="Image Not Available">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="pairedDiamondLotData.bottom_image" alt="Image Not Available">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="((diamondLotData && diamondLotData.brilliance_image) && (pairedDiamondLotData && pairedDiamondLotData.brilliance_image))">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="diamondLotData.brilliance_image" alt="Image Not Available">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="pairedDiamondLotData.brilliance_image" alt="Image Not Available">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="((diamondLotData && diamondLotData.symmetry_image) && (pairedDiamondLotData && pairedDiamondLotData.symmetry_image))">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="diamondLotData.symmetry_image" alt="Image Not Available">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="pairedDiamondLotData.symmetry_image" alt="Image Not Available">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="((diamondLotData && diamondLotData.arrow_image) && (pairedDiamondLotData && pairedDiamondLotData.arrow_image) && (pairedDiamondLotData.heart_n_arrow))">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="diamondLotData.arrow_image" alt="Image Not Available">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="pairedDiamondLotData.arrow_image" alt="Image Not Available">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="((diamondLotData && diamondLotData.heart_image) && (pairedDiamondLotData && pairedDiamondLotData.heart_image) && (pairedDiamondLotData.heart_n_arrow))">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="diamondLotData.heart_image" alt="Image Not Available">
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="detail-img-div">
                                        <img [src]="pairedDiamondLotData.heart_image" alt="Image Not Available">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
                <div class="certi-div">
                    <div class="certi-div-wrap">
                        <div *ngIf="diamondLotData?.certificate_url">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'CERTIFICATE', diamondLotData?.certificate_url)">
                                <img [src]="diamondLotData?.certificate_url ? diamondLotData?.certificate_url : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!diamondLotData?.certificate_url">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="diamondLotData?.certificate_url ? diamondLotData?.certificate_url : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Certificate</p>
                    </div>
                    <div class="certi-div-wrap">
                        <div *ngIf="diamondLotData?.laser_inscription">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'LASER INSCRIPTION',  diamondLotData?.laser_inscription)">
                                <img [src]="diamondLotData?.laser_inscription ? diamondLotData?.laser_inscription : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!diamondLotData?.laser_inscription">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="diamondLotData?.laser_inscription ? diamondLotData?.laser_inscription : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Laser Inscription</p>
                    </div>
                    <div class="certi-div-wrap">
                        <div *ngIf="diamondLotData?.proportion_diagram">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'PROPORTION DIAGRAM', diamondLotData?.proportion_diagram)">
                                <img [src]="diamondLotData?.proportion_diagram ? diamondLotData?.proportion_diagram : './assets/img/proportion-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!diamondLotData?.proportion_diagram">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="diamondLotData?.proportion_diagram ? diamondLotData?.proportion_diagram : './assets/img/proportion-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Proportion Diagram</p>
                    </div>
                    <div class="certi-div-wrap">
                        <div *ngIf="pairedDiamondLotData?.certificate_url">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'CERTIFICATE', pairedDiamondLotData?.certificate_url)">
                                <img [src]="pairedDiamondLotData?.certificate_url ? pairedDiamondLotData?.certificate_url : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!pairedDiamondLotData?.certificate_url">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="pairedDiamondLotData?.certificate_url ? pairedDiamondLotData?.certificate_url : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Certificate</p>
                    </div>
                    <div class="certi-div-wrap">
                        <div *ngIf="pairedDiamondLotData?.laser_inscription">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'LASER INSCRIPTION',  pairedDiamondLotData?.laser_inscription)">
                                <img [src]="pairedDiamondLotData?.laser_inscription ? pairedDiamondLotData?.laser_inscription : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!pairedDiamondLotData?.laser_inscription">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="pairedDiamondLotData?.laser_inscription ? pairedDiamondLotData?.laser_inscription : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Laser Inscription</p>
                    </div>
                    <div class="certi-div-wrap">
                        <div *ngIf="pairedDiamondLotData?.proportion_diagram">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'PROPORTION DIAGRAM', pairedDiamondLotData?.proportion_diagram)">
                                <img [src]="pairedDiamondLotData?.proportion_diagram ? pairedDiamondLotData?.proportion_diagram : './assets/img/proportion-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!pairedDiamondLotData?.proportion_diagram">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="pairedDiamondLotData?.proportion_diagram ? pairedDiamondLotData?.proportion_diagram : './assets/img/proportion-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Proportion Diagram</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div class="modal-header">
        <h3 class="modal-title">{{certiTitle}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <img [src]="certiUrlForModal" width="100%">
        </div>
    </div>
</ng-template>