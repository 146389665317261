import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { ModalService } from './modal.service';
import { CommonService } from 'src/app/core/_common/common.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { orderBy, union } from 'lodash';
import { environment } from 'src/environments/environment';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [ModalService, DecimalPipe]

})
export class ModalComponent implements OnInit {

  private subscription: Subscription = new Subscription();

  closeResult: string;
  showRetailsPrice:any;
  showCostPrice:any;
  showAdditionalLines:any;

  public retailPrice: string;
  public costPrice: string;
  public message: string;
  public isPairedShareToClient: boolean = false;

  @Input() public shareClientData;
  @Input() public displayPrice;

  constructor(
    private modalService: NgbModal,
    public commonService: CommonService,
    private router: Router,
    public loader: LoaderService,
    public notifyService: NotifyService,
    public modalComService: ModalService,
    public activeModal: NgbActiveModal,
    private _decimalPipe: DecimalPipe
  ) { }

  shareToClient() {
    const params = {
        lot_id : this.shareClientData.lot_id,
        actual_price : this.shareClientData.conversion_amount,
    };

    if(this.isPairedShareToClient){
      params['lot_id'] = this.shareClientData.lot.lot_id ? this.shareClientData.lot.lot_id : null;
      params['paired_lot_id'] = this.shareClientData.paired_diamond_lot.lot_id ? this.shareClientData.paired_diamond_lot.lot_id : null;
    } else {
      params['lot_id'] = this.shareClientData.lot_id ? this.shareClientData.lot_id : null;
    }

    if(this.retailPrice && (this.displayPrice === 'b' || this.displayPrice === 's')){
      params['retail_price'] = +this.retailPrice.replace(",","");
    }

    if(this.costPrice && (this.displayPrice === 'c')){
      params['retail_price'] = +this.costPrice.replace(",","");
    }
    if(this.message){
      params['message'] = this.message;
    }
    this.loader.showSpinner();
    this.subscription.add(
        this.modalComService.shareToClient(params).subscribe(
            data => {
                if (data['code'] === 200) {
                    // this.notifyService.showToast(data['message']);
                    setTimeout(() => {
                        this.loader.hideSpinner();
                    }, 200);
                    this.modalService.dismissAll();
                    const url = data['data']['url'];
                    window.open(`${environment.webUrl}` + url, '_blank');
                }
                else {
                    this.notifyService.showToast(data['message']);
                    this.loader.hideSpinner();
                }
            },
            error => {
                this.loader.hideSpinner();
                console.log(error);
            }
        )
    );
  }

  ngOnInit(): void {
    this.retailPrice = this._decimalPipe.transform(this.shareClientData.conversion_amount);
    this.costPrice = this._decimalPipe.transform(this.shareClientData.cost_conversion_amount);
    if(this.shareClientData.paired_diamond_lot){
      this.isPairedShareToClient = true;
    } else {
      this.isPairedShareToClient = false;
    }
  }

  public isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
}

 
}
