<section class="maincontainer">
    <p class="mainLabel2">Notifications</p>
    <div class="line1"></div>
    <div class="text-right pb-3" *ngIf="dataCount > 0"><button mat-raised-button class="blackbtnForm" type="button" (click)="getViewAndUpdate(true)">View All</button></div>
    <!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
        <p class="loading">Loading...</p>
    </ngx-spinner> -->
    <div class="formDiv">
        <div class="list-view">
            <div class="table-responsive" *ngIf="dataCount > 0">
                <table class="table table-striped table-fixed mb-0">
                    <tbody (scroll)="onScroll($event)" class="thikScroll">
                        <a *ngFor="let data of notificationList; index as i">
                            <tr style="padding:23px 0;" class="justify-content-between">
                                <!-- <td class="pr-3">
                                </td> -->
                                <td class="messageContainer d-flex align-items-center" (click)="getViewAndUpdate(false, data.notification_id, data.event_name, data.pk_id)">
                                    <div class="pl-2">
                                        <a href="#" class="iconDiv" [ngClass]="data.is_read == true ? 'isRead' : 'notRead'"><img src="./assets/img/svg/notification.svg" class="icon2"></a>
                                    </div>
                                    <div class="pl-2 messageDiv">
                                        <p class="titleLbl pb-1">{{data.message}}</p>
                                        <p class="dateLbl">{{data.created_at | date:'MM-dd-yyyy, h:mm:ss a'}}</p>
                                    </div>
                                </td>
                                <td class="d-flex pr-4">
                                    <a><img src="./assets/img/svg/delete.svg" class="iconSize" (click)="openDialog(data.notification_id)"></a>
                                </td>
                            </tr>
                        </a>
                    </tbody>
                </table>
            </div>
            <div class="container text-center notificationEmptyFont" *ngIf="dataCount <= 0">
                Notification list is empty
            </div>
        </div>
    </div>
</section>