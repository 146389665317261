import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class SharedHeaderService {

    @Output() reloadHeaderAction: EventEmitter<any> = new EventEmitter();
    public stockId: any;

    constructor() { }

    public reloadHeader(){
        this.reloadHeaderAction.emit(null);
    }

}
