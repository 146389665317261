import { CookieService } from 'ngx-cookie-service';
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { SECRET_KEY } from '../_constant/api.config';
declare var forge: any;
@Injectable()
export class CommonService {

    private field: string;
    public eventObservable = new Subject<any>();
    public event: any = {};
    public formattedDate: any;
    private userPreferences: any = {};

    constructor(public cookieService: CookieService
    ) { }

    /**
      * @param key : Getter returns user data as passed key in argument
      */
    public get(key: string): any {
        return this.userPreferences[key];
    }

    /**
     * @param key : Removed user data as passed key in argument
     */
    public remove(key: string): any {
        delete this.userPreferences[key];
        this.cookieService.set('userPreferences', JSON.parse(JSON.stringify(this.userPreferences)));
    }

    /**
     * @param key : Property nane sets user data with a named key as passed key in argument
     * @param data : Setter sets user data on passed key in argument
     */
    public set(key: string, data: any): void {
        this.userPreferences[key] = data;
        // Update userPreferences in app storage
        this.cookieService.set('userPreferences', JSON.parse(JSON.stringify(this.userPreferences)));
        console.log(this.userPreferences, 'set preferences');
    }


    clearEvent() {
        this.eventObservable.next();
    }

    public encryptData(data: string) {
        try {
            const publicKey = forge.pki.publicKeyFromPem(SECRET_KEY);
            // console.log(publicKey);
            const encrypted = publicKey.encrypt(data, 'RSA-OAEP', {
                md: forge.md.sha256.create()
            });
            // console.log(encrypted);

            let base64Param = encodeURIComponent(forge.util.encode64(encrypted));
            if (base64Param) {
                return base64Param;
            }
            return false;

        } catch (e) {
            console.log(e);
            return false;

        }
    }

    /**	    
     * @param key : Property name sets user data with a named key as passed key in argument
     * @param data : Setter sets user data on passed key in argument
     */

    public setEvent(event: string, data: any) {
        /* console.log("setEvent"); 
        console.log('data in service',data);     */
        this.event = { 'event': event, 'data': data };
        this.eventObservable.next(this.event);
    }





}