<section class="section first-section">
    <div class="container-fluid">
        <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner>
        <div class="title">
            <h1>Paired Diamond</h1>
        </div>
        <!-- <app-filter [diamondType]="diamondType" (filterChange)="onFilterChangeHandler()" #filterData></app-filter> -->
        <app-filter></app-filter>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-4">
                <div class="diamond-wrap mb-3">
                    <div *ngIf="showDiamondDetail">
                        <div class="row ml-0 mr-0" style="background: #F9F9F9;">
                            <div class="col-4"></div>
                            <div class="col-4 diamond">
                                <img src="../../../assets/img/diamond.png">
                            </div>
                            <div class="col-4 diamond">
                                <img src="../../../assets/img/diamond.png">
                            </div>
                        </div>
                        <!-- <div class="links row ml-0 mr-0">
                            <div class="col-4"></div>
                            <div class="col-4">
                                <a href="#">View Video ></a>
                            </div>
                            <div class="col-4">
                                <a href="#">View Images ></a>
                            </div>
                        </div> -->
                        <div class="values">
                            <div class="row">
                                <div class="col-4 label">Certificate :</div>
                                <div class="col-4"><a href="#">VIEW</a></div>
                                <div class="col-4"><a href="#">VIEW</a></div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Symmetry :</div>
                                <div class="col-4">EX</div>
                                <div class="col-4">EX</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Polish :</div>
                                <div class="col-4">EX</div>
                                <div class="col-4">EX</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Table :</div>
                                <div class="col-4">56.22%</div>
                                <div class="col-4">56.22%</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Lab :</div>
                                <div class="col-4">Very Good</div>
                                <div class="col-4">Very Good</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Fluoroscence :</div>
                                <div class="col-4">3.5MM</div>
                                <div class="col-4">3.5MM</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">total Price :</div>
                                <div class="col-4">$390 (₹28468)</div>
                                <div class="col-4"></div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Totall Carat :</div>
                                <div class="col-4">4.58</div>
                                <div class="col-4"></div>
                            </div>
                        </div>
                        <div class="card">
                            <button type="button" class="btn btn-outline">Add to Cart</button>
                        </div>
                    </div>
                    <div class="default-diamond" *ngIf="!showDiamondDetail">
                        <img src="../../../assets/img/defaultDiamond.png">
                        <p>Select a diamond in the table to view more information</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-8 pl-0">
                <div class="list mb-3">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>All Diamonds</a>
                            <ng-template ngbNavContent>
                                <div class="diamond-tab">
                                    <div class="header-strip">
                                        <div class="results">
                                            <p>Showing <span> 130 </span> Results</p>
                                        </div>
                                        <div class="right-header-strip mat-select-custom">
                                            <div class="location">
                                                <label for="location">Location :</label>
                                                <!-- <select class="form-control" id="location">
                                                    <option>South Carolina</option>
                                                    <option>India</option>
                                                </select> -->
                                                <mat-form-field appearance="fill">
                                                    <!-- <mat-label>Favorite food</mat-label> -->
                                                    <mat-select [(value)]="selectedLocation">
                                                        <mat-option *ngFor="let location of locations" [value]="location.value">
                                                            {{location.viewValue}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="available">
                                                <label for="availability">Availability :</label>
                                                <!-- <select class="form-control" id="availability">
                                                    <option>Immediately</option>
                                                    <option>Not Available</option>
                                                </select> -->
                                                <mat-form-field appearance="fill">
                                                    <!-- <mat-label>Favorite food</mat-label> -->
                                                    <mat-select [(value)]="selectedAvailability">
                                                        <mat-option *ngFor="let Availability of Availabilities" [value]="Availability.value">
                                                            {{Availability.viewValue}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-view" *ngIf="viewList">
                                        <div class="table-responsive">
                                            <table class="table table-striped table-fixed mb-0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="col-1">Shape</th>
                                                        <th scope="col" class="col-1">Carat</th>
                                                        <th scope="col" class="col-1">Color</th>
                                                        <th scope="col" class="col-1">Clarity</th>
                                                        <th scope="col" class="col-1">Cut</th>
                                                        <th scope="col" class="col-1">Report</th>
                                                        <th scope="col" class="col-2">Price USD</th>
                                                        <th scope="col" class="col-2">Price INR</th>
                                                        <th scope="col" class="col-2">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <a (click)="showDiamondDetails();" *ngFor="let diamond of diamondList; index as i">
                                                        <tr>
                                                            <td class="col-1">{{ diamond.shape }}</td>
                                                            <td class="col-1">{{ diamond.carat}}</td>
                                                            <td class="col-1">{{ diamond.color}}</td>
                                                            <td class="col-1">{{ diamond.clarity }}</td>
                                                            <td class="col-1">{{ diamond.cut}}</td>
                                                            <td class="col-1">{{ diamond.report}}</td>
                                                            <td class="col-2" rowspan='2'>{{ diamond.priceUsd }}</td>
                                                            <td class="col-2" rowspan='2'>{{ diamond.priceInr}}</td>
                                                            <td class="col-2" rowspan='2'>
                                                                <a href="#"><img src="../../../assets/img/Vision.png"></a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td class="col-1">{{ diamond.shape }}</td>
                                                            <td class="col-1">{{ diamond.carat}}</td>
                                                            <td class="col-1">{{ diamond.color}}</td>
                                                            <td class="col-1">{{ diamond.clarity }}</td>
                                                            <td class="col-1">{{ diamond.cut}}</td>
                                                            <td class="col-1">{{ diamond.report}}</td>
                                                            <!-- <td class="col-2">{{ diamond.priceUsd }}</td>
                                                            <td class="col-2">{{ diamond.priceInr}}</td>
                                                            <td class="col-2">
                                                                <a href="#"><img src="../../../assets/img/Vision.png"></a>
                                                            </td> -->
                                                        </tr>
                                                    </a>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>Recently Viewed</a>
                            <ng-template ngbNavContent>
                                <div style="height:635px;display:flex;justify-content:center;align-items:center;">
                                    Recently Viewed
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</section>