import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { DataService } from './data-service.service';
import { URLS } from '../core/_constant/api.config';

@Injectable({
    providedIn: 'root'
})

export class WishListService {
    wishListCount = 0;
    wishListData: any;
    constructor(private http: HttpClient,
        public dataService: DataService, ) { }
    private subscription: Subscription = new Subscription();

    getWishListCount(): any {
        const params = {
            wishlist_count: true
        }
        this.subscription.add(
            this.dataService.post(URLS.getWishlist, params).subscribe(
                data => {
                    if (data["code"] == 200) {
                        this.wishListCount = data["data"]['count'];
                        this.wishListData = data["data"];
                    }
                },
                error => {
                    console.log(error);
                }
            )
        );
    }

}
