import { Component, OnInit, Input } from '@angular/core';
import { OrderCartService } from './order-cart.service';
import { CommonService } from 'src/app/core/_common/common.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { ViewChild } from '@angular/core';
import { WishListService } from 'src/app/services/wishlist.service';
import { AddToCartService } from './../../services/addtocart.service';
import { orderBy, union } from 'lodash';
import { SharedHeaderService } from 'src/app/services/sharedHeader.service';
import { SharedCartService } from 'src/app/services/sharedCartHeader.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../core/_component/confirmation-dialog/confirmation-dialog.component';
@Component({
    selector: 'app-order-cart',
    templateUrl: './order-cart.component.html',
    styleUrls: ['./order-cart.component.scss'],
    providers: [OrderCartService],
})
export class OrderCartComponent implements OnInit {

    private subscription: Subscription = new Subscription();
    private cartReloadSubscription: Subscription = new Subscription();
    private cartRemoveStatus: Subscription = new Subscription();
    // RemoveEventSubscription: Subscription;

    public orderList = [];
    public currencyCode: any;
    public currencySymbol: any;
    public totalAmount: any;
    public totalConversionAmount: any;
    public totalCostConversionAmount: any;
    public requestMessage: any;
    public selectedLots: any;
    public shippingAddress: any;
    public selectedAddress: any;
    public businessShippingAdd: any;
    public businessShipindIds: any = [];
    public displayPrice: any;
    public address: any;
    public isLotBooked: boolean = false;

    //Scroll pagination 
    scrollposition: any;
    paginationScroll: boolean = false;
    isApiLoading: boolean = false;
    public filterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 30
    };

    constructor(
        private orderCartService: OrderCartService,
        public commonService: CommonService,
        public wishlistServ: WishListService,
        private router: Router,
        public loader: LoaderService,
        public notifyService: NotifyService,
        public addToCartService: AddToCartService,
        public sharedHeaderService: SharedHeaderService,
        public sharedCartService: SharedCartService,
        private modalService: NgbModal,
        public dialog: MatDialog
    ) {
        this.commonService.setEvent('SelectMenu', { key: 'Order Cart', value: 'orderCart' });
    }

    ngOnInit() {
        setTimeout(() => {
            this.notifyService.hideToast()
        }, 1000);
        this.getOrderList();

        this.cartReloadSubscription = this.sharedCartService.reloadCartAction.subscribe(data => {
            this.getOrderList();
        })

        this.cartRemoveStatus = this.addToCartService.removeCartAction.subscribe(data => {
            this.getOrderList();
        })

        this.getcreditFormShippingAddress();

    }

    getToolTipDEata(data) {
        let msg = "Select Address";
        if (data) {
            this.shippingAddress.forEach(res => {
                if (res.business_shipping_add_id == data) {
                    msg = res.address_line_1;
                }
            });
        }
        return msg;
    }



    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    //Scroll pagination 
    ngAfterViewInit() {
        this.scrollposition = 0;
    }
    onScroll(e) {
        const currentValue = this.scrollposition;
        const element = e.target;
        let pos = (element.scrollTop || element.scrollTop) + element.offsetHeight;
        let max = element.scrollHeight;
        const difference = max - pos;
        if (difference >= 0 && difference <= 20 && currentValue < element.scrollTop) {
            if (this.filterObj.enableNext) {
                this.paginationScroll = true;
                this.getOrderList(true);
                this.paginationScroll = false;
            }
        }
        this.scrollposition = element.scrollTop;
    }

    // Get order cart data
    getOrderList(isInfinteScrollCalled?: boolean) {
        this.loader.showSpinner();
        if (this.isApiLoading) {
            return;
        }
        this.isApiLoading = true;
        let params = {
            "page_record": this.filterObj.pageRecord,
        }
        // Scroll pagination
        if (this.paginationScroll) {
            params['page_no'] = this.filterObj.page
        } else {
            params['page_no'] = 1
        }
        this.subscription.add(
            this.orderCartService.getOrderCartList(params).subscribe(
                data => {
                    this.isApiLoading = false;
                    if (data["code"] == 200 && !isInfinteScrollCalled) {
                        this.orderList = data["data"]['rows'];
                        // this.isLotBooked = false;
                        // this.orderList.forEach(element => {
                        //     if(element.lot.is_booked && !this.isLotBooked){
                        //         this.isLotBooked = true;
                        //     } 
                        // });
                        this.filterObj.enableNext = data["data"]['enableNext'];
                        this.currencyCode = data["data"]['conversion_currency_code'];
                        this.currencySymbol = data["data"]['currency_symbol'];
                        this.totalAmount = data["data"]['total_amount'];
                        this.totalConversionAmount = data["data"]['total_conversion_amount'];
                        this.totalCostConversionAmount = data["data"]['total_cost_conversion_amount'];
                        this.displayPrice = data["data"]['display_price'];
                    }
                    // Scroll pagination
                    else if (data["code"] == 200 && isInfinteScrollCalled) {
                        this.orderList = orderBy(union(this.orderList, data['data']['rows']));
                        this.filterObj.enableNext = data["data"]['enableNext'];
                        this.currencyCode = data["data"]['conversion_currency_code'];
                        this.currencySymbol = data["data"]['currency_symbol'];
                        this.totalAmount = data["data"]['total_amount'];
                        this.totalConversionAmount = data["data"]['total_conversion_amount'];
                        this.totalCostConversionAmount = data["data"]['total_cost_conversion_amount'];
                        this.displayPrice = data["data"]['display_price'];
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                    this.filterObj.page++
                    this.loader.hideSpinner();

                    this.orderList.forEach(element => {
                        if (element.lot.is_wishlist) {
                            element['checkWish'] = true;
                        } else {
                            element['checkWish'] = false;
                        }
                    });
                }, error => {
                    this.loader.hideSpinner();
                    this.isApiLoading = false;
                    console.log(error);
                }
            )
        );
    }

    getcreditFormShippingAddress() {
        this.subscription.add(
            this.orderCartService.getcreditFormShippingAddress().subscribe(
                data => {
                    if (data['code'] == 200) {
                        this.shippingAddress = data['data'];
                        this.shippingAddress.forEach((element, index) => {
                            if (element.is_default && !element.is_company_business) {
                                this.selectedAddress = element.business_shipping_add_id;
                                this.address = false;
                            } else if (element.is_default && element.is_company_business) {
                                this.selectedAddress = element.company_id;
                                this.address = true;
                            }
                        });

                    }
                }
            ));
    }

    dataAddress(data) {
        this.address = data.is_company_business;
    }

    // Remove From Cart
    openDialog(diamondData) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            height: '230px',
            width: '400px',
            data: { headerName: 'Remove from cart', text: 'Are you sure to remove the item from cart?' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addToCartService.removeFromCart(diamondData);
            }
        });
    }
    // Add Data in wishlist
    addtoWish(wishData) {
        this.loader.showSpinner();
        const params = {
            "lot_id": +wishData.lot_id
        };
        this.subscription.add(
            this.orderCartService.addWishList(params).subscribe(
                data => {
                    if (data['code'] === 400 && data['message'] === "Lot is already booked by someone else.") {
                        wishData['checkWish'] = false;
                    }
                    if (data['code'] === 200) {
                        this.notifyService.showToast(data['message']);
                        wishData['checkWish'] = true;
                        wishData['lot']['is_wishlist'] = true;
                        this.wishlistServ.getWishListCount();
                        setTimeout(() => {
                            this.loader.hideSpinner();
                        }, 200);
                    } else {
                        this.notifyService.showToast(data['message']);
                        this.loader.hideSpinner();
                    }
                }, error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    //Delete Data from wishlist 
    deleteWishList(data) {
        data['checkWish'] = false;
        data['lot']['is_wishlist'] = null;
        // this.isLoading = true;
        this.loader.showSpinner();
        let params = {
            "lot_id": +data.lot_id,
        }
        this.subscription.add(
            this.orderCartService.removeWishList(params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data["code"] == 200) {
                        this.notifyService.showToast(data["message"]);
                        this.wishlistServ.getWishListCount();
                        setTimeout(() => {
                            this.loader.hideSpinner();
                        }, 200);
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                }, error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    request(data, content) {
        if (this.orderList.length < 1) {
            this.notifyService.showToast("Your cart is empty.Please add lot in your cart");
            this.router.navigate(['/whiteDiamond']);
            return;
        }
        const lotArr = [];
        data.forEach(element => {
            lotArr.push(element.lot_id);
        });
        if (this.selectedAddress) {
             var params = {
                lot_ids: lotArr,
                business_shipping_add_id: this.selectedAddress,
                is_company_business: this.address
            }
        } else {
            this.notifyService.showToast("Please Select Shipping Address!")
            return;
        }
        if (this.requestMessage) {
            params['message'] = this.requestMessage;
        }
        this.loader.showSpinner();
        this.subscription.add(
            this.orderCartService.requestOrder(params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    this.selectedLots = data;
                    console.log(this.selectedLots);
                    if (data["code"] === 200) {
                        this.wishlistServ.getWishListCount();
                        // console.log(data);
                        if (data["is_all_booked"] === true || data["is_proceed"] === true) {
                            this.modalService.open(content, { size: 'md' });
                        } else {
                            this.router.navigate(['/order-history'])
                        }
                        this.addToCartService.getOrderList().then(() => {
                            this.notifyService.showToast(data["message"]);
                        })
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                }, error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    goToDiamondDetail(diamondData) {
        let url = diamondData.paired_diamond_lot ? '/pairedDiamondDetail/' + diamondData.lot_id + '?pairedLotId=' + diamondData.paired_lot_id
            : '/diamondDetail/' + diamondData.lot_id + '?diamondType=' + diamondData.lot.diamond_type;
        window.open(`${environment.webUrl}` + url, '_blank');
    }

    proceed(data) {
        this.modalService.dismissAll();
        this.loader.showSpinner();

        const lotArr = [];
        data.forEach(element => {
            lotArr.push(element.lot_id);
        });

        let params = {
            lot_ids: lotArr,
            is_proceed: true
        }
        if (this.requestMessage) {
            params['message'] = this.requestMessage;
        }

        this.subscription.add(
            this.orderCartService.requestOrder(params).subscribe(
                data => {
                    if (data["code"] === 200) {
                        this.notifyService.showToast(data["message"]);
                        this.router.navigate(['/order-history']);
                        this.addToCartService.getOrderList();
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                    this.loader.hideSpinner();
                }, error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    bookAll(data) {
        this.modalService.dismissAll();
        this.loader.showSpinner();

        const lotArr = [];
        data.forEach(element => {
            lotArr.push(element.lot_id);
        });
        let params = {
            lot_ids: lotArr,
            is_all_booked: true
        }
        if (this.requestMessage) {
            params['message'] = this.requestMessage;
        }
        this.subscription.add(
            this.orderCartService.requestOrder(params).subscribe(
                data => {
                    if (data["code"] === 200) {
                        this.notifyService.showToast(data["message"]);
                        this.router.navigate(['/whiteDiamond']);
                        this.addToCartService.getOrderList();
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                    this.loader.hideSpinner();
                }, error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

}
