import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/core/_constant/api.config';

@Injectable({
    providedIn: 'root'
})
export class WhiteDiamondService {

    constructor(private http: HttpClient) { }

    getDiamondList(param?): Observable<{}> {
        return this.http.post(URLS.getDiamondList, param);
    }
    getrecentlyViewedList(param?): Observable<{}> {
        return this.http.post(URLS.recentlyViewed, param);
    }

    getLocation(param?): Observable<{}> {
        return this.http.get(URLS.getLocation, { params: param });
    }

    addWishList(param?): Observable<{}> {
        return this.http.post(URLS.addToWishlist, param);
    }

    removeWishList(param?): Observable<{}> {
        return this.http.delete(URLS.wishListRemoveItem, { params: param });
    }

    addToCart(param?): Observable<{}> {
        return this.http.post(URLS.addToCart, param);
    }

    getPairedDiamondList(param?): Observable<{}> {
        return this.http.post(URLS.getPairedDiamondList, param);
    }
    getRecentlyViewedPairedList(param?): Observable<{}> {
        return this.http.post(URLS.recentlyPairedViewed, param);
    }

    addRecentlyViewData(param?): Observable<{}> {
        return this.http.post(URLS.addRecentlyViewData, param); 
    }
    
}