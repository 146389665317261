import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ALTR Web';
  showHeader:boolean = false;

  constructor(private router: Router,
    private authervice: AuthenticationService,
    public cookieService: CookieService) {
      // const token = this.cookieService.get('token');
      // console.log(token);
     
      // let isLoggedIn;
      //   this.authervice.isLoggedIn().subscribe(
      //       (data) => {
      //         console.log(data);
      //           isLoggedIn = data;
      //           if(token && isLoggedIn){
      //             this.showHeader = true;
      //           }
      //           else{
      //             this.showHeader = false;
      //           }
      //       }
      //   );
       
        
     }

}
