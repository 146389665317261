import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { URLS } from 'src/app/core/_constant/api.config';
import { DataService } from 'src/app/services/data-service.service';

@Component({
    selector: 'app-manage-sell-price',
    templateUrl: './manage-sell-price.component.html',
    styleUrls: ['./manage-sell-price.component.scss'],
})
export class ManageSellPriceComponent implements OnInit {
    markupSelectArray = [];
    delete = false;
    enableadd = true;
    addMarkupArray: boolean;
    duplicateValidation;
    private subscription: Subscription = new Subscription();
    currencySymbol: any;
    limitMessage: any;
    minLimit: any;
    constructor(
        public notifyService: NotifyService,
        public loader: LoaderService,
        private dataService: DataService,
    ) {

    }

    ngOnInit() {
        this.getMarkupList();
        // this.addfirstRow();

    }

    onCancel() {
        this.getMarkupList();
        // this.addfirstRow();
    }
    addfirstRow() {
        let lengthofcol = this.markupSelectArray.length;
        if (lengthofcol != 0) {
            this.delete = true;
        }

        this.markupSelectArray = [
            {
                markup_id: 0,
                range_from: 1,
                range_to: null,
                markup: null,
            },
            ...this.markupSelectArray
        ];
        // this.i++;

    }

    
    toChange(e, index) {
        this.markupSelectArray[index - 1].range_from = +(e.target.value) + 1;
    }

    duplicateValueValidation(value: any) {
        // let arrayOfRangeFrom = value.map(element => {
        //     return element.range_from
        // })
        // let duplicateRangeFrom = arrayOfRangeFrom.some((item, index) => {
        //     return arrayOfRangeFrom.indexOf(item) !== index

        // })
        // let arrayRangeTo = value.map(element => {
        //     return element.range_to
        // })
        // let duplicateRangeTo = arrayRangeTo.some((item, index) => {
        //     return arrayRangeTo.indexOf(item) !== index

        // })
        // let arrayOfMarkup = value.map(element => {
        //     return element.markup
        // })
        // this.duplicateValidation = duplicateRangeFrom && duplicateRangeTo; //&& duplicateMarkup

        let valueExist = false;
        value.forEach((element, elementIndex) => {
            value.forEach((item, itemIndex) => {
                if (elementIndex != itemIndex) {
                    if ((element.range_from > item.range_from && element.range_from < item.range_to) ||
                        (element.range_to > item.range_from && element.range_to < item.range_to) ||
                        (element.range_from == element.range_to)) {
                        valueExist = true
                    }
                }
            })

        })

        this.duplicateValidation = valueExist;
    }


    addRow() {
        let validation;
        let formValidation;
        let toValidation;
        let duplicateValidation;
        this.markupSelectArray.forEach(element => {
            if (!element.range_from || !element.range_to || !element.markup) {
                validation = true;
            }
            else if (element.range_from < 0) {
                formValidation = true;
            }
            else if (element.range_to < element.range_from) {
                toValidation = true;
            }

        });
        this.duplicateValueValidation(this.markupSelectArray)
        if (validation === true) {
            this.notifyService.showToast("Please enter a valid margin details.")
            return;
        }
        if (formValidation === true) {
            this.notifyService.showToast("Please enter a valid range from value of margin details.")
            return;
        }
        if (toValidation === true) {
            this.notifyService.showToast("Please enter a valid range to value of margin details.")
            return;
        }
        if (this.duplicateValidation) {
            this.notifyService.showToast("Markup with same values already added.")
            return

        }
        // stop here if form is invalid
        let lengthofcol = this.markupSelectArray.length;
        if (lengthofcol != 0) {
            this.delete = true;
        }

        console.log(this.markupSelectArray);
        this.markupSelectArray = [{
            markup_id: 0,
            range_from: +(this.markupSelectArray[0].range_to) + 1,
            range_to: null,
            markup: null,
        },
        ...this.markupSelectArray]

        this.addMarkupArray = false;
    }

    deleteRow(data, index): any {
        if (this.enableadd) {
            this.markupSelectArray.splice(index, 1);
            this.markupSelectArray = [...this.markupSelectArray]

            if (this.markupSelectArray.length == 0) {
                this.markupSelectArray.push({
                    markup_id: 0,
                    range_from: 1,
                    range_to: null,
                    markup: null
                })
            }
            this.markupSelectArray[index - 1].range_from = +(this.markupSelectArray[index].range_to) + 1;
        } else {
            this.notifyService.showToast('Please enable edit for deleteing the row')
        }
    }

    omit_char(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31
            && (charCode < 48 || charCode > 57))
            return false;

        return true;
    }
    omit_charKey(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode == 45 || charCode == 48) {
            return true;
        }
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
            return false;

        return true;
    }

    getMarkupList() {
        this.loader.showSpinner();
        this.subscription.add(
            this.dataService.get(URLS.getMarkupList).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data["code"] == 200) {
                        this.markupSelectArray = data["data"];
                        this.currencySymbol = data["currency_symbol"];
                        this.limitMessage = data["message"];
                        this.minLimit = data["min_limit"];

                        // this.isLoading = false;
                        if (this.markupSelectArray.length < 1) {
                            this.addfirstRow();
                        }
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    onSubmit() {
        let validation;
        let formValidation;
        let toValidation;
        let duplicateValidation;
        this.markupSelectArray.forEach(element => {
            if (!element.range_from || !element.range_to || !element.markup) {
                validation = true;
            }
            else if (element.range_from < 0) {
                formValidation = true;
            }
            else if (element.range_to < element.range_from) {
                toValidation = true;
            }

        });
        if(this.markupSelectArray[0].range_to === null || this.markupSelectArray[0].range_to < this.markupSelectArray[0].range_from) {
            this.notifyService.showToast("Please enter valid range to value of margin details.")
            return;
        } else if(this.markupSelectArray[0].range_to < this.minLimit){
            this.notifyService.showToast(this.limitMessage);
            return;
        }
        this.duplicateValueValidation(this.markupSelectArray)
        if (validation === true) {
            this.notifyService.showToast("Please enter a valid margin details.")
            return;
        }
        if (formValidation === true) {
            this.notifyService.showToast("Please enter a valid range from value of margin details.")
            return;
        }
        if (toValidation === true) {
            this.notifyService.showToast("Please enter a valid range to value of margin details.")
            return;
        }
        if (this.duplicateValidation) {
            this.notifyService.showToast("Markup with same values already added.")
            return

        }

        const params = {
            markup: this.markupSelectArray
        }
        // this.isLoading = true;
        this.loader.showSpinner();
        this.subscription.add(
            this.dataService.post(URLS.createMarkup, params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data["code"] == 200) {
                        this.notifyService.showToast(data["message"]);
                        this.getMarkupList();
                    } else {
                        this.notifyService.showToast("Error " + data["message"]);
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }
}
