import { Component, OnInit, Inject, Input } from '@angular/core';
import { AddToCartService } from '../../../services/addtocart.service';
import { SharedHeaderService } from 'src/app/services/sharedHeader.service';
import { SharedCartService } from 'src/app/services/sharedCartHeader.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeaderComponent } from '../header/header.component';
export interface DialogData {
  // title: string;
  // message: string;
}
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    public addToCartService: AddToCartService,
    public sharedHeaderService: SharedHeaderService,
    public sharedCartService: SharedCartService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {

  }
  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
