<section class="section first-section">
    <div class="container-fluid">
        <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner>
        <!-- <ngx-spinner [fullScreen]="true" size="medium" template="<img src=''./assets/img/loader8.gif' alt='' height='80' width='80'>">
            <p class="loading">Loading...</p>
        </ngx-spinner> -->
        <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 1)" template="<img src='https://media.giphy.com/media/o8igknyuKs6aY/giphy.gif' />">
        </ngx-spinner> -->
        <div class="title">
            <div class="title-container">
                <h1 *ngIf="diamondType != 'p'">{{diamondType === 'w' ? 'White Diamond' : 'Colored Diamond'}}</h1>
                <h1 *ngIf="diamondType === 'p'">Matching Pairs</h1>
            </div>
        </div>
        <app-filter [diamondType]="diamondType" (filterChange)="onFilterChangeHandler()" #filterData></app-filter>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-3 diamond-inspect-section" *ngIf="!comparisonTable && diamondType != 'p'">
                <div class="diamond-wrap mb-3">
                    <div *ngIf="showDiamondDetail">
                        <div class="diamond">
                            <img *ngIf="diamondViewList.image_path" [src]="diamondViewList.image_path" style="margin: 20px 0px 0px 0px;">
                            <!-- <img *ngIf="!diamondViewList.image_path" src="./assets/img/defaultDiamond.png" style="padding: 25px;"> -->
                            <div (click)="showDetailImage(detailContent, diamondViewList)"><a>View Image ></a></div>
                        </div>
                        <div class="links">
                            <a (click)="showDetailVideo(detailContent, diamondViewList)">View Video ></a>
                            <a (click)="showClientModal(diamondViewList)" class="pointerClass">Show Client ></a>
                        </div>
                        <div class="values">
                            <div class="value-wrap">
                                <label>Stock ID :</label>
                                <!-- <p>{{diamondViewList.altr_stone_id}}</p> -->
                            </div>
                            <div class="value-wrap stock-ID">
                                <p class="stock-ID">{{diamondViewList.altr_stone_id ? diamondViewList.altr_stone_id :
                                    '-'}}</p>
                                <!-- <label>Length :</label> -->
                                <!-- <p>{{diamondViewList.length}}</p> -->
                            </div>
                            <div class="value-wrap">
                                <label>Measurement :</label>
                                <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name}}</p>
                                    <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.polish.polish_name}}</p> -->
                            </div>
                            <div class="value-wrap pr-0 pl-0">
                                <!-- <label>Width :</label> -->
                                <p class="measurementLabel">{{diamondViewList.measurement ? diamondViewList.measurement
                                    : '-'}}</p>
                                <!-- <p>{{diamondViewList.width}}</p> -->
                            </div>
                            <div *ngIf="diamondType == 'w'" class="value-wrap">
                                <label>Cut :</label>
                                <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name}}</p>
                                        <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.polish.polish_name}}</p> -->
                            </div>
                            <div *ngIf="diamondType == 'w'" class="value-wrap">
                                <!-- <label>Width :</label> -->
                                <p *ngIf="!recentlyViewedClick">{{diamondViewList.cut?.cut_name ?
                                    diamondViewList.cut?.cut_name : '-'}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot.cut.cut_name ?
                                    diamondViewList.lot.cut.cut_name : '-'}}</p>
                            </div>
                            <div *ngIf="diamondType == 'c'" class="value-wrap">
                                <label>Intensity :</label>
                                <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name}}</p>
                                            <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.polish.polish_name}}</p> -->
                            </div>
                            <div *ngIf="diamondType == 'c'" class="value-wrap">
                                <!-- <label>Width :</label> -->
                                <p *ngIf="!recentlyViewedClick">{{diamondViewList.intensity?.intensity_name ?
                                    diamondViewList.intensity?.intensity_name : '-'}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot.intensity.intensity_name ?
                                    diamondViewList.lot.intensity.intensity_name : '-'}}</p>
                            </div>
                            <div class="value-wrap">
                                <label>Polish :</label>
                                <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.polish.polish_name}}</p> -->
                            </div>
                            <div class="value-wrap">
                                <!-- <label>Width :</label> -->
                                <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name ?
                                    diamondViewList.polish?.polish_name : '-'}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot.polish.polish_name ?
                                    diamondViewList.lot.polish.polish_name: '-' }}</p>
                                <!-- <p>{{diamondViewList.width}}</p> -->
                            </div>
                            <div class="value-wrap">
                                <label>Symmetry :</label>
                                <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.symmetry?.symmetry_name}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.symmetry.symmetry_name}}</p> -->
                            </div>
                            <div class="value-wrap">
                                <p *ngIf="!recentlyViewedClick">{{diamondViewList.symmetry?.symmetry_name ?
                                    diamondViewList.symmetry?.symmetry_name : '-'}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot.symmetry.symmetry_name ?
                                    diamondViewList.lot.symmetry.symmetry_name : '-'}}</p>
                                <!-- <label>Hearts & Arrow :</label> -->
                                <!-- <p>{{diamondViewList.heart_n_arrow === false ? 'No' : 'Yes'}}</p> -->
                            </div>
                            <div class="value-wrap">
                                <label>Hearts & Arrow :</label>
                                <!-- <p>{{diamondViewList.depth_percentage ? diamondViewList.depth_percentage : '-'}}%</p> -->
                            </div>
                            <div class="value-wrap">
                                <p>{{diamondViewList.heart_n_arrow === false ? 'No' : 'Yes'}}</p>
                                <!-- <label>3X :</label> -->
                                <!-- <p>{{diamondViewList['3x'] === false ? 'No' : 'Yes'}}</p> -->
                            </div>
                            <!-- <div class="value-wrap">
                                <label>3X :</label> -->
                            <!-- <p>{{diamondViewList.depth_percentage ? diamondViewList.depth_percentage : '-'}}%</p> -->
                            <!-- </div>
                            <div class="value-wrap">
                                <p>{{diamondViewList['3x'] === false ? 'No' : 'Yes'}}</p> -->
                            <!-- <label>3X :</label> -->
                            <!-- <p>{{diamondViewList['3x'] === false ? 'No' : 'Yes'}}</p> -->
                            <!-- </div> -->
                            <div class="value-wrap">
                                <label>Depth :</label>
                                <!-- <p>{{diamondViewList.depth_percentage ? diamondViewList.depth_percentage : '-'}}%</p> -->
                            </div>
                            <div class="value-wrap">
                                <p>{{diamondViewList.depth_percentage ? diamondViewList.depth_percentage : '-'}}%</p>
                                <!-- <label>3X :</label> -->
                                <!-- <p>{{diamondViewList['3x'] === false ? 'No' : 'Yes'}}</p> -->
                            </div>
                            <div class="value-wrap">
                                <label>Table :</label>
                                <!-- <p>{{diamondViewList.table_percentage ? diamondViewList.table_percentage : '-'}}%</p> -->
                            </div>
                            <div class="value-wrap">
                                <p>{{diamondViewList.table_percentage ? diamondViewList.table_percentage : '-'}}%</p>
                                <!-- <label>Tinge :</label> -->
                                <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.master_tinge?.tinge_name}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.master_tinge.tinge_name}}</p> -->
                            </div>
                            <!-- <div class="value-wrap">
                                <label>Tinge :</label> -->
                            <!-- <p>{{diamondViewList.table_percentage ? diamondViewList.table_percentage : '-'}}%</p> -->
                            <!-- </div> -->
                            <!-- <div class="value-wrap">
                                <p *ngIf="!recentlyViewedClick">{{diamondViewList.master_tinge ?
                                    diamondViewList.master_tinge.tinge_name : '-'}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot.master_tinge ?
                                    diamondViewList.lot.master_tinge.tinge_name : '-'}}</p> -->
                            <!-- <label>Tinge :</label> -->
                            <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.master_tinge?.tinge_name}}</p>
                                    <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.master_tinge.tinge_name}}</p> -->
                            <!-- </div> -->
                            <!-- <div class="value-wrap">
                                <label>AGS000 :</label>
                                <p>{{diamondViewList.ags000 === false ? 'No' : 'Yes'}}</p>
                            </div> -->
                            <div class="value-wrap">
                                <label>Ratio :</label>
                                <!-- <p>{{diamondViewList.ratio ? diamondViewList.ratio : '-'}}</p> -->
                            </div>
                            <div class="value-wrap">
                                <p>{{diamondViewList.ratio ? diamondViewList.ratio : '-'}}</p>
                            </div>
                            <!-- <div class="value-wrap" *ngIf="!this.diamondViewList.ags000">
                                <label>GCAL :</label> -->
                            <!-- <p>{{diamondViewList.gcal_8x === false ? 'No' : 'Yes'}}</p> -->
                            <!-- </div> -->
                            <!-- <div class="value-wrap" *ngIf="!this.diamondViewList.ags000">
                                <p>{{diamondViewList.gcal_8x === false ? 'No' : 'Yes'}}</p>
                            </div> -->
                            <!-- <div class="value-wrap" *ngIf="this.diamondViewList.ags000">
                                <label>AGS :</label> -->
                            <!-- <p>{{diamondViewList.ags000 === false ? 'No' : 'Yes'}}</p> -->
                            <!-- </div>
                            <div class="value-wrap" *ngIf="this.diamondViewList.ags000">
                                <p>{{diamondViewList.ags000 === false ? 'No' : 'Yes'}}</p>
                            </div> -->
                        </div>
                        <div class="card">
                            <button type="button" class="btn btn-outline" (click)="AddToCart(diamondViewList)">{{
                                getLabelForCart(diamondViewList)}}</button>
                        </div>
                    </div>
                    <div class="default-diamond" *ngIf="!showDiamondDetail" (click)="showAllDiamondsTab()">
                        <img src="assets/img/defaultDiamond.png">
                        <p>Select a diamond in the table to view more information</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4 diamond-inspect-section" *ngIf="diamondType === 'p'">
                <div class="pairedDiamond-wrap mb-3">
                    <div *ngIf="showDiamondDetail">
                        <div class="row ml-0 mr-0" style="background: #F9F9F9;padding-top: 31px;">
                            <div class="col-4"></div>
                            <div class="col-4 diamond">
                                <img [src]="diamondViewList.lot.image_path ? diamondViewList.lot.image_path : '../../../assets/img/diamond.png'">
                            </div>
                            <div class="col-4 diamond">
                                <img [src]="diamondViewList.paired_diamond_lot.image_path ? diamondViewList.paired_diamond_lot.image_path : '../../../assets/img/diamond.png'">
                            </div>
                        </div>
                        <div class="blankLinks row ml-0 mr-0" style="border: none;">
                            <div class="col-4">
                                <!-- <a (click)="showClientModal(diamondViewList)" class="pointerClass">Show Client ></a> -->
                            </div>
                            <div class="col-4">
                            </div>
                            <div class="col-4">
                            </div>
                        </div>
                        <div class="links row ml-0 mr-0">
                            <div class="col-4">
                                <!-- <a (click)="showClientModal(diamondViewList)" class="pointerClass">Show Client ></a> -->
                            </div>
                            <div class="col-4">
                                <a (click)="showPairedVideo(pairedContent, diamondViewList)">View Video ></a>
                            </div>
                            <div class="col-4">
                                <a (click)="showPairedImage(pairedContent, diamondViewList)">View Images ></a>
                            </div>
                        </div>
                        <div class="blankLinks row ml-0 mr-0">
                            <div class="col-4">
                                <!-- <a (click)="showClientModal(diamondViewList)" class="pointerClass">Show Client ></a> -->
                            </div>
                            <div class="col-4">
                            </div>
                            <div class="col-4">
                            </div>
                        </div>
                        <div class="values">
                            <div class="row">
                                <div class="col-4 label">Stock ID :</div>
                                <div class="col-4 label-content stock-ID">{{diamondViewList.lot?.altr_stone_id ?
                                    diamondViewList.lot?.altr_stone_id : '-'}}</div>
                                <div class="col-4 label-content stock-ID">{{diamondViewList.paired_diamond_lot?.altr_stone_id
                                    ? diamondViewList.paired_diamond_lot?.altr_stone_id : '-'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Measurement :</div>
                                <div class="col-4 label-content pl-0 pr-0 measurementLabel">{{diamondViewList.lot?.measurement
                                    ?
                                    diamondViewList.lot?.measurement : '-'}}</div>
                                <div class="col-4 label-content pl-0 pr-0 measurementLabel">{{diamondViewList.paired_diamond_lot?.measurement
                                    ? diamondViewList.paired_diamond_lot?.measurement : '-'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Cut :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot.cut?.cut_name ?
                                    diamondViewList.lot.cut?.cut_name : '-'}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot.paired_cut?.cut_name
                                    ?
                                    diamondViewList.paired_diamond_lot.paired_cut?.cut_name : '-'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Polish :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot?.polish.polish_name ?
                                    diamondViewList.lot?.polish.polish_name : '-'}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot?.paired_polish.polish_name
                                    ? diamondViewList.paired_diamond_lot?.paired_polish.polish_name : '-'}}</div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-4 label">Width :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot.width}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot.width}}</div>
                            </div> -->
                            <div class="row">
                                <div class="col-4 label">Symmetry :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot.symmetry?.symmetry_name ?
                                    diamondViewList.lot.symmetry?.symmetry_name : '-'}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot.paired_symmetry?.symmetry_name
                                    ? diamondViewList.paired_diamond_lot.paired_symmetry?.symmetry_name : '-'}}</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Hearts & Arrow :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot.heart_n_arrow === true ? 'Yes' :
                                    'No'}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot.heart_n_arrow ===
                                    true ? 'Yes' : 'No'}}</div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-4 label">3X :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot['3x'] === true ? 'Yes' : 'No'}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot['3x'] === true ?
                                    'Yes' : 'No'}}</div>
                            </div> -->
                            <div class="row">
                                <div class="col-4 label">Depth :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot?.depth_percentage ?
                                    diamondViewList.lot?.depth_percentage : '-'}}%</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot?.depth_percentage
                                    ? diamondViewList.paired_diamond_lot?.depth_percentage : '-'}}%</div>
                            </div>
                            <div class="row">
                                <div class="col-4 label">Table :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot?.table_percentage ?
                                    diamondViewList.lot?.table_percentage : '-'}}%</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot?.table_percentage
                                    ? diamondViewList.paired_diamond_lot?.table_percentage : '-'}}%</div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-4 label">Tinge :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot.master_tinge ?
                                    diamondViewList.lot.master_tinge.tinge_name : '-'}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot.paired_tinge ?
                                    diamondViewList.paired_diamond_lot.paired_tinge.tinge_name : '-'}}</div>
                            </div> -->
                            <div class="row ratioRow">
                                <div class="col-4 label">Ratio :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot.ratio}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot.ratio}}</div>
                            </div>
                            <!-- <div class="row" *ngIf="!this.diamondViewList.lot.ags000">
                                <div class="col-4 label">GCAL :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot.gcal_8x === true ? 'Yes' :
                                    'No'}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot.gcal_8x === true
                                    ? 'Yes' : 'No'}}</div>
                            </div>
                            <div class="row" *ngIf="this.diamondViewList.lot.ags000">
                                <div class="col-4 label">AGS :</div>
                                <div class="col-4 label-content">{{diamondViewList.lot.ags000 === true ? 'Yes' : 'No'}}</div>
                                <div class="col-4 label-content">{{diamondViewList.paired_diamond_lot.ags000 === true ?
                                    'Yes' : 'No'}}</div>
                            </div> -->
                        </div>
                        <div class="card" *ngIf="displayPrice === 'c'">
                            <div class="row">
                                <div class="col-3 label priceLabel">Total Price :</div>
                                <div class="col-4 price">{{diamondViewList.currency_symbol}}{{diamondViewList.cost_conversion_amount
                                    | number}}
                                </div>
                                <div class="col-5 px-0">
                                    <button type="button" class="btn btn-outline" (click)="AddToCart(diamondViewList)">{{getLabelForCart(diamondViewList)}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="card" *ngIf="displayPrice === 's' || displayPrice === 'b'">
                            <div class="row">
                                <div class="col-3 label priceLabel">Total Price :</div>
                                <div class="col-4 price">{{diamondViewList.currency_symbol}}{{diamondViewList.conversion_amount
                                    | number}}
                                </div>
                                <div class="col-5 px-0">
                                    <button type="button" class="btn btn-outline" (click)="AddToCart(diamondViewList)">{{getLabelForCart(diamondViewList)}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="default-diamond" *ngIf="!showDiamondDetail">
                        <img src="../../../assets/img/defaultDiamond.png">
                        <p>Select a diamond in the table to view more information</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12" [ngClass]="[comparisonTable === true ? 'col-lg-12' : 'col-lg-8', diamondType === 'p' ? 'col-lg-8' : 'col-lg-9']">
                <div class="list mb-3">
                    <ul ngbNav #nav="ngbNav" (navChange)="navChange()" [(activeId)]="active" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink (click)="allDiamondsFlag();">All Diamonds</a>
                            <ng-template ngbNavContent>
                                <div class="diamond-tab">
                                    <div class="header-strip">
                                        <div class="results">
                                            <p *ngIf="diamondType == 'w' || diamondType == 'c'">Showing <span>
                                                    {{diamondList.length}} of {{totalRecords}} </span> Results</p>
                                            <p *ngIf="diamondType == 'p'">Showing <span> {{pairedDiamondList.length}}
                                                    of {{totalPairedRecords}} </span> Results</p>
                                        </div>
                                        <div class="right-header-strip mat-select-custom">
                                            <div class="location">
                                                <label for="location">Location :</label>
                                                <mat-form-field appearance="fill">
                                                    <mat-select value="0" multiple (selectionChange)="locationChange($event)"
                                                        placeholder="Select Location" [(value)]="selectedLocation">
                                                        <!-- <mat-option value="0" selected>Select Location</mat-option> -->
                                                        <mat-option *ngFor="let location of locationList" [value]="location.lot_location_id">
                                                            {{location.lot_location_name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="available">
                                                <label for="availability">Availability :</label>
                                                <mat-form-field appearance="fill">
                                                    <mat-select [(ngModel)]="selectedAvailability" (selectionChange)="availabilityChange($event)">
                                                        <mat-option *ngFor="let Availability of Availabilities" [value]="Availability.value">
                                                            {{Availability.viewValue}}
                                                            <span *ngIf="Availability.value == 'Next Business Day'" class="shape-active"></span>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <ul *ngIf="diamondType != 'p'">
                                                <li>
                                                    <button class="btn list active" (click)="listGridBtnClick($event);"><i
                                                            class="fa fa-list-ul" aria-hidden="true"></i></button>
                                                </li>
                                                <li>
                                                    <button class="btn grid" (click)="listGridBtnClick($event);"><i
                                                            class="fa fa-th-large" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="list-view" *ngIf="viewList">
                                        <div>
                                            <table class="table table-striped table-fixed mb-0">
                                                <thead>
                                                    <tr *ngIf="diamondType == 'w' || diamondType == 'p'">
                                                        <!-- "[data.is_two ? 'col-2' : 'col-1', diamondType === 'p' && !data.is_price ? 'pointerNone' : '']" -->
                                                        <ng-container *ngIf="displayPrice === 'c'">
                                                            <th *ngFor="let data of diamondColum" scope="col" [ngClass]="[data.key == data.value ? data.key:data.value ,
                                                            diamondType === 'p' && data.key === 'Shape' ? 'shapeHead' : '',
                                                            diamondType === 'p' && data.key === 'Carat' ? 'caratHead' : '',
                                                            diamondType === 'p' && data.key === 'Color' ? 'colorHead' : '',
                                                            diamondType === 'p' && data.key === 'Clarity' ? 'clarityHead' : '',
                                                            diamondType === 'p' && data.key === 'Cut' ? 'cutHead' : '',
                                                            diamondType === 'p' && data.key === 'Cost' ? 'CostHead' : '',
                                                            diamondType === 'p' && data.key === 'Retail' ? 'retailHead' : '']">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price || data.is_total_carat">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'"
                                                                [ngStyle]="{'padding-right' : diamondType == 'p' ? '0px' : '30px'}">Actions</th>
                                                        </ng-container>
                                                        <ng-container *ngIf="displayPrice === 's'">
                                                            <th *ngFor="let data of diamondColumOne" scope="col"
                                                                [ngClass]="[data.key == data.value ? data.key:data.value ,
                                                                diamondType === 'p' && data.key === 'Shape' ? 'shapeHead' : '',
                                                                diamondType === 'p' && data.key === 'Carat' ? 'caratHead' : '',
                                                                diamondType === 'p' && data.key === 'Color' ? 'colorHead' : '',
                                                                diamondType === 'p' && data.key === 'Clarity' ? 'clarityHead' : '',
                                                                diamondType === 'p' && data.key === 'Cut' ? 'cutHead' : '',
                                                                diamondType === 'p' && data.key === 'Cost' ? 'CostHead' : '',
                                                                diamondType === 'p' && data.key === 'Retail' ? 'retailHead' : '']">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price || data.is_total_carat">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'"
                                                                [ngStyle]="{'padding-right' : diamondType == 'p' ? '0px' : '30px'}">Actions</th>
                                                        </ng-container>
                                                        <ng-container *ngIf="displayPrice === 'b'">
                                                            <th *ngFor="let data of diamondColumTwo" scope="col"
                                                                [ngClass]="[data.key == data.value ? data.key:data.value ,
                                                                            diamondType === 'p' && data.key === 'Shape' ? 'shapeHead' : '',
                                                                            diamondType === 'p' && data.key === 'Carat' ? 'caratHead' : '',
                                                                            diamondType === 'p' && data.key === 'Color' ? 'colorHead' : '',
                                                                            diamondType === 'p' && data.key === 'Clarity' ? 'clarityHead' : '',
                                                                            diamondType === 'p' && data.key === 'Cut' ? 'cutHead' : '',
                                                                            diamondType === 'p' && data.key === 'Cost' ? 'CostHead' : '',
                                                                            diamondType === 'p' && data.key === 'Retail' ? 'retailHead' : '']">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price || data.is_total_carat">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'"
                                                                [ngStyle]="{'padding-right' : diamondType == 'p' ? '0px' : '30px'}">Actions</th>
                                                        </ng-container>
                                                        <!-- <th scope="col" class="pairedDiamondAction text-center " *ngIf="diamondType === 'p'">
                                                            Actions</th> -->
                                                    </tr>
                                                    <tr *ngIf="diamondType == 'c'">
                                                        <ng-container *ngIf="displayPrice === 'c'">
                                                            <th *ngFor="let data of colorDiamondColum" scope="col"
                                                                [ngClass]="data.key == data.value ? data.key:data.value">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'">Actions</th>
                                                        </ng-container>
                                                        <ng-container *ngIf="displayPrice === 's'">
                                                            <th *ngFor="let data of colorDiamondColumOne" scope="col"
                                                                [ngClass]="data.key == data.value ? data.key:data.value"
                                                                (click)="diamondSort(data)">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span class="sort-wrap" *ngIf="diamondType != 'p' || data.is_price">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'">Actions</th>
                                                        </ng-container>
                                                        <ng-container *ngIf="displayPrice === 'b'">
                                                            <th *ngFor="let data of colorDiamondColumTwo" scope="col"
                                                                [ngClass]="data.key == data.value ? data.key : data.value"
                                                                (click)="diamondSort(data)">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span class="sort-wrap" *ngIf="diamondType != 'p' || data.is_price">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="Action text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'">Actions</th>
                                                        </ng-container>
                                                    </tr>
                                                </thead>
                                                <tbody (scroll)="onScroll($event)" class="listBody thikScroll" *ngIf="diamondType == 'w' || diamondType == 'c'" id="diamondListBody">
                                                    <a *ngFor="let diamond of diamondList; index as i" (click)="showDiamondDetails(diamond);allDiamond();">
                                                        <tr (click)="addDataToRecentlyViewed(diamond, ('diamondRow' + (i+1)))" [ngClass]="[diamondViewList.lot_id === diamond.lot_id && showDiamondDetail ? 'active': '', ]"
                                                            style="border-bottom: 1px solid #e5e5e5;" id="{{ 'diamondRow' + (1+i)}}">
                                                            <td class="Shape">{{ diamond.master_shape?.shape_name }}
                                                                <span *ngIf="diamond.availability === 'Next Business Day'" class="shape-active"></span>
                                                            </td>
                                                            <td class="Carat">{{ diamond.weight}}</td>
                                                            <td class="Color">{{ diamond.master_color ?
                                                                diamond.master_color.color_name : '-'}}</td>
                                                            <td *ngIf="diamondType == 'c'" class="Intensity intensityData d-flex justify-content-start">{{
                                                                diamond.intensity ? diamond.intensity.intensity_name :
                                                                '-'}}
                                                                <!-- <span class="pl-1">
                                                                            <img *ngIf="diamond.heart_n_arrow" style="width: 13px;position: relative;bottom:1px;"
                                                                                src="../../../assets/img/svg/heart&arrow.svg">
                                                                        </span> -->
                                                            </td>
                                                            <td class="Clarity" [ngStyle]="{'padding-left': diamondType === 'c' ? '12px' : '5px'}">{{
                                                                diamond.master_clarity?.clarity_name
                                                                }}
                                                            </td>
                                                            <td *ngIf="diamondType == 'w'" class="Cut d-flex justify-content-start">{{
                                                                diamond.cut ? diamond.cut.cut_name : '-'}}
                                                                <span class="pl-1">
                                                                    <img *ngIf="diamond.heart_n_arrow" style="width: 13px;position: relative;bottom:1px;"
                                                                        src="../../../assets/img/svg/heart&arrow.svg">
                                                                </span>
                                                            </td>
                                                            <td *ngIf="diamondType == 'w'" class="labData d-flex align-items-center">
                                                                <div class="pl-1" style="position:relative;top: 1px;">
                                                                    <span class="tooltip action">
                                                                        <a [href]="diamond.grading_link ? diamond.grading_link : '#'"
                                                                            target="_blank" class="gradingLink">{{diamond.master_lab?.lab_name}}</a>
                                                                        <span class="tooltiptext">View Certificate</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td *ngIf="diamondType == 'c'" class="labData d-flex align-items-center">
                                                                <div class="pl-1" style="position:relative;top: 1px;">
                                                                    <span class="tooltip action">
                                                                        <a [href]="diamond.grading_link ? diamond.grading_link : '#'"
                                                                            target="_blank" class="gradingLink">{{diamond.master_lab?.lab_name}}</a>
                                                                        <span class="tooltiptext">View Certificate</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <!-- <td class="col-2">${{ diamond.total_amount }}</td> -->
                                                            <td *ngIf="displayPrice === 'c' || displayPrice === 'b'"
                                                                class="Price pr-xxl-2 pr-xl-2 pr-lg-2 pr-md-2">
                                                                {{currencySymbol}}{{ diamond.cost_conversion_amount |
                                                                number}}
                                                            </td>
                                                            <td *ngIf="displayPrice === 's' || displayPrice === 'b'"
                                                                class="Price pr-xxl-2 pr-xl-2 pr-lg-2 pr-md-2">
                                                                {{currencySymbol}}{{ diamond.conversion_amount |
                                                                number}}
                                                            </td>
                                                            <td [ngClass]="displayPrice === 'b' ? 'Action' : ''" style="padding: 0px 0px;" (click)="preventDefaultAction($event)">
                                                                <p *ngIf="!getCompareStatus(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/CompareWhite.svg"
                                                                            style="width: 27px;height: 27px;position: relative;top: 3px;"
                                                                            (click)="addDiamondToCompare(diamond,('diamondRow' + (i+1)))">
                                                                        <span class="tooltiptext">Add to Compare</span>
                                                                    </span>
                                                                </p>
                                                                <p *ngIf="getCompareStatus(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/CompareBlack.svg"
                                                                            style="width: 27px;height: 27px;position: relative;top: 3px;"
                                                                            (click)="removeDiamondFromCompare(diamond)">
                                                                        <span class="tooltiptext">Remove from Compare</span>
                                                                    </span>
                                                                </p>
                                                                <p *ngIf="!getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="./assets/img/svg/bag.svg" class="iconSize"
                                                                            style="width: 12px;">
                                                                        <span class="tooltiptext">Add to Cart</span>
                                                                    </span>
                                                                </p>
                                                                <p *ngIf="getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="./assets/img/svg/bag-fill.svg" class="iconSize"
                                                                            style="width: 15px;">
                                                                        <span class="tooltiptext">Remove from Cart</span>
                                                                    </span>
                                                                </p>
                                                                <p (click)="addtoWish(diamond, i)" *ngIf="diamond.checkWish === false">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/svg/heart.svg">
                                                                        <span class="tooltiptext">Add to Wishlist</span>
                                                                    </span>
                                                                </p>
                                                                <p (click)="deleteWishList(diamond, i)" *ngIf="diamond.checkWish === true">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/svg/heartfill.svg">
                                                                        <span class="tooltiptext">Remove from Wishlist</span>
                                                                    </span>
                                                                </p>
                                                                <p (click)="goToDiamondDetail(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/svg/vision.svg">
                                                                        <span class="tooltiptext">Diamond Detail</span>
                                                                    </span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </a>
                                                    <tr *ngIf="diamondList.length <= 0" class="d-flex justify-content-center">
                                                        <p> No Records Found </p>
                                                    </tr>
                                                </tbody>
                                                <tbody class="pairedListBody thikScroll" (scroll)="onScroll($event)"
                                                    *ngIf="diamondType === 'p'">
                                                    <a (click)="showDiamondDetails(diamond);addDataToRecentlyViewed(diamond, ('diamondRow' + (i+1)));"
                                                        *ngFor="let diamond of pairedDiamondList; index as i" [ngClass]="diamondViewList.lot_id === diamond.lot_id && diamondViewList.paired_lot_id === diamond.paired_lot_id && showDiamondDetail ? 'active': ''"
                                                        style=" border-bottom: 1px solid #e5e5e5;" id="{{ 'diamondRow' + (1+i)}}">
                                                        <tr>
                                                            <td class="pairedDiamondShape">{{
                                                                diamond.lot.master_shape.shape_name }}
                                                                <span *ngIf="diamond.lot.availability === 'Next Business Day'"
                                                                    class="shape-active"></span>
                                                            </td>
                                                            <td class="pairedDiamondCarat">{{ diamond.lot.weight}}</td>
                                                            <td class="pairedDiamondCarat Total">{{
                                                                diamond.total_carat}}</td>
                                                            <td class="pairedDiamondColor">{{ diamond.lot.master_color
                                                                ? diamond.lot.master_color.color_name : '-'}}</td>
                                                            <td class="pairedDiamondClarity">{{
                                                                diamond.lot.master_clarity.clarity_name }}
                                                            </td>
                                                            <td class="pairedDiamondCut d-flex justify-content-start">{{
                                                                diamond.lot.cut ? diamond.lot.cut.cut_name : '-'}}
                                                                <span class="pl-1">
                                                                    <img *ngIf="diamond.lot.heart_n_arrow" style="width: 13px;position: relative;bottom:1px;"
                                                                        src="../../../assets/img/svg/heart&arrow.svg">
                                                                </span>
                                                            </td>
                                                            <td class="pairedDiamondLab d-flex justify-content-start align-items-center">
                                                                <div style="position:relative;top: 1px;">
                                                                    <span class="tooltip action">
                                                                        <a [href]="diamond.lot.grading_link ? diamond.lot.grading_link : '#'"
                                                                            target="_blank" class="gradingLink">{{diamond.lot.lab_name}}</a>
                                                                        <span class="tooltiptext">View Certificate</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <!-- <td class="col-2" rowspan="2">${{ diamond.total_amount }}
                                                            </td> -->
                                                            <td *ngIf="displayPrice === 'c' || displayPrice === 'b'"
                                                                class="pairedDiamondPrice pr-xxl-2 pr-xl-2 pr-lg-2 pr-md-2"
                                                                rowspan="
                                                                2"
                                                                style="min-width: 14%;max-width: 14%;">
                                                                {{currencySymbol}}{{ diamond.cost_conversion_amount |
                                                                number}}
                                                            </td>
                                                            <td *ngIf="displayPrice === 's' || displayPrice === 'b'"
                                                                class="pairedDiamondPrice pr-xxl-2 pr-xl-2 pr-lg-2 pr-md-2"
                                                                rowspan="
                                                                2"
                                                                style="min-width: 15%;max-width: 15%;">
                                                                {{currencySymbol}}{{ diamond.conversion_amount |
                                                                number}}
                                                            </td>
                                                            <td class="pairedDiamondAction justify-content-center align-items-center"
                                                                style="padding-top: 8px 0;" rowspan="2" [ngStyle]="{'padding-left': displayPrice === 'c' || displayPrice === 's' ? '15px' : '10px'}" (click)="preventDefaultAction($event)">
                                                                <p [ngClass]="displayPrice === 'c' || displayPrice === 's' ? 'pr-3' : 'pr-2 actionPad'"
                                                                    *ngIf="!getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="./assets/img/svg/bag.svg" class="iconSize"
                                                                            style="width: 12px;">
                                                                        <span class="tooltiptext">Add to Cart</span>
                                                                    </span>
                                                                </p>
                                                                <p [ngClass]="displayPrice === 'c' || displayPrice === 's' ? 'pr-3' : 'pr-2'"
                                                                    *ngIf="getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="./assets/img/svg/bag-fill.svg" class="iconSize"
                                                                            style="width: 15px;">
                                                                        <span class="tooltiptext">Remove from Cart</span>
                                                                    </span>
                                                                </p>
                                                                <p (click)="goToDiamondDetail(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/svg/vision.svg">
                                                                        <span class="tooltiptext">Diamond Detail</span>
                                                                    </span>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr class="secTr">
                                                            <td class="pairedDiamondShape">{{
                                                                diamond.paired_diamond_lot.paired_shape.shape_name }}
                                                                <span *ngIf="diamond.paired_diamond_lot.availability === 'Next Business Day'"
                                                                    class="shape-active"></span>
                                                            </td>
                                                            <td class="pairedDiamondCarat">{{
                                                                diamond.paired_diamond_lot.weight}}
                                                            </td>
                                                            <td class="pairedDiamondCarat Total">
                                                            </td>
                                                            <td class="pairedDiamondColor">{{
                                                                diamond.paired_diamond_lot.paired_color ?
                                                                diamond.paired_diamond_lot.paired_color.color_name :
                                                                '-'}}
                                                            </td>
                                                            <td class="pairedDiamondClarity">{{
                                                                diamond.paired_diamond_lot.paired_clarity.clarity_name
                                                                }}
                                                            </td>
                                                            <td class="pairedDiamondCut d-flex justify-content-start">{{
                                                                diamond.paired_diamond_lot.paired_cut ?
                                                                diamond.paired_diamond_lot.paired_cut.cut_name : '-'}}
                                                                <span class="pl-1">
                                                                    <img *ngIf="diamond.paired_diamond_lot.heart_n_arrow"
                                                                        style="width: 13px;position: relative;bottom:1px;"
                                                                        src="../../../assets/img/svg/heart&arrow.svg">
                                                                </span>
                                                            </td>
                                                            <td class="pairedDiamondLab d-flex justify-content-start align-items-center">
                                                                <div style="position:relative;top: 1px;">
                                                                    <span class="tooltip action">
                                                                        <a [href]="diamond.paired_diamond_lot.grading_link ? diamond.paired_diamond_lot.grading_link : '#'"
                                                                            target="_blank" class="gradingLink">{{diamond.paired_diamond_lot.lab_name}}</a>
                                                                        <span class="tooltiptext">View Certificate</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </a>
                                                    <tr *ngIf="pairedDiamondList.length <= 0" class="d-flex justify-content-center">
                                                        <p> No Records Found </p>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="grid-view" *ngIf="viewGrid" (scroll)="onScroll($event)">
                                        <div class="diamond-box pt-3" (click)="showDiamondDetails(diamond);allDiamond();addDataToRecentlyViewed(diamond);"
                                            *ngFor="let diamond of diamondList" [ngClass]="diamondViewList.lot_id === diamond.lot_id && showDiamondDetail? 'active': ''">
                                            <div class="detail">
                                                <img *ngIf="diamond.heart_n_arrow" class="heartNarrow" src="../../../assets/img/svg/heart&arrow.svg">
                                                <img *ngIf="diamond.image_path" class="mb-2" [src]="diamond.image_path">
                                                <img *ngIf="!diamond.image_path" class="mb-2" src="./assets/img/defaultDiamond.png"
                                                    style="padding: 25px;">
                                                <div class="d-flex justify-content-center">
                                                    <div>
                                                        <p *ngIf="diamondType == 'w'">{{diamond.master_shape?.shape_name ? diamond.master_shape?.shape_name : '-'}}
                                                            {{diamond.weight ? diamond.weight : '-'}}
                                                            {{diamond.master_color?.color_name ? diamond.master_color?.color_name : '-'}}
                                                            {{diamond.master_clarity?.clarity_name ? diamond.master_clarity?.clarity_name : '-'}} {{ diamond.cut?.cut_name }} {{diamond.master_lab?.lab_name
                                                            ?
                                                            diamond.master_lab.lab_name : '-'}}</p>
                                                        <p *ngIf="diamondType == 'c'">{{diamond.master_shape?.shape_name ? diamond.master_shape?.shape_name : '-'}}
                                                            {{diamond.weight ? diamond.weight : '-'}}
                                                            {{diamond.master_color?.color_name ? diamond.master_color?.color_name : '-'}}
                                                            {{diamond.intensity?.intensity_name
                                                            ?
                                                            diamond.intensity?.intensity_name :
                                                            '-'}}
                                                            {{diamond.master_clarity?.clarity_name ? diamond.master_clarity?.clarity_name : '-'}}
                                                            {{diamond.cut?.cut_name}}
                                                            {{diamond.master_lab?.lab_name ?
                                                            diamond.master_lab?.lab_name : '-'}}</p>
                                                    </div>
                                                    <div class="pl-1" style="position:relative;bottom: 4.5px;">
                                                        <span *ngIf="diamond.availability === 'Next Business Day'" class="shape-active"></span>
                                                    </div>
                                                </div>
                                                <span *ngIf="displayPrice === 'b' || displayPrice === 'c'">Cost :
                                                    {{currencySymbol}}{{diamond.cost_conversion_amount |
                                                    number}}</span>
                                                <span *ngIf="displayPrice === 'b' || displayPrice === 's'" style="margin-right: 0px;">Retail
                                                    :
                                                    {{currencySymbol}}{{diamond.conversion_amount | number}}</span>
                                            </div>
                                            <div class="footer">
                                                <ul>
                                                    <li>
                                                        <p class="mx-4" *ngIf="!getCompareStatus(diamond)">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/CompareWhite.svg" style="width: 27px;height: 27px;position: relative;top: 3px;"
                                                                    (click)="addDiamondToCompare(diamond)">
                                                                <span class="tooltiptext">Add to Compare</span>
                                                            </span>
                                                        </p>
                                                        <p class="mx-4" *ngIf="getCompareStatus(diamond)">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/CompareBlack.svg" style="width: 27px;height: 27px;position: relative;top: 3px;"
                                                                    (click)="removeDiamondFromCompare(diamond)">
                                                                <span class="tooltiptext">Remove from Compare</span>
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="mx-4" *ngIf="!getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                            <span class="tooltip action">
                                                                <img src="./assets/img/svg/bag.svg" class="iconSize"
                                                                    style="width: 12px;">
                                                                <span class="tooltiptext">Add to Cart</span>
                                                            </span>
                                                        </p>
                                                        <p class="mx-4" *ngIf="getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                            <span class="tooltip action">
                                                                <img src="./assets/img/svg/bag-fill.svg" class="iconSize"
                                                                    style="width: 15px;">
                                                                <span class="tooltiptext">Remove from Cart</span>
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p (click)="addtoWish(diamond)" *ngIf="diamond.checkWish === false">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/svg/heart.svg">
                                                                <span class="tooltiptext">Add to Wishlist</span>
                                                            </span>
                                                        </p>
                                                        <p (click)="deleteWishList(diamond)" *ngIf="diamond.checkWish === true">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/svg/heartfill.svg">
                                                                <span class="tooltiptext">Remove from Wishlist</span>
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="['/diamondDetail',diamond.lot_id]"
                                                            [queryParams]="{diamondType: diamondType}" target="_blank">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/Vision.png">
                                                                <span class="tooltiptext">Diamond Detail</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div *ngIf="diamondList.length <= 0" class="no-record-div">
                                            <p> No Records Found </p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink (click)="recentlyViewedFlag()">Recently Viewed</a>
                            <ng-template ngbNavContent>
                                <div class="diamond-tab">
                                    <div class="header-strip">
                                        <div class="results">
                                            <p *ngIf="diamondType == 'w' || diamondType == 'c'">Showing <span>
                                                    {{recentDiamondList.length}} of {{totalRecentRecords}} </span>
                                                Results
                                                <p *ngIf="diamondType == 'p'">Showing <span>
                                                        {{recentPairedDiamondList.length}} of
                                                        {{totalpairedRecentRecords}} </span> Results</p>
                                        </div>
                                        <div class="right-header-strip mat-select-custom">
                                            <div class="location">
                                                <label for="location">Location :</label>
                                                <mat-form-field appearance="fill">
                                                    <mat-select value="0" multiple (selectionChange)="locationChange($event)"
                                                        placeholder="Select Location" [(value)]="selectedLocation">
                                                        <!-- <mat-option value="0" selected>Select Location</mat-option> -->
                                                        <mat-option *ngFor="let location of locationList" [value]="location.lot_location_id">
                                                            {{location.lot_location_name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="available">
                                                <label for="availability">Availability :</label>
                                                <mat-form-field appearance="fill">
                                                    <mat-select [(ngModel)]="selectedAvailability" (selectionChange)="availabilityChange($event)">
                                                        <mat-option *ngFor="let Availability of Availabilities" [value]="Availability.value">
                                                            {{Availability.viewValue}}
                                                            <span *ngIf="Availability.value == 'Next Business Day'" class="shape-active"></span>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <ul *ngIf="diamondType != 'p'">
                                                <li>
                                                    <button class="btn list active" (click)="listGridBtnClick($event);"><i
                                                            class="fa fa-list-ul" aria-hidden="true"></i></button>
                                                </li>
                                                <li>
                                                    <button class="btn grid" (click)="listGridBtnClick($event);"><i
                                                            class="fa fa-th-large" aria-hidden="true"></i></button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="list-view" *ngIf="viewList">
                                        <div>
                                            <table class="table table-striped table-fixed mb-0">
                                                <thead>
                                                    <tr *ngIf="diamondType == 'w' || diamondType == 'p'">
                                                        <!-- "[data.is_two ? 'col-2' : 'col-1', diamondType === 'p' && !data.is_price ? 'pointerNone' : '']" -->
                                                        <ng-container *ngIf="displayPrice === 'c'">
                                                            <th *ngFor="let data of diamondColum" scope="col" [ngClass]="[data.key == data.value ? data.key:data.value ,
                                                            diamondType === 'p' && data.key === 'Shape' ? 'shapeHead' : '',
                                                            diamondType === 'p' && data.key === 'Carat' ? 'caratHead' : '',
                                                            diamondType === 'p' && data.key === 'Color' ? 'colorHead' : '',
                                                            diamondType === 'p' && data.key === 'Clarity' ? 'clarityHead' : '',
                                                            diamondType === 'p' && data.key === 'Cut' ? 'cutHead' : '',
                                                            diamondType === 'p' && data.key === 'Cost' ? 'CostHead' : '',
                                                            diamondType === 'p' && data.key === 'Retail' ? 'retailHead' : '']">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price || data.is_total_carat">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'"
                                                                [ngStyle]="{'padding-right' : diamondType == 'p' ? '0px' : '5px'}">Actions</th>
                                                        </ng-container>
                                                        <ng-container *ngIf="displayPrice === 's'">
                                                            <th *ngFor="let data of diamondColumOne" scope="col"
                                                                [ngClass]="[data.key == data.value ? data.key:data.value ,
                                                                diamondType === 'p' && data.key === 'Shape' ? 'shapeHead' : '',
                                                                diamondType === 'p' && data.key === 'Carat' ? 'caratHead' : '',
                                                                diamondType === 'p' && data.key === 'Color' ? 'colorHead' : '',
                                                                diamondType === 'p' && data.key === 'Clarity' ? 'clarityHead' : '',
                                                                diamondType === 'p' && data.key === 'Cut' ? 'cutHead' : '',
                                                                diamondType === 'p' && data.key === 'Cost' ? 'CostHead' : '',
                                                                diamondType === 'p' && data.key === 'Retail' ? 'retailHead' : '']">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price || data.is_total_carat">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'"
                                                                [ngStyle]="{'padding-right' : diamondType == 'p' ? '0px' : '5px'}">Actions</th>
                                                        </ng-container>
                                                        <ng-container *ngIf="displayPrice === 'b'">
                                                            <th *ngFor="let data of diamondColumTwo" scope="col"
                                                                [ngClass]="[data.key == data.value ? data.key:data.value ,
                                                                diamondType === 'p' && data.key === 'Shape' ? 'shapeHead' : '',
                                                                diamondType === 'p' && data.key === 'Carat' ? 'caratHead' : '',
                                                                diamondType === 'p' && data.key === 'Color' ? 'colorHead' : '',
                                                                diamondType === 'p' && data.key === 'Clarity' ? 'clarityHead' : '',
                                                                diamondType === 'p' && data.key === 'Cut' ? 'cutHead' : '',
                                                                diamondType === 'p' && data.key === 'Cost' ? 'CostHead' : '',
                                                                diamondType === 'p' && data.key === 'Retail' ? 'retailHead' : '']">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price || data.is_total_carat">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="Action text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'"
                                                                [ngStyle]="{'padding-right' : diamondType == 'p' ? '0px' : '5px'}">Actions</th>
                                                        </ng-container>
                                                        <!-- <th scope="col" class="pairedDiamondAction text-center " *ngIf="diamondType === 'p'">
                                                                    Actions</th> -->
                                                    </tr>
                                                    <tr *ngIf="diamondType == 'c'">
                                                        <ng-container *ngIf="displayPrice === 'c'">
                                                            <th *ngFor="let data of colorDiamondColum" scope="col"
                                                                [ngClass]="data.key == data.value ? data.key:data.value">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'">Actions</th>
                                                        </ng-container>
                                                        <ng-container *ngIf="displayPrice === 's'">
                                                            <th *ngFor="let data of colorDiamondColumOne" scope="col"
                                                                [ngClass]="data.key == data.value ? data.key:data.value">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'">Actions</th>
                                                        </ng-container>
                                                        <ng-container *ngIf="displayPrice === 'b'">
                                                            <th *ngFor="let data of colorDiamondColumTwo" scope="col"
                                                                [ngClass]="data.key == data.value ? data.key:data.value">{{data.key}}
                                                                <span *ngIf="data.is_code">
                                                                    {{currencyCode}}
                                                                </span>
                                                                <span (click)="diamondSort(data)" class="sort-wrap"
                                                                    *ngIf="diamondType != 'p' || data.is_price">
                                                                    <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                                    <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                                                </span>
                                                            </th>
                                                            <th scope="col" class="Action text-center" [ngClass]="diamondType != 'p' ? 'Actions' : 'pairedDiamondAction'">Actions</th>
                                                        </ng-container>
                                                    </tr>
                                                </thead>
                                                <tbody (scroll)="onScroll($event)" class="listBody thikScroll" *ngIf="diamondType == 'w' || diamondType == 'c'">
                                                    <a (click)="showDiamondDetails(diamond);recentlyViewed();" *ngFor="let diamond of recentDiamondList; index as i">
                                                        <tr [ngClass]="diamondViewList.lot_id === diamond.lot_id && showDiamondDetail ? 'active': ''"
                                                            style="border-bottom: 1px solid #e5e5e5;">
                                                            <td class="Shape">{{ diamond.lot.master_shape?.shape_name
                                                                }}
                                                                <span *ngIf="diamond.availability === 'Next Business Day'" class="shape-active"></span>
                                                            </td>
                                                            <td class="Carat">{{ diamond.weight}}</td>
                                                            <td class="Color">{{ diamond.lot.master_color ?
                                                                diamond.lot.master_color.color_name : '-'}}</td>
                                                            <td *ngIf="diamondType=='c'" class="intensityData d-flex justify-content-start align-items-center">{{
                                                                diamond.lot.intensity ?
                                                                diamond.lot.intensity.intensity_name : '-'}}
                                                                <!-- <span class="pl-1">
                                                                            <img *ngIf="diamond.heart_n_arrow" style="width: 13px;position: relative;bottom:-1px;"
                                                                                src="../../../assets/img/svg/heart&arrow.svg">
                                                                        </span> -->
                                                            </td>
                                                            <td class="Clarity" [ngStyle]="{'padding-left': diamondType === 'c' ? '12px' : '5px'}">{{
                                                                diamond.lot.master_clarity ?
                                                                diamond.lot.master_clarity.clarity_name : '-' }}
                                                            </td>
                                                            <td *ngIf="diamondType=='w'" class="Cut d-flex justify-content-start">{{
                                                                diamond.lot.cut.cut_name ? diamond.lot.cut.cut_name :
                                                                '-'}}
                                                                <span class="pl-1">
                                                                    <img *ngIf="diamond.heart_n_arrow" style="width: 13px;position: relative;bottom:1px;"
                                                                        src="../../../assets/img/svg/heart&arrow.svg">
                                                                </span>
                                                            </td>
                                                            <td *ngIf="diamondType == 'w'" class="labData recentLab d-flex justify-content-start align-items-center">
                                                                <div class="pl-3" style="position:relative;top: 1px;">
                                                                    <span class="tooltip action">
                                                                        <a [href]="diamond.grading_link ? diamond.grading_link : '#'"
                                                                            target="_blank" class="gradingLink">{{diamond.lot.master_lab?.lab_name}}</a>
                                                                        <span class="tooltiptext">View Certificate</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td *ngIf="diamondType == 'c'" class="labData d-flex align-items-center justify-content-start">
                                                                <div style="position:relative;top: 1px;">
                                                                    <span class="tooltip action">
                                                                        <a [href]="diamond.grading_link ? diamond.grading_link : '#'"
                                                                            target="_blank" class="gradingLink">{{diamond.lot.master_lab?.lab_name}}</a>
                                                                        <span class="tooltiptext">View Certificate</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <!-- <td class="col-2">${{ diamond.total_amount }}</td> -->
                                                            <td *ngIf="displayPrice === 'c' || displayPrice === 'b'"
                                                                class="Price pr-xxl-2 pr-xl-2 pr-lg-2 pr-md-2">
                                                                {{currencySymbol}}{{ diamond.cost_conversion_amount |
                                                                number}}
                                                            </td>
                                                            <td *ngIf="displayPrice === 's' || displayPrice === 'b'"
                                                                class="Price pr-xxl-2 pr-xl-2 pr-lg-2 pr-md-2">
                                                                {{currencySymbol}}{{ diamond.conversion_amount |
                                                                number}}
                                                            </td>
                                                            <td [ngClass]="displayPrice === 'b' ? 'Action' : ''" style="padding: 6px 0;" (click)="preventDefaultAction($event)">
                                                                <p *ngIf="!getCompareStatus(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/CompareWhite.svg"
                                                                            style="width: 27px;height: 27px;position: relative;top: 3px;"
                                                                            (click)="addDiamondToCompare(diamond)">
                                                                        <span class="tooltiptext">Add to Compare</span>
                                                                    </span>
                                                                </p>
                                                                <p *ngIf="getCompareStatus(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/CompareBlack.svg"
                                                                            style="width: 27px;height: 27px;position: relative;top: 3px;"
                                                                            (click)="removeDiamondFromCompare(diamond)">
                                                                        <span class="tooltiptext">Remove from Compare</span>
                                                                    </span>
                                                                </p>
                                                                <!-- {{diamond.wishlist | json}}  -->
                                                                <p *ngIf="!getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="./assets/img/svg/bag.svg" class="iconSize"
                                                                            style="width: 12px;">
                                                                        <span class="tooltiptext">Add to Cart</span>
                                                                    </span>
                                                                </p>
                                                                <p *ngIf="getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="./assets/img/svg/bag-fill.svg" class="iconSize"
                                                                            style="width: 15px;">
                                                                        <span class="tooltiptext">Remove from Cart</span>
                                                                    </span>
                                                                </p>
                                                                <p (click)="addtoWish(diamond)" *ngIf="diamond.checkWish === false">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/svg/heart.svg">
                                                                        <span class="tooltiptext">Add to Wishlist</span>
                                                                    </span>
                                                                </p>
                                                                <p (click)="deleteWishList(diamond)" *ngIf="diamond.checkWish === true">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/svg/heartfill.svg">
                                                                        <span class="tooltiptext">Remove from Wishlist</span>
                                                                    </span>
                                                                </p>
                                                                <a [routerLink]="['/diamondDetail',diamond.lot_id]"
                                                                    [queryParams]="{diamondType: diamondType}" target="_blank">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/svg/vision.svg">
                                                                        <span class="tooltiptext">Diamond Detail</span>
                                                                    </span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </a>
                                                    <tr *ngIf="recentDiamondList.length <= 0" class="d-flex justify-content-center">
                                                        <p> No Records Found </p>
                                                    </tr>
                                                </tbody>
                                                <tbody class="pairedListBody thikScroll" (scroll)="onScroll($event)"
                                                    *ngIf="diamondType === 'p'">
                                                    <a (click)="showDiamondDetails(diamond);" *ngFor="let diamond of recentPairedDiamondList; index as i"
                                                        [ngClass]="diamondViewList.lot_id === diamond.lot_id && diamondViewList.paired_lot_id === diamond.paired_lot_id && showDiamondDetail ? 'active': ''"
                                                        style=" border-bottom: 1px solid #e5e5e5;">
                                                        <tr>
                                                            <td class="pairedDiamondShape">{{
                                                                diamond.lot.master_shape.shape_name }}
                                                                <span *ngIf="diamond.lot.availability === 'Next Business Day'"
                                                                    class="shape-active"></span>
                                                            </td>
                                                            <td class="pairedDiamondCarat">{{ diamond.lot.weight}}</td>
                                                            <td class="pairedDiamondCarat Total">{{
                                                                diamond.total_carat}}</td>
                                                            <td class="pairedDiamondColor">{{ diamond.lot.master_color
                                                                ?
                                                                diamond.lot.master_color.color_name : '-'}}</td>
                                                            <td class="pairedDiamondClarity">{{
                                                                diamond.lot.master_clarity.clarity_name }}
                                                            </td>
                                                            <td class="pairedDiamondCut pairedDiamondRecentCut d-flex justify-content-start">{{
                                                                diamond.lot.cut ? diamond.lot.cut.cut_name : '-'}}
                                                                <span class="pl-1">
                                                                    <img *ngIf="diamond.lot.heart_n_arrow" style="width: 13px;position: relative;bottom:1px;"
                                                                        src="../../../assets/img/svg/heart&arrow.svg">
                                                                </span>
                                                            </td>
                                                            <td class="pairedDiamondLab d-flex justify">
                                                                <div style="position:relative;top: 1px;">
                                                                    <span class="tooltip action">
                                                                        <a [href]="diamond.lot.grading_link ? diamond.lot.grading_link : '#'"
                                                                            target="_blank" class="gradingLink">{{
                                                                            diamond.lot.lab_name}}</a>
                                                                        <span class="tooltiptext">View Certificate</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <!-- <td class="col-2" rowspan="2">${{ diamond.total_amount }}
                                                            </td> -->
                                                            <td *ngIf="displayPrice === 'c' || displayPrice === 'b'"
                                                                class="pairedDiamondPrice pr-xxl-2 pr-xl-2 pr-lg-2 pr-md-2"
                                                                rowspan="2" style="min-width: 14%;max-width: 14%;">
                                                                {{currencySymbol}}{{ diamond.cost_conversion_amount |
                                                                number}}
                                                            </td>
                                                            <td *ngIf="displayPrice === 's' || displayPrice === 'b'"
                                                                class="pairedDiamondPrice pr-xxl-2 pr-xl-2 pr-lg-2 pr-md-2"
                                                                rowspan="2" style="min-width: 15%;max-width: 15%;">
                                                                {{currencySymbol}}{{ diamond.conversion_amount |
                                                                number}}
                                                            </td>
                                                            <td class="pairedDiamondAction justify-content-center align-items-center"
                                                                rowspan="2" (click)="preventDefaultAction($event)">
                                                                <p class="pr-2" *ngIf="!getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="./assets/img/svg/bag.svg" class="iconSize"
                                                                            style="width: 12px;">
                                                                        <span class="tooltiptext">Add to Cart</span>
                                                                    </span>
                                                                </p>
                                                                <p class="pr-2" *ngIf="getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                                    <span class="tooltip action">
                                                                        <img src="./assets/img/svg/bag-fill.svg" class="iconSize"
                                                                            style="width: 15px;">
                                                                        <span class="tooltiptext">Remove from Cart</span>
                                                                    </span>
                                                                </p>
                                                                <a [routerLink]="['/pairedDiamondDetail',diamond.lot_id]"
                                                                    [queryParams]="{pairedLotId: diamond.paired_lot_id}"
                                                                    target="_blank">
                                                                    <span class="tooltip action">
                                                                        <img src="../../../assets/img/svg/vision.svg">
                                                                        <span class="tooltiptext">Diamond Detail</span>
                                                                    </span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr class="secTr">
                                                            <td class="pairedDiamondShape">{{
                                                                diamond.paired_diamond_lot.paired_shape.shape_name }}
                                                                <span *ngIf="diamond.paired_diamond_lot.availability === 'Next Business Day'"
                                                                    class="shape-active"></span>
                                                            </td>
                                                            <td class="pairedDiamondCarat">{{
                                                                diamond.paired_diamond_lot.weight}}</td>
                                                            <td class="pairedDiamondCarat Total">
                                                            </td>
                                                            <td class="pairedDiamondColor">{{
                                                                diamond.paired_diamond_lot.paired_color ?
                                                                diamond.paired_diamond_lot.paired_color.color_name :
                                                                '-'}}
                                                            </td>
                                                            <td class="pairedDiamondClarity">{{
                                                                diamond.paired_diamond_lot.paired_clarity.clarity_name
                                                                }}
                                                            </td>
                                                            <td class="pairedDiamondCut pairedDiamondRecentCut d-flex justify-content-start">{{
                                                                diamond.paired_diamond_lot.paired_cut ?
                                                                diamond.paired_diamond_lot.paired_cut.cut_name : '-'}}
                                                                <span class="pl-1">
                                                                    <img *ngIf="diamond.paired_diamond_lot.heart_n_arrow"
                                                                        style="width: 13px;position: relative;bottom:1px;"
                                                                        src="../../../assets/img/svg/heart&arrow.svg">
                                                                </span>
                                                            </td>
                                                            <td class="pairedDiamondLab d-flex align-items-center"
                                                                style="justify-content: flex-start;padding-left: 5px !important;">
                                                                <div style="position:relative;top: 1px;">
                                                                    <span class="tooltip action">
                                                                        <a [href]="diamond.paired_diamond_lot.grading_link ? diamond.paired_diamond_lot.grading_link : '#'"
                                                                            target="_blank" class="gradingLink">{{diamond.paired_diamond_lot.lab_name}}</a>
                                                                        <span class="tooltiptext">View Certificate</span>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </a>
                                                    <tr *ngIf="recentPairedDiamondList.length <= 0" class="d-flex justify-content-center">
                                                        <p> No Records Found </p>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="grid-view" *ngIf="viewGrid" (scroll)="onScroll($event)">
                                        <div class="pt-3 diamond-box" (click)="showDiamondDetails(diamond);recentlyViewed();"
                                            *ngFor="let diamond of recentDiamondList" [ngClass]="diamondViewList.lot_id === diamond.lot_id && showDiamondDetail ? 'active': ''">
                                            <div class="detail">
                                                <img *ngIf="diamond.heart_n_arrow" class="heartNarrow" src="../../../assets/img/svg/heart&arrow.svg">
                                                <img *ngIf="diamond.image_path" class="mb-2" [src]="diamond.image_path">
                                                <img *ngIf="!diamond.image_path" class="mb-2" src="./assets/img/defaultDiamond.png"
                                                    style="padding: 25px;">
                                                <div class="d-flex justify-content-center">
                                                    <div>
                                                        <p *ngIf="diamondType == 'w'">
                                                            {{diamond.lot.master_shape?.shape_name ?
                                                            diamond.lot.master_shape?.shape_name :
                                                            '-'}}
                                                            {{diamond.weight ? diamond.weight : '-'}} {{
                                                            diamond.lot.master_color?.color_name ?
                                                            diamond.lot.master_color?.color_name:
                                                            '-'}}
                                                            {{diamond.lot.master_clarity?.clarity_name ?
                                                            diamond.lot.master_clarity?.clarity_name : '-'}}
                                                            {{ diamond.lot.cut?.cut_name }}
                                                            {{diamond.lot.master_lab?.lab_name ?
                                                            diamond.lot.master_lab?.lab_name : '-'}}</p>
                                                        <p *ngIf="diamondType == 'c'">{{diamond.lot.master_shape?.shape_name ?
                                                            diamond.lot.master_shape?.shape_name :
                                                            '-'}}
                                                            {{diamond.weight ? diamond.weight : '-'}}
                                                            {{diamond.lot.master_color?.color_name ?
                                                            diamond.lot.master_color?.color_name:
                                                            '-'}}
                                                            {{diamond.lot.intensity?.intensity_name ?
                                                            diamond.lot.intensity?.intensity_name : '-'}}
                                                            {{diamond.lot.master_clarity?.clarity_name ?
                                                            diamond.lot.master_clarity?.clarity_name : '-'}}
                                                            {{ diamond.lot.cut?.cut_name }}
                                                            {{diamond.lot.master_lab?.lab_name ?
                                                            diamond.lot.master_lab?.lab_name : '-'}}</p>
                                                    </div>
                                                    <div class="pl-1" style="position:relative;bottom: 4.5px;">
                                                        <span *ngIf="diamond.availability === 'Next Business Day'" class="shape-active"></span>
                                                    </div>
                                                </div>
                                                <span *ngIf="displayPrice === 'b' || displayPrice === 'c'">Cost :
                                                    {{currencySymbol}}{{diamond.cost_conversion_amount ?
                                                    diamond.cost_conversion_amount : '-' |
                                                    number}}</span>
                                                <span *ngIf="displayPrice === 'b' || displayPrice === 's'" style="margin-right: 0px;">Retail
                                                    :
                                                    {{currencySymbol}}{{diamond.conversion_amount ?
                                                    diamond.conversion_amount : '-' | number}}</span>
                                            </div>
                                            <div class="footer">
                                                <ul>
                                                    <li>
                                                        <p class="mx-4" *ngIf="!getCompareStatus(diamond)">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/CompareWhite.svg" style="width: 27px;height: 27px;position: relative;top: 3px;"
                                                                    (click)="addDiamondToCompare(diamond)">
                                                                <span class="tooltiptext">Add to Compare</span>
                                                            </span>
                                                        </p>
                                                        <p class="mx-4" *ngIf="getCompareStatus(diamond)">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/CompareBlack.svg" style="width: 27px;height: 27px;position: relative;top: 3px;"
                                                                    (click)="removeDiamondFromCompare(diamond)">
                                                                <span class="tooltiptext">Remove from Compare</span>
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="mx-4" *ngIf="!getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                            <span class="tooltip action">
                                                                <img src="./assets/img/svg/bag.svg" class="iconSize"
                                                                    style="width: 12px;">
                                                                <span class="tooltiptext">Add to Cart</span>
                                                            </span>
                                                        </p>
                                                        <p class="mx-4" *ngIf="getCartStatus(diamond)" (click)="AddToCart(diamond)">
                                                            <span class="tooltip action">
                                                                <img src="./assets/img/svg/bag-fill.svg" class="iconSize"
                                                                    style="width: 15px;">
                                                                <span class="tooltiptext">Remove from Cart</span>
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p (click)="addtoWish(diamond)" *ngIf="diamond.checkWish === false">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/svg/heart.svg">
                                                                <span class="tooltiptext">Add to Wishlist</span>
                                                            </span>
                                                        </p>
                                                        <p (click)="deleteWishList(diamond)" *ngIf="diamond.checkWish === true">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/svg/heartfill.svg">
                                                                <span class="tooltiptext">Remove from Wishlist</span>
                                                            </span>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <a [routerLink]="['/diamondDetail',diamond.lot_id]"
                                                            [queryParams]="{diamondType: diamondType}" target="_blank">
                                                            <span class="tooltip action">
                                                                <img src="../../../assets/img/Vision.png">
                                                                <span class="tooltiptext">Diamond Detail</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div *ngIf="recentDiamondList.length <= 0" class="no-record-div">
                                            <p> No Records Found </p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3" *ngIf="diamondType != 'p'">
                            <a ngbNavLink (click)="comparisonFlag()">Comparison</a>
                            <ng-template ngbNavContent>
                                <div class="container-fluid comparisonContainer">
                                    <div class="row comparisonHeadRow">
                                        <div class="firstHeadBlank">

                                        </div>
                                        <div class="text-center headText">
                                            Product 1
                                        </div>
                                        <div class="text-center headText">
                                            Product 2
                                        </div>
                                        <div class="text-center headText">
                                            Product 3
                                        </div>
                                    </div>
                                    <div class="row ComparisonDataRow">
                                        <div class="comparisonColOne comparisonColFirst">
                                            <div class="d-flex flex-column align-items-end iconrowContainer">
                                                <div class="d-flex flex-column align-items-center youtubeContainer"
                                                    [ngClass]="diamondComparisonArr.length == 0 ? 'disabled' : ''"
                                                    (click)="showVideo(content, diamondComparisonArr)">
                                                    <div class="youtupeIconContainer">
                                                        <img class="youtubeIcon" src="../../../assets/img/svg/youtube.svg">
                                                    </div>
                                                    <div class="videoTextContainer">
                                                        <span class="iconFont">Video</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column align-items-center imageContainer"
                                                    [ngClass]="diamondComparisonArr.length == 0 ? 'disabled' : ''"
                                                    (click)="showImage(content, diamondComparisonArr)">
                                                    <div class="iconImageContainer">
                                                        <img class="iconImage" src="../../../assets/img/svg/image.svg">
                                                    </div>
                                                    <div class="imageTextContainer">
                                                        <span class="iconFont">Image</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="diamondSpecification">
                                                Stock ID :
                                            </div>
                                            <div *ngIf="displayPrice === 'c' || displayPrice === 'b'" class="diamondSpecification priceRow">
                                                Cost Price :
                                            </div>
                                            <div *ngIf="displayPrice === 's' || displayPrice === 'b'" class="diamondSpecification priceRow">
                                                Retail Price :
                                            </div>
                                            <div class="diamondSpecification">
                                                Shape :
                                            </div>
                                            <div class="diamondSpecification">
                                                Laboratory :
                                            </div>
                                            <div class="diamondSpecification">
                                                Carat :
                                            </div>
                                            <div class="diamondSpecification">
                                                Color :
                                            </div>
                                            <div class="diamondSpecification">
                                                Clarity :
                                            </div>
                                            <div class="diamondSpecification">
                                                Measurements :
                                            </div>
                                            <div *ngIf="diamondType == 'c'" class="diamondSpecification">
                                                Intensity :
                                            </div>
                                            <div *ngIf="diamondType == 'w'" class="diamondSpecification">
                                                Cut :
                                            </div>
                                            <div class="diamondSpecification">
                                                Polish :
                                            </div>
                                            <div class="diamondSpecification">
                                                Symmetry :
                                            </div>
                                            <div class="diamondSpecification">
                                                Depth :
                                            </div>
                                            <div class="diamondSpecification">
                                                Table :
                                            </div>
                                            <div class="diamondSpecification">
                                                Ratio :
                                            </div>
                                            <div class="diamondSpecification">
                                                Hearts & Arrows :
                                            </div>
                                            <!-- <div class="diamondSpecification">
                                                SHAPE :
                                            </div> -->

                                            <!-- <div class="diamondSpecification blankContainer">

                                            </div> -->
                                            <!-- <div class="diamondSpecification blankContainerLast">

                                            </div> -->
                                        </div>
                                        <div class="comparisonColOne" *ngFor="let data of diamondComparisonArr;index as i">
                                            <div class="d-flex justify-content-between carouselContents">
                                                <div class="removeIconContainer">
                                                    <span class="tooltip action">
                                                        <img src="../../../assets/img/svg/close.svg" class="removeIcon"
                                                            (click)="removeDiamondTableData(i)">
                                                        <span class="tooltiptext">Remove</span>
                                                    </span>
                                                    <div class="addTocartComapreWrap">
                                                        <p *ngIf="!getCartStatus(data)" (click)="AddToCart(data)">
                                                            <span class="tooltip action">
                                                                <img src="./assets/img/svg/bag.svg" class="iconSize"
                                                                    style="width: 12px;">
                                                                <span class="tooltiptext">Add to Cart</span>
                                                            </span>
                                                        </p>
                                                        <p *ngIf="getCartStatus(data)" (click)="AddToCart(data)">
                                                            <span class="tooltip action">
                                                                <img src="./assets/img/svg/bag-fill.svg" class="iconSize"
                                                                    style="width: 15px;">
                                                                <span class="tooltiptext">Remove from Cart</span>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="carouselContainer">
                                                    <ngb-carousel [showNavigationArrows]="data.slider_images.length > 1"
                                                        class="diamondComparisonImageSlider" [interval]="false">
                                                        <ng-template *ngFor="let images of data.slider_images" ngbSlide>
                                                            <div class="picsum-img-wrapper text-center">
                                                                <!-- <lib-ngx-image-zoom magnification="1" enableScrollZoom="true"
                                                                    enableLens="true" lensWidth="70" lensHeight="70"
                                                                    circularLens="true" class="diamondImage" thumbImage={{images}}
                                                                    fullImage={{images}}></lib-ngx-image-zoom> -->
                                                                <img class="diamondImage" src={{images}} alt="Image Not Available">
                                                            </div>
                                                        </ng-template>
                                                    </ngb-carousel>
                                                </div>
                                                <!-- <div class="carouselContainer" *ngIf="displayVideo">
                                                    <iframe width="130" height="129.5" src="https://www.altr.nyc/videos/diamond.html?d=440013676" frameborder="0" allowfullscreen></iframe>
                                                </div> -->
                                                <div class="d-flex flex-column justify-content-between iconCol">
                                                    <div (click)="addtoWish(data)" *ngIf="!data.checkWish">
                                                        <span class="tooltip action">
                                                            <img src="../../../assets/img/svg/heart.svg">
                                                            <span class="tooltiptext">Add to Wishlist</span>
                                                        </span>
                                                    </div>
                                                    <div (click)="deleteWishList(data)" *ngIf="data.checkWish">
                                                        <span class="tooltip action">
                                                            <img src="../../../assets/img/svg/heartfill.svg">
                                                            <span class="tooltiptext">Remove from Wishlist</span>
                                                        </span>
                                                    </div>
                                                    <!-- <div class="diamondSpecificationList blackButtonContainer">
                                                        <button mat-raised-button class="blackbtnForm" type="submit" (click)="AddToCart(data)">{{getLabelForCart(data)}}</button>
                                                    </div> -->
                                                    <div class="matchIconContainer">
                                                        <a (click)="matchClick(data)" target="_blank">
                                                            <span class="tooltip action">
                                                                <img class="matchIcon" src="assets/img/Match.png">
                                                                <span class="tooltiptext">Match</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="diamondSpecificationList">
                                                {{data.altr_stone_id ? data.altr_stone_id : '-'}}
                                            </div>
                                            <div *ngIf="displayPrice === 'b'|| displayPrice === 'c'" class="diamondSpecificationList priceFont"
                                                [ngClass]="displayPrice === 'c' || displayPrice === 'b' ? 'listBackground' : ''">
                                                <!-- <span class="pr-1">${{data.total_amount ? data.total_amount :
                                                    '-'}}</span> -->
                                                <!-- ₹{{data.conversion_amount ? data.conversion_amount : '-'}} -->
                                                <span>{{currencySymbol}}{{
                                                    data.cost_conversion_amount | number}}</span>
                                            </div>
                                            <div *ngIf="displayPrice === 'b' || displayPrice === 's'" class="diamondSpecificationList priceFont"
                                                [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                <!-- <span class="pr-1">${{data.total_amount ? data.total_amount :
                                                        '-'}}</span> -->
                                                <!-- ₹{{data.conversion_amount ? data.conversion_amount : '-'}} -->
                                                <span>{{currencySymbol}}{{
                                                    data.conversion_amount | number}}</span>
                                            </div>
                                            <div *ngIf="data.master_shape" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.master_shape ? data.master_shape.shape_name : '-'}}
                                            </div>
                                            <div *ngIf="data.lot" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.lot.master_shape.shape_name ? data.lot.master_shape.shape_name :
                                                '-'}}
                                            </div>
                                            <div *ngIf="data.master_shape" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                {{data.master_lab ? data.master_lab.lab_name : '-'}}
                                            </div>
                                            <div *ngIf="data.lot" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                {{data.lot.master_lab.lab_name ? data.lot.master_lab.lab_name : '-'}}
                                            </div>
                                            <div class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.weight ? data.weight : '-'}}
                                            </div>
                                            <div *ngIf="data.master_shape" class="diamondSpecificationList " [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                {{data.master_color ? data.master_color.color_name : '-'}}
                                            </div>
                                            <div *ngIf="data.lot" class="diamondSpecificationList " [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                {{data.lot.master_color.color_name ? data.lot.master_color.color_name :
                                                '-'}}
                                            </div>
                                            <div *ngIf="data.master_shape" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.master_clarity ? data.master_clarity.clarity_name : '-'}}
                                            </div>
                                            <div *ngIf="data.lot" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.lot.master_clarity.clarity_name ?
                                                data.lot.master_clarity.clarity_name : '-'}}
                                            </div>
                                            <div class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                <span class="measurementLabel">{{data.measurement}}</span>
                                            </div>
                                            <div *ngIf="diamondType == 'w'">
                                                <div *ngIf="data.master_shape" class="diamondSpecificationList"
                                                    [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                    {{data.cut ? data.cut.cut_name : '-'}}
                                                </div>
                                                <div *ngIf="data.lot" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                    {{data.lot.cut.cut_name ? data.lot.cut.cut_name : '-'}}
                                                </div>
                                            </div>
                                            <div *ngIf="diamondType == 'c'">
                                                <div *ngIf="data.master_shape" class="diamondSpecificationList"
                                                    [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                    {{data.intensity ? data.intensity.intensity_name : '-'}}
                                                </div>
                                                <div *ngIf="data.lot" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                    {{data.lot.intensity.intensity_name ?
                                                    data.lot.intensity.intensity_name : '-'}}
                                                </div>
                                            </div>
                                            <div *ngIf="data.master_shape" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                {{data.polish ? data.polish.short_code : '-'}}
                                            </div>
                                            <div *ngIf="data.lot" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                {{data.lot.polish.short_code ? data.lot.polish.short_code : '-'}}
                                            </div>
                                            <div *ngIf="data.master_shape" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.symmetry ? data.symmetry.short_code : '-'}}
                                            </div>
                                            <div *ngIf="data.lot" class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.lot.symmetry.short_code ? data.lot.symmetry.short_code : '-'}}
                                            </div>
                                            <div class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                {{data.depth_percentage ? data.depth_percentage : '-'}} %
                                            </div>
                                            <div class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.table_percentage ? data.table_percentage : '-' }} %
                                            </div>
                                            <div class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">
                                                {{data.ratio ? data.ratio : '-'}}
                                            </div>
                                            <div class="diamondSpecificationList" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">
                                                {{data.heart_n_arrow == false ? 'No' : 'Yes'}}
                                            </div>
                                            <!-- <div class="diamondSpecificationList">
                                                {{data.master_shape ? data.master_shape.shape_name : '-'}}
                                            </div> -->

                                            <!-- <div class="diamondSpecificationList blankContainer">




                                            </div> -->
                                            <!-- <div class="diamondSpecificationList blackButtonContainer">
                                                <button mat-raised-button class="blackbtnForm" type="submit" (click)="AddToCart(data)">{{getLabelForCart(data)}}</button>
                                            </div> -->
                                        </div>
                                        <ng-container *ngIf="diamondType == 'w'">
                                            <div class="comparisonColOne" *ngFor="let item of arrayOne(stateLength)">
                                                <div class="d-flex flex-column align-items-center seeMoreContainer">
                                                    <div>
                                                        <img class="roundDiamond" src="assets/img/defaultDiamond.png">
                                                    </div>
                                                    <div class="diamondSelectionText text-center" (click)="showAllDiamondsTab()">
                                                        <a class="diamondSelection">Select
                                                            a diamond in the
                                                            table to compare</a>
                                                    </div>
                                                </div>
                                                <div class="blankListRow">

                                                </div>
                                                <div *ngIf="displayPrice === 'c' || displayPrice === 'b'" class="blankListRow firstBlankRow"
                                                    [ngClass]="displayPrice === 'c' || displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div *ngIf="displayPrice === 's' || displayPrice === 'b'" class="blankListRow firstBlankRow"
                                                    [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <!-- <div class="blankListRow">
            
                                                        </div> -->
                                                <!-- <div class="diamondSpecification blankContainer">
            
                                                        </div> -->
                                                <!-- <div class="diamondSpecification blankContainerLast">
            
                                                        </div> -->
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="diamondType == 'c'">
                                            <div class="comparisonColOne" *ngFor="let item of arrayTwo(stateLengthTwo)">
                                                <div class="d-flex flex-column align-items-center seeMoreContainer">
                                                    <div>
                                                        <img class="roundDiamond" src="assets/img/defaultDiamond.png">
                                                    </div>
                                                    <div class="diamondSelectionText text-center" (click)="showAllDiamondsTab()">
                                                        <a class="diamondSelection">Select
                                                            a diamond in the
                                                            table to compare</a>
                                                    </div>
                                                </div>
                                                <div class="blankListRow">

                                                </div>
                                                <div *ngIf="displayPrice === 'c' || displayPrice === 'b'" class="blankListRow firstBlankRow"
                                                    [ngClass]="displayPrice === 'c' || displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div *ngIf="displayPrice === 's' || displayPrice === 'b'" class="blankListRow firstBlankRow"
                                                    [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? '' : 'listBackground'">

                                                </div>
                                                <div class="blankListRow" [ngClass]="displayPrice === 'b' ? 'listBackground' : ''">

                                                </div>
                                                <!-- <div class="blankListRow">
                
                                                            </div> -->
                                                <!-- <div class="diamondSpecification blankContainer">
                
                                                            </div> -->
                                                <!-- <div class="diamondSpecification blankContainerLast">
                
                                                            </div> -->
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="mt-2"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <!-- <h2 class="modal-title text-center" style="margin: 0 auto;width: 100%;">{{displayVideo ? 'Videos' : 'Images'}}
        </h2> -->
    <div class="modal-body pb-0 pt-0 pr-0" *ngIf="dataForModal.lot_id">
        <button type="button" class="close" style="outline: none !important;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="row px-3 pt-3" *ngIf="displayImage">
            <div class="col-12 col-md-12 imgSliderBlock">
                <div class="py-2 text-center">
                    <h4 style="text-transform: capitalize;white-space: nowrap;">Stock Id : {{dataForModal?.altr_stone_id ?
                        dataForModal?.altr_stone_id :
                        "-"}}</h4>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="carouselContainer">
                        <ngb-carousel [showNavigationArrows]="dataForModal.slider_images_array.length > 1" [interval]="false">
                            <ng-template *ngFor="let images of dataForModal.slider_images_array" ngbSlide>
                                <div class="picsum-img-wrapper">
                                    <img class="diamondImagePopUp" src={{images.url}} alt="Image Not Available">
                                    <div class="text-center imagesText">{{images.name}}</div>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
            </div>
        </div>
        <div class="row px-3 pt-3 justify-content-center">
            <div class="col-12 col-md-12" class="text-center">
                <h4 class="mb-3 mt-3" style="text-transform: capitalize;">
                    {{dataForModal?.master_shape ? dataForModal?.master_shape.shape_name :
                    '-'}}&nbsp;{{dataForModal?.weight ? dataForModal?.weight :
                    '-'}}&nbsp;{{dataForModal?.master_color ?
                    dataForModal?.master_color.color_name
                    : '-'}}&nbsp;{{dataForModal?.master_clarity ?
                    dataForModal?.master_clarity.clarity_name : '-'}}&nbsp;
                </h4>
            </div>
        </div>
    </div>
    <div class="modal-body pb-0 pt-0 pr-0 pl-2" *ngIf="!dataForModal.lot_id">
        <button type="button" class="close" style="outline: none !important;position: relative;right: 8px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <!-- <div class="row px-3 modal-title">
            <div class="col-12" [ngClass]="dataForModal.length === 1 ? 'col-md-12' : dataForModal.length === 2 ? 'col-md-6' : 'col-md-4'"
                *ngIf="dataForModal.length >= 1">
                <h4 class="text-center mb-3" style="text-transform: uppercase;">Product 1</h4>
            </div>
            <div class="col-12" [ngClass]="dataForModal.length === 1 ? 'col-md-12' : dataForModal.length === 2 ? 'col-md-6' : 'col-md-4'"
                *ngIf="dataForModal.length >= 2">
                <h4 class="text-center mb-3" style="text-transform: uppercase;">Product 2</h4>
            </div>
            <div class="col-12" [ngClass]="dataForModal.length === 1 ? 'col-md-12' : dataForModal.length === 2 ? 'col-md-6' : 'col-md-4'"
                *ngIf="dataForModal.length >= 3">
                <h4 class="text-center mb-3" style="text-transform: uppercase;">Product 3</h4>
            </div>
        </div> -->
        <div class="row pt-3" *ngIf="displayVideo">
            <div class="col-12 d-flex flex-column" [ngClass]="dataForModal.length === 1 ? 'col-md-12' : dataForModal.length === 2 ? 'col-md-6' : 'col-md-4'"
                *ngFor="let data of dataForModal" class="text-center">
                <div class="pb-2">
                    <h4 style="text-transform: capitalize;white-space: nowrap;">Stock Id : {{data?.altr_stone_id ? data?.altr_stone_id :
                        "-"}}</h4>
                </div>
                <div>
                    <iframe class="videoIfrmae" id="diamondVideoIframe" [src]="data.video_path | safe :'resourceUrl'"
                        width="350" height="285" scrolling="no" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="row pt-3" *ngIf="displayImage">
            <div class="col-12 imgSliderBlock" [ngClass]="dataForModal.length === 1 ? 'col-md-12' : dataForModal.length === 2 ? 'col-md-6' : 'col-md-4'"
                *ngFor="let data of dataForModal">
                <div class="pb-3 text-center">
                    <h4 style="text-transform: capitalize;white-space: nowrap;">Stock Id : {{data?.altr_stone_id ? data?.altr_stone_id :
                        "-"}}</h4>
                </div>
                <div class="d-flex justify-content-between">
                    <div class="carouselContainer">
                        <ngb-carousel [showNavigationArrows]="data.slider_images_array.length > 1" [interval]="false">
                            <ng-template *ngFor="let images of data.slider_images_array" ngbSlide>
                                <div class="picsum-img-wrapper">
                                    <img class="diamondImagePopUp" src={{images.url}} alt="Image Not Available">
                                    <div class="text-center imagesText">{{images.name}}</div>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
            </div>
        </div>
        <div class="row px-3" [ngClass]="displayImage === true ? 'pt-3' : ''">
            <div class="col-12" [ngClass]="dataForModal.length === 1 ? 'col-md-12' : dataForModal.length === 2 ? 'col-md-6' : 'col-md-4'"
                *ngFor="let data of dataForModal" class="text-center">
                <h4 *ngIf="!data.lot" class="mb-3 mt-3" style="text-transform: capitalize;">
                    {{data?.master_shape ? data?.master_shape.shape_name :
                    '-'}}&nbsp;{{data?.weight ? data?.weight : '-'}}&nbsp;{{data?.master_color ?
                    data?.master_color.color_name
                    : '-'}}&nbsp;{{data?.master_clarity ?
                    data?.master_clarity.clarity_name : '-'}}&nbsp;
                </h4>
                <h4 *ngIf="data.lot" class="mb-3" style="text-transform: capitalize;">
                    {{data?.lot.master_shape ? data?.lot.master_shape.shape_name :
                    '-'}}&nbsp;{{data?.weight ? data?.weight : '-'}}&nbsp;{{data?.lot.master_color ?
                    data?.lot.master_color.color_name
                    : '-'}}&nbsp;{{data?.lot.master_clarity ?
                    data?.lot.master_clarity.clarity_name : '-'}}&nbsp;
                </h4>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #pairedContent let-modal>
    <!-- <h2 class="modal-title text-center" style="margin: 0 auto;width: 100%;">{{displayVideo ? 'Videos' : 'Images'}}
        </h2> -->
    <div class="modal-body pb-0 pt-0">
        <button type="button" class="close" style="outline: none !important;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="displayVideo">
            <div class="row px-3 pt-3">
                <div class="col-12 col-md-6">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;white-space: nowrap;">Stock Id :
                        {{pairedModalVideoData.lot.altr_stone_id}}
                    </h4>
                </div>
                <div class="col-12 col-md-6">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;white-space: nowrap;">Stock Id :
                        {{pairedModalVideoData.paired_diamond_lot.altr_stone_id}}
                    </h4>
                </div>
            </div>
            <div class="row px-3">
                <!-- <div class="col-12 col-md-4" *ngFor="let data of dataForModal">
                    <iframe width="350" height="500" src={{data?.video_path}} frameborder="0" allowfullscreen></iframe>
                </div> -->
                <div class="col-12 col-md-6">
                    <iframe class="videoIfrmae" width="350" height="290" scrolling="no" [src]="pairedModalVideoData.lot.video_path | safe :'resourceUrl'"
                        frameborder="0" allowfullscreen></iframe>
                    <div class="text-center">
                        <h4 class="mb-3" style="text-transform: capitalize;">
                            {{pairedModalVideoData.lot.master_shape ?
                            pairedModalVideoData.lot.master_shape.shape_name :
                            '-'}}&nbsp;{{pairedModalVideoData.lot.weight ? pairedModalVideoData.lot.weight :
                            '-'}}&nbsp;{{pairedModalVideoData.lot.master_color ?
                            pairedModalVideoData.lot?.master_color.color_name
                            : '-'}}&nbsp;{{pairedModalVideoData.lot.master_clarity ?
                            pairedModalVideoData.lot.master_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <iframe class="videoIfrmae" width="350" height="290" scrolling="no" [src]="pairedModalVideoData.paired_diamond_lot.video_path | safe :'resourceUrl'"
                        frameborder="0" allowfullscreen></iframe>
                    <div class="text-center">
                        <h4 class="mb-3" style="text-transform: capitalize;">
                            {{pairedModalVideoData.paired_diamond_lot.paired_shape ?
                            pairedModalVideoData.paired_diamond_lot.paired_shape.shape_name :
                            '-'}}&nbsp;{{pairedModalVideoData.paired_diamond_lot.weight ?
                            pairedModalVideoData.paired_diamond_lot.weight :
                            '-'}}&nbsp;{{pairedModalVideoData.paired_diamond_lot.paired_color ?
                            pairedModalVideoData.paired_diamond_lot?.paired_color.color_name
                            : '-'}}&nbsp;{{pairedModalVideoData.paired_diamond_lot.paired_clarity ?
                            pairedModalVideoData.paired_diamond_lot.paired_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="displayImage">
            <div class="row px-3 pt-3">
                <div class="col-12 col-md-6">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;white-space: nowrap;">Stock Id :
                        {{pairedModalImageData.lot.altr_stone_id}}
                    </h4>
                </div>
                <div class="col-12 col-md-6">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;white-space: nowrap;">Stock Id :
                        {{pairedModalImageData.paired_diamond_lot.altr_stone_id}}
                    </h4>
                </div>
            </div>
            <div class="row px-3">
                <div class="col-12 col-md-6 imgSliderBlock">
                    <div class="d-flex justify-content-between">
                        <div class="carouselContainer">
                            <ngb-carousel [showNavigationArrows]="pairedModalImageData.lot.slider_images_array.length > 1" [interval]="false">
                                <ng-template *ngFor="let images of pairedModalImageData.lot.slider_images_array" ngbSlide>
                                    <div class="picsum-img-wrapper">
                                        <img class="diamondImagePopUp" src="{{images.url}}" alt="Image Not Available">
                                        <div class="text-center imagesText">{{images.name}}</div>
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                    <div class="text-center">
                        <h4 class="mt-4 mb-3" style="text-transform: capitalize;">
                            {{pairedModalImageData.lot.master_shape ?
                            pairedModalImageData.lot.master_shape.shape_name :
                            '-'}}&nbsp;{{pairedModalImageData.lot.weight ? pairedModalImageData.lot.weight :
                            '-'}}&nbsp;{{pairedModalImageData.lot.master_color ?
                            pairedModalImageData.lot?.master_color.color_name
                            : '-'}}&nbsp;{{pairedModalImageData.lot.master_clarity ?
                            pairedModalImageData.lot.master_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
                <div class="col-12 col-md-6 imgSliderBlock">
                    <div class="d-flex justify-content-between">
                        <div class="carouselContainer">
                            <ngb-carousel [showNavigationArrows]="pairedModalImageData.paired_diamond_lot.slider_images_array.length > 1" [interval]="false">
                                <ng-template *ngFor="let images of pairedModalImageData.paired_diamond_lot.slider_images_array"
                                    ngbSlide>
                                    <div class="picsum-img-wrapper">
                                        <img class="diamondImagePopUp" src="{{images.url}}" alt="Image Not Available">
                                        <div class="text-center imagesText">{{images.name}}</div>
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                    <div class="text-center">
                        <h4 class="mt-4 mb-3" style="text-transform: capitalize;">
                            {{pairedModalImageData.paired_diamond_lot.paired_shape ?
                            pairedModalImageData.paired_diamond_lot.paired_shape.shape_name :
                            '-'}}&nbsp;{{pairedModalImageData.paired_diamond_lot.weight ?
                            pairedModalImageData.paired_diamond_lot.weight :
                            '-'}}&nbsp;{{pairedModalImageData.paired_diamond_lot.paired_color ?
                            pairedModalImageData.paired_diamond_lot?.paired_color.color_name
                            : '-'}}&nbsp;{{pairedModalImageData.paired_diamond_lot.paired_clarity ?
                            pairedModalImageData.paired_diamond_lot.paired_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #diamondContent let-modal>
    <!-- <h2 class="modal-title text-center" style="margin: 0 auto;width: 100%;">{{displayVideo ? 'Videos' : '-'}}
        </h2> -->
    <div class="modal-body pb-0 pt-0">
        <button type="button" class="close" style="outline: none !important;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="displayVideo">
            <div class="row px-3 pt-3 align-items-center justify-content-center">
                <div class="col-12 col-md-6">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;white-space: nowrap;">Stock Id :
                        {{this.diamindModalVideoData.altr_stone_id}}
                    </h4>
                </div>
            </div>
            <div class="row px-3 align-items-center justify-content-center">
                <!-- <div class="col-12 col-md-4" *ngFor="let data of dataForModal">
                        <iframe width="350" height="500" src={{data?.video_path}} frameborder="0" allowfullscreen></iframe>
                    </div> -->
                <div class="col-12 col-md-6">
                    <iframe class="videoIfrmae" width="350" height="290" scrolling="no" [src]="diamindModalVideoData.video_path | safe :'resourceUrl'"
                        frameborder="0" allowfullscreen></iframe>
                    <div class="text-center">
                        <h4 class="mb-3" style="text-transform: capitalize;">
                            {{diamindModalVideoData.master_shape ?
                            diamindModalVideoData.master_shape.shape_name :
                            '-'}}&nbsp;{{diamindModalVideoData.weight ? this.diamindModalVideoData.weight :
                            '-'}}&nbsp;{{diamindModalVideoData.master_color ?
                            diamindModalVideoData?.master_color.color_name
                            : '-'}}&nbsp;{{diamindModalVideoData.master_clarity ?
                            diamindModalVideoData.master_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #detailContent let-modal>
    <!-- <h2 class="modal-title text-center" style="margin: 0 auto;width: 100%;">{{displayVideo ? 'Videos' : '-'}}
            </h2> -->
    <div class="modal-body pb-0 pt-0">
        <button type="button" class="close" style="outline: none !important;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="displayVideo">
            <div class="row px-3 pt-3 align-items-center justify-content-center">
                <div class="col-12 col-md-6">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;white-space: nowrap;">Stock Id :
                        {{diamindModalVideoData.altr_stone_id}}
                    </h4>
                </div>
            </div>
            <div class="row px-3 align-items-center justify-content-center">
                <!-- <div class="col-12 col-md-4" *ngFor="let data of dataForModal">
                            <iframe width="350" height="500" src={{data?.video_path}} frameborder="0" allowfullscreen></iframe>
                        </div> -->
                <div class="col-12">
                    <div class="d-flex justify-content-center">
                        <iframe class="videoIfrmae" width="350" height="290" scrolling="no" [src]="diamindModalVideoData.video_path | safe :'resourceUrl'"
                            frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div class="text-center">
                        <h4 *ngIf="!diamindModalVideoData.lot" class="mb-3" style="text-transform: capitalize;">
                            {{diamindModalVideoData.master_shape ?
                            diamindModalVideoData.master_shape.shape_name :
                            '-'}}&nbsp;{{diamindModalVideoData.weight ? this.diamindModalVideoData.weight :
                            '-'}}&nbsp;{{diamindModalVideoData.master_color ?
                            diamindModalVideoData?.master_color.color_name
                            : '-'}}&nbsp;{{diamindModalVideoData.master_clarity ?
                            diamindModalVideoData.master_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                        <h4 *ngIf="diamindModalVideoData.lot" class="mb-3" style="text-transform: capitalize;">
                            {{diamindModalVideoData.lot.master_shape ?
                            diamindModalVideoData.lot.master_shape.shape_name :
                            '-'}}&nbsp;{{diamindModalVideoData.weight ? this.diamindModalVideoData.weight :
                            '-'}}&nbsp;{{diamindModalVideoData.lot.master_color ?
                            diamindModalVideoData.lot.master_color.color_name
                            : '-'}}&nbsp;{{diamindModalVideoData.lot.master_clarity ?
                            diamindModalVideoData.lot.master_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="displayImage">
            <div class="row px-3 pt-3 justify-content-center">
                <div class="col-12 col-md-6">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;white-space: nowrap;">Stock Id :
                        {{diamindModalVideoData.altr_stone_id}}
                    </h4>
                </div>
            </div>
            <div class="row px-3">
                <div class="col-12 imgSliderBlock">
                    <div class="d-flex justify-content-between">
                        <div class="carouselContainer">
                            <ngb-carousel [showNavigationArrows]="diamindModalVideoData.slider_images_array.length > 1" [interval]="false">
                                <ng-template *ngFor="let images of diamindModalVideoData.slider_images_array;let index as i;"
                                    ngbSlide>
                                    <div class="picsum-img-wrapper">
                                        <img class="diamondImagePopUp" src="{{images.url}}" alt="Image Not Available">
                                    </div>
                                    <div class="text-center imagesText">{{images.name}}</div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                    <div class="text-center pt-3">
                        <h4 *ngIf="!diamindModalVideoData.lot" class="mb-3 mt-3" style="text-transform: capitalize;">
                            {{diamindModalVideoData.master_shape ?
                            diamindModalVideoData.master_shape.shape_name :
                            '-'}}&nbsp;{{diamindModalVideoData.weight ? this.diamindModalVideoData.weight :
                            '-'}}&nbsp;{{diamindModalVideoData.master_color ?
                            diamindModalVideoData?.master_color.color_name
                            : '-'}}&nbsp;{{diamindModalVideoData.master_clarity ?
                            diamindModalVideoData.master_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                        <h4 *ngIf="diamindModalVideoData.lot" class="mb-3 mt-3" style="text-transform: capitalize;">
                            {{diamindModalVideoData.lot.master_shape ?
                            diamindModalVideoData.lot.master_shape.shape_name :
                            '-'}}&nbsp;{{diamindModalVideoData.weight ? this.diamindModalVideoData.weight :
                            '-'}}&nbsp;{{diamindModalVideoData.lot.master_color ?
                            diamindModalVideoData.lot.master_color.color_name
                            : '-'}}&nbsp;{{diamindModalVideoData.lot.master_clarity ?
                            diamindModalVideoData.lot.master_clarity.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>