import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, Subject } from 'rxjs';
import { DataService } from './data-service.service';
import { URLS } from '../core/_constant/api.config';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';

@Injectable({
    providedIn: 'root'
})

export class AddToCartService {
    public IsAddToCart: boolean = false;
    storedLotIds: any = [];
    orderCartListObject: any = [];
    public orderLength:any;
    @Output() removeCartAction: EventEmitter<any> = new EventEmitter();

    constructor(
        private http: HttpClient,
        public dataService: DataService,
        public loader: LoaderService,
        public notifyService: NotifyService,
    ) { }

    private subscription: Subscription = new Subscription();

    getOrderList() {
        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.post(URLS.getOrderList, { page_record: 1000, page_no: 1 }).subscribe(res => {
                    if (res['code'] == 200) {
                        this.orderCartListObject = res.data;
                        this.orderLength = this.orderCartListObject.rows.length;
                    } else {
                        this.notifyService.showToast(res["message"]);
                    }
                    resolve(res);
                }, err => {
                    reject(err);
                })
            )
        })
    }

    addToCart(diamondData, wishList?) {
        this.loader.showSpinner();
        const params = {
            lot_id: diamondData.lot_id

        };
        console.log(diamondData);
        if (diamondData.paired_diamond_lot && diamondData.paired_diamond_lot.lot_id) {
            params['paired_lot_id'] = diamondData.paired_lot_id;
        }
        if (wishList) {
            params['from_wishlist'] = true;
        }

        this.subscription.add(
            this.dataService.post(URLS.addToCart, params).subscribe(
                data => {
                    if(data["code"] == 400 && data["message"] == "Lot has been already added to cart."){
                        this.getOrderList();
                    }
                    if (data['code'] === 200) {
                        setTimeout(() => {
                            this.loader.hideSpinner();
                        }, 200);
                        this.getOrderList().then(() => {
                            this.notifyService.showToast(data['message']);
                        });
                    } else {
                        this.notifyService.showToast(data['message']);
                        this.loader.hideSpinner();
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );

    }


    removeFromCart(diamondData, callback = null, orderCart?) {
        this.loader.showSpinner();
        
        const params = {
            lot_id: diamondData.lot_id
        }

        let cartObject;
        if (diamondData.paired_lot_id)
            cartObject = this.orderCartListObject.rows.find(x => x.paired_lot_id == diamondData.paired_lot_id && x.lot_id == diamondData.lot_id);        

        if (cartObject) {
            params['order_cart_id'] = cartObject.order_cart;
        }
        this.subscription.add(
            this.dataService.post(URLS.removeFromCart, params).subscribe(
                data => {
                    if (data["code"] == 200) {
                        // this.storedLotIds.splice(this.storedLotIds.indexOf(lotId), 1);
                        // this.notifyService.showToast(data["message"]);
                        setTimeout(() => {
                            this.loader.hideSpinner();
                        }, 200);
                        this.removeCartAction.emit(null);
                        this.getOrderList().then(() => {
                            this.notifyService.showToast(data['message']);
                        });
                        if (callback) {
                            callback(data['data']['add_to_cart']);
                        }
                        if(orderCart){
                            orderCart;
                        }
                    } else {
                        this.notifyService.showToast(data["message"]);
                        this.loader.hideSpinner();
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

}
