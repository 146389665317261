import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class SharedCartService {

    @Output() reloadCartAction: EventEmitter<any> = new EventEmitter();

    constructor() { }

    public reloadCart(){
        this.reloadCartAction.emit(null);
    }

}
