<section class="maincontainer">
    <p class="mainLabel2">Manage Retail Price</p>
    <div class="line1"></div>
    <div class="formDiv">
        <div style=" padding: 0 0px;">
            <div style="margin:20px 0" class="floatLeft">
                <p class="d-flex align-items-center">Margin
                    <span class="tooltip">
                        <i class="fa fa-info"></i>
                        <span class="tooltiptext">{{limitMessage}}</span>
                    </span>
                </p>
            </div>
            <div style="margin:20px 0" class="floatRight">
                <button mat-stroked-button class="whitebtnForm" (click)="addRow()">Add</button>
            </div>
        </div>
        <div class="list-view" style="padding :0px;padding-bottom: 75px;">
            <div class="table-responsive">
                <table class="table table-striped table-fixed mb-0">
                    <thead>
                        <tr>
                            <th scope="col" class="col-auto columnWidth">From ({{currencySymbol}})</th>
                            <th scope="col" class="col-auto columnWidth">To ({{currencySymbol}})</th>
                            <th scope="col" class="col-auto columnWidth">Margin %</th>
                            <th scope="col" class="col-1" style="height: 38px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of markupSelectArray;let i =index" style="background:#F9F9F9;height: 60px;">
                            <td class="col-auto columnWidth">
                                <div class="inputdiv">
                                    <input type="number" matInput placeholder="From" [(ngModel)]="data.range_from" (keypress)="omit_char($event)" disabled />
                                </div>
                            </td>
                            <td class="col-auto columnWidth">
                                <div class="inputdiv">
                                    <input type="number" matInput placeholder="To" [(ngModel)]="data.range_to" (keypress)="omit_char($event)" (input)="toChange($event, i)" />
                                </div>
                            </td>
                            <td class="col-auto columnWidth">
                                <div class="inputdiv">
                                    <input type="number" matInput [(ngModel)]="data.markup" (keypress)="omit_charKey($event)" />
                                </div>
                            </td>
                            <td class="col-1">
                                <a href="javascript:void(0)" (click)="deleteRow(data,i)"><img src="./assets/img/svg/delete.svg" class="iconSize"></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div style="margin-top:20px" class="floatRight">
                <button mat-stroked-button class="whitebtnForm" type="button" style="margin-right: 10px;" (click)="onCancel()">Reset</button>
                <button mat-raised-button class="blackbtnForm" type="submit" (click)="onSubmit()">Save</button>
            </div>
        </div>
    </div>
</section>