import { Component, OnInit } from '@angular/core';
import { PairedDiamondService } from './paired-diamond.service';
import { diamondList } from '../../core/_model/diamond';
import { CommonService } from 'src/app/core/_common/common.service';
import { Router, NavigationStart } from '@angular/router';

const DIAMONDLIST: diamondList[] = [
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    },
    {
        shape: 'Round',
        carat: '2.29',
        color: 'G',
        clarity: 'VS1',
        cut: 'VG',
        report: 'GIA',
        priceUsd: '$390',
        priceInr: '$28468',
    }
];

interface locations {
    value: string;
    viewValue: string;
}
interface Availabilities {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-paired-diamond',
    templateUrl: './paired-diamond.component.html',
    styleUrls: ['./paired-diamond.component.scss'],
    providers: [PairedDiamondService]

})
export class PairedDiamondComponent implements OnInit {

    public diamondType: any;

    public viewGrid = false;
    public viewList = true;

    public showDiamondDetail = false;

    active = 1;

    // List Tables  
    diamondList = DIAMONDLIST;

    diamondListForGridView: any = [
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
        { 'id': "1", 'title': "0.31CT ROUND K SI2 EXCELLENT CUT", 'img': "./assets/img/diamond.png", 'price1': '$390', 'price2': '₹28468' },
    ];

    locations: locations[] = [
        {value: 'southCarolina', viewValue: 'South Carolina'},
        {value: 'india', viewValue: 'India'},
    ];
    selectedLocation = this.locations[0].value;

    Availabilities: Availabilities[] = [
        {value: 'immediately', viewValue: 'Immediately'},
        {value: 'notAvailable', viewValue: 'Not Available'},
    ];
    selectedAvailability = this.Availabilities[0].value;

    constructor(
        private pairedDiamondService: PairedDiamondService, 
        public commonService: CommonService,
        private router: Router,
    ) { }

    showDiamondDetails() {
        this.showDiamondDetail = true;
    }

    ngOnInit(): void {
        if (this.router.url === '/whiteDiamond') {
            this.diamondType = 'w';
        } else if (this.router.url === '/coloredDiamond') {
            this.diamondType = 'c';
        } else if (this.router.url === '/pairedDiamond'){
            this.diamondType = 'p';
        }

        this.commonService.setEvent('SelectMenu', { key: 'Paired Diamond', value: 'paired' });
    }

}
