import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './core/_guard/auth.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './core/_interceptor/httpconfig.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { NotificationlistComponent } from './pages/notificationlist/notificationlist.component';
import { PairedDiamondComponent } from './pages/paired-diamond/paired-diamond.component';
import { HomeComponent } from './pages/home/home.component';
import { CommonService } from './core/_common/common.service';
import { NotifyService } from './core/_common/snackbar.service';
import { OrderCartComponent } from './pages/order-cart/order-cart.component';
import { OrderHistoryComponent } from './pages/order-history/order-history.component';
import { DiamondDetailComponent } from './pages/diamond-detail/diamond-detail.component';
import { PairedDiamondDetailComponent } from './pages/paired-diamond-detail/paired-diamond-detail.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoaderService } from './core/_common/loader.service';
import { ViewUserComponent } from './pages/view-user/view-user.component';
import { DataService } from './services/data-service.service';
import { WhiteDiamondModule } from './pages/white-diamond/white-diamond.module';
// import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { ModalComponent } from './core/_component/modal/modal.component';
import { ConfirmationDialogComponent } from './core/_component/confirmation-dialog/confirmation-dialog.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';

@NgModule({
    declarations: [
        AppComponent,
        // ChangePasswordComponent,
        // UserDetailsComponent,
        // BusinessDetailComponent,
        // RoleManagementComponent,
        // WishlistComponent,
        NotificationlistComponent,
        // ManageUsersComponent,
        // ManageSellPriceComponent,
        PairedDiamondComponent,
        HomeComponent,
        // AddRoleComponent,
        // AddUserComponent,
        OrderCartComponent,
        OrderHistoryComponent,
        DiamondDetailComponent,
        PairedDiamondDetailComponent,
        OrderDetailComponent,
        PageNotFoundComponent,
        ViewUserComponent,
        ComingSoonComponent,
        // CreditFormsComponent,
        // ForgotpasswordComponent,
    ],
    imports: [
        NgbModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        CoreModule,
        NgxSpinnerModule,
        BrowserAnimationsModule, // required animations module
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        WhiteDiamondModule,
        // NgxSpinnerModule,
    ],
    providers: [
        AuthGuard,
        CookieService,
        CommonService,
        NotifyService,
        LoaderService,
        DataService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    ],
    entryComponents:[ModalComponent,ConfirmationDialogComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }
