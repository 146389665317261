import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {


    constructor(private router: Router,
        private authervice: AuthenticationService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let activeRoute = JSON.parse(localStorage.getItem('routeCheck'));
        let routeUrl: any;
        if (next.url && next.url.length > 0) {
            routeUrl = next.url[0].path.replace(/-/g, "_");
        }
        const getAccessToken = this.authervice.hasToken();
        if(!state.url.includes('diamondDetail') && !state.url.includes('pairedDiamondDetail')) {
            if (getAccessToken && activeRoute) {
                Object.keys(activeRoute).forEach(key => {
                    if (!activeRoute[key] && routeUrl == key) {
                        this.router.navigate(['whiteDiamond']);
                    }
                })
    
                return true;
            }
        }

        let isLoggedIn;
        this.authervice.isLoggedIn().subscribe(
            (data) => {
                isLoggedIn = data;
            }
        );
        if (isLoggedIn || (state.url.includes('diamondDetail') || state.url.includes('pairedDiamondDetail'))) {
            // authorised so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        // this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        this.router.navigateByUrl('/login');
        return false;
        // return true;
    }
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.canActivate(childRoute, state)
    }

}
