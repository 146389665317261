import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data-service.service';
import { WishListService } from 'src/app/services/wishlist.service';
import { CommonService } from '../../_common/common.service';
import { HeaderService } from './header.service';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { URLS } from '../../_constant/api.config';
import { orderBy, union } from 'lodash';
import { AddToCartService } from '../../../services/addtocart.service';
import { SharedHeaderService } from 'src/app/services/sharedHeader.service';
import { SharedCartService } from 'src/app/services/sharedCartHeader.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from "ngx-cookie-service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @ViewChild('cartElement') cartElement: ElementRef;
    private subscription: Subscription = new Subscription();
    private sharedHeaderSubscription: Subscription = new Subscription();
    public tabs: any = [];
    public orderList = [];
    public currencyCode: any;
    public currencySymbol: any;
    public totalRecords: any;
    public totalAmount: any;
    public totalConversionAmount: any;
    public isCollapsed = true;
    public shareToclient: boolean = false;
    public displayPrice: any;
    public isMenuCollapsed = true;
    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.cartElement.nativeElement.contains(event.target)) {
            this.isCollapsed = true;
        }
    }

    //Scroll pagination 
    scrollposition: any;
    paginationScroll: boolean = false;
    isApiLoading: boolean = false;

    public filterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 15
    };

    wishListCount: any;
    constructor(private router: Router,
        public commonService: CommonService,
        public dataService: DataService,
        public loader: LoaderService,
        public notifyService: NotifyService,
        public wishlistServ: WishListService,
        public headerService: HeaderService,
        public addToCartService: AddToCartService,
        public sharedHeaderService: SharedHeaderService,
        public sharedCartService: SharedCartService,
        public dialog: MatDialog,
        public cookieService: CookieService,
        public authService: AuthenticationService,
        private modalService: NgbModal,
    ) {
        this.commonService.eventObservable.subscribe(event => {
            // console.log(event);
            if (event.event == 'SelectMenu') {
                for (var i = 0; i < this.tabs.length; i++) {
                    if (this.tabs[i].value == event.data.value) {
                        this.tabs[i].select = true;
                    }
                    else {
                        this.tabs[i].select = false;
                    }
                }

            }
        });
    }
    openDialog(diamondData) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            height: '230px',
            width: '400px',
            data: { headerName: 'Remove from cart', text: 'Are you sure to remove the item from cart?' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.removeFromCart(diamondData);
            }
        });
    }

    getMenuList() {
        this.loader.showSpinner();
        const params = {
            login_type: "WEB"
        };
        this.subscription.add(
            this.headerService.getMenuList(params).subscribe(
                data => {
                    if (data['code'] === 200) {
                        data['data'].forEach(element => {
                            if (element.type === 'H' || element.type === 'B') {
                                this.tabs.push(element);
                            }
                        });

                        setTimeout(() => {
                            // this.loader.hideSpinner();
                        }, 200);
                    }
                    else {
                        // this.loader.hideSpinner();
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    ngOnInit(): void {
        // this.sharedHeaderSubscription = this.sharedHeaderService.reloadHeaderAction.subscribe(data => {
        //     this.getOrderList();
        // });
        const shareUrl = this.router.url.split('?');
        if (shareUrl[0] === '/diamondDetail' || shareUrl[0] === '/pairedDiamondDetail') {
            this.shareToclient = true;
        } else {
            this.getMenuList();
            this.wishlistServ.getWishListCount();
            this.addToCartService.getOrderList();
            this.getOrderList();
        }
        
    }

    tabClick(e: any, dataTab: any) {
        var tab = document.getElementsByClassName("tab");
        for (var i = 0; i < tab.length; i++) {
            tab[i].className = tab[i].className.replace(" active", "");
        }
        e.currentTarget.className += " active";

        switch (dataTab.value) {
            case 'white':
                this.router.navigate(['/whiteDiamond']); break;
            case 'colored':
                this.router.navigate(['/coloredDiamond']); break;
            case 'volumeBuy':
                this.router.navigate(['/volumeBuy']); break;
            case 'bestValue':
                this.router.navigate(['/bestValue']); break;
            case 'paired':
                this.router.navigate(['/pairedDiamond']); break;
            case 'melee':
                this.router.navigate(['/Melee']); break;
            case 'orderHistory':
                this.router.navigate(['/order-history']); break;
            case 'manageUsers':
                this.router.navigate(['/user-setting/manage-user']); break;
            default:
                this.router.navigate(['/whiteDiamond']); break;
        }
    }


    gotoUserSetting() {
        this.router.navigateByUrl('/user-setting/user-detail');
        var tab = document.getElementsByClassName("tab");
        for (var i = 0; i < tab.length; i++) {
            tab[i].className = tab[i].className.replace(" active", "");
        }
    }

    gotoWishList() {
        this.router.navigateByUrl('/wishlist');
        // this.router.navigateByUrl('/user-setting/wishlist');
    }

    gotoOrderCart() {
        this.isCollapsed = true;
        this.router.navigateByUrl('/order-cart');
    }

    //Scroll pagination 
    ngAfterViewInit() {
        this.scrollposition = 0;
    }
    onScroll(e) {
        const currentValue = this.scrollposition;
        const element = e.target;
        let pos = (element.scrollTop || element.scrollTop) + element.offsetHeight;
        let max = element.scrollHeight;
        const difference = max - pos;
        if (difference >= 0 && difference <= 20 && currentValue < element.scrollTop) {
            if (this.filterObj.enableNext) {
                this.paginationScroll = true;
                this.getOrderList(true);
                this.paginationScroll = false;
            }
        }
        this.scrollposition = element.scrollTop;
    }


    convertToPDF() {
        html2canvas(document.body).then(canvas => {
            const contentDataURL = canvas.toDataURL('image/png');
            let pdf = new jsPDF('p', 'mm', 'a4');
            const imageProps = pdf.getImageProperties(contentDataURL);
            var width = pdf.internal.pageSize.getWidth();
            var height = canvas.height * width / imageProps.width;
            pdf.addImage(contentDataURL, 'PNG', 0, 0, width, height);
            pdf.save('diamondDetail.pdf');
        });
    }

    // Get order cart data
    getOrderList(isInfinteScrollCalled?: boolean) {
        if (this.isApiLoading) {
            return;
        }
        this.isApiLoading = true;
        let params = {
            "page_record": this.filterObj.pageRecord,
        }
        // Scroll pagination
        if (this.paginationScroll) {
            params['page_no'] = this.filterObj.page
        } else {
            params['page_no'] = 1
        }
        this.subscription.add(
            this.headerService.getOrderCartList(params).subscribe(
                data => {
                    this.isApiLoading = false;
                    if (data["code"] == 200 && !isInfinteScrollCalled) {
                        this.orderList = data["data"]['rows'];
                        this.filterObj.enableNext = data["data"]['enableNext'];
                        this.currencyCode = data["data"]['conversion_currency_code'];
                        this.currencySymbol = data["data"]['currency_symbol'];
                        this.totalRecords = data["data"]['count'];
                        this.totalAmount = data["data"]['total_amount'];
                        this.totalConversionAmount = data["data"]['total_conversion_amount'];
                        this.displayPrice = data["data"]["display_price"];
                    }

                    // Scroll pagination
                    else if (data["code"] == 200 && isInfinteScrollCalled) {
                        this.orderList = orderBy(union(this.orderList, data['data']['rows']));
                        this.filterObj.enableNext = data["data"]['enableNext'];
                        this.currencyCode = data["data"]['conversion_currency_code'];
                        this.currencySymbol = data["data"]['currency_symbol'];
                        this.totalRecords = data["data"]['count'];
                        this.totalAmount = data["data"]['total_amount'];
                        this.totalConversionAmount = data["data"]['total_conversion_amount'];
                        this.displayPrice = data["data"]["display_price"];
                    }
                    else {
                        this.notifyService.showToast(data["message"]);
                    }
                    this.filterObj.page++;
                    this.orderList.forEach(element => {
                        if (!this.addToCartService.storedLotIds.includes(element.lot_id)) {
                            this.addToCartService.storedLotIds.push(element.lot_id)
                        }
                    });
                },
                error => {
                    this.isApiLoading = false;
                    console.log(error);
                }
            )
        );
    }

    // Remove From Cart
    removeFromCart(diamondData) {
        this.addToCartService.removeFromCart(diamondData);
    }

    copyLink() {
        const copyText = document.createElement('input');
        document.body.appendChild(copyText);
        copyText.value = window.location.href;
        copyText.select();
        document.execCommand('copy');
        document.body.removeChild(copyText);
        this.notifyService.showToast("URL copied successfully");
    }

    shareLink(content) {
        // let navigator: any;
        // navigator = window.navigator;
        // if (navigator.share) {
        //     navigator.share({
        //         title: "Diamond Detail",
        //         url: window.location.href
        //     });
        // }
        this.modalService.open(content, { size: 'sm', centered: true });
    }

    twitterShare() {
        let current_url = window.location.href;
        let tw_shareUrl = "https://twitter.com/intent/tweet?text=" + current_url;
        window.open(tw_shareUrl, "_blank");
        return false;
    }

    facebookShare() {
        let current_url = window.location.href;
        let fb_shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + current_url;
        window.open(fb_shareUrl, "_blank");
        return false;
    }

    whatsappShare() {
        let current_url = window.location.href;
        let wt_shareUrl = "https://web.whatsapp.com/send?text=" + current_url;
        window.open(wt_shareUrl, "_blank");
        return false;
    }

    openLogoutConf() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            height: '230px',
            width: '400px',
            data: { headerName: 'Sign Out', text: 'Are you Sure?' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.authService.logout().subscribe(
                    data => {
                        if (data["code"] == 200) {
                            this.cookieService.delete("token");
                            this.cookieService.delete("userData");
                            this.cookieService.deleteAll();
                            this.notifyService.showToast(data['message']);
                            this.router.navigateByUrl("/login");
                            localStorage.removeItem('DiamondCompareList');
                            localStorage.removeItem('colorDiamondCompareList');
                            if (window['Tawk_API']) {
                                window['Tawk_API'].minimize();
                                window['Tawk_API'].hideWidget();
                            }
                        }
                    },
                    err => {
                        console.error(err);
                    }
                );

            }
        });
    }

    logout() {
        this.openLogoutConf()
    }
}
