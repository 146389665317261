import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { CommonService } from 'src/app/core/_common/common.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PairedDiamondDetailService } from './paired-diamond-detail.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { FilterComponent } from 'src/app/core/_component/filter/filter.component';
import { ViewChild } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { orderBy, union } from 'lodash';
import { AddToCartService } from 'src/app/services/addtocart.service';
import { SharedHeaderService } from 'src/app/services/sharedHeader.service';
import { ModalComponent } from 'src/app/core/_component/modal/modal.component';

@Component({
    selector: 'app-paired-diamond-detail',
    templateUrl: './paired-diamond-detail.component.html',
    styleUrls: ['./paired-diamond-detail.component.scss'],
    providers: [PairedDiamondDetailService]
})
export class PairedDiamondDetailComponent implements OnInit {

    private subscription: Subscription = new Subscription();

    public diamondLotId: any;
    public pairedDiamondLotId: any;
    public diamondDetailData: any;
    public diamondLotData: any;
    public pairedDiamondLotData: any;
    public certiUrlForModal: any;
    public certiTitle: any;
    public measurement: any;
    public pairedMeasurement: any;
    public token: any;
    public isShareToClient: boolean = false;
    public displayPrice: any;


    constructor(
        private pairedDiamondDetailService: PairedDiamondDetailService,
        public commonService: CommonService,
        public activeRoute: ActivatedRoute,
        private router: Router,
        config: NgbCarouselConfig,
        private modalService: NgbModal,
        public loader: LoaderService,
        public addToCartService: AddToCartService,
        public sharedHeaderService: SharedHeaderService
    ) {
        config.showNavigationArrows = true;
        config.showNavigationIndicators = false;
        this.diamondLotId = this.activeRoute.snapshot.paramMap.get("id");
    }

    ngOnInit(): void {
        window.scroll(0, 0);
        this.commonService.setEvent('SelectMenu', { key: 'Paired Diamond Detail', value: 'pairedDiamondDetail' });

        this.activeRoute.queryParams.subscribe((params) => {
            this.pairedDiamondLotId = params['pairedLotId'];
            this.token = params['code'];
        });
        if (this.token) {
            const shareParams = {
                code: this.token,
            }
            this.getSharePairedDiamondDetail(shareParams);
        }
        else {
            const params = {
                lot_id: +this.diamondLotId,
                paired_lot_id: this.pairedDiamondLotId
            }
            this.getPairedDiamondDetail(params);
        }

    }


    // Diamond List Data
    getPairedDiamondDetail(params) {
        this.loader.showSpinner();
        this.subscription.add(
            this.pairedDiamondDetailService.getPairedDiamondDetail(params).subscribe(
                data => {
                    if (data['code'] === 200) {
                        this.diamondDetailData = data['data'];
                        this.diamondLotData = data['data']['lot'];
                        this.pairedDiamondLotData = data['data']['paired_diamond_lot'];
                        this.displayPrice = data['data']['display_price'];
                        this.measurement = this.diamondLotData.measurement ? this.diamondLotData.measurement.replaceAll("*", " x ") : '-';
                        this.pairedMeasurement = this.pairedDiamondLotData.measurement ? this.pairedDiamondLotData.measurement.replaceAll("*", " x ") : '-';
                        const videoUrl = this.diamondLotData.video_path ? this.diamondLotData.video_path : 'https://www.altr.nyc/videos/diamond.html?d=440013676';
                        const videoUrl2 = this.pairedDiamondLotData.video_path ? this.pairedDiamondLotData.video_path : 'https://www.altr.nyc/videos/diamond.html?d=440013676';
                        document.getElementById("diamondIframe").setAttribute("src", videoUrl);
                        document.getElementById("diamondIframe2").setAttribute("src", videoUrl2);
                        // this.diamondDetailData.forEach(element => {
                        //     if (element.in_order_cart && !this.addToCartService.storedLotIds.includes(element.lot_id)) {
                        //         this.addToCartService.storedLotIds.push(element.lot_id)
                        //     }
                        // });
                    }
                    this.loader.hideSpinner();
                },

                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    getSharePairedDiamondDetail(params) {
        this.loader.showSpinner();
        this.subscription.add(
            this.pairedDiamondDetailService.getSharePairedDiamondDetail(params).subscribe(
                data => {
                    if (data['code'] === 200) {

                        this.diamondDetailData = data['data'];
                        this.diamondLotData = data['data']['lot'];
                        this.pairedDiamondLotData = data['data']['paired_diamond_lot'];
                        this.displayPrice = data['data']['display_price'];
                        this.measurement = this.diamondLotData.measurement ? this.diamondLotData.measurement.replaceAll("*", " x ") : '-';
                        this.pairedMeasurement = this.pairedDiamondLotData.measurement ? this.pairedDiamondLotData.measurement.replaceAll("*", " x ") : '-';
                        const videoUrl = this.diamondLotData.video_path ? this.diamondLotData.video_path : 'https://www.altr.nyc/videos/diamond.html?d=440013676';
                        const videoUrl2 = this.pairedDiamondLotData.video_path ? this.pairedDiamondLotData.video_path : 'https://www.altr.nyc/videos/diamond.html?d=440013676';
                        document.getElementById("diamondIframe").setAttribute("src", videoUrl);
                        document.getElementById("diamondIframe2").setAttribute("src", videoUrl2);
                        if (this.diamondDetailData.share_to_client_id) {
                            this.isShareToClient = true;
                        }
                    }
                    this.loader.hideSpinner();
                },

                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }


    openCertiModal(content, type, url) {
        if (type === 'CERTIFICATE') {
            this.certiUrlForModal = url ? url : './assets/img/certi.png';
        }
        else if (type === 'LASER INSCRIPTION') {
            this.certiUrlForModal = url ? url : './assets/img/certi.png';
        }
        else if (type === 'PROPORTION DIAGRAM') {
            this.certiUrlForModal = url ? url : './assets/img/proportion-btn.png';
        }
        this.certiTitle = type;
        this.modalService.open(content, { size: 'xl' });
    }

    //Add to cart
    AddToCart(diamondData) {
        if (this.getCartObject(diamondData)) {
            this.addToCartService.removeFromCart(diamondData);
        } else {
            this.addToCartService.addToCart(diamondData);
        }
    }

    getCartObject(diamondData) {
        let cartObject;
        if (diamondData) {
            if (diamondData.paired_diamond_lot && diamondData.paired_diamond_lot.lot_id)
                cartObject = this.addToCartService.orderCartListObject.rows.find(x => x.paired_lot_id == diamondData.paired_lot_id && x.lot_id == diamondData.lot_id);
            else
            if(this.addToCartService.orderCartListObject && this.addToCartService.orderCartListObject.rows)
                cartObject = this.addToCartService.orderCartListObject.rows.find(x => x.lot_id == diamondData.lot_id && !x.paired_lot_id );
        }
        return cartObject ? true : false;
    }

    getLabelForCart(diamondData) {

        if (this.getCartObject(diamondData))
            return "Remove from Cart";
        else
            return "Add to Cart";
    }

    //Share to client modal
    showClientModal(data) {
        const modalRef = this.modalService.open(ModalComponent, { centered: true, scrollable: true });
        modalRef.componentInstance.shareClientData = data;
        modalRef.componentInstance.displayPrice = this.displayPrice;
    }

}
