<section class="section first-section">
    <div class="container-fluid">
        <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner>

        <div class="title">
        </div>
        <div class="row">
            <div class="col-12 tableDiv">
                <div class="header-strip">
                    <!-- <div class="search-block">
                        <input type="text" name="search" autocomplete="off" placeholder="Search by Order No, Stock Id">
                        <i class="fa fa-search"></i>
                    </div> -->
                    <div class="search-block">
                        <input type="text" name="search" autocomplete="off" [(ngModel)]="search" placeholder="Search by Order No, Stock Id" (keydown.enter)="searchData()" (keyup)="searchKeyData()">
                        <i *ngIf="this.search" class="fa fa-search" (click)="searchData()"></i>
                        <!-- <i *ngIf="this.search && searchActive" class="fa fa-times-circle-o clear" (click)="clearData()"></i> -->
                    </div>
                    <div class="right-header-strip mat-select-custom">
                            <div class="order-category">
                                    <label for="order">Order Category :</label>
                                    <mat-form-field appearance="fill" class="listDropdown">
                                        <mat-select [(ngModel)]="selectedOrder" (selectionChange)="orderChange($event)">
                                            <mat-option *ngFor="let order of orderCategory" [value]="order.value">
                                                {{order.value}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                        <div class="role">
                            <label for="role">Users List :</label>
                            <!-- <mat-form-field appearance="fill">
                                <mat-select value="0" (selectionChange)="roleChange($event)">
                                    <mat-option value="0" selected>All</mat-option>
                                    <mat-option *ngFor="let role of roles" [value]="role.client_role_id">
                                        {{role.client_role_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <mat-form-field appearance="fill" class="listDropdown">
                                    <mat-select value="0" (selectionChange)="userListChange($event)">
                                        <mat-option value="0" selected>All</mat-option>
                                        <mat-option *ngFor="let user of userList;" [value]="user.user_id">
                                                {{user.user_name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                        </div>
                        <div class="status">
                            <label for="status">Status :</label>
                            <mat-form-field appearance="fill" class="listDropdown">
                                <mat-select [(ngModel)]="selectedStatus" (selectionChange)="statusChange($event)">
                                    <mat-option *ngFor="let status of statuss" [value]="status.value">
                                        {{status.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped table-fixed mb-0">
                        <thead>
                            <tr>
                                <th scope="col">Order No</th>
                                <th scope="col">Date</th>
                                <th scope="col">Total Lots</th>
                                <th scope="col">Pending Lots</th>
                                <!-- <th scope="col" *ngIf="displayPrice === 'b' || displayPrice === 'c' ">Cost Price USD</th> -->
                                <th scope="col" *ngIf="displayPrice === 'b' || displayPrice === 'c'">Cost {{currencyCode}}</th>
                                <!-- <th scope="col" *ngIf="displayPrice === 'b' || displayPrice === 's'">Sell Price USD</th> -->
                                <th scope="col" *ngIf="displayPrice === 'b' || displayPrice === 's'">Retail {{currencyCode}}</th>
                                <th scope="col">Placed By</th>
                                <th scope="col">Role</th>
                                <th scope="col" class="statusCol">Status</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody (scroll)="onScroll($event)" class="thikScroll">
                            <a *ngFor="let order of orderList; index as i">
                                <tr (click)="goToOrderDetail(order.diamond_order_id);" [ngClass]="displayPrice === 's' || displayPrice === 'c' ? 'singlePrice' : ''">
                                    <td class="">{{order.order_no}}</td>
                                    <td class="">{{order.date_of_order | date:'MM-dd-yyyy'}}</td>
                                    <td class="">{{order.no_of_lots}}</td>
                                    <td class="">{{order.remaining_approval_lots === null ? '-' : order.remaining_approval_lots}}</td>
                                    <!-- <td class="" *ngIf="displayPrice === 'b' || displayPrice === 'c' ">${{ order.total_amount }}</td> -->
                                    <td class="" *ngIf="displayPrice === 'b' || displayPrice === 'c'">{{currencySymbol}}{{ order.local_total_amount | number}}
                                    </td>
                                    <!-- <td class="" *ngIf="displayPrice === 'b' || displayPrice === 's'">${{ order.total_sell_amount }}</td> -->
                                    <td class="" *ngIf="displayPrice === 'b' || displayPrice === 's'">{{currencySymbol}}{{ order.local_total_sell_amount | number}}
                                    </td>
                                    <td class="">{{order.place_by_name}}</td>
                                    <td>{{order.role_name}}</td>
                                    <td *ngIf="order.status == 1" class=" pending" class="statusCol">Pending</td>
                                    <td *ngIf="order.status == 2" class=" placed" class="statusCol">Order Placed</td>
                                    <td *ngIf="order.status == 3" class=" rejected" class="statusCol">Rejected</td>
                                    <td *ngIf="order.status == 4" class=" approve" class="statusCol">Approved</td>
                                    <td *ngIf="order.status == 5" class=" approve" class="statusCol">Shipped</td>
                                    <!-- <td class="col-2" [ngClass]="[order.status === 1 ? 'pending' : '',  order.status === 3 ? 'rejected' : '', order.status === 2 ? 'placed' : '', order.status === 4 ? 'shipped' : '',]">
                                        {{order.statusVal}}</td> -->
                                    <td class="col-1">
                                        <div>
                                            <a (click)="goToOrderDetail(order.diamond_order_id);"><img src="../../../assets/img/Vision.png" style="cursor: pointer"></a>
                                        </div>
                                    </td>
                                </tr>
                            </a>
                            <tr *ngIf="orderList.length <= 0" class="d-flex justify-content-center">
                                <p> No Records Found </p>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>