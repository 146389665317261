import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/core/_common/common.service";
import { Subscription } from "rxjs";
import { NotifyService } from "../../core/_common/snackbar.service";
import { NavigationExtras, Router } from "@angular/router";
import { LoaderService } from "src/app/core/_common/loader.service";
import { NgZone } from "@angular/core";
import { DataService } from "src/app/services/data-service.service";
import { URLS } from "src/app/core/_constant/api.config";
import { orderBy, union } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../core/_component/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: "app-manage-users",
    templateUrl: "./manage-users.component.html",
    styleUrls: ["./manage-users.component.scss"],
})
export class ManageUsersComponent implements OnInit {

    manageUserList = [];
    roleList = [];
    private subscription: Subscription = new Subscription();
    // pageRecord = 10;
    // pageNo = 1;
    totalRecords: any;
    search: any;
    searchActive: boolean = false;
    countryCode: any;
    is_sub_user: any;
    sub_user_role_id: any;
    sub_user_role_name: any;
    filterParams: any;

    //Scroll pagination 
    scrollposition: any;
    paginationScroll: boolean = false;
    isApiLoading: boolean = false;
    public filterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 12
    };

    constructor(
        public commonService: CommonService,
        public dataService: DataService,
        private notifyService: NotifyService,
        public router: Router,
        public loader: LoaderService,
        public zone: NgZone,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.getRole();
        this.getUserData();
    }

    //Scroll pagination 
    ngAfterViewInit() {
        this.scrollposition = 0;
    }

    onScroll(e) {
        const currentValue = this.scrollposition;
        const element = e.target;
        let pos = (element.scrollTop || element.scrollTop) + element.offsetHeight;
        let max = element.scrollHeight;
        const difference = max - pos;
        if (difference >= 0 && difference <= 20 && currentValue < element.scrollTop) {
            if (this.filterObj.enableNext) {
                this.paginationScroll = true;
                // this.getDiamondList(params, true);
                this.getUserData(true);
                this.paginationScroll = false;
            }
        }
        this.scrollposition = element.scrollTop;
    }


    getRole() {
        //   const params ={
        //     is_master:false
        //   }
        // this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getRoleMaster).subscribe(
                data => {
                    if (data["code"] == 200) {
                        this.roleList = data["data"];
                        console.log(this.roleList);
                        // this.isLoading = false;
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        );
    }


    filterData(ev) {
        if (ev !== "null") {
            this.filterParams = +ev;
            this.filterObj.page = 1;
            this.getUserData();
        }
        else {
            this.filterParams = null;
            this.filterObj.page = 1;
            this.getUserData();
        }

    }

    clearData() {
        this.search = null;
        this.searchActive = false;
        this.getUserData();
    }
    searchKeyData() {
        if (this.search == '' || this.search == null) {
            this.filterObj.page = 1;
            this.getUserData();
        }
    }
    searchData() {
        console.log(this.search);

        if (this.search == '' || this.search == null || this.search.trim().length == 0) {
            this.notifyService.showToast('Please Enter Email ID or First Name');
            return;
        }
        else {
            this.filterObj.page = 1;
            this.getUserData();
        }

    }


    /**
     * Add Customer
     */
    addUser() {

        const navigationExtras: NavigationExtras = {
            queryParams: {
                countryCode: this.countryCode,
                is_sub_user: this.is_sub_user,
                sub_user_role_id: this.sub_user_role_id,
                sub_user_role_name: this.sub_user_role_name
            },
        };

        this.router.navigate(["/user-setting/manage-user/addUser"], navigationExtras);
    }

    getUserData(isInfinteScrollCalled?: boolean) {
        // this.isLoading = true;
        if (this.isApiLoading) {
            return;
        }
        this.isApiLoading = true;
        this.loader.showSpinner();
        this.searchActive = false;

        let params = {
            "page_record": this.filterObj.pageRecord,
        }
        // Scroll pagination
        if (this.paginationScroll) {
            params['page_no'] = this.filterObj.page
        } else {
            params['page_no'] = 1
        }

        if (this.filterParams) {
            params['filterby'] = { client_role_id: this.filterParams };
        }
        if (this.search) {
            params['search'] = this.search
        }

        this.subscription.add(
            this.dataService.post(URLS.getUsers, params).subscribe(
                data => {
                    this.isApiLoading = false;
                    if (data["code"] == 200 && !isInfinteScrollCalled) {
                        this.manageUserList = data["data"]['rows'];
                        this.countryCode = data["data"]['country_code'];
                        this.is_sub_user = data["data"]['is_sub_user'];
                        this.sub_user_role_id = data["data"]['sub_user_role_id'];
                        this.sub_user_role_name = data["data"]['sub_user_role_name'];
                        this.filterObj.enableNext = data["data"]['enableNext'];
                        if (this.filterObj.page == 1) {
                            this.totalRecords = data["data"]['count'];
                        }
                        this.manageUserList = [...this.manageUserList]
                        if (this.search) {
                            this.searchActive = true;
                        }
                        // this.isLoading = false;
                    }
                    // Scroll pagination
                    else if (data["code"] == 200 && isInfinteScrollCalled) {
                        this.manageUserList = orderBy(union(this.manageUserList, data['data']['rows']));
                        this.countryCode = data["data"]['country_code'];
                        this.is_sub_user = data["data"]['is_sub_user'];
                        this.sub_user_role_id = data["data"]['sub_user_role_id'];
                        this.sub_user_role_name = data["data"]['sub_user_role_name'];
                        this.filterObj.enableNext = data["data"]['enableNext'];
                        if (this.filterObj.page == 1) {
                            this.totalRecords = data["data"]['count'];
                        }
                        this.manageUserList = [...this.manageUserList]
                        if (this.search) {
                            this.searchActive = true;
                        }
                        // this.isLoading = false;
                    }
                    else {
                        this.notifyService.showToast(data["message"]);
                    }
                    this.filterObj.page++
                    this.loader.hideSpinner();
                },
                error => {
                    this.loader.hideSpinner();
                    this.isApiLoading = false;
                    console.log(error);
                }
            )
        );
    }

    methodCall(page: number) {
        console.log(page);
        this.filterObj.page = page;
        this.getUserData();

    }
    
    activeToggle(event, data, dataobj) {
        let obj = {
            user_id: +data,
            is_active: event.checked,
        }
        if (event.checked) {
            this.updateUser(obj)
        }
        else {
            this.openDialog(event, data, dataobj)
        }

    }

    updateUser(obj) {
        this.loader.showSpinner();
        this.dataService.post(URLS.userUpdateStatus, obj).subscribe(
            response => {
                if (response["code"] == 200) {
                    // this.switchLoading = false;
                    this.notifyService.showToast(response["message"]);
                    this.loader.hideSpinner();
                } else {
                    this.notifyService.showToast('Error ' + response["message"]);
                    this.loader.hideSpinner();
                }
            },
            error => {
                this.loader.hideSpinner();
            }
        );
    }

    openDialog(event, data, dataobj) {
        const roleManagementDialog = this.dialog.open(ConfirmationDialogComponent, {
            height: '230px',
            width: '400px',
            data: { headerName: 'Deactivate User', text: 'Are you sure you want to deactivate user' },
            disableClose: true,
        });
        roleManagementDialog.afterClosed().subscribe(result => {
            if (result) {
                let obj = {
                    user_id: +data,
                    is_active: event.checked
                }
                this.updateUser(obj);
            }
            else {
                dataobj.is_active = true;
            }
        });
    }

    getRecord() {
        let datalength = this.filterObj.page * this.filterObj.pageRecord;
        let startlength = (datalength - this.filterObj.pageRecord) + 1;
        if (datalength > this.totalRecords) {
            datalength = this.totalRecords
        }
        return startlength + " - " + datalength + " Items of " + this.totalRecords;
    }
}
