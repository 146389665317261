import { Injectable } from '@angular/core';
import { DataService } from '../../services/data-service.service';
import { Observable } from 'rxjs';
import { URLS } from '../../core/_constant/api.config';
import { refineResponse } from '../../core/_model/refineList';

@Injectable ({
    providedIn: 'root'
})
export class PairedDiamondService {

  constructor(private dataService: DataService) { }

}