import { Component, OnInit } from '@angular/core';
import { OrderHistoryService } from './order-history.service';
import { CommonService } from 'src/app/core/_common/common.service';
import { Router, NavigationStart, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { orderBy, union } from 'lodash';
import { SharedHeaderService } from 'src/app/services/sharedHeader.service';

interface statuss {
    value: number;
    viewValue: string;
}
@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

    private subscription: Subscription = new Subscription();
    public orderList = [];
    public displayPrice: any;
    public orderStatus: any;
    public statusValue: any;
    public roleValue: any;
    public roles = [];
    public userList = [];
    public userListValue: any;
    public userObj: any;
    public orderValue: any;

    currencyCode: any;
    currencySymbol: any;

    public searchValue: any;
    search: any;
    searchActive: boolean = false;
    //Scroll pagination 
    scrollposition: any;
    paginationScroll: boolean = false;
    isApiLoading: boolean = false;
    public userData = this.userList
    public filterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 15
    };

    statuss: statuss[] = [
        { value: 0, viewValue: 'All' },
        { value: 1, viewValue: 'Pending' },
        { value: 2, viewValue: 'Order Placed' },
        { value: 3, viewValue: 'Rejected' },
        // { value: 4, viewValue: 'Approved' },
        // { value: 5, viewValue: 'Shipped' },

        // 1 : pending, 2 : order placed, 3 : reject, 4 : approved, 5 : shipped



    ];
    selectedStatus = this.statuss[0].value;

    orderCategory = [
        { value: 'All'},
        { value: 'Open'},
        { value: 'Closed'}
    ]
    selectedOrder = this.orderCategory[0].value;

    constructor(
        public orderHistoryService: OrderHistoryService,
        public commonService: CommonService,
        private router: Router,
        public loader: LoaderService,
        public notifyService: NotifyService,
        public sharedHeaderService: SharedHeaderService,
    ) {
        this.commonService.setEvent('SelectMenu', { key: 'Order History', value: 'orderHistory' });
    }

    goToOrderDetail(orderId) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                diamondOrderId: orderId,
            },
        };

        this.router.navigate(["/orderDetail"], navigationExtras);


    }

    ngOnInit(): void {
        setTimeout(() => {
            this.notifyService.hideToast()
        }, 1000);
        this.getOrderHistoryList();
        this.getRole();
        this.getPlaceUserList();
        this.sharedHeaderService.reloadHeader();
    }

    // Search
    clearData() {
        this.search = null;
        this.searchValue = null;
        this.searchActive = false;
        this.getOrderHistoryList();
    }

    searchKeyData() {
        if (this.search == '' || this.search == null) {
            this.getOrderHistoryList();
        }
    }
    searchData() {
        if (this.search == '' || this.search == null || this.search.trim().length == 0) {
            this.notifyService.showToast('Please Enter Order No or Stock Id');
            return;
        }
        this.searchValue = this.search;
        this.searchActive = true;
        this.getOrderHistoryList();
    }

    //Role Api
    getRole() {
        this.loader.showSpinner();
        this.subscription.add(
            this.orderHistoryService.getRole().subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data['code'] === 200) {
                        this.roles = data['data'];
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    //Scroll pagination 
    ngAfterViewInit() {
        this.scrollposition = 0;
    }

    onScroll(e) {
        const currentValue = this.scrollposition;
        const element = e.target;
        let pos = (element.scrollTop || element.scrollTop) + element.offsetHeight;
        let max = element.scrollHeight;
        const difference = max - pos;
        if (difference >= 0 && difference <= 20 && currentValue < element.scrollTop) {
            if (this.filterObj.enableNext) {
                this.paginationScroll = true;
                this.getOrderHistoryList(true);
                this.paginationScroll = false;
            }
        }
        this.scrollposition = element.scrollTop;
    }

    // Get order History List
    getOrderHistoryList(isInfinteScrollCalled?: boolean) {
        if (this.isApiLoading) {
            return;
        }
        console.log(this.userObj);
        this.isApiLoading = true;
        let params = {
            "page_record": this.filterObj.pageRecord,
            "status_filter" : this.statusValue,
            // role_filter: this.roleValue
        }
        if (this.userObj) {
            params['user_filter'] = {
                "user_id": this.userObj.user_id ,
                "place_by_type": this.userObj.place_by_type
            }
        }
        if (this.orderValue != "All") {
            params['filter'] = this.orderValue;
        }
        // Scroll pagination
        if (this.paginationScroll) {
            params['page_no'] = this.filterObj.page
        } else {
            params['page_no'] = 1
        }
        //Search
        if (this.searchValue) {
            params['search'] = this.searchValue
        }
        if (this.roleValue > 0) {
            params['role_filter'] = this.roleValue
        }
        this.loader.showSpinner();
        this.subscription.add(
            this.orderHistoryService.getOrderHistoryList(params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    this.isApiLoading = false;
                    if (data["code"] == 200 && !isInfinteScrollCalled) {
                        this.orderList = data["data"]["rows"];
                        console.log(this.orderList);
                        this.displayPrice = data["data"]["display_price"];
                        this.filterObj.enableNext = data["data"]['enableNext'];
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']

                        data["data"]["rows"].forEach(element => {
                            if (element.status === 1) {
                                element['statusVal'] = "Pending";
                            } else if (element.status === 2) {
                                element['statusVal'] = "Order Placed";
                            } else if (element.status === 3) {
                                element['statusVal'] = "Rejected";
                            } else if (element.status === 4) {
                                element['statusVal'] = "Shipped";
                            }
                        });
                    }
                    // Scroll pagination
                    else if (data["code"] == 200 && isInfinteScrollCalled) {
                        this.orderList = orderBy(union(this.orderList, data['data']['rows']));
                        this.filterObj.enableNext = data["data"]['enableNext'];
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']

                        data["data"]["rows"].forEach(element => {
                            if (element.status === 1) {
                                element['statusVal'] = "Pending";
                            } else if (element.status === 2) {
                                element['statusVal'] = "Order Placed";
                            } else if (element.status === 3) {
                                element['statusVal'] = "Rejected";
                            } else if (element.status === 4) {
                                element['statusVal'] = "Shipped";
                            }
                        });
                    }
                    else {
                        this.notifyService.showToast(data["message"]);
                    }
                    this.filterObj.page++
                    this.loader.hideSpinner();
                },
                error => {
                    this.loader.hideSpinner();
                    this.isApiLoading = false;
                    console.log(error);
                }
            )
        );
    }

    getPlaceUserList() {
        this.subscription.add(
            this.orderHistoryService.getPlaceUserList().subscribe(
                data => {
                    if (data['code'] == 200) {
                        this.userList = data['data'];
                        console.log(this.userList)
                    }
                }
            )
        );
    }

    // Status Dropdown
    statusFunction(data) {
        this.statusValue = data;
    }
    statusChange(e) {
        this.statusFunction(e.value);
        this.getOrderHistoryList();
    }

    orderChange(e) {
        this.orderValue = e.value;
        this.getOrderHistoryList();
    }

    // Role Dropdown
    // roleChange(e) {
    //     this.roleValue = e.value;
    //     this.getOrderHistoryList();
    // }

    userListChange(e) {
        this.userListValue = e.value;
        if(this.userListValue > 0){
            this.userList.forEach(element => {
                if (element.user_id == e.value) {
                    this.userObj = element;
                }
            });
        } else {
            this.userObj = null;
        }
        this.getOrderHistoryList();
    }

}
