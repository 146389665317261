<section class="section first-section">
    <div class="container-fluid">
        <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner>
        <div class="title">
            <h1>My Cart</h1>
        </div>
        <div class="row">
            <div class="col-12 col-md-9 tableDiv pr-0">
                <div class="table-responsive">
                    <table class="table table-striped table-fixed mb-0">
                        <thead>
                            <tr>
                                <th scope="col" class="align-items-center stockID">Stock ID
                                    <!-- <span *ngIf="isLotBooked" class="info tooltip action topTooltip">
                                        <i class="fa fa-info position-absolute"></i> -->
                                    <!-- <span class="tooltiptext">Unavailable</span> -->
                                    <!-- </span> -->
                                </th>
                                <th scope="col" class="shapeClass">Shape</th>
                                <th scope="col" class="">Carat</th>
                                <th scope="col" class="">Color</th>
                                <th scope="col" class="">Intensity</th>
                                <th scope="col" class="">Clarity</th>
                                <th scope="col" class="">Cut</th>
                                <th scope="col" class="">Lab</th>
                                <th scope="col" class="">Location</th>
                                <!-- <th scope="col" [ngClass]="currencyCode != 'USD' ? 'col-1' : 'col-2'" style="padding: 12px 0;">Price USD</th> -->
                                <th scope="col" class="retail" *ngIf="displayPrice === 'c' || displayPrice === 'b'">Cost
                                    {{currencyCode}}</th>
                                <th scope="col" class="retail" *ngIf="displayPrice === 's' || displayPrice === 'b'">Retail
                                    {{currencyCode}}</th>
                                <th scope="col" class="">Actions</th>
                            </tr>
                        </thead>
                        <tbody (scroll)="onScroll($event)" class="thikScroll">
                            <a *ngFor="let order of orderList; index as i" [ngClass]="order.paired_lot_id ? 'pairedDiamondA' : ''">
                                <tr class="justify-content-center" [ngClass]="order.paired_lot_id ? 'pairedDiamondBorder' : ''">
                                    <td>{{ order.lot.altr_stock_id }}
                                        <span class="tooltip action rightTooltip">
                                            <span *ngIf="order.lot.is_booked == true" class="lot-booked"></span>
                                            <span class="tooltiptext">Already Booked</span>
                                        </span>
                                    </td>
                                    <td [ngClass]="!order.paired_lot_id ? 'padTd' : 'padTd2'">{{
                                        order.lot.shape_name }} <span *ngIf="order.lot.availability === 'next_day'"
                                            class="shape-active"></span></td>
                                    <td>{{ order.lot.weight }}</td>
                                    <td class="colorClass">{{ order.lot.color_name }}</td>
                                    <td>{{ order.lot.diamond_type === 'c' ? order.lot.intensity_name :
                                        '-' }}</td>
                                    <td class="clarityClass">{{ order.lot.clarity_name }}</td>
                                    <td class="d-flex justify-center cutClass">{{ order.lot.cut_name ? order.lot.cut_name :
                                        '-'}}
                                        <span class="pl-1">
                                            <img *ngIf="order.lot.heart_n_arrow" style="width: 13px;position: relative;bottom:1px;"
                                                src="../../../assets/img/svg/heart&arrow.svg">
                                        </span>
                                    </td>
                                    <td class="labData">
                                        <span class="tooltip action">
                                            <a [href]="order.lot.grading_link ? order.lot.grading_link : '#'" target="_blank"
                                                class="gradingLink">{{ order.lot.lab_name}}</a>
                                            <span class="tooltiptext">View Certificate</span>
                                        </span>
                                    </td>
                                    <td>{{ order.lot.lot_location_name ? order.lot.lot_location_name : '-' }}</td>
                                    <!-- <td [ns]="currencyCode != 'USD' ? 'col-1' : 'col-2'">${{ order.total_amount }}</td> -->
                                    <td *ngIf="displayPrice === 'c' || displayPrice === 'b'" class="retail">{{currencySymbol}}{{
                                        order.cost_conversion_amount | number}}

                                    <td *ngIf="displayPrice === 's' || displayPrice === 'b'" class="retail">{{currencySymbol}}{{
                                        order.conversion_amount | number}}
                                    </td>
                                    <td class="lastTd justify-content-between">
                                        <p (click)="addtoWish(order)" [ngClass]="order.paired_lot_id ? 'hideWishForPaired' : ''"
                                            *ngIf="order.checkWish === false">
                                            <span class="tooltip action">
                                                <img src="../../../assets/img/svg/heart.svg" class="iconSize">
                                                <span class="tooltiptext">Add to Wishlist</span>
                                            </span>
                                        </p>
                                        <p (click)="deleteWishList(order)" [ngClass]="order.paired_lot_id ? 'hideWishForPaired' : ''"
                                            *ngIf="order.checkWish === true">
                                            <span class="tooltip action">
                                                <img src="../../../assets/img/svg/heartfill.svg" class="iconSize">
                                                <span class="tooltiptext">Remove from Wishlist</span>
                                            </span>
                                        </p>
                                        <p (click)="openDialog(order)">
                                            <span class="tooltip action">
                                                <img src="./assets/img/svg/delete.svg" class="iconSize">
                                                <span class="tooltiptext">Delete from Cart</span>
                                            </span>
                                        </p>
                                        <p class="" (click)="goToDiamondDetail(order)">
                                            <span class="tooltip action">
                                                <img src="../../../assets/img/svg/vision.svg">
                                                <span class="tooltiptext">Diamond Detail</span>
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr *ngIf="order.paired_lot_id" [ngClass]="displayPrice === 'b' ? 'pairedTr' : 'singlePrice'">
                                    <td class="commonWidth pairedStockIdClass">{{ order.paired_diamond_lot.altr_stock_id }}</td>
                                    <td class="padTd2 commonWidth pairedTd pairedShapeClass">{{ order.paired_diamond_lot.shape_name }}
                                        <span *ngIf="order.paired_diamond_lot.availability === 'next_day'" class="shape-active"></span></td>
                                    <td class="commonWidth pairedCaratClass">{{ order.paired_diamond_lot.weight}}</td>
                                    <td class="commonWidth pairedColorClass">{{ order.paired_diamond_lot.color_name}}</td>
                                    <td class="commonWidth pairedIntensityClass">{{ order.paired_diamond_lot.diamond_type === 'c' ?
                                        order.paired_diamond_lot.intensity_name : '-' }}</td>
                                    <td class="commonWidth pairedClarityClass">{{ order.paired_diamond_lot.clarity_name }}</td>
                                    <td class="d-flex justify-center-start commonWidth pairedCutClass">{{
                                        order.paired_diamond_lot.cut_name ? order.paired_diamond_lot.cut_name :'-'}}
                                        <span class="pl-1">
                                            <img *ngIf="order.paired_diamond_lot.heart_n_arrow" style="width: 13px;position: relative;bottom:1px;"
                                                src="../../../assets/img/svg/heart&arrow.svg">
                                        </span>
                                    </td>
                                    <td class="commonWidth labData">
                                        <span class="tooltip action">
                                            <a [href]="order.paired_diamond_lot.grading_link ? order.paired_diamond_lot.grading_link : '#'"
                                                target="_blank" class="gradingLink">{{
                                                order.paired_diamond_lot.lab_name}}</a>
                                            <span class="tooltiptext">View Certificate</span>
                                        </span>
                                    </td>
                                    <td class="commonWidth"></td>
                                    <td class="commonWidth" *ngIf="displayPrice === 'c' || displayPrice === 'b'"></td>
                                    <td class="commonWidth" *ngIf="displayPrice === 's' || displayPrice === 'b'"></td>
                                    <td class="commonWidth"></td>
                                </tr>
                            </a>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 col-md-3 order-info">
                <!-- <div class="title-info">
                    <p>Order Information</p>
                </div> -->
                <div>
                    <ul>
                        <li class="totalPrice" *ngIf="displayPrice === 'b' || displayPrice === 'c'">
                            <label>Total Cost Price :</label>
                            <span>
                                <span>{{currencySymbol}}{{totalCostConversionAmount | number}}</span>
                            </span>
                        </li>
                        <li class="totalPrice" *ngIf="displayPrice === 'b' || displayPrice === 's'">
                            <label>Total Retail Price :</label>
                            <span>
                                <span>{{currencySymbol}}{{totalConversionAmount | number}}</span>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="location pb-2 mt-2">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label class="shippingLabel" for="location">Shipping Address</mat-label>
                        <mat-select placeholder="Select Address" [(value)]="selectedAddress" matTooltip="{{getToolTipDEata(selectedAddress)}}"
                            matTooltipClass="customTooltip">
                            <mat-option *ngFor="let address of shippingAddress" matTooltip="{{ address.address_line_1}}"
                                [value]="address.business_shipping_add_id ? address.business_shipping_add_id : address.company_id"
                                matTooltipClass="customTooltip" (click)="dataAddress(address)">
                                {{address.address_line_1}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <ul>
                    <!-- <li>
                        <label>Sub Total :</label>
                        <span> 
                            <span>{{currencySymbol}}{{totalConversionAmount}}</span>
                        </span>
                    </li> -->
                    <!-- <li style="margin-bottom: 20px;">
                        <label class="tbold">Sub Total :</label>
                        <span>
                            <span>{{currencySymbol}}{{totalConversionAmount | number}}</span>
                        </span>
                    </li> -->
                    <li>
                        <textarea name="msg" placeholder="Message" [(ngModel)]="requestMessage" rows="5"></textarea>
                    </li>
                </ul>
                <div class="d-flex justify-content-center">
                    <button type="button" class="btn req w-100" (click)="request(orderList, content)">Request</button>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div class="modal-header">
        <h2 class="modal-title text-center" style="margin: 0 auto;width: 100%;">Continue Shopping</h2>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center mb-2" style="line-height: 1.3;">
            {{selectedLots.message}}
            <!-- <div> Booked Lot Stock ID: {{selectedLots}}</div> -->
            <div *ngIf="!selectedLots.is_all_booked"> Do you want to proceed with available lots?</div>
        </div>
    </div>
    <div class="modal-footer modal-btn">
        <button type="button" class="btn cancel" type="button" (click)="modal.dismiss('Cross click')" *ngIf="selectedLots.is_proceed">Cancel</button>
        <button type="button" class="btn" type="button" (click)="proceed(orderList)" *ngIf="selectedLots.is_proceed">Proceed</button>
        <button type="button" class="btn" type="button" *ngIf="selectedLots.is_all_booked" (click)="bookAll(orderList)"
            style="width: 145px">Keep Shopping</button>
    </div>
</ng-template>