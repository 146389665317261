<section class="maincontainer">
    <p class="mainLabel2" (click)="goBack()"> <span>
            <i class="fa fa-angle-left backiconcss"></i></span>{{this.dataId ? 'Edit User' : 'Add User'}}</p>
    <div class="line1"></div>
    <div class="formDiv">
        <!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner> -->

        <form [formGroup]="firstFormGroup">
            <div class="row ">
                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <mat-form-field appearance="outline">
                        <mat-label>First Name*</mat-label>
                        <input matInput placeholder="First name" formControlName="firstname">
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'firstname', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <mat-form-field appearance="outline">
                        <mat-label>Last Name*</mat-label>
                        <input matInput placeholder="Last name" formControlName="lastname">
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'lastname', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <mat-form-field appearance="outline">
                        <mat-label>Email*</mat-label>
                        <input *ngIf="!dataId" matInput placeholder="Email" formControlName="email" autocomplete="new-email">
                        <input *ngIf="dataId" matInput placeholder="Email" formControlName="email" readonly="true"
                            autocomplete="new-email">
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <mat-form-field appearance="outline">
                        <mat-label>Mobile No</mat-label>
                        <div style="display: flex;">
                            <span class="mobileCodeSpan" *ngIf="this.firstFormGroup.controls['mobile_no'].value">+{{countryCode}}</span>
                            <input style="margin: 0px" matInput type="tel" placeholder="Mobile No" formControlName="mobile_no"
                                (blur)="mobileinput()" (input)="mobileinputValidation()">
                        </div>
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'mobile_no', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <mat-form-field appearance="outline">
                        <mat-label>Whatsapp No</mat-label>
                        <div style="display: flex;">
                            <span class="mobileCodeSpan" *ngIf="this.firstFormGroup.controls['whatsapp_no'].value">+{{countryCode}}</span>
                            <input style="margin: 0px" matInput type="tel" placeholder="Whatsapp No" formControlName="whatsapp_no"
                                (input)="whatsappNo()">
                        </div>
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'whatsapp_no', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin" *ngIf="!dataId">
                    <mat-form-field appearance="outline">
                        <mat-label>Password*</mat-label>
                        <input matInput type="{{newPasswordType}}" placeholder="Password" formControlName="password"
                            autocomplete="new-password">
                        <i matSuffix *ngIf="newPasswordType == 'text'" class="fa fa-eye icon2" (click)="newPasswordType = 'password'"></i>
                        <i matSuffix *ngIf="newPasswordType == 'password'" class="fa fa-eye-slash icon2" (click)="newPasswordType = 'text'"></i>
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>
                <!-- <div class="col-lg-6 col-md-12 col-sm-12 rowMargin"></div> -->
                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin" *ngIf="!dataId">
                    <mat-form-field appearance="outline">
                        <mat-label>Confirm Password*</mat-label>
                        <input matInput type="{{confPasswordType}}" placeholder="Confirm Password" formControlName="confirmPassword">
                        <i matSuffix *ngIf="confPasswordType == 'text'" class="fa fa-eye icon2" (click)="confPasswordType = 'password'"></i>
                        <i matSuffix *ngIf="confPasswordType == 'password'" class="fa fa-eye-slash icon2" (click)="confPasswordType = 'text'"></i>
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'confirmPassword', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>
                <!-- <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput placeholder="Password" formControlName="password">
                        <mat-error *ngFor="let validation of validation_messages.password">
                            <mat-error *ngIf="firstFormGroup.controls.password.hasError(validation.type)">
                                {{validation.message}}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <mat-form-field appearance="outline">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput placeholder="Confirm Password" formControlName="confirmPassword">
                        <mat-error *ngFor="let validation of validation_messages.confirmPassword">
                            <mat-error *ngIf="firstFormGroup.controls.confirmPassword.hasError(validation.type)">
                                {{validation.message}}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div> -->
                <div class="col-lg-6 col-md-12 col-sm-12" *ngIf="this.is_sub_user != 'true'">
                    <!-- <div class="row"> -->
                    <!-- <div class="col-7 d-flex">
                            <div class="centerRole" style="padding-top: 16px;">
                                <label class="labelInput"></label>
                                <mat-radio-group class="floatRight textEnd" formControlName="standard_role">
                                    <mat-radio-button value="0" class="radiocss pr-3">Standard</mat-radio-button>
                                    <mat-radio-button value="1" class="radiocss">Custom</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div> -->
                    <!-- <div class="col-5" style="padding-left: 0px;"> -->
                    <mat-form-field appearance="outline">
                        <mat-label>Role*</mat-label>
                        <mat-select placeholder="Role" formControlName="role">
                            <mat-option *ngFor="let role of roleList" [value]='role.client_role_id'>
                                {{role.client_role_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'role', 'formName': firstFormGroup} }">
                    </ng-container>
                    <!-- </div> -->
                    <!-- </div> -->
                </div>
                <!-- <div class="col-lg-6 col-md-12 col-sm-12 rowMargin" *ngIf="!dataId">
                    <mat-form-field appearance="outline">
                        <mat-label>Country Currency*</mat-label>
                        <mat-select placeholder="Country Currency" formControlName="country_currency">
                            <mat-option *ngFor="let currency of countryCurrency" [value]='currency.country_currency_id'>
                                {{currency.currency_short_code}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'country_currency', 'formName': firstFormGroup} }">
                    </ng-container>
                </div> -->
            </div>
            <div style="margin-top:20px" class="floatRight">
                <button mat-stroked-button class="whitebtnForm" type="button" style="margin-right: 10px;" (click)="onCancel()">Reset</button>
                <button mat-raised-button class="blackbtnForm" type="submit" *ngIf="!dataId" (click)="firstFormSubmit('add')">Create</button>
                <button mat-raised-button class="blackbtnForm" type="submit" *ngIf="dataId" (click)="firstFormSubmit('edit')">Update</button>
            </div>
        </form>
    </div>
</section>
<ng-template let-formObject #validationMessages>
    <ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
        <mat-error *ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
            {{validation.message}}
        </mat-error>
    </ng-container>
</ng-template>