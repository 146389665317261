<section class="maincontainer">
    <p class="mainLabel2" (click)="goBack()"> <span>
            <i class="fa fa-angle-left backiconcss"></i></span>{{this.dataId ? 'Edit Role' : 'Add Role'}}</p>
    <div class="line1"></div>
    <div class="formDiv">
        <!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner> -->
        <form [formGroup]="firstFormGroup" (submit)="firstFormSubmit()">
            <div class="row ">
                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <mat-form-field appearance="outline">
                        <mat-label>Name Of Role*</mat-label>
                        <input matInput placeholder="Name Of Role" formControlName="role_name">

                    </mat-form-field>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'role_name', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin">
                    <div class="centerRole">
                        <label class="labelInput">Order Approval Required</label>
                        <mat-radio-group class="floatRight textEnd" formControlName="orderApproval">
                            <mat-radio-button value="1" class="radiocss">Yes</mat-radio-button>
                            <mat-radio-button value="0" class="radiocss">No</mat-radio-button>
                        </mat-radio-group>

                    </div>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'orderApproval', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin" style="margin-bottom: 25px;">
                    <div class="centerRole">
                        <label class="labelInput">Give Order Approval Access</label>
                        <mat-radio-group class="floatRight textEnd" formControlName="orderApprovalAccess" (change)="orderApprovalClick()">
                            <mat-radio-button value="1" class="radiocss">Yes</mat-radio-button>
                            <mat-radio-button value="0" class="radiocss">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'orderApprovalAccess', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin" style="margin-bottom: 25px;">
                    <div class="centerRole">
                        <label class="labelInput">Give Access to User management</label>
                        <mat-radio-group class="floatRight textEnd" formControlName="userApprovalAccess">
                            <mat-radio-button value="1" class="radiocss">Yes</mat-radio-button>
                            <mat-radio-button value="0" class="radiocss">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'userApprovalAccess', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin" style="margin-bottom: 25px;">
                    <div class="centerRole">
                        <label class="labelInput">Show Cost Price</label>
                        <!-- disabled="{{disableCostPrice}}" -->
                        <mat-radio-group class="floatRight textEnd" formControlName="showCostPrice">
                            <mat-radio-button value="1" class="radiocss">Yes</mat-radio-button>
                            <mat-radio-button value="0" class="radiocss">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'showCostPrice', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 rowMargin" style="margin-bottom: 25px;">
                    <div class="centerRole">
                        <label class="labelInput">Show Retail Price</label>
                        <mat-radio-group class="floatRight textEnd" formControlName="showSellPrice">
                            <mat-radio-button value="1" class="radiocss">Yes</mat-radio-button>
                            <mat-radio-button value="0" class="radiocss">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <ng-container *ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'showSellPrice', 'formName': firstFormGroup} }">
                    </ng-container>
                </div>

            </div>
            <div style="margin-top:20px;width:100%;" class="floatRight textEnd">
                <button mat-stroked-button class="whitebtnForm" type="button" style="margin-right: 10px;" (click)="onCancel()">Reset</button>
                <button *ngIf="!dataId" mat-raised-button class="blackbtnForm" type="submit" (click)="showConfirm('add')">Create
                    Role</button>
                <button *ngIf="dataId" mat-raised-button class="blackbtnForm" type="submit" (click)="showConfirm('update')">Update
                    Role</button>
            </div>
        </form>
    </div>
</section>
<ng-template let-formObject #validationMessages>
    <ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
        <mat-error *ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
            {{validation.message}}
        </mat-error>
    </ng-container>
</ng-template>