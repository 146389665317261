import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/core/_constant/api.config';

@Injectable({
    providedIn: 'root'
})
export class OrderHistoryService {

    constructor(private http: HttpClient) {}

    getOrderHistoryList(param?): Observable<{}> {
        return this.http.post(URLS.getOrderHistoryList, param);
    }

    getPlaceUserList(): Observable<{}> {
        return this.http.get(URLS.getPlaceUserList);
    }

    getRole(): Observable<{}> {
        return this.http.get(URLS.getRole);
    }
    
}