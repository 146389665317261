import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'floatValue'
  })

export class floatValuePipe implements PipeTransform {
    public transform(n: number) {
        return parseFloat("" + n).toFixed(2);
    }
}