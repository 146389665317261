import { Injectable } from '@angular/core';
import { DataService } from '../../services/data-service.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/core/_constant/api.config';

@Injectable({
    providedIn: 'root'
})
export class PairedDiamondDetailService {

    constructor(private dataService: DataService, private http: HttpClient) {

    }

    getPairedDiamondDetail(param?): Observable<{}> {
        return this.http.post(URLS.getPairedDiamondDetail, param);
    }
    getSharePairedDiamondDetail(param?): Observable<{}> {
        return this.http.post(URLS.getSharePairedDiamondDetail, param);
    }
    
}