import { FormGroup } from "@angular/forms";

export class CustomValidator {
    // Validates URL
    static urlValidator(url): any {
        if (url.value === null || url.value === "") return null;
        if (url.pristine) {
            return null;
        }
        const URL_REGEXP = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        url.markAsTouched();
        if (URL_REGEXP.test(url.value)) {
            return null;
        }
        return {
            invalidUrl: true
        };
    }
    // Validates passwords
    static matchPassword(group: FormGroup): any {
        const password = group.controls.password;
        const confirm = group.controls.confirmpassword;
        if (password.pristine || confirm.pristine) {
            return null;
        }
        group.markAsTouched();
        if (password.value === confirm.value) {
            return null;
        }
        return {
            invalidPassword: true
        };
    }
    // Validates numbers
    static numberValidator(number): any {
        if (number.pristine) {
            return null;
        }
        if (number.value && number.value.length > 0) {
            const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
            number.markAsTouched();
            if (NUMBER_REGEXP.test(number.value)) {
                return null;
            }
            return {
                invalidNumber: true,
            };
        }

    }

    // Validators integer numbers
    static integerNumberValidator(number): any {
        if (number.pristine) {
            return null;
        }
        if (number.value && number.value.length > 0) {
            const NUMBER_REGEXP = /(?:\s|^)\d+(?=\s|$)/;
            number.markAsTouched();
            if (NUMBER_REGEXP.test(number.value)) {
                return null;
            }
            return {
                invalidNumber: true,
            };
        }
    }
    // Validates US SSN
    static ssnValidator(ssn): any {
        if (ssn.pristine) {
            return null;
        }
        const SSN_REGEXP = /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
        ssn.markAsTouched();
        if (SSN_REGEXP.test(ssn.value)) {
            return null;
        }
        return {
            invalidSsn: true
        };
    }
    // Validates US phone numbers
    static phoneValidator(number): any {
        if (number.pristine) {
            return null;
        }
        if (number.value && number.value.length > 0) {
            // const PHONE_REGEXP = /^[0-9]{10,15}$/;
            //  const PHONE_REGEXP = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
            const PHONE_REGEXP = /^([1-9]{1})([0-9]{9})$/;

            number.markAsTouched();
            if (PHONE_REGEXP.test(number.value)) {
                return null;
            }
            return {
                invalidNumber: true,
            };
        }

    }
    // Validates zip codes
    static zipCodeValidator(zip): any {
        if (zip.value === null || zip.value === "") return null;
        if (zip.pristine) {
            return null;
        }
        if (zip.value.length > 10) {
            return null;
        }
        // const ZIP_REGEXP = /^[0-9]{6}(?:-[0-9]{4})?$/;
        const ZIP_REGEXP = /([A-Za-z0-9 ]{4,8})/g;
        const ONLY_ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9 ]+$/i;

        zip.markAsTouched();
        if (ZIP_REGEXP.test(zip.value) && ONLY_ALPHA_NUMERIC_REGEX.test(zip.value)) {
            return null;
        }
        return {
            invalidZip: true
        };
    }
    // Vlidates password pattern
    static passwordPatternValidator(password): any {
        if (password.pristine) {
            return null;
        }
        // const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
        const PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
        password.markAsTouched();
        if (password.value && password.value.length > 0) {
            if (PASSWORD_REGEXP.test(password.value)) {
                return null;
            }
            return {
                invalidPassword: true
            };
        }

    }
    // Vlidates password pattern
    static faxNumberValidator(faxno): any {
        if (faxno.pristine) {
            return null;
        }
        if (faxno.value && faxno.value.length > 0) {
            const FAX_REGEXP = /^\+?[0-9]+$/;
            faxno.markAsTouched();
            if (FAX_REGEXP.test(faxno.value)) {
                return null;
            }
            if (isNaN(faxno.value) && (faxno.value.length < 5 || faxno.value.length > 40)) {
                return null;
            }
            return {
                invalidFax: true
            };
        }

    }

    // Validates white space
    // Validates white space
    static noWhiteSpaceValidator(string): any {
        if (string.value === null || string.value === "") return null;
        if (string.pristine) return null;
        string.markAsTouched();
        const isWhitespace = (string.value || '').toString().trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    static restrictWhiteSpaceValidator(string): any {
        if (string.value === null || string.value === "" || string.value === undefined) return null;
        if (string.pristine) return null;
        string.markAsTouched();
        if (string.value.includes(' ')) {
            return { invalidSpace: true }
        }
        else {
            return null;
        }



    }

    // Validates double quotes
    static noQuotesValidator(string): any {
        if (string.value) {
            let isQuateInvalid = false;
            if (string.value.includes('\"') || string.value.includes("\'")) {
                isQuateInvalid = true;
            }
            return isQuateInvalid ? { 'quotes': true } : null;
        }
    }

    // Vlidates facebook pattern
    static facebookPatternValidator(facebook): any {
        if (facebook.value === null || facebook.value === "") return null;
        if (facebook.pristine) {
            return null;
        }
        const FACEBOOK_REGEXP = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/i;
        facebook.markAsTouched();
        if (FACEBOOK_REGEXP.test(facebook.value)) {
            return null;
        }
        return {
            invalidFacebook: true
        };
    }

    // Vlidates Twiter pattern
    static twiterPatternValidator(twiter): any {
        if (twiter.value === null || twiter.value === "") return null;
        if (twiter.pristine) {
            return null;
        }
        const TWITER_REGEXP = /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
        twiter.markAsTouched();
        if (TWITER_REGEXP.test(twiter.value)) {
            return null;
        }
        return {
            invalidTwiter: true
        };
    }

    // Vlidates Instagram pattern
    static instagramPatternValidator(instagram): any {
        if (instagram.value === null || instagram.value === "") return null;
        if (instagram.pristine) {
            return null;
        }
        const INSTAGRAM_REGEXP = /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9(\.\?)?]/i;
        instagram.markAsTouched();
        if (INSTAGRAM_REGEXP.test(instagram.value)) {
            return null;
        }
        return {
            invalidInstagram: true
        };
    }

    // Do not allow special characters
    static blockSpecialCharacter(string): any {
        if (string.value === null || string.value === "") return null;
        if (string.pristine) return null;
        const ONLY_ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9 ]+$/i;
        string.markAsTouched();
        if (ONLY_ALPHA_NUMERIC_REGEX.test(string.value)) {
            return null;
        }
        return {
            invalidString: true
        }
    }

    static nameValidator(name): any {
        if (name.pristine) {
            return null;
        }
        if (name.value && name.value.length > 0) {
            const NAME_REGEXP = /^(?=.{1,50}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/;
            name.markAsTouched();
            if (NAME_REGEXP.test(name.value)) {
                return null;
            }
            if (name.value.length > 50) {
                return null;
            }
            return {
                invalidName: true
            };
        }
    }

    // Validates Email ID
    static emailValidator(email): any {
        if (email.value === null || email.value === "") return null;
        if (email.pristine) {
            return null;
        }
        const EMAIL_REGEXP = /^(([^<>()\[\]\.,;:\s@\=\^\%\^\&\*\(\)\;\:\,\!\#\$\&\+\\\|\{\}\,\;\/\?]+(\.[^<>()\[\]\.,;:\s@\=\^\%\^\&\*\(\)\;\:\,\!\#\$\&\+\\\|\{\}\,\;\/\?]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\=\^\%\^\&\*\(\)\-\;\:\,\!\#\$\&\-\+\_\\\|\{\}\,\;\/\?]+\.)+[^<>()[\]\.,;:\s@\=\^\%\^\&\*\(\)\-\;\:\,\!\#\$\&\-\+\_\\\|\{\}\,\;\/\?]{2,})$/;
        email.markAsTouched();
        if (EMAIL_REGEXP.test(email.value)) {
            return null;
        }
        return {
            invalidEmail: true
        };
    }

    static accountNoValidator(accountNo): any {
        if (accountNo.value === null || accountNo.value === "") return null;
        if (accountNo.pristine) {
            return null;
        }
        const ACCOUNTNO_REGEXP = /^[a-zA-Z0-9]*$/;
        accountNo.markAsTouched();
        if (ACCOUNTNO_REGEXP.test(accountNo.value)) {
            return null;
        }
        return {
            invalidaccountno: true
        };
    }

    static bankNameValidator(bankName): any {
        if (bankName.value === null || bankName.value === "") return null;
        if (bankName.pristine) {
            return null;
        }
        const ACCOUNTNO_REGEXP = /^[ A-Za-z0-9-]*$/
        bankName.markAsTouched();
        if (ACCOUNTNO_REGEXP.test(bankName.value)) {
            return null;
        }
        return {
            invalidbankName: true
        };
    }

}
