import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data-service.service';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/core/_constant/api.config';
import { orderBy, union } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../core/_component/confirmation-dialog/confirmation-dialog.component';
import { LoaderService } from '../../core/_common/loader.service';
import { NotifyService } from '../../core/_common/snackbar.service';
import { Router, NavigationExtras } from '@angular/router';

@Component({
    selector: 'app-notificationlist',
    templateUrl: './notificationlist.component.html',
    styleUrls: ['./notificationlist.component.scss']
})
export class NotificationlistComponent implements OnInit {

    private subscription: Subscription = new Subscription();
    scrollposition: any;
    paginationScroll: boolean = false;
    readStatus: boolean;
    dataCount: any;
    isViewAll: boolean = false

    public notificationList: any = [];

    public filterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 20
    };

    constructor(private dataService: DataService,
        private router: Router,
        public dialog: MatDialog,
        public loader: LoaderService,
        public notifyService: NotifyService, ) { }

    ngOnInit(): void {
        let params = {
            page_no: 1,
            page_record: 20
        }
        this.getNotificationList(params);
    }

    ngAfterViewInit() {
        this.scrollposition = 0;
    }

    openDialog(data) {
        console.log(data);
        const removeNotificationDialog = this.dialog.open(ConfirmationDialogComponent, {
            height: '230px',
            width: '400px',
            data: { headerName: 'Remove Notification', text: 'Are you sure you want to remove this notification' }
        });
        removeNotificationDialog.afterClosed().subscribe(result => {
            if (result) {
                this.removeNotificationItem(data);
            }
        });
    }

    removeNotificationItem(data) {
        let param = {
            "notification_id": data
        }
        this.loader.showSpinner();
        this.dataService.post(URLS.getDeleteItemList, param)
            .subscribe(res => {
                if (res["code"] == 200) {
                    // this.switchLoading = false;
                    this.onFilterChangeHandler(this.filterObj.enableNext);
                    setTimeout(() => {
                        this.notifyService.showToast(res["message"]);
                    },600);
                }
            }, error => {
                this.loader.hideSpinner();

            })
    }

    getNotificationList(params?, isInfinteScrollCalled?: boolean) {
        // const params = {
        //     page_no: this.filterObj.page,
        //     page_record: this.filterObj.pageRecord
        // }
        this.loader.showSpinner();
        this.subscription.add(this.dataService.get(URLS.getNotificationList, params).subscribe(
            data => {
                if (data['code'] == 200 && !isInfinteScrollCalled) {
                    this.notificationList = data['data']['rows'];
                    this.dataCount = data['data']['count'];
                    console.log(this.dataCount);
                    this.filterObj.enableNext = data['data']['enableNext'];
                    console.log(this.filterObj.enableNext);
                }
                if (data['code'] == 200 && isInfinteScrollCalled) {
                    this.notificationList = orderBy(union(this.notificationList, data['data']['rows']));
                    this.filterObj.enableNext = data["data"]['enableNext'];
                }
                this.filterObj.page++;
                this.loader.hideSpinner();
            }
        ));
    }

    onScroll(e) {
        const currentValue = this.scrollposition;
        const element = e.target;
        let pos = (element.scrollTop || element.scrollTop) + element.offsetHeight;
        let max = element.scrollHeight;
        const difference = max - pos;
        if (difference >= 0 && difference <= 20 && currentValue < element.scrollTop) {
            console.log(this.filterObj.enableNext);
            if (this.filterObj.enableNext) {
                this.paginationScroll = true;
                this.onFilterChangeHandler(this.filterObj.enableNext);
                this.paginationScroll = false;
            }
        }
        this.scrollposition = element.scrollTop;
    }

    onFilterChangeHandler(isInfinteScroll?: boolean) {
        let params = {
            page_no: this.filterObj.page,
            page_record: this.filterObj.pageRecord
        }
        if (this.paginationScroll) {
            params['page_no'] = this.filterObj.page
        } else {
            params['page_no'] = 1
            this.filterObj.page = 1;
        }
        this.getNotificationList(params, isInfinteScroll);

    }

    getViewAndUpdate(isViewAll, notificationId, eventName, orderId) {
        console.log(notificationId);
        console.log(isViewAll);
        let params = {}
        if (isViewAll) {
            params = {
                "is_all_notification": true,
            }
        } else {
            params = {
                "notification_id": notificationId,
                "is_all_notification": false,
            }
        }
        console.log(params);
        this.loader.showSpinner();
        this.subscription.add(
            this.dataService.put(URLS.getViewAndUpdate, params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data['code'] == 200) {
                        console.log(data);
                        this.onFilterChangeHandler(this.filterObj.enableNext);
                        const navigationExtras: NavigationExtras = {
                            queryParams: {
                                diamondOrderId: orderId,
                            },
                        };
                        if(!isViewAll){
                            this.router.navigate(['/orderDetail'], navigationExtras);
                        }
                    }

                }
            ));
    }
}
