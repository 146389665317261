import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable()
export class NotifyService {

    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(public _snackBar: MatSnackBar

    ) { }

    showToast(msg) {
        this._snackBar.open(msg, '', {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }
    hideToast() {
        this._snackBar.dismiss();
    }





}