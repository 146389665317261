import { CustomValidator } from "src/app/core/_common/custom-validators";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from "@angular/forms";
import { CommonService } from "src/app/core/_common/common.service";
import { Subscription } from "rxjs";
import { NotifyService } from "../../core/_common/snackbar.service";
import { MustMatch } from "../../core/_common/must-match.validator";
import { LoaderService } from "src/app/core/_common/loader.service";
import { DataService } from "src/app/services/data-service.service";
import { URLS } from "src/app/core/_constant/api.config";

@Component({
    selector: "app-add-user",
    templateUrl: "./add-user.component.html",
    styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
    firstFormGroup: FormGroup;

    public validation_messages = {
        firstname: [
            { type: "required", message: "Please enter a first name" },
            {
                type: "maxlength",
                message: "First name should not be more than 50 characters long"
            },
            { type: "whitespace", message: "Please enter a valid first name" },
            { type: "quotes", message: "Please enter a valid first name" },
            { type: "invalidName", message: "Please enter a valid first name" }
        ],
        lastname: [
            { type: "required", message: "Please enter a last name" },
            {
                type: "maxlength",
                message: "First name should not be more than 50 characters long"
            },
            { type: "whitespace", message: "Please enter a valid last name" },
            { type: "quotes", message: "Please enter a valid last name" },
            { type: "invalidName", message: "Please a enter valid last name!" }
        ],
        email: [
            { type: "required", message: "Please enter email id" },
            { type: "invalidEmail", message: "Please enter a valid personal email id" },
            { type: "whitespace", message: "Please enter a valid personal email" },
            { type: "quotes", message: "Please enter a valid personal email" }
        ],
        mobile_no: [
            { type: "required", message: "Please enter a mobile no" },
            // {
            //     type: "maxlength",
            //     message: "mobile no should not be more than 10 characters long"
            // },
            { type: "whitespace", message: "Please enter a valid mobile no" },
            { type: "quotes", message: "Please enter a valid mobile no" },
            { type: "invalidNumber", message: "Please enter a valid mobile no" },
            { type: 'maxlength', message: 'Max 40 Digit Mobile no' },
            { type: 'minlength', message: 'Min 5 Digit Mobile no' }
        ],
        whatsapp_no: [
            { type: 'required', message: 'Please enter a whatsapp no' },
            { type: 'whitespace', message: 'Please enter a valid  whatsapp no' },
            { type: 'quotes', message: 'Please enter a valid  whatsapp no' },
            { type: 'invalidNumber', message: 'Please enter a valid  whatsapp no' },
            { type: 'maxlength', message: 'Max 40 Digit Mobile no' },
            { type: 'minlength', message: 'Min 5 Digit Mobile n' }
        ],
        password: [
            { type: "required", message: "Password is required" },
            { type: "minLength", message: "Password must be at least 6 characters" },
            { type: 'invalidPassword', message: 'Password must be at least 8 characters long, one uppercase, one lowercase, one number and one special character' },

        ],
        confirmPassword: [
            { type: 'required', message: 'Please re-enter a password' },
            { type: 'whitespace', message: 'Please re-enter a valid password' },
            { type: 'confirm', message: 'Password does not match' },
            { type: 'invalidPassword', message: 'Password must be at least 8 characters long, one uppercase, one lowercase, one number and one special character' },
        ],
        role: [
            { type: "required", message: "Please select a business role" },
            { type: "whitespace", message: "Please select a valid business role" },
            { type: "quotes", message: "Please select a valid business role" }
        ],
        // country_currency: [
        //     { type: "required", message: "Please select Currency!" },
        //     { type: "whitespace", message: "Please select valid Currency!" },
        //     { type: "quotes", message: "Please select valid Currency!" }
        // ]
    };
    dataId: any;
    userData = [];
    roleList = [];
    passwordType = "password";
    newPasswordType = "password";
    confPasswordType = "password";
    countryCode: any = "+1";
    is_sub_user: any;
    sub_user_role_id: any;
    sub_user_role_name: any;
    countryCurrency: any;
    private subscription: Subscription = new Subscription();


    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public commonService: CommonService,
        public dataService: DataService,
        private activeRoute: ActivatedRoute,
        private notifyService: NotifyService,
        public loader: LoaderService,
    ) {
        this.dataId = this.activeRoute.snapshot.paramMap.get("id");

        //check utm params
        this.activeRoute.queryParams.subscribe(params => {
            if (params.countryCode) {
                console.log(params);
                this.countryCode = params.countryCode;
                this.is_sub_user = params.is_sub_user;
                this.sub_user_role_id = params.sub_user_role_id;
                this.sub_user_role_name = params.sub_user_role_name;
            }

        });
    }

    ngOnInit() {
        this.getRole();
        this.getCountryCurrency();
        if (this.dataId) {
            this.firstFormGroup = this.initializeFormUpdate();
            this.getUserDetails();
        }
        else {
            this.firstFormGroup = this.initializeForm();
        }

        if (this.is_sub_user != 'true') {
            this.firstFormGroup.get('role').setValidators([Validators.required, CustomValidator.noWhiteSpaceValidator]);
            this.firstFormGroup.get('role').updateValueAndValidity();
        }
        else {
            this.firstFormGroup.get('role').setValidators([CustomValidator.noWhiteSpaceValidator]);
            this.firstFormGroup.get('role').updateValueAndValidity();
        }
    }


    mobileinput() {
        this.firstFormGroup.controls['whatsapp_no'].setValue(this.f.mobile_no.value);
    }

    mobileinputValidation() {
        if (isNaN(this.firstFormGroup.controls.mobile_no.value)) {
            this.firstFormGroup.get('mobile_no').setValidators([CustomValidator.numberValidator]);
            this.firstFormGroup.get('mobile_no').updateValueAndValidity();
            this.firstFormGroup.get('whatsapp_no').setValidators([CustomValidator.numberValidator]);
            this.firstFormGroup.get('whatsapp_no').updateValueAndValidity();
        } else {
            this.firstFormGroup.get('mobile_no').setValidators([Validators.minLength(5), Validators.maxLength(40)]);
            this.firstFormGroup.get('mobile_no').updateValueAndValidity();
            this.firstFormGroup.get('whatsapp_no').setValidators([Validators.minLength(5), Validators.maxLength(40)]);
            this.firstFormGroup.get('whatsapp_no').updateValueAndValidity();
        }
    }

    whatsappNo() {
        if (isNaN(this.firstFormGroup.controls.whatsapp_no.value)) {
            this.firstFormGroup.get('whatsapp_no').setValidators([CustomValidator.numberValidator]);
            this.firstFormGroup.get('whatsapp_no').updateValueAndValidity();
        } else {
            this.firstFormGroup.get('whatsapp_no').setValidators([Validators.minLength(5), Validators.maxLength(40)]);
            this.firstFormGroup.get('whatsapp_no').updateValueAndValidity();
        }
    }



    onCancel() {
        if (this.dataId) {
            this.getUserDetails();
        }
        else {
            // this.firstFormGroup = this.initializeForm();
            this.firstFormGroup.reset();
            Object.keys(this.firstFormGroup.controls).forEach(key => {
                this.firstFormGroup.controls[key].setErrors(null);
            });
        }
    }

    // confirm password validator
    public confirmValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { required: true };
        } else if (control.value !== this.f.password.value) {
            return { confirm: true, error: true };
        }
        return {};
    };
    // convenience getter for easy access to form fields
    get f() {
        return this.firstFormGroup.controls;
    }

    getUserDetails() {
        const params = {
            user_id: this.dataId
        }
        // this.isLoading = true;
        this.loader.showSpinner();
        this.subscription.add(this.dataService.get(URLS.getUsersData, params).subscribe(
            data => {
                this.loader.hideSpinner();
                if (data["code"] == 200) {
                    this.userData = data["data"];
                    console.log(this.userData);
                    this.firstFormGroup.setValue({
                        firstname: this.userData['first_name'],
                        lastname: this.userData['last_name'],
                        email: this.userData['username'],
                        whatsapp_no: this.userData['whatsapp_no'] ? this.userData['whatsapp_no'] : null,
                        mobile_no: this.userData['mobile_no'] ? this.userData['mobile_no'] : null,
                        password: '*****',
                        confirmPassword: '*****',
                        role: this.userData['client_role_id'],
                    });
                    this.countryCode = this.userData['country_code'];
                    this.is_sub_user = this.userData['is_sub_user'].toString();
                    this.sub_user_role_id = this.userData['sub_user_role_id'];
                    this.sub_user_role_name = this.userData['sub_user_role_name'];
                    // this.isLoading = false;
                } else {
                    this.notifyService.showToast(data["message"]);
                }
            },
            error => {
                this.loader.hideSpinner();
                console.log(error);
            }));
    }
    // convenience getter for easy access to form fields

    getCountryCurrency(){
        this.subscription.add(this.dataService.get(URLS.getCountryCurrency).subscribe(
            data =>{
                if(data['code'] === 200){
                    this.countryCurrency = data['data'];
                }
            },
            error => {
                console.log(error);
            }));
    }

    firstFormSubmit(actionType) {
        for (const i in this.f) {
            this.f[i].markAsDirty();
            this.f[i].updateValueAndValidity();
            this.f[i].markAsTouched();
        }

        // stop here if form is invalid
        if (this.firstFormGroup.invalid) {
            return;
        }
        else {

            if (actionType === 'add') {
                const params = {
                    // "user_id": sub user id
                    first_name: this.f.firstname.value,
                    last_name: this.f.lastname.value,
                    email: this.f.email.value,
                    password: this.f.password.value,
                    confirm_password: this.f.confirmPassword.value,
                    phone_no: this.f.mobile_no.value,
                    whatsapp_no: this.f.whatsapp_no.value,
                    // local_currency_id: this.f.country_currency.value
                };
                if (this.is_sub_user == 'true') {
                    params['role_id'] = +this.sub_user_role_id
                }
                else {
                    params['role_id'] = +this.f.role.value;
                }
                const dataParams = this.commonService.encryptData(JSON.stringify(params));
                this.loader.showSpinner();
                this.subscription.add(
                    this.dataService.post(URLS.addUserData, { data: dataParams }).subscribe(
                        data => {
                            this.loader.hideSpinner();
                            if (data["code"] == 200) {
                                this.notifyService.showToast(data["message"]);
                                this.router.navigate(["/user-setting/manage-user"]);
                                // this.isLoading = false;
                            } else {
                                this.notifyService.showToast(data["message"]);
                            }
                        },
                        error => {
                            this.loader.hideSpinner();
                            console.log(error);
                        }
                    )
                );
            }
            else {
                const params = {
                    user_id: +this.dataId,
                    first_name: this.f.firstname.value,
                    last_name: this.f.lastname.value,
                    email: this.f.email.value,
                    phone_no: this.f.mobile_no.value,
                    whatsapp_no: this.f.whatsapp_no.value,
                    client_user_role_map_id: +this.userData['client_user_role_map_id']
                };
                if (this.is_sub_user == 'true') {
                    params['role_id'] = +this.sub_user_role_id
                }
                else {
                    params['role_id'] = +this.f.role.value;
                }
                // console.log(params);

                // const dataParams = this.commonService.encryptData(JSON.stringify(params));
                this.loader.showSpinner();
                this.subscription.add(
                    this.dataService.put(URLS.updateUserData, params).subscribe(
                        data => {
                            this.loader.hideSpinner();
                            if (data["code"] == 200) {
                                this.notifyService.showToast(data["message"]);
                                this.router.navigate(["/user-setting/manage-user"]);
                                // this.isLoading = false;
                            } else {
                                this.notifyService.showToast(data["message"]);
                            }
                        },
                        error => {
                            this.loader.hideSpinner();
                            console.log(error);
                        }
                    )
                );
            }
        }
    }

    getRole() {
        //   const params ={
        //     is_master:false
        //   }
        // this.isLoading = true;
        this.subscription.add(
            this.dataService.get(URLS.getRoleMaster).subscribe(
                data => {
                    if (data["code"] == 200) {
                        this.roleList = data["data"];
                        console.log(this.roleList);

                        // this.isLoading = false;
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                },
                error => {
                    console.log(error);
                }
            )
        );
    }

    goBack() {
        // this.commonService.setEvent("backUserSetting", 7);
        this.router.navigateByUrl('/user-setting/manage-user');
    }

    initializeFormUpdate() {
        return this.formBuilder.group(
            {
                firstname: new FormControl(null, {
                    validators: Validators.compose([
                        CustomValidator.nameValidator, Validators.required, Validators.maxLength(50)
                    ])
                }),
                lastname: new FormControl(null, {
                    validators: Validators.compose([
                        CustomValidator.nameValidator, Validators.required, Validators.maxLength(50)
                    ])
                }),

                email: new FormControl(null, {
                    validators: Validators.compose([
                        Validators.required, CustomValidator.noWhiteSpaceValidator, CustomValidator.emailValidator
                    ])
                }),

                mobile_no: new FormControl(null, {
                    validators: Validators.compose([
                    ])
                }),
                whatsapp_no: new FormControl(null, {
                    validators: Validators.compose([]),
                }),
                password: new FormControl({ value: '', disabled: true }),
                confirmPassword: new FormControl({ value: '', disabled: true }),
                role: new FormControl(null, {
                    validators: Validators.compose([
                        Validators.required, CustomValidator.noWhiteSpaceValidator
                    ])
                }),
            });
    }
    initializeForm() {
        return this.formBuilder.group(
            {
                firstname: new FormControl(null, {
                    validators: Validators.compose([
                        CustomValidator.nameValidator, Validators.required, Validators.maxLength(50)
                    ])
                }),
                lastname: new FormControl(null, {
                    validators: Validators.compose([
                        CustomValidator.nameValidator, Validators.required, Validators.maxLength(50)
                    ])
                }),

                email: new FormControl(null, {
                    validators: Validators.compose([
                        Validators.required, CustomValidator.noWhiteSpaceValidator, CustomValidator.emailValidator
                    ])
                }),

                mobile_no: new FormControl(null, {
                    validators: Validators.compose([

                    ])
                }),
                whatsapp_no: new FormControl(null, {
                    validators: Validators.compose([]),
                }),
                password: new FormControl("", [
                    Validators.required, CustomValidator.passwordPatternValidator
                ]),
                confirmPassword: new FormControl(null,{ validators: this.confirmValidator }),
                role: new FormControl(null, {
                    validators: Validators.compose([
                        Validators.required, CustomValidator.noWhiteSpaceValidator
                    ])
                }),
                // country_currency: new FormControl(null, {
                //     validators: Validators.compose([
                //         Validators.required, CustomValidator.noWhiteSpaceValidator
                //     ])
                // })
            },
            {
                validator: MustMatch("password", "confirmPassword")
            }
        );
    }
}
