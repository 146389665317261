<section class="maincontainer">
    <p class="mainLabel2" (click)="goBack()"> <span>
            <i class="fa fa-angle-left backiconcss"></i></span>View User</p>
    <div class="line1"></div>

    <div class="formDiv">
        <!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner> -->
        <div class="row" *ngIf="userData">
            <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">First Name</p>
                <p class="value">{{userData.first_name}}</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">Last Name</p>
                <p class="value">{{userData.last_name}}</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">Username</p>
                <p class="value">{{userData.username}}</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">Role</p>
                <p class="value">{{userData.role_name ? userData.role_name : " - "}}</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">Mobile No</p>
                <p class="value">{{userData.mobile_no ? "+"+userData.country_code + " " +userData.mobile_no : " - "}}
                </p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">Whatsapp No</p>
                <p class="value">{{userData.whatsapp_no ? "+"+userData.country_code + " " +userData.whatsapp_no : " - "}}
                </p>
            </div>
            <!-- <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">Updated At</p>
                <p class="value">{{dateFormat(userData.updated_at)}}</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">Created At</p>
                <p class="value">{{dateFormat(userData.created_at)}}</p>
            </div> -->
            <div class="col-lg-6 col-md-12 col-sm-12 viewBlock">
                <p class="label">Status</p>
                <p class="value" *ngIf="userData.is_active == 1" style="color:green">Activated</p>
                <p class="value" *ngIf="userData.is_active == 0" style="color:red">Deactivated</p>
            </div>
        </div>
    </div>
</section>