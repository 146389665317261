<section>
    <nav class="navbar navbar-expand-lg header" [ngClass]="shareToclient ? 'dFlexHeader' : ''" data-html2canvas-ignore="true">
        <div class="d-flex justify-content-between navbarBrand">
            <div class="navbar-brand logo">
                <a href="#"><img src="../../../../assets/img/altr_gray_logo.png"></a>
            </div>
            <div>
                <div class="navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed">
                    <i class="fa fa-bars fa-lg" aria-hidden="true"></i>
                </div>
            </div>
        </div>
        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse w-100 justify-content-between">
            <div class="nav" *ngIf="!shareToclient">
                <ul class="navbar-nav">
                    <li class="nav-item navItems" [routerLink]="tab.link" *ngFor="let tab of tabs" [routerLinkActive]="tab.link ? 'tab2' : ''"><a (click)="isMenuCollapsed = true">{{tab.menu_name}}</a>
                    </li>
                </ul>
            </div>
            <div class="right-menu" *ngIf="!shareToclient">
                <ul class="navbar-nav navBarItems">
                    <li class="nav-item userIconContainer">
                        <a [matMenuTriggerFor]="userMenu"><img src="../../../../assets/img/user.png"></a>
                        <mat-menu #userMenu="matMenu" xPosition="after" class="customize">
                            <button mat-menu-item (click)="gotoUserSetting();isMenuCollapsed = true;"><a>My Profile</a></button>
                            <button mat-menu-item (click)="logout();isMenuCollapsed = true;"><a>Sign Out</a></button>
                        </mat-menu>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" (click)="gotoWishList();isMenuCollapsed = true;"><img src="../../../../assets/img/heart_icon.png"
                                style="width: 19px;"><span *ngIf="wishlistServ.wishListCount != 0">{{wishlistServ.wishListCount}}</span></a>
                    </li>
                    <li class="nav-item">
                        <a (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
                            #cartElement><img src="../../../../assets/img/bag.png"><span *ngIf="addToCartService.orderCartListObject.count != 0">{{addToCartService.orderCartListObject?.count}}</span></a>
                    </li>
                </ul>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="cart-menu" #cartElement1>
                    <div class="title">Cart(<span>{{addToCartService.orderCartListObject?.count}}</span>)</div>
                    <div class="detail">
                        <ul (scroll)="onScroll($event)">
                            <li *ngFor="let order of addToCartService.orderCartListObject?.rows; index as i">
                                <div style="width: 50%;">
                                    <p>{{order.lot.shape_name}} {{order.lot.weight}} {{order.lot.color_name}}
                                        {{order.lot.clarity_name}}</p>
                                    <p *ngIf="order.paired_lot_id" style="margin-top: 10px;">{{order.paired_diamond_lot.shape_name}}
                                        {{order.paired_diamond_lot.weight}} {{order.paired_diamond_lot.color_name}}
                                        {{order.paired_diamond_lot.clarity_name}}</p>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p class="value">
                                        <span *ngIf="displayPrice === 'b' || displayPrice === 's'">{{addToCartService.orderCartListObject.currency_symbol}}{{
                                            order.conversion_amount | number}}</span>
                                        <span *ngIf="displayPrice === 'c'">{{addToCartService.orderCartListObject.currency_symbol}}{{
                                            order.cost_conversion_amount | number}}</span>
                                    </p>
                                    <img src="../../../../assets/img/delete.png" (click)="openDialog(order)">
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="footer">
                        <div class="d-flex justify-content-between">
                            <label class="value">Total :</label>
                            <p class="value">
                                <span *ngIf="displayPrice === 'b' || displayPrice === 's'">{{addToCartService.orderCartListObject?.currency_symbol}}{{addToCartService.orderCartListObject?.total_conversion_amount
                                    | number}}</span>
                                <span *ngIf="displayPrice === 'c'">{{addToCartService.orderCartListObject?.currency_symbol}}{{addToCartService.orderCartListObject?.total_cost_conversion_amount
                                    | number}}</span>
                            </p>
                        </div>
                        <button class="btn" [disabled]="addToCartService.orderCartListObject?.count <= 0" (click)="gotoOrderCart();isMenuCollapsed = true;">View
                            Cart</button>
                    </div>
                </div>
            </div>
            <div class="right-menu right-icons d-flex justify-content-end w-100" *ngIf="shareToclient" id="btnDiv">
                <!-- <button class="btn btn-outline copyBtn mr-2" (click)="shareLink(content)">Share</button> -->
                <div class="copyBtn mr-4 pr-3">
                    <span class="tooltip action">
                        <i class="fa fa-share-alt" (click)="shareLink(content)" aria-hidden="true"></i>
                        <span class="tooltiptext">Share</span>
                    </span>
                    </div>
                <!-- <button class="btn btn-outline convertPdfBtn mr-2" (click)="convertToPDF()">Convert To PDF</button> -->
                <!-- <div class="copyBtn mr-4 pr-3">
                    <span class="tooltip action">
                        <i class="fa fa-download" (click)="convertToPDF()" aria-hidden="true"></i>
                        <span class="tooltiptextPDF">Download PDF</span>
                    </span>
                </div> -->
                <!-- <button class="btn btn-outline copyBtn" (click)="copyLink()">Copy Link</button> -->
                <div class="copyBtn">
                    <span class="tooltip action">
                        <i class="fa fa-clipboard" (click)="copyLink()" aria-hidden="true"></i>
                        <span class="tooltiptextCopy">Copy URL</span>
                    </span>
                </div>
            </div>
        </div>
    </nav>
</section>
<!-- <section>
    <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
        <div class="logo">
            <a href="#" class="navbar-brand"><img src="../../../../assets/img/altr_gray_logo.png"></a>
        </div>
        <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
            &#9776;
        </button>

        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li class="nav-item active" [routerLink]="tab.link" *ngFor="let tab of tabs" [routerLinkActive]="tab.link ? 'tab2' : ''"><a
                        class="nav-link">{{tab.menu_name}}</a>
                </li>
                <li>
                    <a [matMenuTriggerFor]="userMenu"><img src="../../../../assets/img/user.png"></a>
                    <mat-menu #userMenu="matMenu" xPosition="after">
                        <button mat-menu-item (click)="gotoUserSetting()"><a>My Profile</a></button>
                        <button mat-menu-item (click)="logout()"><a>Sign Out</a></button>
                    </mat-menu>
                </li>
                <li>
                    <a href="javascript:void(0)" (click)="gotoWishList()"><img src="../../../../assets/img/heart_icon.png"
                            style="width: 19px;"><span *ngIf="wishlistServ.wishListCount != 0">{{wishlistServ.wishListCount}}</span></a>
                </li>
                <li>
                    <a (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
                        #cartElement><img src="../../../../assets/img/bag.png"><span>{{addToCartService.orderCartListObject?.count}}</span></a>

                </li>
            </ul>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="cart-menu" #cartElement1>
                <div class="title">Cart(<span>{{addToCartService.orderCartListObject?.count}}</span>)</div>
                <div class="detail">
                    <ul (scroll)="onScroll($event)">
                        <li *ngFor="let order of addToCartService.orderCartListObject?.rows; index as i">
                            <div style="width: 50%;">
                                <p>{{order.lot.shape_name}} {{order.lot.weight}} {{order.lot.color_name}}
                                    {{order.lot.clarity_name}}</p>
                                <p *ngIf="order.paired_lot_id" style="margin-top: 10px;">{{order.paired_diamond_lot.shape_name}}
                                    {{order.paired_diamond_lot.weight}} {{order.paired_diamond_lot.color_name}}
                                    {{order.paired_diamond_lot.clarity_name}}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="value">
                                    <span *ngIf="displayPrice === 'b' || displayPrice === 's'">{{addToCartService.orderCartListObject.currency_symbol}}{{
                                        order.conversion_amount | number}}</span>
                                    <span *ngIf="displayPrice === 'c'">{{addToCartService.orderCartListObject.currency_symbol}}{{
                                        order.cost_conversion_amount | number}}</span>
                                </p>
                                <img src="../../../../assets/img/delete.png" (click)="openDialog(order)">
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="footer">
                    <div class="d-flex justify-content-between">
                        <label class="value">Total :</label>
                        <p class="value">
                            <span *ngIf="displayPrice === 'b' || displayPrice === 's'">{{addToCartService.orderCartListObject?.currency_symbol}}{{addToCartService.orderCartListObject?.total_conversion_amount
                                | number}}</span>
                            <span *ngIf="displayPrice === 'c'">{{addToCartService.orderCartListObject?.currency_symbol}}{{addToCartService.orderCartListObject?.total_cost_conversion_amount
                                | number}}</span>
                        </p>
                    </div>
                    <button class="btn" [disabled]="addToCartService.orderCartListObject?.count <= 0" (click)="gotoOrderCart()">View
                        Cart</button>
                </div>
            </div>
        </div>
    </nav>
</section> -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h2 class="modal-title text-center" style="margin: 0 auto;width: 100%;">Share
        </h2>
        <button type="button" class="close" style="outline: none !important;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="share-icon-container">
            <div class="row d-flex justify-content-around">
                <div class="twitter-icon shareIcons" (click)="twitterShare()">
                    <i class="fa fa-twitter-square fa-3x" aria-hidden="true"></i>
                </div>
                <div class="facebook-icon shareIcons" (click)="facebookShare()">
                    <i class="fa fa-facebook-square fa-3x" aria-hidden="true"></i>
                </div>
                <div class="whatsapp-icon shareIcons" (click)="whatsappShare()">
                    <i class="fa fa-whatsapp fa-3x" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </div>
</ng-template>