<section class="maincontainer pb-0">
    <p class="mainLabel2">Manage Users</p>
    <div class="line1"></div>
    <div class="formDiv">
        <div style=" padding: 0 20px;">
            <div style="margin:20px 0" class="floatLeft">
                <div class="search-block">
                    <input type="text" name="search" autocomplete="off" [(ngModel)]="search" placeholder="Search by Email ID, First Name."
                        (keydown.enter)="searchData()" (keyup)="searchKeyData()">
                    <i *ngIf="this.search" class="fa fa-search" (click)="searchData()"></i>
                    <!-- <i *ngIf="this.search && searchActive" class="fa fa-times-circle-o clear" (click)="clearData()"></i> -->

                </div>
            </div>

            <div style="margin:20px 0;display:flex;" class="floatRight">
                <!-- <div class="filter"> -->
                <!-- <mat-form-field appearance="fill" style="height: 36px;">
                        <mat-label>Role</mat-label>
                        <mat-select>
                            <mat-option value="1">Distributer</mat-option>
                            <mat-option value="2">Retailer</mat-option>
                            <mat-option value="3">WholeSeller</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                <div class="filter" *ngIf="roleList.length > 0 && is_sub_user != true">
                    <label for="role">Role :</label>
                    <select class="form-control" id="role" (change)="filterData($event.target.value)">
                        <option value="null" selected>Select Role</option>
                        <option *ngFor="let role of roleList" [value]='role.client_role_id' [label]="role.client_role_name">{{role.client_role_name}}</option>
                    </select>
                </div>
                <!-- </div> -->

                <button mat-stroked-button class="whitebtnForm" (click)="addUser()">Add User</button>
            </div>
        </div>
        <div class="list-view">
            <div class="table-responsive">
                <table class="table table-striped table-fixed mb-0">
                    <thead>
                        <tr>
                            <th scope="col" class="col-2 commonHead">First Name</th>
                            <th scope="col" class="col-2 commonHead">Last Name</th>
                            <th scope="col" class="col-3 email-id">Email Id</th>
                            <th scope="col" class="col-2 commonHead">Role</th>
                            <th scope="col" class="col-1 commonHead">Status</th>
                            <th scope="col" class="col-2 commonHead" style="text-align:center">Actions</th>
                        </tr>
                    </thead>
                    <tbody (scroll)="onScroll($event)" class="thikScroll">
                        <a *ngFor="let data of manageUserList; index as i">
                            <tr>
                                <td class="col-2 commonHead">{{ data.first_name }}</td>
                                <td class="col-2 commonHead">{{ data.last_name}}</td>
                                <td class="col-3 email-id">{{ data.username}}</td>
                                <td class="col-2 commonHead">{{ data.client_role_name ? data.client_role_name : '-'}}</td>
                                <td class="col-1 d-flex commonHead" style="text-align:center">
                                    <div>
                                        <mat-slide-toggle class="example-margin" (change)="activeToggle($event,data.user_id, data)"
                                            [(ngModel)]="data.is_active">
                                        </mat-slide-toggle>
                                    </div>
                                    <div class="pl-2">
                                        <p class="value" *ngIf="data.is_active == 1" style="color:green">Active</p>
                                        <p class="value" *ngIf="data.is_active == 0" style="color:red">Inactive</p>
                                    </div>
                                </td>
                                <td class="col-2 commonHead">
                                    <a [routerLink]="['editUser',data.user_id]">
                                        <span class="tooltip action">
                                            <img src="./assets/img/svg/edit.svg" class="iconSize" style="margin-right: 18px;">
                                            <span class="tooltiptext">Edit User</span>
                                        </span>
                                    </a>
                                    <a [routerLink]="['viewUser',data.user_id]">
                                        <span class="tooltip action">
                                            <img src="./assets/img/svg/vision.svg" class="iconSize">
                                            <span class="tooltiptext">View User</span>
                                        </span>
                                    </a>
                                </td>
                            </tr>
                        </a>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <div class="paginationRow" *ngIf="manageUserList.length > 0">
            <ngb-pagination [(page)]="pageNo" (pageChange)="methodCall($event)" [pageSize]="pageRecord"
                [collectionSize]="this.totalRecords">
            </ngb-pagination>
            <p class="showRowLabel">{{getRecord()}}</p>
        </div> -->

        <div *ngIf="manageUserList.length < 1" style="text-align:center;margin: 10px auto;">
            <p style="font-size:13px"> No Records Found </p>
        </div>
    </div>
</section>