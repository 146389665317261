import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/_common/common.service';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { DataService } from 'src/app/services/data-service.service';
import { URLS } from 'src/app/core/_constant/api.config';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from '../../core/_component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

    orderDetailData: any;
    detailsData: any;
    orderDetailList = [];
    public displayPrice: any;

    diamondOrderId: any;
    private subscription: Subscription = new Subscription();

    totalRecords: any;

    public filterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 10
    };
    constructor(public commonService: CommonService,
        private router: Router,
        private route: ActivatedRoute,
        public dataService: DataService,
        private notifyService: NotifyService,
        public loader: LoaderService,
        public dialog: MatDialog
    ) {
        this.orderDetailData = this.router.getCurrentNavigation().extras.state;
        //check utm params
        this.route.queryParams.subscribe(params => {
            if (params.diamondOrderId) {
                this.diamondOrderId = params.diamondOrderId;
                this.getOrderDetails()
            }

        });
    }

    backToOrderHistory() {
        this.router.navigate(['/order-history']);
    }

    ngOnInit(): void {
        this.commonService.setEvent('SelectMenu', { key: 'Order History', value: 'orderHistory' });
    }

    gradingLink(event, gradingLink) {
        event.preventDefault();
        event.stopPropagation();
        window.open(gradingLink, '_blank');
    }

    shipmentTracking(event, shippingLink) {
        event.preventDefault();
        event.stopPropagation();
        if (shippingLink) {
            if (shippingLink.includes("https://")) {
                window.open(shippingLink, '_blank');
            } else {
                window.open("https://" + shippingLink, '_blank');
            }
        }
    }

    getOrderDetails() {
        this.loader.showSpinner();
        const params = {
            "diamond_order_id": +this.diamondOrderId
        }
        this.subscription.add(
            this.dataService.post(URLS.getOrderDetail, params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data["code"] == 200) {
                        this.detailsData = data['data'];
                        this.displayPrice = data["data"]["display_price"];
                        if (data['data']['diamond_order_details'].length > 0) {
                            this.orderDetailList = data['data']['diamond_order_details'];
                        }

                        // this.isLoading = false;
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    getDate(value) {
        return moment(value).format("DD MMM yyyy");
    }
    getTime(value) {
        return moment(value).format("hh:mm A");
    }
    goToDiamondDetail(diamondData) {
        let url = diamondData.lot.diamond_type == 'p' ? '/pairedDiamondDetail/' + diamondData.lot_id + '?pairedLotId=' + diamondData.paired_lot_id
            : '/diamondDetail/' + diamondData.lot_id + '?diamondType=' + diamondData.lot.diamond_type;
        window.open(`${environment.webUrl}` + url, '_blank');
    }

    approveOrder() {
        this.openDialogApprove();
    }

    openDialogApprove() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            height: '230px',
            width: '400px',
            data: { headerName: 'Approve Order', text: 'Are you sure you want to Approve Order?' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loader.showSpinner();
                const params = {
                    "diamond_order_id": +this.detailsData['diamond_order_id'],
                    "status": 2,
                }
                this.subscription.add(
                    this.dataService.put(URLS.putOrderApproval, params).subscribe(
                        data => {
                            this.loader.hideSpinner();
                            if (data["code"] == 200) {
                                this.notifyService.showToast(data["message"]);
                                this.router.navigateByUrl('/order-history');
                            } else {
                                this.notifyService.showToast(data["message"]);
                            }
                        },
                        error => {
                            this.loader.hideSpinner();
                            console.log(error);
                        }
                    )
                );
            }
        });
    }

    rejectOrder() {
        this.openDialog();
    }

    openDialog() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            height: '230px',
            width: '400px',
            data: { headerName: 'Reject Order', text: 'Are you sure you want to Reject Order?' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loader.showSpinner();
                const params = {
                    "diamond_order_id": +this.detailsData['diamond_order_id'],
                    "status": 3,
                }
                this.subscription.add(
                    this.dataService.put(URLS.putOrderApproval, params).subscribe(
                        data => {
                            this.loader.hideSpinner();
                            if (data["code"] == 200) {
                                this.notifyService.showToast(data["message"]);
                                this.router.navigate(['/order-history']);
                                // this.isLoading = false;
                            } else {
                                this.notifyService.showToast(data["message"]);
                            }
                        },
                        error => {
                            this.loader.hideSpinner();
                            console.log(error);
                        }
                    )
                );
            }
        });
    }

}
