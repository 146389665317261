import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as crypto from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { SECRET_KEY, URLS } from '../core/_constant/api.config';


declare var forge: any;

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    // secret key to encrypt the login credentails
    private secretKey: string = SECRET_KEY;

    // observable user details
    // public currentUserSubject: BehaviorSubject<any>;

    // onserveable logged in status
    public loggedInStatus;

    // logged in user tpe
    // public currentUser: Observable<any>;

    // login api url
    private loginUrl = URLS.login;

    // logout api url
    private logoutUrl = URLS.logout;
    constructor(private http: HttpClient, private cookieService: CookieService) {
        this.loggedInStatus = new BehaviorSubject<boolean>(this.hasToken());
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }


    /**
  * if we have token the user is loggedIn
  * @returns {boolean}
  */
    public hasToken(): boolean {
        // return !!localStorage.getItem('token');
        return !!this.cookieService.get('token');
    }



    /**
    *
    * @returns {Observable<T>}
    */
    public isLoggedIn() {
        return this.loggedInStatus.asObservable();
    }

    /**
*  Login the user then tell all the subscribers about the new status
*/
    public login(loginData: any) {
        // const cipherData = crypto.AES.encrypt(JSON.stringify(loginData), this.secretKey).toString();
        // const cipherPasswrd = crypto.AES.encrypt(password, this.secretKey).toString();
        const dataParams = JSON.stringify(loginData);
        const publicKey = forge.pki.publicKeyFromPem(SECRET_KEY);
        const encrypted = publicKey.encrypt(dataParams, 'RSA-OAEP', {
            md: forge.md.sha256.create()
        });
        const base64Param = encodeURIComponent(forge.util.encode64(encrypted));


        return this.http.post<any>(`${this.loginUrl}`, { 'data': base64Param })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.code === 200) {

                    this.cookieService.set('token', user.data.access_token, null, null, null, null, 'Lax');
                    this.cookieService.set('userData', user.data.user_data, null, null, null, null, 'Lax');
                    this.loggedInStatus.next(true);

                    return user;
                } else if (user && user.code === 409) {
                    return user;
                } else {
                    return false;
                }
            }));
    }

    public logout(data?) {
        if(window['Tawk_API']){
            window['Tawk_API'].minimize();
            window['Tawk_API'].hideWidget();
        }
        this.loggedInStatus.next(false);
        this.cookieService.deleteAll();
        return this.http.post<any>(`${this.logoutUrl}`, data);
    }
}
