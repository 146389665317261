import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[appLoader]',
})
export class LoaderDirective implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() isDataTable = false;
  @Input() text = '';
  @Input() size = 30;
  @Input() background = 'transparent';
  @Input() color = '#01685c';

  constructor(private el: ElementRef) {}

  setText() {
    this.el.nativeElement.innerHTML = this.loading === true ? this.getHtml : this.text;
  }

  get imageLink() {
    return 'assets/img/spinner.gif';
  }
  get getHtml() {
    return '<img src="' + this.imageLink + '" width="' + this.size + '"/>&nbsp;' + this.text;
  }

  ngOnInit() {
    this.setText();
  }
  ngOnChanges() {
    this.setText();
  }
}
