import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationExtras } from '@angular/router';
import { CommonService } from 'src/app/core/_common/common.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DiamondDetailService } from './diamond-detail.service';
import { Subscription, async, Observable, Observer } from 'rxjs';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AddToCartService } from 'src/app/services/addtocart.service';
import { SharedHeaderService } from 'src/app/services/sharedHeader.service';
import { ModalComponent } from 'src/app/core/_component/modal/modal.component';
import { DataService } from 'src/app/services/data-service.service';

@Component({
    selector: 'app-diamond-detail',
    templateUrl: './diamond-detail.component.html',
    styleUrls: ['./diamond-detail.component.scss'],
    providers: [DiamondDetailService]
})
export class DiamondDetailComponent implements OnInit {

    private subscription: Subscription = new Subscription();

    public diamondID: any;
    public diamondType: any;
    public diamondDetailData: any;
    public shareDiamondDetailData: any;
    public certiUrlForModal: any;
    public certiTitle: any;
    public measurement: any;
    public shareMeasurement: any;
    public token: any;
    public isShareToClient: boolean = false;
    public displayPrice: any;
    public mainImage: any;
    public base64DefaultURL: any;

    constructor(
        private diamondDetailService: DiamondDetailService,
        public commonService: CommonService,
        public activeRoute: ActivatedRoute,
        private router: Router,
        config: NgbCarouselConfig,
        private modalService: NgbModal,
        public loader: LoaderService,
        public addToCartService: AddToCartService,
        public sharedHeaderService: SharedHeaderService,
        public notifyService: NotifyService,
        private dataService: DataService,
    ) {
        config.showNavigationArrows = true;
        config.showNavigationIndicators = false;
        this.diamondID = this.activeRoute.snapshot.paramMap.get("id");
    }

    ngOnInit(): void {
        window.scroll(0, 0);
        this.commonService.setEvent('SelectMenu', { key: 'Diamond Detail', value: 'diamondDetail' });

        this.activeRoute.queryParams.subscribe((params) => {
            this.diamondType = params['diamondType'];
            this.token = params['code'];
        });
        //Share to client Diamond Details
        if (this.token) {
            const shareParams = {
                code: this.token
            }
            this.getShareDiamondDetail(shareParams);
        } else {
            const params = {
                lot_id: +this.diamondID,
            }
            if (this.diamondType === 'c') {
                params['diamond_type'] = "c"
            } else {
                params['diamond_type'] = "w"
            }
            this.getDiamondDetail(params);
        }
    }
    // Diamond List Data
    getDiamondDetail(params) {
        this.loader.showSpinner();
        this.subscription.add(
            this.diamondDetailService.getDiamondDetail(params).subscribe(
                data => {
                    if (data['code'] === 200) {
                        this.diamondDetailData = data['data'];
                        this.displayPrice = data['data']['display_price'];
                        this.measurement = this.diamondDetailData.measurement ? this.diamondDetailData.measurement.replaceAll("*", " x ") : '-';
                        const videoUrl = this.diamondDetailData.video_path ? this.diamondDetailData.video_path : 'https://www.altr.nyc/videos/diamond.html?d=440013676';
                        document.getElementById("diamondIframe").setAttribute("src", videoUrl);
                        // this.diamondDetailData.forEach(element => {
                        //     if (element.in_order_cart && !this.addToCartService.storedLotIds.includes(element.lot_id)) {
                        //         this.addToCartService.storedLotIds.push(element.lot_id)
                        //     }
                        // });
                    }
                    this.loader.hideSpinner();
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    getShareDiamondDetail(params) {
        this.loader.showSpinner();
        this.subscription.add(
            this.diamondDetailService.getShareDiamondDetail(params).subscribe(
                data => {
                    if (data['code'] === 200) {
                        this.diamondDetailData = data['data'];
                        this.displayPrice = data['data']['display_price'];
                        this.measurement = this.diamondDetailData.measurement ? this.diamondDetailData.measurement.replaceAll("*", " x ") : '-';
                        const videoUrl = this.diamondDetailData.video_path ? this.diamondDetailData.video_path : 'https://www.altr.nyc/videos/diamond.html?d=440013676';
                        this.diamondType = data['data']['diamond_type'];
                        document.getElementById("diamondIframe").setAttribute("src", videoUrl);
                        if (this.diamondDetailData.share_to_client_id) {
                            this.isShareToClient = true;
                        }

                    }
                    this.loader.hideSpinner();
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    openCertiModal(content, type, url) {
        if (type === 'CERTIFICATE') {
            this.certiUrlForModal = url ? url : './assets/img/certi.png';
        }
        else if (type === 'LASER INSCRIPTION') {
            this.certiUrlForModal = url ? url : './assets/img/certi.png';
        }
        else if (type === 'PROPORTION DIAGRAM') {
            this.certiUrlForModal = url ? url : './assets/img/proportion-btn.png';
        }
        this.certiTitle = type;
        this.modalService.open(content, { size: 'xl' });
    }

    //Add to cart
    AddToCart(diamondData) {
        if (this.getCartObject(diamondData)) {
            this.addToCartService.removeFromCart(diamondData);
        } else {
            this.addToCartService.addToCart(diamondData);
        }
    }

    //Share to client modal
    showClientModal(data) {
        const modalRef = this.modalService.open(ModalComponent, { centered: true, scrollable: true });
        modalRef.componentInstance.shareClientData = data;
        modalRef.componentInstance.displayPrice = this.displayPrice;
    }

    //Match 
    matchClick(data) {
        if (data.paired_lot_id) {
            this.router.navigate(['/pairedDiamond']);
            this.sharedHeaderService.stockId = data.altr_stone_id;
        } else {
            this.notifyService.showToast('No Match Found');
        }
    }

    getCartObject(diamondData) {
        let cartObject;
        if (diamondData) {
            if (diamondData.paired_diamond_lot && diamondData.paired_diamond_lot.lot_id)
                cartObject = this.addToCartService.orderCartListObject.rows.find(x => x.paired_lot_id == diamondData.paired_lot_id && x.lot_id == diamondData.lot_id);
            else
                if (this.addToCartService.orderCartListObject.rows)
                    cartObject = this.addToCartService.orderCartListObject.rows.find(x => x.lot_id == diamondData.lot_id);
        }
        return cartObject ? true : false;
    }

    getLabelForCart(diamondData) {
        if (this.getCartObject(diamondData))
            return "Remove from Cart";
        else
            return "Add to Cart";
    }
}
