import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpResponseBase
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '../_common/snackbar.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private cookieService: CookieService,
        private router: Router,
        private notifyService: NotifyService,
        private authService: AuthenticationService,
        private activeRoute: ActivatedRoute,) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.cookieService.get('token');

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            if (!request.url.includes('sendCreditPdfMail'))
                request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        request = request.clone({ headers: request.headers.set('platform_request', 'web') });

        // return next.handle(request).pipe(
        //     map((event: HttpEvent<any>) => {
        //         if (event instanceof HttpResponse) {
        //             // console.log('event--->>>', event);
        //         }
        //         return event;
        //     },));
        return next.handle(request).pipe(
            map((event: any) => {
                if (event instanceof HttpResponseBase) return this.handleData(event);
                return event;
            }),
            catchError((err: HttpErrorResponse) => {
                console.log(err);
                return this.handleData(err);
            })

        );
    }



    private handleData(ev: any): Observable<any> {
        // console.log(ev)

        if (!ev['body']) {
            ev['body'] = ev['error'];
        }
        if (ev['body']) {
            switch (ev['body']['code']) {
                case 200:
                    break;
                case 400:
                    this.notifyService.showToast(`${ev['body']['message']}`);
                    break;
                case 401:
                    this.notifyService.showToast(`${ev['body']['message']}`);
                    this.router.navigate(['/login']);
                    this.authService.logout();
                    break;
                case 403:
                case 404:
                case 410:
                    this.notifyService.showToast(`${ev['body']['message']}`);
                    break;
                case 409:
                    this.notifyService.showToast(`${JSON.stringify(ev['body']['errors'])}`);
                    break;
                case 500:
                    this.notifyService.showToast(`Something went wrong`);
                    break;
                default:
                    if (ev instanceof HttpErrorResponse) {
                        console.warn(`I don't know the error, most of it is caused by the backend not supporting CORS or invalid configuration.`, ev);
                        return throwError(ev);
                    }
                    break;
            }
        }
        return ev;
    }
}
