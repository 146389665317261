import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/core/_constant/api.config';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private http: HttpClient) { }

    shareToClient(param?): Observable<{}> {
        return this.http.post(URLS.shareToClient, param);
    }
}
