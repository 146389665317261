<div class="mr-2 ">
</div>
<div class="mainDiv">
    <div class="headerContent">
        <div class="crossButton">
            <button type="button" class="close closeButton" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <h4 class="modal-title" *ngIf="!isPairedShareToClient">Show Client &nbsp;
            <!-- <span>{{shareClientData.lab_name}}</span>  -->
            {{shareClientData.altr_stone_id}}
        </h4>
        <h4 class="modal-title" *ngIf="isPairedShareToClient">Show Client
            <br>
            <span>{{shareClientData.lot.lab_name}}</span> {{shareClientData.lot.altr_stone_id}}
            <span>&nbsp;-&nbsp;</span>
            <span>{{shareClientData.paired_diamond_lot.lab_name}}</span>
            {{shareClientData.paired_diamond_lot.altr_stone_id}}
        </h4>
        <p class="paraText  text-center pt-2 pb-4" *ngIf="displayPrice === 'b' || displayPrice === 's'"> Your price is
            {{shareClientData.currency_symbol}}{{shareClientData.conversion_amount | number}} exc. GST</p>
        <p class="paraText  text-center pt-2 pb-4" *ngIf="displayPrice === 'c'"> Your price is
            {{shareClientData.currency_symbol}}{{shareClientData.cost_conversion_amount | number}} exc. GST</p>
    </div>
    <div class="detailContent border-top">
        <div class="form-check pt-3 " *ngIf="displayPrice === 'b' || displayPrice === 's'">
            <input class="form-check-input " type="checkbox" value="" id="retailCheck" [(ngModel)]="showRetailsPrice" />
            <label class="form-check-label" for="retailCheck" class="labelClass ml-1">
                Show Retail Price
            </label>
        </div>
        <div class="form-check pt-3 " *ngIf="displayPrice === 'c'">
            <input class="form-check-input " type="checkbox" value="" id="costCheck" [(ngModel)]="showCostPrice" />
            <label class="form-check-label" for="costCheck" class="labelClass ml-1">
                Show Cost Price
            </label>
        </div>
        <div *ngIf="showRetailsPrice && (displayPrice === 'b' || displayPrice === 's')" class="w-100 pt-1">
            <p class="pClass pt-1 pb-2">Enter the retail price to show on the page.</p>
            <mat-form-field appearance="outline" class="inputMAt">
                <mat-label>Retail Price ({{shareClientData.currency_symbol}})</mat-label>
                <input matInput class="mt-0" [(ngModel)]="retailPrice" (keypress)="isNumberKey($event)">
            </mat-form-field>
        </div>
        <div *ngIf="showCostPrice && displayPrice === 'c'" class="w-100 pt-1">
            <p class="pClass pt-1 pb-2">Enter the cost price to show on the page.</p>
            <mat-form-field appearance="outline" class="inputMAt">
                <mat-label>Cost Price ({{shareClientData.currency_symbol}})</mat-label>
                <input matInput class="mt-0" [(ngModel)]="costPrice" (keypress)="isNumberKey($event)">
            </mat-form-field>
        </div>
        <div class="form-check pt-2">
            <input class="form-check-input" type="checkbox" value="" id="messageCheck" [(ngModel)]="showAdditionalLines">
            <label class="form-check-label" for="messageCheck" class="labelClass ml-1">
                Show additional lines
            </label>
        </div>
        <div *ngIf="showAdditionalLines" class="form-group pt-1 mb-0">
            <p class="pClass pt-1 pb-2" *ngIf="displayPrice === 'b' || displayPrice === 's'">Enter additional
                information which will show below the retail price.
            </p>
            <p class="pClass pt-1 pb-2" *ngIf="displayPrice === 'c'">Enter additional information which will show below
                the cost price.
            </p>
            <mat-form-field class="example-full-width w-100" appearance="outline">
                <mat-label>Additional lines</mat-label>
                <textarea matInput [(ngModel)]="message"></textarea>
            </mat-form-field>
        </div>
        <div class="buttonClass">
            <button class="btn btnColorClas" (click)="activeModal.dismiss('Cross click')">Cancel</button>
            <button class="btn btnColorClass" (click)="shareToClient()">Generate</button>
        </div>

    </div>
</div>