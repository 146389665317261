<div *ngIf="data">
    <h2 mat-dialog-title class="headerText"><b>{{data.headerName}}</b></h2>
    <p class="paraText">{{data.text}}</p>
</div>
<!-- <div *ngIf="data">
        <h2 mat-dialog-title class="headerText"><b>{{data.headerName}}</b></h2>
        <p class="paraText">{{data.text}}</p>
    </div> -->
<mat-dialog-actions class="buttonDiv">
    <button class="noBtnClass" mat-button mat-dialog-close (click)=onDismiss()>NO</button>
    <button class="yesBtnClass" mat-button mat-dialog-close (click)=onConfirm()>YES</button>
</mat-dialog-actions>