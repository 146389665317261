import { CustomValidator } from 'src/app/core/_common/custom-validators';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/_common/common.service';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { DataService } from 'src/app/services/data-service.service';
import { URLS } from 'src/app/core/_constant/api.config';
import { type } from 'os';

@Component({
    selector: 'app-add-role',
    templateUrl: './add-role.component.html',
    styleUrls: ['./add-role.component.scss'],
})
export class AddRoleComponent implements OnInit {

    firstFormGroup: FormGroup;


    public validation_messages = {
        role_name: [
            { type: 'required', message: 'Please enter name of role!' },
            { type: 'maxlength', message: 'Name of role should not be more than 50 characters long' },
            { type: 'whitespace', message: 'Please enter valid name of role!' },
            { type: 'quotes', message: 'Please enter valid name of role!' },
        ],

        orderApproval: [
            { type: 'required', message: 'Please selecet Order approval!' },

        ],
        orderApprovalAccess: [
            { type: 'required', message: 'Please selece Order approval access!' },
        ],
        userApprovalAccess: [
            { type: 'required', message: 'Please selece User approval access!' },
        ],
        userAccess: [
            { type: 'required', message: 'Please select user access!' },

        ],
        showCostPrice: [
            { type: 'required', message: 'Please select show cost price!' },

        ],
        showSellPrice: [
            { type: 'required', message: 'Please selecet show sell price!' },

        ],
    }

    disableCostPrice = false;
    dataId: any;
    roleData = [];
    private subscription: Subscription = new Subscription();

    constructor(private formBuilder: FormBuilder,
        private router: Router,
        public notifyService: NotifyService,
        public dataService: DataService,
        public activeRoute: ActivatedRoute,
        public loader: LoaderService,
        public commonService: CommonService) {
        this.dataId = this.activeRoute.snapshot.paramMap.get("id");
        console.log(this.dataId);
    }

    ngOnInit(): void {
        this.firstFormGroup = this.initializeFirstForm();

        if (this.dataId) {
            this.getRoleDetails();
        }
        else {
            this.firstFormGroup.controls['orderApproval'].setValue("1");
            this.firstFormGroup.controls['orderApprovalAccess'].setValue("0");
            this.firstFormGroup.controls['userApprovalAccess'].setValue("1");
            this.firstFormGroup.controls['showCostPrice'].setValue("0");
            this.firstFormGroup.controls['showSellPrice'].setValue("1");

        }

    }

    onCancel() {
        if (this.dataId) {
            this.getRoleDetails();
        }
        else {
            this.firstFormGroup = this.initializeFirstForm();
            this.firstFormGroup.controls['orderApproval'].setValue("1");
            this.firstFormGroup.controls['orderApprovalAccess'].setValue("0");
            this.firstFormGroup.controls['userApprovalAccess'].setValue("1");
            this.firstFormGroup.controls['showCostPrice'].setValue("0");
            this.firstFormGroup.controls['showSellPrice'].setValue("1");
        }
    }
    firstFormSubmit() {

    }

    goBack() {
        // this.commonService.setEvent("backUserSetting", 7);
        this.router.navigateByUrl('/user-setting/role-management');
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.firstFormGroup.controls;
    }


    getRoleDetails() {
        const params = {
            client_role_id: +this.dataId
        };
        this.loader.showSpinner();
        this.subscription.add(
            this.dataService.post(URLS.getRoleDetails, params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data['code'] == 200) {
                        this.roleData = data['data'];

                        this.firstFormGroup.setValue({
                            role_name: this.roleData['client_role_name'],
                            orderApproval: this.roleData['order_access_required'] == true ? '1' : '0',
                            orderApprovalAccess: this.roleData['access_order_approval'] == true ? '1' : '0',
                            userApprovalAccess: this.roleData['access_user_management'] == true ? '1' : '0',
                            showCostPrice: this.roleData['show_cost_price'] == true ? '1' : '0',
                            showSellPrice: this.roleData['show_sell_price'] == true ? '1' : '0',
                        });
                    } else {
                        this.notifyService.showToast('Error ' + data['message']);
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }


    orderApprovalClick() {
        // if (this.f.orderApprovalAccess.value == 1) {
        //     this.firstFormGroup.controls['showCostPrice'].setValue("1");
        //     this.disableCostPrice = true;
        // }
        // else {
        //     this.disableCostPrice = false;
        // }
    }


    // showSellPriceClick() {
    //     if (this.f.showSellPrice.value == 1) {
    //         this.firstFormGroup.controls['showCostPrice'].setValue("0");
    //     }
    //     if (this.f.showSellPrice.value == 0) {
    //         this.firstFormGroup.controls['showCostPrice'].setValue("1");
    //     }

    // }

    // showCostPriceClick() {
    //     if (this.f.showCostPrice.value == 1) {
    //         this.firstFormGroup.controls['showSellPrice'].setValue("0");
    //     }

    //     if (this.f.showCostPrice.value == 0) {
    //         this.firstFormGroup.controls['showSellPrice'].setValue("1");
    //     }
    // }

    showConfirm(actionType) {
        if(this.f.showCostPrice.value == 0 && this.f.showSellPrice.value == 0)
        { 
            this.notifyService.showToast('Select alteast one cost price or retail price');
            return;
        }
        for (const i in this.f) {
            this.f[i].markAsDirty();
            this.f[i].updateValueAndValidity();
            this.f[i].markAsTouched();
        }

        // stop here if form is invalid
        if (this.firstFormGroup.invalid) {
            return;
        }
        else {
            if (actionType === 'add') {
                const params = {
                    "client_role_name": this.f.role_name.value,
                    "is_master": true,
                    "show_cost_price": this.f.showCostPrice.value == 0 ? false : true,
                    "order_access_required": this.f.orderApproval.value == 0 ? false : true,
                    "show_sell_price": this.f.showSellPrice.value == 0 ? false : true,
                    "access_order_approval": this.f.orderApprovalAccess.value == 0 ? false : true,
                    "access_user_management": this.f.userApprovalAccess.value == 0 ? false : true,
                }
                this.loader.showSpinner();
                this.subscription.add(
                    this.dataService.post(URLS.addRoleData, params).subscribe(
                        data => {
                            this.loader.hideSpinner();
                            if (data['code'] == 200) {
                                this.notifyService.showToast(data['message']);
                                this.router.navigate(['/user-setting/role-management']);                               
                            } else {
                                this.notifyService.showToast(data['message']);
                            }
                            if(data['data']['reset_sell_price']){
                                this.firstFormGroup.controls['showSellPrice'].setValue("0");
                            }
                        },
                        error => {
                            this.loader.hideSpinner();
                            console.log(error);
                        }
                    )
                );
            }
            else {
                const params = {
                    "client_role_name": this.f.role_name.value,
                    "is_master": true,
                    "client_role_id": +this.dataId,
                    "show_cost_price": this.f.showCostPrice.value == 0 ? false : true,
                    "order_access_required": this.f.orderApproval.value == 0 ? false : true,
                    "show_sell_price": this.f.showSellPrice.value == 0 ? false : true,
                    "access_order_approval": this.f.orderApprovalAccess.value == 0 ? false : true,
                    "access_user_management": this.f.userApprovalAccess.value == 0 ? false : true,
                }
                this.loader.showSpinner();
                this.subscription.add(
                    this.dataService.put(URLS.updateRoleData, params).subscribe(
                        data => {
                            this.loader.hideSpinner();
                            if (data['code'] == 200) {
                                this.notifyService.showToast(data['message']);
                                this.router.navigate(['/user-setting/role-management']);
                            } else {
                                this.notifyService.showToast(data['message']);
                            }
                            if(data['data']['reset_sell_price']){
                                this.firstFormGroup.controls['showSellPrice'].setValue("0");
                            }
                        },
                        error => {
                            this.loader.hideSpinner();
                            console.log(error);
                        }
                    )
                );
            }

        }
    }

    initializeFirstForm() {
        return this.formBuilder.group({
            role_name: new FormControl(null, {
                validators: Validators.compose([Validators.required, Validators.maxLength(50), CustomValidator.noWhiteSpaceValidator]),
            }),
            orderApproval: new FormControl(null, {
                validators: Validators.compose([Validators.required,])
            }),
            orderApprovalAccess: new FormControl(null, {
                validators: Validators.compose([Validators.required,])
            }),
            userApprovalAccess: new FormControl(null, {
                validators: Validators.compose([Validators.required,])
            }),
            showCostPrice: new FormControl(null, {
                validators: Validators.compose([Validators.required,])
            }),
            showSellPrice: new FormControl(null, {
                validators: Validators.compose([Validators.required,])
            }),

        });
    }
}