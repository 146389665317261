// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'https://altrapi.dev.brainvire.net',
    webUrl: 'https://altrweb.dev.brainvire.net',
    reCaptchaKey: '6Ld63qUeAAAAAIQdGdeGKwIZNGmmJ0lsA4AOz-EC',
    // baseUrl: 'http://04921c708e74.ngrok.io',
    rsa_public_key: '-----BEGIN RSA PUBLIC KEY-----\nMIIECgKCBAEAuxWodBoYfS+o0RMWobO1S5rPdemAEgvXvT1waLRsBbEUzh/58lW4\nVjmutyfDc/WqgVnsYIxAfmnrBJM8pCwrENuiJ5gthJjvdOBWxTj01KrQFfEYUgjX\nEtP1i+lxlTq9Ck1kCG+zCv+EOMa4oo5Vx9WYSt23oAX7YUutZMK2xMvoX7l3Avnz\n+YAn6tcpWHlZ533OR24EdnJzsOofyvSV+w9swO+MgI1zGpF7ndLZzF6CGBDzOWoy\nPqP1+F0w+Dee52f5cr1e2F8iCnbSykwi3i66fGwVhQQkZWDpgT4iRh4ya+N47ISa\nzO/b3Yr2TAS0JdJmaNBbcKJdfawyVdGDOyHZr4My3I2QuP0gjRi6ybCXIT0sGO7H\nOEH/qxAfsV0wkyd0q2ivMWeaWvK6L84PFG7iYX6AY4jGFShamfs2f5ARErB4dI+e\nvKbMeQkYAk3Nne0KmrpYVJz6rBBdV1XpPC2Z3ZjZiFWfRqyQIEHJaqDOIpI843mz\n9O48k4cPApQzDpfDqJEl5Tn0oXqRzM+q84owVb3U2JSfk8eh3a2za27XHnS+gevE\nixAQG7qp+4WcbBL9BqOru8PGPr4setlzS4Q0zxppyp0QdB2uTrKA2j3LgBYSm6WU\n/a/O/sSRNcNh3FsnA0yCk8vVjBMelcjIAu7IxPqwAmR0qUIE2k3191UJKAq20Uvt\nUQ+2WIorcv9BTqVMs20j+dcFBXiAPH/mqQ14LUeLaNf4fENzrn6GjME57484v5Uh\nSALv0JDDK+qWPsQb++EKHPKI6YYMSl4ap/YSDLtUS6f0ygyjr/ObzMRLdM3Pdeuj\n7gcS5Ij4McYzQjg6pq25SipH93RlcQKEurA1anvoQk9gScXofx8NUHh6rYcOo2AO\nC500CEb+Db1HGGhN7rW9L6lZYRCmdDwyNU5YjHK/IOBWRg/130zsgJkVqi1a0M3g\ny8/kN0cDZ5ZK8QdjMaNa0GJfCJ3Ji+oMxOJkvGWhoaXFDKIbkvAw7DdleU2oC57m\n8EvrG9aJng1ofMYGaeYoDI3rjlRNg4PKgv4BcCjKaV/zttBYsqI/ocfVCQLqyrX2\nch7M1UjFUn108/Oc3SeM9wZsSAc9k1AR6oJ1fmRGxT7NOTXIG+gg27p1nP82Wsch\nMuYhI0HpAbYNdIAbfUQQ03HL6qGy6g7ZD8mxkkwDst6xE0K+Xq6LMh5L+tZ6zEqE\nEJfarOTCDJjMl54xEnmnIol3wxh6U4Ft46OZPUydK9WeX53fdDzZbnddr6F1RdPx\nyqJp8Eu6C+3OdqD3fM2N8a9gIuSFCwx+6oESPRQWPBowZGFJeWPX76GeQ2OkKswk\ntkLSoC0AGAt/mlZ4P7dtCtC5KCyHj30WrwIDAQAB\n-----END RSA PUBLIC KEY-----\n'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
