import { CommonService } from 'src/app/core/_common/common.service';
import { LoaderService } from './../../core/_common/loader.service';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { DataService } from 'src/app/services/data-service.service';
import { URLS } from 'src/app/core/_constant/api.config';

@Component({
    selector: 'app-view-user',
    templateUrl: './view-user.component.html',
    styleUrls: ['./view-user.component.scss'],
})
export class ViewUserComponent implements OnInit {
    dataId: any;
    userData: any = [];
    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        public notifyService: NotifyService,
        public dataService: DataService,
        public activeRoute: ActivatedRoute,
        public loader: LoaderService,
        public commonService: CommonService) {
        this.dataId = this.activeRoute.snapshot.paramMap.get("id");
        console.log(this.dataId);
    }

    ngOnInit(): void {
        if (this.dataId) {
            this.getUserDetails();
        }

    }

    goBack() {
        // this.commonService.setEvent("backUserSetting", 7);
        this.router.navigateByUrl('/user-setting/manage-user');
    }


    getUserDetails() {
        const params = {
            user_id: this.dataId
        }
        // this.isLoading = true;
        this.loader.showSpinner();
        this.subscription.add(this.dataService.get(URLS.getUsersData, params).subscribe(
            data => {
                this.loader.hideSpinner();
                if (data["code"] == 200) {
                    if (data['data']) {
                        this.userData = data["data"];
                        console.log(this.userData);
                    }

                    // this.isLoading = false;
                } else {
                    this.notifyService.showToast(data["message"]);
                }
            },
            error => {
                this.loader.hideSpinner();
                console.log(error);
            }));
    }
    // convenience getter for easy access to form fields


    dateFormat(data) {
        return moment(data).format('DD MMM YYYY - HH:mm:ss a');
    }
}