import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/core/_constant/api.config';

@Injectable({
    providedIn: 'root'
})
export class OrderCartService {

    constructor(private http: HttpClient) {}

    getOrderCartList(param?): Observable<{}> {
        return this.http.post(URLS.getOrderList, param);
    }   
    
    addWishList(param?): Observable<{}> {
        return this.http.post(URLS.addToWishlist, param);
    }
    
    removeWishList(param?): Observable<{}> {
        return this.http.delete(URLS.wishListRemoveItem, { params: param });
    }

    requestOrder(param?): Observable<{}> {
        return this.http.post(URLS.requestOrder, param);
    } 
    
    getcreditFormShippingAddress(param?): Observable<{}> {
        return this.http.post(URLS.getcreditFormShippingAddress, param);
    }
    
}