import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options, LabelType, CustomStepDefinition } from '@angular-slider/ngx-slider';
import { FilterService } from './filter.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { DataService } from 'src/app/services/data-service.service';
import { URLS } from '../../_constant/api.config';
import { floatValuePipe } from '../../_pipes/floatValue.pipe';
import { DecimalPipe } from '@angular/common';
import { SharedHeaderService } from '../../../services/sharedHeader.service';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    providers: [DecimalPipe]

})
export class FilterComponent implements OnInit {

    @Output() filterChange: EventEmitter<any> = new EventEmitter();
    @Input() diamondType: any;

    fireFilterChange() {
        this.filterChange.emit(null);
    }

    media: any = window.matchMedia("(max-width: 992px)");
    private subscription: Subscription = new Subscription();
    public floatPipe = new floatValuePipe();
    public isCollapsed = true;
    public laboratoryisCollapsed = true;
    public polishisCollapsed = true;
    public symmetryisCollapsed = true;
    public depthisCollapsed = true;
    public tableisCollapsed = true;
    public tingeisCollapsed = true;
    public ratioisCollapsed = true;

    public shapeData: any = [];
    public clarityData: any = [];
    public colorData: any = [];
    public cutData: any = [];
    public intensityData: any = [];
    public labData: any = [];
    public polishData: any = [];
    public symmetryData: any = [];
    public tingeData: any = [];
    public priceData: any = [];
    public currency: any;

    public colorString: any = [];
    public cutString: any = [];
    public clerityString: any = [];

    public shapecheckedIDs: any = [];
    public colorcheckedIDs: any = [];
    public cutcheckedIDs: any = [];
    public intensitycheckedIDs: any = [];
    public claritycheckedIDs: any = [];
    public pricecheckedIDs: any = [];
    public caratFromIDs: any;
    public caratToIDs: any;
    public sizeFromIDs: any = 0;
    public sizeToIDs: any = 25;
    public searchValue: any;
    public depthMinValue: any;
    public depthMaxValue: any;
    public tableMinValue: any;
    public tableMaxValue: any;
    public ratioMinValue: any;
    public ratioMaxValue: any;
    public labcheckedIDs: any = [];
    public polishcheckedIDs: any = [];
    public symmetrycheckedIDs: any = [];
    public tingecheckedIDs: any = [];
    public depthError: boolean = false;
    public tableError: boolean = false;
    public resetClicked = false;
    public priceMinValueCustom: any;
    public priceMaxValueCustom: any;
    public filterCollapsed: boolean;
    caratMinValueCustom: any = 0;
    caratMaxValueCustom: any = 10;
    sizeMinValueCustom: any = 0;
    sizeMaxValueCustom: any = 25;
    priceFromcheckedIDs: any;
    priceTocheckedIDs: any;
    search: any;
    searchActive: boolean = false;
    superIdealValue: any;
    cutcheckedIDsBackup = [];


    refineList: any = [
        { 'name': "Superior Round", 'value': false, 'is_info': true },
        { 'name': "Superior Fancies", 'value': false, 'is_info': true },
        { 'name': "Hearts & Arrows", 'value': false },
        { 'name': "AGS 000", 'value': false },
        { 'name': "3X", 'value': false },
        // { 'name': "GCAL 8X", 'value': false, 'is_info': true },
        { 'name': "As Grown", 'value': false }
    ];

    //Range slider

    // Color Slider
    alphabet: string = '';
    colorMinValue: number;
    colorMaxValue: number;
    colorOptions: Options = {
        floor: 0,
        ceil: 0,
        draggableRange: true,
        onlyBindHandles: true,
        hidePointerLabels: true,
    };

    // Cut Slider
    cutMinValue: number;
    cutMaxValue: number;
    cutOptions: Options = {
        floor: 0,
        ceil: 0,
        draggableRange: true,
        onlyBindHandles: true,
        hidePointerLabels: true,
    };

    // Clarity Slider
    clarityMinValue: number;
    clarityMaxValue: number;
    clarityOptions: Options = {
        floor: 0,
        ceil: 0,
        draggableRange: true,
        onlyBindHandles: true,
        hidePointerLabels: true,
    };

    // Carat Slider
    caratMinValue: number = 0;
    caratMaxValue: number = 10;
    space: any = {
        0: 0,
        1: 0.165,
        2: 0.33,
        3: 0.495,
        4: 0.66,
        5: 0.825,
        10: 0.883,
        15: 0.9416,
        20: 1
    }
    caratOptions: Options = {
        floor: 0,
        ceil: 20,
        step: 0.01,
        showTicks: true,
        showTicksValues: true,
        hidePointerLabels: true,
        ticksArray: [0, 1, 2, 3, 4, 5, 10, 15, 20],
        customValueToPosition: (val: number, minVal: number, maxVal: number): number => {
            const obj = this.getObject()
            return Number(obj[val]);
        },
        customPositionToValue: (percent: number, minVal: number, maxVal: number): number => {
            const obj = this.getObject();
            const swipped = this.swap(obj);
            const per = Number(percent).toFixed(4);
            const sortedArr = Object.values(obj).sort((e1: any, e2: any) => e1 - e2);
            const ind = sortedArr.findIndex((e: any) => {
                return e >= per;
            });
            var key = Object.keys(obj).filter(function (key) { return obj[key] === sortedArr[ind] })[0];
            return Number(key);
        }
    };

    // object to value & value to object
    swap(json: any) {
        const ret: any = {};
        for (var key in json) {
            ret[json[key]] = key;
        }
        return ret;
    }

    // get object of selected points
    getObject = () => {
        let step = 0.1;
        if (this.caratOptions.step) {
            step = this.caratOptions.step;
        }
        const arr = Object.keys(this.space);
        const result: any = {}
        for (let i = 1; i < arr.length; i++) {
            let max: number = Number(arr[i]);
            let min: number = Number(arr[i - 1]);
            let perstep = (this.space[max] - this.space[min]) / ((max - min) / step);
            var base = this.space[min];
            for (let j = min; j <= max; j = this.add(j, step, 3)) {
                result[j] = Number(base).toFixed(4);
                base = base + perstep;
            }
        }
        return result;
    }

    // Addition bug (0.1+0.7)
    add(a: any, b: any, precision: any) {
        var x = Math.pow(10, precision || 2);
        return (Math.round(a * x) + Math.round(b * x)) / x;
    }

    // Price Slider
    priceMinValue: number;
    priceMaxValue: number;
    priceOptions: Options = {
        floor: 0,
        ceil: 0,
        draggableRange: true,
        onlyBindHandles: true,
        hidePointerLabels: true,
    };
    // Size Slider
    sizeMinValue: number = 0;
    sizeMaxValue: number = 25;
    sizeOptions: Options = {
        floor: 0,
        ceil: 25,
        step: 0.01,
        showTicks: true,
        showTicksValues: true,
        hidePointerLabels: true,
        ticksArray: [0, 5, 10, 15, 20, 25]
    };

    // Intensity Slider
    intensityMinValue: number;
    intensityMaxValue: number;
    intensityOptions: Options = {
        floor: 0,
        ceil: 0,
        draggableRange: true,
        onlyBindHandles: true,
        hidePointerLabels: true,
    };

    letterToIndex(letter: string): number {
        return this.alphabet.indexOf(letter);
    }
    indexToLetter(index: number): string {
        return this.alphabet[index];
    }

    selectedDropdown = '';

    priceDefaultValues: any;
    caratDefaultValues: any;

    constructor(
        private filterService: FilterService,
        private router: Router,
        public loader: LoaderService,
        private dataService: DataService,
        public notifyService: NotifyService,
        private _decimalPipe: DecimalPipe,
        public sharedHeaderService: SharedHeaderService
    ) {

    }

    ngOnInit(): void {
        if (this.router.url === '/pairedDiamond') {
            this.diamondType = 'p';
        }
        let params = {
            diamond_type: this.diamondType
        }
        this.getDefaultValues().then(() => {
            this.getFilterData(params);
        });
        this.sizeMinValueCustom = this.floatPipe.transform(this.sizeMinValueCustom);
        this.sizeMaxValueCustom = this.floatPipe.transform(this.sizeMaxValueCustom);
    }

    // Filter Data
    getFilterData(params) {
        this.loader.showSpinner();
        this.subscription.add(
            this.filterService.getFilterData(params).subscribe(
                data => {
                    // this.loader.hideSpinner();
                    if (data['code'] === 200) {
                        this.shapeData = data['data']['shapeData'];
                        this.clarityData = data['data']['clarityData'];
                        this.colorData = data['data']['colorData'];
                        this.cutData = data['data']['cutData'];
                        this.intensityData = data['data']['intensityData'];
                        this.labData = data['data']['labData'];
                        this.polishData = data['data']['polishData'];
                        this.symmetryData = data['data']['symmetryData'];
                        this.tingeData = data['data']['tingeData'];
                        this.priceData = data['data']['price_array'];
                        this.currency = data['data']['currency_symbol'];

                        // Price 
                        this.priceMinValue = this.priceData[0];
                        this.priceMinValueCustom = this._decimalPipe.transform(this.priceData[0]);
                        this.priceMaxValue = this.priceData[this.priceData.length - 1]
                        this.priceMaxValueCustom = this._decimalPipe.transform(this.priceData[this.priceData.length - 1]);
                        this.priceOptions = {
                            floor: this.priceData[0],
                            ceil: this.priceData[this.priceData.length - 1],
                            logScale: true,
                            showTicks: true,
                            showTicksValues: true,
                            hidePointerLabels: true,
                            ticksArray: [...this.priceData],
                            translate: (value: any): string => {
                                return value.toLocaleString('en');
                            }
                        };

                        this.pricecheckedIDs.push(this.priceData[0]);
                        this.pricecheckedIDs.push(this.priceData[this.priceData.length - 1]);

                        // Color
                        if (this.diamondType === 'w' || this.diamondType === 'p') {
                            this.colorData.forEach(element => {
                                this.colorString.push(element.color_name);
                            });
                            this.alphabet = this.colorString.join('').toString();
                            this.colorMinValue = this.letterToIndex(this.alphabet.charAt(0));
                            this.colorMaxValue = this.letterToIndex(this.alphabet.substr(this.alphabet.length - 1));
                            this.colorOptions = {
                                stepsArray: this.alphabet.split('').map((letter: string): CustomStepDefinition => {
                                    return { value: this.letterToIndex(letter) };
                                }),
                                translate: (value: number, label: LabelType): string => {
                                    return this.indexToLetter(value);
                                },
                                showTicks: true,
                                showTicksValues: true,
                                hideLimitLabels: true
                            };
                        }
                        else if (this.diamondType === 'c') {
                            this.colorOptions = {
                                showTicks: true,
                                showTicksValues: false,
                                stepsArray: [],
                                hideLimitLabels: true,
                                hidePointerLabels: true,
                            };
                            this.colorData.forEach(element => {
                                let colorDataArr = { value: null, legend: '' };
                                colorDataArr['value'] = element.master_color_id;
                                colorDataArr['legend'] = element.color_name;
                                this.colorOptions.stepsArray.push(colorDataArr);
                            });
                            this.colorMinValue = this.colorOptions.stepsArray[0].value;
                            this.colorMaxValue = this.colorOptions.stepsArray[this.colorOptions.stepsArray.length - 1].value;
                        }
                        this.colorData.forEach(element => {
                            this.colorcheckedIDs.push(element.master_color_id);
                        });

                        // Cut  
                        if (this.diamondType != 'c') {
                            this.cutOptions = {
                                showTicks: true,
                                showTicksValues: false,
                                stepsArray: [],
                                hideLimitLabels: true,
                                hidePointerLabels: true,
                            };
                            this.cutData.forEach(element => {
                                let cutDataArr = { value: null, legend: '' };
                                cutDataArr['value'] = element.master_cut_id;
                                cutDataArr['legend'] = element.cut_name;
                                this.cutOptions.stepsArray.push(cutDataArr);
                            });
                            this.cutMinValue = this.cutOptions.stepsArray[0].value;
                            this.cutMaxValue = this.cutOptions.stepsArray[this.cutOptions.stepsArray.length - 1].value;

                            this.cutData.forEach(element => {
                                this.cutcheckedIDs.push(element.master_cut_id);
                                if (element.cut_name === "Super Ideal") {
                                    this.superIdealValue = element.master_cut_id;
                                }
                            });
                        }

                        // Intensity
                        if (this.diamondType === 'c') {
                            this.intensityOptions = {
                                showTicks: true,
                                showTicksValues: false,
                                stepsArray: [],
                                hideLimitLabels: true,
                                hidePointerLabels: true,
                            };
                            this.intensityData.forEach(element => {
                                let intensityDataArr = { value: null, legend: '' };
                                intensityDataArr['value'] = element.intensity_id;
                                intensityDataArr['legend'] = element.fancy_color_name;
                                this.intensityOptions.stepsArray.push(intensityDataArr);
                            });
                            this.intensityMinValue = this.intensityOptions.stepsArray[0].value;
                            this.intensityMaxValue = this.intensityOptions.stepsArray[this.intensityOptions.stepsArray.length - 1].value;

                            this.intensityData.forEach(element => {
                                this.intensitycheckedIDs.push(element.intensity_id);
                            });
                        }

                        //Clerity
                        this.clarityOptions = {
                            showTicks: true,
                            showTicksValues: false,
                            stepsArray: [],
                            hideLimitLabels: true,
                            hidePointerLabels: true,
                        };
                        this.clarityData.forEach(element => {
                            let clarityDataArr = { value: null, legend: '' };
                            clarityDataArr['value'] = element.master_clarity_id;
                            clarityDataArr['legend'] = element.clarity_name;
                            this.clarityOptions.stepsArray.push(clarityDataArr);
                        });
                        this.clarityMinValue = this.clarityOptions.stepsArray[0].value;
                        this.clarityMaxValue = this.clarityOptions.stepsArray[this.clarityOptions.stepsArray.length - 1].value;

                        this.clarityData.forEach(element => {
                            this.claritycheckedIDs.push(element.master_clarity_id);
                        });
                        this.search = this.sharedHeaderService.stockId;
                        this.searchValue = this.search;
                        this.fetchShapeCheckedIDs(null, null);
                    }
                },
                error => {
                    // this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    getDefaultValues() {
        return new Promise((resolve, reject) => {
            this.subscription.add(
                this.dataService.get(URLS.getDefaultMasterValues).subscribe(
                    res => {
                        if (res['code'] == 200) {
                            if (res.data && res.data.length > 0) {
                                res.data.forEach(element => {
                                    if (element.attribute_name == 'Price') {
                                        this.priceDefaultValues = [element.min_value, element.max_value];
                                        this.priceMaxValueCustom = this._decimalPipe.transform(element.max_value);
                                        this.priceMinValueCustom = this._decimalPipe.transform(element.min_value);
                                        this.priceMaxValue = element.max_value;
                                        this.priceMinValue = element.min_value;
                                        // this.pricecheckedIDs = [element.min_value, element.max_value];
                                    }
                                    if (element.attribute_name == 'Carat') {
                                        this.caratDefaultValues = [element.min_value, element.max_value];
                                        this.caratMaxValueCustom = this.floatPipe.transform(element.max_value);
                                        this.caratMinValueCustom = this.floatPipe.transform(element.min_value);
                                        this.caratMaxValue = element.max_value;
                                        this.caratMinValue = element.min_value;
                                        this.caratFromIDs = element.min_value;
                                        this.caratToIDs = element.max_value;
                                    }
                                });
                            }
                            resolve(res);
                        } else {
                            reject();
                            // this.phoneVerificationMessage = null;
                        }
                    },
                    error => {
                        reject();
                        console.log(error);
                    }
                )
            );
        })
    }

    resetCollapseValues() {

    }

    toggleDropdown(dropdownRef, dropdownType) {
        dropdownRef.toggle();
        if (!dropdownRef.collapsed) {
            document.getElementById("filterCard").style.height = "140px";
            this.filterCollapsed = true;
        }
        if (dropdownRef.collapsed && !this.media.matches) {
            document.getElementById("filterCard").style.height = "40px";
            this.filterCollapsed = false;
        } else {
            document.getElementById("filterCard").style.height = "140px";
        }
        if (dropdownType != this.selectedDropdown) {
            this.selectedDropdown = dropdownType;
        }
        else {
            this.selectedDropdown = '';
        }
    }

    // advancedFilterClick(){
    //     if(this.media.matches && this.isCollapsed == false){
    //         document.getElementById("filterCard").style.height = "140px"; 
    //     } else {
    //         document.getElementById("filterCard").style.height = "40px";
    //     }
    // }

    // Shape Change
    // fetchShapeCheckedIDs() {
    //     this.shapecheckedIDs = [];
    //     this.shapeData.forEach((value) => {
    //         if (value.is_checked) {
    //             this.shapecheckedIDs.push(value.master_shape_id);
    //         }
    //     });
    //     this.fireFilterChange();
    // }
    fetchShapeCheckedIDs(event, shapeId, fireChange?) {
        this.shapecheckedIDs = [];
        let callApi = true;


        this.shapeData.forEach((value) => {

            if (this.refineList[0]['value']) {
                value.pointer = true;
                if (value.master_shape_id != 1)
                    value.pointer = false;

                if (value.is_checked && value.master_shape_id == 1) {
                    this.shapecheckedIDs.push(value.master_shape_id);
                } else {
                    value.is_checked = false;
                }
            } else if (this.refineList[1]['value']) {

                value.pointer = true;
                if (value.master_shape_id == 1)
                    value.pointer = false;
                if (value.is_checked && value.master_shape_id != 1) {
                    this.shapecheckedIDs.push(value.master_shape_id);
                }
                else {
                    value.is_checked = false;

                }
            } else {
                if (value.is_checked) {
                    this.shapecheckedIDs.push(value.master_shape_id);
                }
                value.pointer = true;
            }
            if ((event && event.target && !value.is_checked && value.master_shape_id == shapeId) && (this.refineList[1]['value'] || this.refineList[0]['value'])) {

                event.target.checked = false;
                callApi = false;
                // value.pointer = false;

            }
            if (this.refineList[0]['value'] && value.master_shape_id == shapeId && shapeId == 1) {
                callApi = true;

            }
            if (this.refineList[1]['value'] && value.master_shape_id == shapeId && shapeId != 1) {
                callApi = true;

            }

        });
        if (!fireChange && callApi)
            this.fireFilterChange();
    }
    changeShapeSelection(event, shapeId) {
        this.fetchShapeCheckedIDs(event, shapeId);
    }

    // Refine Change
    // changeRefineSelection(name, is_checked, option, event) {
    //     let supFancy: any
    //     let supRound: any
    //     let threeX: any
    //     this.refineList.forEach(x => {
    //         supFancy = this.refineList.find(obj => obj.name === "Superior Fancies");
    //         supRound = this.refineList.find(obj => obj.name === "Superior Round");
    //         threeX = this.refineList.find(obj => obj.name === "3X");
    //         if (x.name == name) {
    //             x.value = is_checked
    //         }
    //     });
    //     if (supFancy.value && option.name == supFancy.name) {
    //         supRound.value = false;
    //         threeX.value = false
    //         // option.value = false;
    //     }
    //     if (supRound.value && option.name == supRound.name) {
    //         supFancy.value = false;
    //         // option.value = false;
    //     }
    //     if (supFancy.value && option.name == threeX.name) {
    //         threeX.value = false
    //         // option.value = false;
    //         event.target.checked = false;
    //         return
    //     }
    //     this.fireFilterChange();
    // }

    changeRefineSelection(name, is_checked, option, event) {
        let supFancy: any
        let supRound: any
        let threeX: any
        this.refineList.forEach(x => {
            supFancy = this.refineList.find(obj => obj.name === "Superior Fancies");
            supRound = this.refineList.find(obj => obj.name === "Superior Round");
            threeX = this.refineList.find(obj => obj.name === "3X");
            if (x.name == name) {
                x.value = is_checked
            }
        });
        if (this.cutcheckedIDsBackup.length === 0) {
            this.cutMinValue = this.cutOptions.stepsArray[0].value;
            this.cutMaxValue = this.cutOptions.stepsArray[this.cutOptions.stepsArray.length - 1].value;
        }
        this.cutOptions = Object.assign({}, this.cutOptions, { disabled: false });
        if (supFancy.value && option.name == supFancy.name) {
            supRound.value = false;
            threeX.value = false
            // option.value = false;
        }
        if (supRound.value && option.name == supRound.name) {
            supFancy.value = false;

            // option.value = false;
        }
        if (supFancy.value || supRound.value) {
            this.cutOptions = Object.assign({}, this.cutOptions, { disabled: true });
            this.cutMinValue = this.superIdealValue;
            this.cutMaxValue = this.superIdealValue;
            this.cutcheckedIDs = []
            this.cutcheckedIDsBackup = []
            this.cutcheckedIDs.push(this.superIdealValue);

        }
        else {
            this.cutcheckedIDs = JSON.parse(JSON.stringify(this.cutcheckedIDsBackup))
        }
        if (supFancy.value && option.name == threeX.name) {
            threeX.value = false
            // option.value = false;
            event.target.checked = false;
            return;
        }
        this.fetchShapeCheckedIDs(null, null, true)
        this.fireFilterChange();
    }

    // Get Range
    getRangeValues(list, key, min, max) {
        return list.filter((e, ind) => ind >= min && ind <= max).map(e => e[key])
    }

    getRangeLabelValues(list, key, min, max) {
        const ids = list.map(e => e[key]);
        return ids.slice(ids.indexOf(min), ids.indexOf(max) + 1)
    }

    // Color change
    changeColorSelection(e) {
        this.colorcheckedIDs = [];

        if (this.diamondType === 'c') {
            this.colorData.forEach((value) => {
                if (value.is_checked) {
                    this.colorcheckedIDs.push(value.master_color_id);
                }
            });
        }

        if (this.diamondType != 'c') {
            const colorRangeData = this.getRangeValues(this.colorData, "master_color_id", e.value, e.highValue)
            this.colorcheckedIDs = colorRangeData;
            // } else if (this.diamondType === 'c') {
            //     const colorRangeData = this.getRangeLabelValues(this.colorData, "master_color_id", e.value, e.highValue)
            //     this.colorcheckedIDs = colorRangeData;
            //     this.colorcheckedIDs.push(e.value);
            //     this.colorcheckedIDs.push(e.highValue);
        }


        this.fireFilterChange();
    }

    // Cut change
    changeCutSelection(e) {
        this.cutcheckedIDs = [];
        // this.cutcheckedIDs.push(e.value);
        // this.cutcheckedIDs.push(e.highValue);
        const cutRangeData = this.getRangeLabelValues(this.cutData, "master_cut_id", e.value, e.highValue)
        this.cutcheckedIDs = cutRangeData;
        if (e)
            this.cutcheckedIDsBackup = JSON.parse(JSON.stringify(this.cutcheckedIDs));
        this.fireFilterChange();

        // if(this.diamondType != 'w'){
        //     this.cutData.forEach((value) => {
        //         if (value.is_checked) {
        //             this.cutcheckedIDs.push(value.master_cut_id);
        //         }
        //     });
        // }

    }

    // Clarity change
    changeClaritySelection(e) {
        this.claritycheckedIDs = [];
        // this.claritycheckedIDs.push(e.value);
        // this.claritycheckedIDs.push(e.highValue);
        const clarityRangeData = this.getRangeLabelValues(this.clarityData, "master_clarity_id", e.value, e.highValue)
        this.claritycheckedIDs = clarityRangeData;
        this.fireFilterChange();
    }

    // Carat change
    changeCaratSelection(e) {

        this.caratFromIDs = [];
        this.caratToIDs = [];
        this.caratFromIDs = e.value;
        this.caratToIDs = e.highValue;
        this.caratMinValueCustom = this.floatPipe.transform(this.caratFromIDs);
        this.fireFilterChange();
    }
    changeCaratValue(event) {
        this.caratMinValueCustom = this.floatPipe.transform(event);

    }
    changeCaratHighValue(event) {
        this.caratMaxValueCustom = this.floatPipe.transform(event);
    }
    caratMinValueInput(e) {
        if ((e.target.valueAsNumber >= this.caratMinValueCustom) && (this.caratMinValueCustom >= 0 && this.caratMinValueCustom <= 20)) {
            this.caratMinValue = e.target.valueAsNumber;
            if (e.target.valueAsNumber > this.caratMaxValueCustom) {
                this.caratFromIDs = +this.caratMaxValueCustom;
                this.caratToIDs = +this.caratMinValueCustom;
            } else {
                this.caratFromIDs = e.target.valueAsNumber
            }
            this.fireFilterChange();
        }

        else if (e.target.valueAsNumber < 0 || e.target.valueAsNumber > 20) {

            this.caratMinValueCustom = this.floatPipe.transform(this.caratMinValue);
            this.caratMinValue = this.caratMinValueCustom;
            this.caratFromIDs = this.caratMinValueCustom;
            // this.fireFilterChange();
        }
        else if (isNaN(e.target.valueAsNumber)) {
            this.caratMinValueCustom = this.floatPipe.transform(this.caratMinValue);
            this.caratMinValue = this.caratMinValueCustom;
            this.caratFromIDs = this.caratMinValueCustom;
            // this.fireFilterChange();
        }
        this.caratMinValueCustom = this.floatPipe.transform(this.caratMinValue);
    }

    caratMaxValueInput(e) {
        if ((e.target.valueAsNumber <= this.caratMaxValueCustom) && (this.caratMaxValueCustom >= 0 && this.caratMaxValueCustom <= 20)) {
            this.caratMaxValue = e.target.valueAsNumber;
            if (e.target.valueAsNumber < this.caratMinValueCustom) {
                this.caratToIDs = +this.caratMinValueCustom;
                this.caratFromIDs = +this.caratMaxValueCustom
            } else {
                this.caratToIDs = e.target.valueAsNumber
            }
            this.fireFilterChange();
        }

        else if (e.target.valueAsNumber < 0 || e.target.valueAsNumber > 20) {

            this.caratMaxValueCustom = this.floatPipe.transform(this.caratMaxValue)
            this.caratMaxValue = +this.caratMaxValueCustom;
            this.caratToIDs = +this.caratMaxValueCustom;
            // this.fireFilterChange();
        }
        else if (isNaN(e.target.valueAsNumber)) {
            this.caratMaxValueCustom = this.floatPipe.transform(this.caratMaxValue)
            this.caratMaxValue = +this.caratMaxValueCustom;
            this.caratToIDs = +this.caratMaxValueCustom;
            // this.fireFilterChange();
        }
        this.caratMaxValueCustom = this.floatPipe.transform(this.caratMaxValue)

    }

    // Price change
    changePriceSelection(e) {
        // this.priceFromcheckedIDs = [];
        // this.priceTocheckedIDs = [];
        this.priceMinValueCustom = this._decimalPipe.transform(e.value);
        this.priceMaxValueCustom = this._decimalPipe.transform(e.highValue);
        this.pricecheckedIDs = [];
        this.pricecheckedIDs.push(e.value);
        this.pricecheckedIDs.push(e.highValue);
        // this.fireFilterChange();
        this.fireFilterChange();
    }

    priceMinBlur(e) {
        if (+e.target.value.replace(',', '') >= this.priceData[0] && +e.target.value.replace(',', '') <= this.priceData[this.priceData.length - 1]) {
            this.pricecheckedIDs[0] = +e.target.value.replace(',', '')
            this.priceMinValue = +e.target.value.replace(',', '');
            this.priceMinValueCustom = this._decimalPipe.transform(this.priceMinValue);
            if (this.priceMinValue > this.priceMaxValue) {
                this.pricecheckedIDs[0] = +this.priceMaxValue;
                this.pricecheckedIDs[1] = this.priceMinValue;
            }
            this.fireFilterChange();
        }
        else if (+e.target.value.replace(',', '') < this.priceData[0] || +e.target.value.replace(',', '') > this.priceData[this.priceData.length - 1]) {
            this.priceMinValueCustom = this._decimalPipe.transform(this.priceMinValue);
            this.pricecheckedIDs[0] = +this.priceMinValue;
        } else if (isNaN(+e.target.value.replace(',', ''))) {
            this.priceMinValueCustom = this._decimalPipe.transform(this.priceMinValue);
            this.pricecheckedIDs[0] = +this.priceMinValue;

        }
    }

    priceMaxBlur(e) {
        if (+e.target.value.replace(',', '') <= this.priceData[this.priceData.length - 1] && +e.target.value.replace(',', '') >= this.priceData[0]) {
            this.priceMaxValue = +e.target.value.replace(',', '');
            this.pricecheckedIDs[1] = +e.target.value.replace(',', '')
            if (this.priceMinValue > this.priceMaxValue) {
                this.pricecheckedIDs[0] = +this.priceMaxValue;
                this.pricecheckedIDs[1] = this.priceMinValue;
            }
            this.fireFilterChange();
        }
        else if (+e.target.value.replace(',', '') > this.priceData[this.priceData.length - 1] || +e.target.value.replace(',', '') < this.priceData[0]) {
            this.priceMaxValueCustom = this._decimalPipe.transform(this.priceMaxValue);
            this.pricecheckedIDs[1] = +this.priceMaxValue;
        } else if (isNaN(+e.target.value.replace(',', ''))) {
            this.priceMaxValueCustom = this._decimalPipe.transform(this.priceMaxValue);
            this.pricecheckedIDs[1] = +this.priceMaxValue;

        }
    }
    changePriceHighValue(event) {

        this.priceMaxValueCustom = this._decimalPipe.transform(event);
    }
    changePriceValue(event) {
        this.priceMinValueCustom = this._decimalPipe.transform(event);
    }

    // Size in MM change
    changeSizeSelection(e) {
        this.sizeFromIDs = [];
        this.sizeToIDs = [];
        this.sizeFromIDs = e.value;
        this.sizeToIDs = e.highValue;
        this.sizeMinValueCustom = this.floatPipe.transform(this.sizeFromIDs);
        this.sizeMaxValueCustom = this.floatPipe.transform(this.sizeToIDs);
        this.fireFilterChange();
    }

    sizeMinValueInput(e) {
        if ((e.target.valueAsNumber >= this.sizeMinValueCustom) && (this.sizeMinValueCustom >= 0 && this.sizeMinValueCustom <= 25)) {
            this.sizeMinValue = e.target.valueAsNumber;
            if (e.target.valueAsNumber > this.sizeMaxValueCustom) {
                this.sizeFromIDs = +this.sizeMaxValueCustom;
                this.sizeToIDs = +this.sizeMinValueCustom;
            } else {
                this.sizeFromIDs = e.target.valueAsNumber;
            }
            this.fireFilterChange();
        } else if (e.target.valueAsNumber < 0 || e.target.valueAsNumber > 25) {
            this.sizeMinValueCustom = this.floatPipe.transform(this.sizeMinValue);
            this.sizeMinValue = this.sizeMinValueCustom;
            this.sizeFromIDs = this.sizeMinValueCustom;
            // this.fireFilterChange();
        } else if (isNaN(e.target.valueAsNumber)) {
            this.sizeMinValueCustom = this.floatPipe.transform(this.sizeMinValue);
            this.sizeMinValue = this.sizeMinValueCustom;
            this.sizeFromIDs = this.sizeMinValueCustom;
            // this.fireFilterChange();
        }
        this.sizeMinValueCustom = this.floatPipe.transform(this.sizeMinValue);
    }

    sizeMaxValueInput(e) {
        if ((e.target.valueAsNumber >= this.sizeMaxValueCustom) && (this.sizeMaxValueCustom >= 0 && this.sizeMaxValueCustom <= 25)) {
            this.sizeMaxValue = e.target.valueAsNumber;
            if (e.target.valueAsNumber < this.sizeMinValueCustom) {
                this.sizeToIDs = +this.sizeMinValueCustom;
                this.sizeFromIDs = +this.sizeMaxValueCustom
            } else {
                this.sizeToIDs = e.target.valueAsNumber;
            }
            this.fireFilterChange();
        } else if (e.target.valueAsNumber > 25 || e.target.valueAsNumber < 0) {
            this.sizeMaxValueCustom = this.floatPipe.transform(this.sizeMaxValue);//parseFloat
            this.sizeMaxValue = this.sizeMaxValueCustom;
            this.sizeToIDs = this.sizeMaxValueCustom;
            // this.fireFilterChange();
        } else if (isNaN(e.target.valueAsNumber)) {
            this.sizeMaxValueCustom = this.floatPipe.transform(this.sizeMaxValue);
            this.sizeMaxValue = this.sizeMaxValueCustom;
            this.sizeToIDs = this.sizeMaxValueCustom;

            // this.fireFilterChange();
        }
        this.sizeMaxValueCustom = this.floatPipe.transform(this.sizeMaxValue);
    }
    changeSizeHighValue(event) {
        this.sizeMaxValueCustom = this.floatPipe.transform(event);
    }
    changeSizeValue(event) {
        this.sizeMinValueCustom = this.floatPipe.transform(event);
    }

    // Intensity change
    changeIntensitySelection(e) {
        // this.intensitycheckedIDs = [];
        // // this.intensitycheckedIDs.push(e.value);
        // // this.intensitycheckedIDs.push(e.highValue);
        // const intensityRangeData = this.getRangeLabelValues(this.intensityData, "intensity_id", e.value, e.highValue)
        // this.intensitycheckedIDs = intensityRangeData;
        // this.fireFilterChange();

        this.intensitycheckedIDs = [];
        this.intensityData.forEach((value) => {
            if (value.is_checked) {
                this.intensitycheckedIDs.push(value.intensity_id);
            }
        });

        // const intensityRangeData = this.getRangeLabelValues(this.intensityData, "intensity_id", e.value, e.highValue)
        // this.intensitycheckedIDs = intensityRangeData;
        this.fireFilterChange();
    }

    // Search
    clearData() {
        this.search = null;
        this.searchValue = null;
        this.searchActive = false;
        this.fireFilterChange();
    }

    searchKeyData() {
        if (this.search == '' || this.search == null) {
            this.searchValue = null;
            this.fireFilterChange();
        }
    }
    searchData() {
        if (this.search == '' || this.search == null || this.search.trim().length == 0) {
            this.notifyService.showToast('Please Enter Certificate/Stock ID.');
            return;
        }
        this.searchValue = this.search;
        this.searchActive = true;
        this.fireFilterChange();
    }

    // Advance Filter Change
    changeLabSelection() {
        this.labcheckedIDs = [];
        this.labData.forEach((value) => {
            if (value.is_checked) {
                this.labcheckedIDs.push(value.master_lab_id);
            }
        });
        this.fireFilterChange();
    }

    changePolishSelection() {
        this.polishcheckedIDs = [];
        this.polishData.forEach((value) => {
            if (value.is_checked) {
                this.polishcheckedIDs.push(value.master_polish_id);
            }
        });
        this.fireFilterChange();
    }

    changeSymmetrySelection() {
        this.symmetrycheckedIDs = [];
        this.symmetryData.forEach((value) => {
            if (value.is_checked) {
                this.symmetrycheckedIDs.push(value.master_symmetry_id);
            }
        });
        this.fireFilterChange();
    }

    changeTingeSelection() {
        this.tingecheckedIDs = [];
        this.tingeData.forEach((value) => {
            if (value.is_checked) {
                this.tingecheckedIDs.push(value.master_tinge_id);
            }
        });
        this.fireFilterChange();
    }

    depthMinChange(e) {
        this.depthMinValue = e.target.value;
        if (this.depthMinValue < 46 || this.depthMaxValue > 78) {
            this.depthError = true;
            return;
        } else if (this.depthMinValue > this.depthMaxValue) {
            this.depthError = true;
            return;
        }
        this.depthError = false;
        this.fireFilterChange();
    }

    depthMaxChange(e) {
        this.depthMaxValue = e.target.value;
        if (this.depthMaxValue > 78 || this.depthMinValue < 46) {
            this.depthError = true;
            return;
        } else if (this.depthMaxValue < this.depthMinValue) {
            this.depthError = true;
            return;
        }
        this.depthError = false;
        this.fireFilterChange();
    }

    tableMinChange(e) {
        this.tableMinValue = e.target.value;
        if (this.tableMinValue < 52 || this.tableMaxValue > 80) {
            this.tableError = true;
            return;
        } else if (this.tableMinValue > this.tableMaxValue) {
            this.tableError = true;
            return;
        }
        this.tableError = false;
        this.fireFilterChange();
    }

    tableMaxChange(e) {
        this.tableMaxValue = e.target.value;
        if (this.tableMinValue < 52 || this.tableMaxValue > 80) {
            this.tableError = true;
            return;
        } else if (this.tableMaxValue < this.tableMinValue) {
            this.tableError = true;
            return;
        }
        this.tableError = false;
        this.fireFilterChange();
    }

    ratioMinChange(e) {
        this.ratioMinValue = e.target.value;
        this.fireFilterChange();
    }

    ratioMaxChange(e) {
        this.ratioMaxValue = e.target.value;
        this.fireFilterChange();
    }

    resetFilter() {
        this.resetClicked = true;
        this.shapecheckedIDs = [];
        this.colorcheckedIDs = [];
        this.cutcheckedIDs = [];
        if (this.diamondType === 'w' || this.diamondType === 'p') {
            this.colorMinValue = 0;
            this.colorMaxValue = this.alphabet.length - 1;
            this.cutMinValue = this.cutOptions.stepsArray[0].value;
            this.cutMaxValue = this.cutOptions.stepsArray[this.cutOptions.stepsArray.length - 1].value;
            this.cutOptions = Object.assign({}, this.cutOptions, { disabled: false });
        }
        if (this.diamondType === 'c') {
            this.intensitycheckedIDs = [];
            this.intensityMinValue = this.intensityOptions.stepsArray[0].value;
            this.intensityMaxValue = this.intensityOptions.stepsArray[this.intensityOptions.stepsArray.length - 1].value;
            this.colorMinValue = this.colorOptions.stepsArray[0].value;
            this.colorMaxValue = this.colorOptions.stepsArray[this.colorOptions.stepsArray.length - 1].value;
        }
        this.claritycheckedIDs = [];
        this.clarityMinValue = this.clarityOptions.stepsArray[0].value;
        this.clarityMaxValue = this.clarityOptions.stepsArray[this.clarityOptions.stepsArray.length - 1].value;;
        this.pricecheckedIDs = [this.priceDefaultValues[0] || this.priceData[0], this.priceDefaultValues[1] || this.priceData[this.priceData.length - 1]];
        this.priceMinValue = this.priceDefaultValues[0] || this.priceData[0];
        this.priceMinValueCustom = this._decimalPipe.transform(this.priceDefaultValues[0]) || this._decimalPipe.transform(this.priceData[0]);
        this.priceMaxValue = this.priceDefaultValues[1] || this.priceData[this.priceData.length - 1];
        this.priceMaxValueCustom = this._decimalPipe.transform(this.priceDefaultValues[1]) || this._decimalPipe.transform(this.priceData[this.priceData.length - 1]);
        this.labcheckedIDs = [];
        this.caratFromIDs = this.caratDefaultValues[0] || 0;
        this.caratToIDs = this.caratDefaultValues[1] || 10;
        this.sizeFromIDs = 0;
        this.sizeToIDs = 25;
        this.sizeMinValueCustom = 0;
        this.sizeMinValueCustom = this.floatPipe.transform(this.sizeMinValueCustom);
        this.sizeMaxValueCustom = 25;
        this.sizeMaxValueCustom = this.floatPipe.transform(this.sizeMaxValueCustom);
        this.sizeMinValue = 0;
        this.sizeMaxValue = 25;
        this.caratMinValue = this.caratDefaultValues[0] || 0;
        this.caratMaxValue = this.caratDefaultValues[1] || 10;
        this.caratMinValueCustom = this.floatPipe.transform(this.caratDefaultValues[0]);
        this.caratMaxValueCustom = this.floatPipe.transform(this.caratDefaultValues[1]);
        this.searchValue = null;
        this.search = null;
        this.depthMinValue = null;
        this.depthMaxValue = null;
        this.tableMinValue = null;
        this.tableMaxValue = null;
        this.ratioMinValue = null;
        this.ratioMaxValue = null;

        this.polishcheckedIDs = [];
        this.symmetrycheckedIDs = [];
        this.tingecheckedIDs = [];
        this.cutcheckedIDsBackup = [];

        this.labData.forEach(element => {
            if (element.is_checked) {
                element.is_checked = false;
            }
        });
        this.polishData.forEach(element => {
            if (element.is_checked) {
                element.is_checked = false;
            }
        });
        this.symmetryData.forEach(element => {
            if (element.is_checked) {
                element.is_checked = false;
            }
        });
        this.tingeData.forEach(element => {
            if (element.is_checked) {
                element.is_checked = false;
            }
        });
        this.shapeData.forEach(element => {
            if (element.is_checked) {
                element.is_checked = false;
            }
        });

        if (this.diamondType == 'c') {
            this.colorData.forEach(element => {
                if (element.is_checked) {
                    element.is_checked = false;
                }
            });
            this.intensityData.forEach(element => {
                if (element.is_checked) {
                    element.is_checked = false;
                }
            });

            // this.cutData.forEach(element => {
            //     if (element.is_checked) {
            //         element.is_checked = false;
            //     }
            // });
        }

        this.refineList.forEach(element => {
            if (element.value) {
                element.value = false;
            }
        });

        this.fireFilterChange();
        this.resetClicked = false;
    }

    decimalCheck(event) {
        // console.log(event);
        var charCode = (event.which) ? event.which : event.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8)) {
            return false;
        }
        return true;
    }

    public isNumberKey(evt) {
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }


}