<section class="section first-section">
    <div class="container-fluid">
        <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner>
        <div class="row diamondRow justify-content-end">
            <div class="detail-section mb-3 mt-4">
                <div class="title-info">
                    <label>Stock ID.</label>
                    <span>{{diamondDetailData?.altr_stone_id ? diamondDetailData?.altr_stone_id : '-'}}</span>
                </div>
                <ul>
                    <li>
                        <label>Shape :</label>
                        <span>{{diamondDetailData?.shape_name ? diamondDetailData?.shape_name : '-'}}</span>
                    </li>
                    <li>
                        <label>Carat :</label>
                        <span>{{diamondDetailData?.weight ? diamondDetailData?.weight : '-'}}</span>
                    </li>
                    <li>
                        <label>Color :</label>
                        <span>{{ diamondDetailData?.color_name ? diamondDetailData?.color_name : '-'}}</span>
                    </li>
                    <li *ngIf="diamondType === 'c'">
                        <label>Intensity :</label>
                        <span>{{diamondDetailData?.intensity_name ? diamondDetailData?.intensity_name : '-'}}</span>
                    </li>
                    <li>
                        <label>Clarity :</label>
                        <span>{{diamondDetailData?.clarity_name ? diamondDetailData?.clarity_name : '-'}}</span>
                    </li>
                    <li>
                        <label>Lab :</label>
                        <span>{{diamondDetailData?.lab_name ? diamondDetailData?.lab_name : '-'}}</span>
                    </li>
                    <hr>
                    <li>
                        <label>Certificate No. :</label>
                        <span>{{diamondDetailData?.report_no ? diamondDetailData?.report_no : '-'}}</span>
                    </li>
                    <li>
                        <label>Measurements :</label>
                        <span class="measurementLabel">{{measurement}}</span>
                    </li>
                    <li *ngIf="diamondType === 'w'">
                        <label>Cut :</label>
                        <span>{{diamondDetailData?.cut_name ? diamondDetailData?.cut_name : '-'}}</span>
                    </li>
                    <li>
                        <label>Polish :</label>
                        <span>{{diamondDetailData?.polish_short_code ? diamondDetailData?.polish_short_code : '-'}}</span>
                    </li>
                    <li>
                        <label>Symmetry :</label>
                        <span>{{diamondDetailData?.symmetry_short_code ? diamondDetailData?.symmetry_short_code : '-'}}</span>
                    </li>
                    <li>
                        <label>Depth :</label>
                        <span>{{diamondDetailData?.depth_percentage ? diamondDetailData?.depth_percentage : '-'}}%</span>
                    </li>
                    <li>
                        <label>Table :</label>
                        <span>{{diamondDetailData?.table_percentage ? diamondDetailData?.table_percentage : '-'}}%</span>
                    </li>
                    <li>
                        <label>Ratio :</label>
                        <span>{{diamondDetailData?.ratio ? diamondDetailData?.ratio : '-'}}</span>
                    </li>
                    <li>
                        <label>Hearts & Arrow :</label>
                        <span>{{diamondDetailData?.heart_n_arrow === true ? 'Yes' : 'No'}}</span>
                    </li>
                    <hr>
                    <li>
                        <label>Price :</label>
                        <span *ngIf="!isShareToClient && displayPrice === 'b' || displayPrice === 's'" class="price-span">{{diamondDetailData?.currency_symbol}}{{diamondDetailData?.conversion_amount
                            | number}}
                        </span>
                        <span *ngIf="!isShareToClient && displayPrice === 'c'" class="price-span">{{diamondDetailData?.currency_symbol}}{{diamondDetailData?.cost_conversion_amount
                            | number}}
                        </span>
                        <span *ngIf="isShareToClient" class="price-span">{{diamondDetailData?.currency_symbol}}{{diamondDetailData?.retail_price
                            | number}}</span>
                    </li>
                    <li *ngIf="isShareToClient" class="msgLi">
                        <mat-form-field appearance="outline" class="w-100" *ngIf="diamondDetailData?.message">
                            <mat-label>Message</mat-label>
                            <textarea matInput placeholder="Message" readonly="true" value="{{diamondDetailData?.message}}"
                                rows="5"></textarea>
                        </mat-form-field>
                    </li>
                    <li *ngIf="!isShareToClient">
                        <button type="button" class="btn" (click)="showClientModal(diamondDetailData)">Show Client ></button>
                        <button type="button" class="btn" (click)="matchClick(diamondDetailData)"><img src="../assets/img/Match.png">Match</button>
                    </li>
                </ul>
                <div class="card" *ngIf="!isShareToClient">
                    <button type="button" class="btn btn-outline" (click)="AddToCart(diamondDetailData)">{{getLabelForCart(diamondDetailData)}}</button>

                </div>
            </div>
            <div class="diamond-section">
                <div class="slider-wrap">
                    <div class="title">
                        <h1>{{diamondDetailData?.shape_name}} {{diamondDetailData?.weight}} {{
                            diamondDetailData?.color_name}}
                            {{diamondDetailData?.clarity_name}} {{diamondDetailData?.cut_name ?
                            diamondDetailData?.cut_name : '-'}}
                        </h1>
                    </div>
                    <ngb-carousel [interval]="false" #c="ngbCarousel">
                        <ng-template ngbSlide>
                            <div class="detail-img-div">
                                <!-- <img src="./assets/img/360.png" alt="360"> -->
                                <iframe id="diamondIframe" scrolling="no" width="465" height="465" frameborder="0" allowfullscreen></iframe>
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="diamondDetailData && diamondDetailData.image_path">
                            <div class="detail-img-div">
                                <img id="imageid" [src]="diamondDetailData?.image_path" alt="Image Not Available"
                                    style="height: 450px;">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="diamondDetailData && diamondDetailData.top_image">
                            <div class="detail-img-div">
                                <img [src]="diamondDetailData?.top_image ? diamondDetailData?.top_image : './assets/img/top.png'"
                                    alt="Image Not Available">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="diamondDetailData && diamondDetailData.bottom_image">
                            <div class="detail-img-div">
                                <img [src]="diamondDetailData?.bottom_image ? diamondDetailData?.bottom_image : './assets/img/bottom.png'"
                                    alt="Image Not Available">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="diamondDetailData && diamondDetailData.brilliance_image">
                            <div class="detail-img-div">
                                <img [src]="diamondDetailData?.brilliance_image ? diamondDetailData?.brilliance_image : './assets/img/brilliance.png'"
                                    alt="Image Not Available">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="diamondDetailData && diamondDetailData.symmetry_image">
                            <div class="detail-img-div">
                                <img [src]="diamondDetailData?.symmetry_image ? diamondDetailData?.symmetry_image : './assets/img/symmetry.png'"
                                    alt="Image Not Available">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="diamondDetailData && diamondDetailData.arrow_image && diamondDetailData.heart_n_arrow">
                            <div class="detail-img-div">
                                <img [src]="diamondDetailData?.arrow_image ? diamondDetailData?.arrow_image : './assets/img/arrow.png'"
                                    alt="Image Not Available">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide *ngIf="diamondDetailData && diamondDetailData.heart_image && diamondDetailData?.heart_n_arrow">
                            <div class="detail-img-div">
                                <img [src]="diamondDetailData?.heart_image ? diamondDetailData?.heart_image : './assets/img/heart-diamond.png'"
                                    alt="Image Not Available">
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
                <div class="certi-div">
                    <div class="certi-div-wrap">
                        <div *ngIf="diamondDetailData?.certificate_url">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'CERTIFICATE', diamondDetailData?.certificate_url)">
                                <img [src]="diamondDetailData?.certificate_url ? diamondDetailData?.certificate_url : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!diamondDetailData?.certificate_url">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="diamondDetailData?.certificate_url ? diamondDetailData?.certificate_url : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Certificate</p>
                    </div>
                    <div class="certi-div-wrap">
                        <div *ngIf="diamondDetailData?.laser_inscription">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'LASER INSCRIPTION',  diamondDetailData?.laser_inscription)">
                                <img [src]="diamondDetailData?.laser_inscription ? diamondDetailData?.laser_inscription : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!diamondDetailData?.laser_inscription">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="diamondDetailData?.laser_inscription ? diamondDetailData?.laser_inscription : './assets/img/certi-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Laser Inscription</p>
                    </div>
                    <div class="certi-div-wrap">
                        <div *ngIf="diamondDetailData?.proportion_diagram">
                            <a href="javascript:void(0)" (click)="openCertiModal(content, 'PROPORTION DIAGRAM', diamondDetailData?.proportion_diagram)">
                                <img [src]="diamondDetailData?.proportion_diagram ? diamondDetailData?.proportion_diagram : './assets/img/proportion-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <div *ngIf="!diamondDetailData?.proportion_diagram">
                            <a href="javascript:void(0)" style="cursor: not-allowed">
                                <img [src]="diamondDetailData?.proportion_diagram ? diamondDetailData?.proportion_diagram : './assets/img/proportion-btn.png'"
                                    alt="Image Not Available">
                            </a>
                        </div>
                        <p>Proportion Diagram</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div class="modal-header">
        <h3 class="modal-title">{{certiTitle}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <img [src]="certiUrlForModal" width="100%">
        </div>
    </div>
</ng-template>