import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WhiteDiamondComponent } from './white-diamond.component';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from 'src/app/core/core.module';

const routes: Routes = [
  {
    path: '',
    component: WhiteDiamondComponent
  }
];

@NgModule({
  declarations: [
    WhiteDiamondComponent,    
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule
  ]
})
export class WhiteDiamondModule { }
