import { Injectable } from '@angular/core';
import { DataService } from '../../services/data-service.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URLS } from 'src/app/core/_constant/api.config';

@Injectable({
    providedIn: 'root'
})
export class DiamondDetailService {

    constructor(private dataService: DataService, private http: HttpClient) {

    }

    getDiamondDetail(param?): Observable<{}> {
        return this.http.post(URLS.getDiamondDetail, param);
    }

    getShareDiamondDetail(param?): Observable<{}> {
        return this.http.post(URLS.getShareDiamondDetail, param);
    }
    
}