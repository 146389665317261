import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { WhiteDiamondService } from './white-diamond.service';
import { CommonService } from 'src/app/core/_common/common.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotifyService } from 'src/app/core/_common/snackbar.service';
import { LoaderService } from 'src/app/core/_common/loader.service';
import { FilterComponent } from 'src/app/core/_component/filter/filter.component';
import { ViewChild } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { orderBy, union } from 'lodash';
import { WishListService } from 'src/app/services/wishlist.service';
import { AddToCartService } from 'src/app/services/addtocart.service';
import { SharedHeaderService } from 'src/app/services/sharedHeader.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/core/_component/modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../core/_component/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

interface Availabilities {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-white-diamond',
    templateUrl: './white-diamond.component.html',
    styleUrls: ['./white-diamond.component.scss'],
    providers: [WhiteDiamondService]

})
export class WhiteDiamondComponent implements OnInit, OnDestroy {

    @ViewChild("filterData") filterData: FilterComponent;
    @ViewChild("video", { static: false }) video: ElementRef;
    isWish: boolean = false;
    private subscription: Subscription = new Subscription();
    public viewGrid = false;
    public viewList = true;
    stateLength = 3;
    stateLengthTwo = 3;
    public diamondList: any = [];
    public pairedDiamondList: any = [];
    public locationList: any = [];
    sortingArray: any = [];
    totalRecords: any;
    totalPairedRecords: any;
    public diamondViewList: any = [];
    totalRecentRecords: any;
    totalpairedRecentRecords: any;
    public recentDiamondList: any = [];
    public recentPairedDiamondList: any = [];

    // public selectedLocation: any;
    public showDiamondDetail = false;
    public availabilityValue: any;
    public locationValue: any;
    public dataForModal: any;
    public pairedModalVideoData: any;
    public diamindModalVideoData: any;
    public pairedModalImageData: any;
    public diamondType: any;
    public shapeIds: any;
    scrollposition: any;
    public filterParams: any;
    public isAddToCart: boolean = false;
    public recentlyViewedClick: boolean = false;
    public displayPrice: any;
    comparisonTable = false;
    // isElementAdded = false;
    diamondComparisonTable = true;
    displayVideo = false;
    displayImage = true;
    productOne = false;
    productTwo = false;
    productThree = false;
    showNavigationArrows = false;
    initialLoad: boolean = true;
    rowToScrollPosition: any;

    images = ["assets/img/diamond.png", "assets/img/diamond.png", "assets/img/diamond.png"];

    // diamondArr: any = [];
    public diamondComparisonArr: any = [];
    public onLoadComparisonArr: any = [];
    public onLoadComparisonColorArr: any = [];

    arrayOne(stateLength: number): any[] {
        return Array(stateLength);
    }

    arrayTwo(stateLengthTwo: number): any[] {
        return Array(stateLengthTwo);
    }


    currencyCode: any;
    currencySymbol: any;

    public filterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 50
    };
    public recentfilterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 50
    };
    isWishList: boolean = false;
    isApiLoading: boolean = false;
    paginationScroll: boolean = false;

    active = 1;

    Availabilities: Availabilities[] = [
        { value: 'All', viewValue: 'All' },
        { value: 'On Request', viewValue: 'On Request' },
        { value: 'Next Business Day', viewValue: 'Next Business Day' },
        { value: '1-3 Days', viewValue: '1-3 Days' },
        { value: '4-7 Days', viewValue: '4-7 Days' },
    ];
    selectedAvailability = this.Availabilities[0].value;
    selectedLocation: any = [];

    diamondColum = [
        { key: 'Shape', value: 'Shape', is_two: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cut', value: 'Cut', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cost', value: 'Price', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    diamondColumOne = [
        { key: 'Shape', value: 'Shape', is_two: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cut', value: 'Cut', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Retail', value: 'Price', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false }
    ];

    diamondColumTwo = [
        { key: 'Shape', value: 'Shape', is_two: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cut', value: 'Cut', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cost', value: 'Price', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Retail', value: 'Price', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    colorDiamondColum = [
        { key: 'Shape', value: 'Shape', is_two: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Intensity', value: 'Intensity', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cost', value: 'Price', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    colorDiamondColumOne = [
        { key: 'Shape', value: 'Shape', is_two: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Intensity', value: 'Intensity', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Retail', value: 'Price', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    colorDiamondColumTwo = [
        { key: 'Shape', value: 'Shape', is_two: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Intensity', value: 'Intensity', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cost', value: 'Price', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Retail', value: 'Price', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    pairedDiamondColumn = [
        { key: 'Shape', value: 'pairedDiamondShape', is_two: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'pairedDiamondCarat', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'pairedDiamondColor', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'pairedDiamondClarity', is_two: true, is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cut', value: 'pairedDiamondCut', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'pairedDiamondLab', sort: 'ASC', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Price', value: 'pairedDiamondPrice', is_two: true, is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    pairedtotalCarat = { key: 'Total Carat', value: 'Total Carat', sort: 'ASC', is_total_carat: true, is_asec: false, is_dsec: false }

    constructor(
        config: NgbCarouselConfig,
        private whiteDiamondService: WhiteDiamondService,
        public commonService: CommonService,
        public wishlistServ: WishListService,
        public addToCartService: AddToCartService,
        private router: Router,
        public loader: LoaderService,
        public notifyService: NotifyService,
        public sharedHeaderService: SharedHeaderService,
        private modalService: NgbModal,
        public dialog: MatDialog,
    ) {
        this.commonService.setEvent('SelectMenu', { key: 'White Diamond', value: 'white' });
        config.showNavigationArrows = true;
        config.showNavigationIndicators = false;
    }

    columnsTosort(data: any) {
        if (this.sortingArray && this.sortingArray.length == 0) {
            this.sortingArray.push({
                sort_by: data.sort,
                sort_type: data.value
            })
        }
        else {
            let obj = this.sortingArray.find(e => e.sort_type == data.value)
            if (obj) {
                obj.sort_by = data.sort
            } else {
                this.sortingArray.push({
                    sort_by: data.sort,
                    sort_type: data.value
                })
            }
        }
    }

    diamondSort(data) {
        // if (!this.customerData || this.diamondType == 'p') {
        //     return
        // }
        this.initialLoad = true;
        if (data.sort === 'ASC') {
            data.is_asec = true;
            data.is_dsec = false;
            this.columnsTosort(data);
            this.filterParams = {
                sort_array: this.sortingArray,
            }
            this.onFilterChangeHandler(false, true, this.filterParams)
            data.sort = 'DESC'
        } else if (data.sort === 'DESC') {
            data.is_asec = false;
            data.is_dsec = true;
            this.columnsTosort(data);
            this.filterParams = {
                sort_array: this.sortingArray,
            }
            this.onFilterChangeHandler(false, true, this.filterParams);
            data.sort = '';
        } else {
            data.is_dsec = false;
            let index = this.sortingArray.findIndex(e => data.sort == '' && e.sort_type == data.value);
            this.sortingArray.splice(index, 1);
            this.filterParams = {
                sort_array: this.sortingArray,
            }
            this.onFilterChangeHandler(false, true, this.filterParams);
            data.sort = 'ASC';
        }
    }

    ngOnInit() {
        this.sharedHeaderService.reloadHeader();
        if (this.router.url === '/whiteDiamond') {
            this.diamondType = 'w';
        } else if (this.router.url === '/coloredDiamond') {
            this.diamondType = 'c';
        } else if (this.router.url === '/pairedDiamond') {
            this.diamondType = 'p';
            if (this.displayPrice === 'c') {
                this.diamondColum = this.pairedDiamondColumn
            }
            if (this.displayPrice === 's') {
                this.diamondColumOne = this.pairedDiamondColumn
            }
            if (this.displayPrice === 'b') {
                this.diamondColumTwo = this.pairedDiamondColumn
            }
        }
        if (this.diamondType === 'p') {
            this.diamondColum.splice(2, 0, this.pairedtotalCarat)
            this.diamondColumOne.splice(2, 0, this.pairedtotalCarat)
            this.diamondColumTwo.splice(2, 0, this.pairedtotalCarat)
        }
        setTimeout(() => {
            this.notifyService.hideToast()
        }, 1000);
        let params = {
            from_diamond_list: true
        }
        this.getLocation(params);
        this.availableValueFunction(this.selectedAvailability);
        this.commonService.setEvent('SelectMenu', { key: 'White Diamond', value: 'white' });
    }

    ngOnDestroy() {
        this.sharedHeaderService.stockId = null;
    }

    deleteWishList(data, i) {
        data['checkWish'] = false;
        data['wishlist'] = null;
        let index = this.diamondComparisonArr.findIndex(function (item, i) {
            return item.lot_id === data.lot_id;
        });
        if (index != -1) {
            this.diamondComparisonArr[index]['checkWish'] = false;
        }
        // this.isLoading = true;
        this.loader.showSpinner();
        let params = {
            "lot_id": +data.lot_id,
        }
        this.subscription.add(
            this.whiteDiamondService.removeWishList(params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data["code"] == 200) {
                        this.notifyService.showToast(data["message"]);
                        this.wishlistServ.getWishListCount();
                        setTimeout(() => {
                            this.loader.hideSpinner();
                        }, 200);
                    } else {
                        this.notifyService.showToast(data["message"]);
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    comparisonFlag() {
        this.comparisonTable = true;
        this.displayVideo = false;
        this.displayImage = true;
        console.log(this.diamondComparisonArr);
    }
    recentlyViewedFlag() {
        this.comparisonTable = false;
        this.onFilterChangeHandler(false, true, this.filterParams);
    }
    allDiamondsFlag() {
        this.comparisonTable = false;
        if(this.rowToScrollPosition) {
            setTimeout(() => {
                var element = document.getElementById(this.rowToScrollPosition);
                element.scrollIntoView({ block: "center" });
            }, 500);
        }
        // this.onFilterChangeHandler(false, true, this.filterParams);
    }

    allDiamond() {
        this.recentlyViewedClick = false;
    }

    recentlyViewed() {
        this.recentlyViewedClick = true;
    }

    ngAfterViewInit() {
        this.scrollposition = 0;
    }
    // openVerticallyCentered(content) {
    //     this.modalService.open(content, { centered: true });
    //   }
    showVideo(content, data) {
        this.dataForModal = data;

        // const videoUrl = this.dataForModal.video_path ? this.dataForModal.video_path : 'https://www.altr.nyc/videos/diamond.html?d=440013676';
        // document.getElementById("diamondVideoIframe").setAttribute("src", videoUrl);

        this.modalService.open(content, { size: 'xl', centered: true });
        this.displayVideo = true;
        this.displayImage = false;
    }

    showImage(content, data) {
        this.dataForModal = data;
        this.modalService.open(content, { size: 'xl', centered: true });
        this.displayVideo = false;
        this.displayImage = true;
    }

    showDetailImage(content, data) {
        this.diamindModalVideoData = data;
        this.modalService.open(content, { size: 'md', centered: true });
        this.displayVideo = false;
        this.displayImage = true;
    }

    showDetailVideo(content, data) {
        this.diamindModalVideoData = data;
        this.modalService.open(content, { size: 'md', centered: true });
        this.displayVideo = true;
        this.displayImage = false;
    }

    showPairedVideo(content, data) {
        this.modalService.open(content, { size: 'lg', centered: true });
        this.displayVideo = true;
        this.displayImage = false;
        this.pairedModalVideoData = data;
    }

    showDiamondVideo(content, data) {
        this.modalService.open(content, { size: 'lg', centered: true });
        this.displayVideo = true;
        this.displayImage = false;
        this.diamindModalVideoData = data;
    }

    showPairedImage(content, data) {
        this.modalService.open(content, { size: 'lg', centered: true });
        this.displayVideo = false;
        this.displayImage = true;
        this.pairedModalImageData = data;
    }

    addDiamondToCompare(data, diamondRowId) {
        this.rowToScrollPosition = diamondRowId;
        if (this.diamondType === 'w') {
            if (localStorage.getItem("DiamondCompareList") != null && JSON.parse(localStorage.getItem("DiamondCompareList")).length >= 3) {
                let index = this.diamondComparisonArr.findIndex(function (item, i) {
                    return item.lot_id === data.lot_id
                });
                if (index == -1) {
                    this.notifyService.showToast('Cannot add more than 3 Diamond to Compare!');
                }
                // else {
                //     this.notifyService.showToast('Same Diamond Already added to Compare');
                // }
                return;
            }
        }
        if (this.diamondType === 'c') {
            if (localStorage.getItem("colorDiamondCompareList") != null && JSON.parse(localStorage.getItem("colorDiamondCompareList")).length >= 3) {
                let index = this.diamondComparisonArr.findIndex(function (item, i) {
                    return item.lot_id === data.lot_id
                });
                if (index == -1) {
                    this.notifyService.showToast('Cannot add more than 3 Diamond to Compare!');
                }
                // else {
                //     this.notifyService.showToast('Same Diamond Already added to Compare');
                //     console.log(this.diamondComparisonArr);
                // }
                return;
            }
        }
        if (this.diamondComparisonArr.length < 3) {
            let index = this.diamondComparisonArr.findIndex(function (item, i) {
                return item.lot_id === data.lot_id
            });
            console.log(index);
            if (index == -1) {
                data.measurement = data.measurement ? data.measurement.replaceAll("x", " x ") : '-';
                data.measurement = data.measurement ? data.measurement.replaceAll("*", " x ") : '-';
                this.diamondComparisonArr.push(data);
                console.log(this.diamondComparisonArr);
                if (this.diamondType == "w") {
                    localStorage.setItem("DiamondCompareList", JSON.stringify(this.diamondComparisonArr));
                    this.stateLength -= 1;
                }
                if (this.diamondType == "c") {
                    localStorage.setItem("colorDiamondCompareList", JSON.stringify(this.diamondComparisonArr));
                    this.stateLengthTwo -= 1;
                    console.log(this.stateLengthTwo);
                }
                this.notifyService.showToast('Diamond added to The Compare');
            }
            // else {
            //     this.notifyService.showToast('Same Diamond Already added to Compare');
            // }
        }
        else {

            let index = this.diamondComparisonArr.findIndex(function (item, i) {
                return item.lot_id === data.lot_id
            });
            if (index == -1) {
                this.notifyService.showToast('Cannot add more than 3 Diamond to Compare!');
            }
            // else {
            //     this.notifyService.showToast('Same Diamond Already added to Compare');
            // }
            return;
        }

        // if((!localStorage.getItem("DiamondCompareList") || !localStorage.getItem("DiamondCompareList")) && (JSON.parse(localStorage.getItem("DiamondCompareList")).length > 3 || JSON.parse(localStorage.getItem("colorDiamondCompareList")).length > 3)){
        //     this.notifyService.showToast('Cannot add more than 3 Diamond to Compare!');
        //     return;
        // }
    }

    removeDiamondTableData(index) {
        // this.diamondComparisonArr = this.diamondComparisonArr.filter(d => d.lot_id !== Number(index));
        this.diamondComparisonArr.splice(index, 1);
        if (this.diamondType == "w") {
            localStorage.setItem("DiamondCompareList", JSON.stringify(this.diamondComparisonArr));
            this.stateLength += 1;
        }
        if (this.diamondType == "c") {
            localStorage.setItem("colorDiamondCompareList", JSON.stringify(this.diamondComparisonArr));
            this.stateLengthTwo += 1;
        }
        this.rowToScrollPosition = null;
    }

    removeDiamondFromCompare(diamondData) {
        this.diamondComparisonArr.forEach((element, index) => {
            if (element.lot_id == diamondData.lot_id) {
                this.diamondComparisonArr.splice(index, 1);
                this.notifyService.showToast('Diamond Removed from Compare');
            }
        });
        if (this.diamondType == "w") {
            localStorage.setItem("DiamondCompareList", JSON.stringify(this.diamondComparisonArr));
            this.stateLength += 1;
        }
        if (this.diamondType == "c") {
            localStorage.setItem("colorDiamondCompareList", JSON.stringify(this.diamondComparisonArr));
            this.stateLengthTwo += 1;
        }
        this.rowToScrollPosition = null;
    }



    // List & Grid view toggle
    listGridBtnClick(e: any) {
        this.showDiamondDetail = false;
        if (e.currentTarget.className === "btn grid") {
            this.viewGrid = true;
            this.viewList = false;
        } else if (e.currentTarget.className === "btn list") {
            this.viewGrid = false;
            this.viewList = true;
        }
        var tab = document.getElementsByClassName("btn");
        for (var i = 0; i < tab.length; i++) {
            tab[i].className = tab[i].className.replace(" active", "");
        }
        e.currentTarget.className += " active";
    }

    //Nav change
    navChange() {
        this.viewGrid = false;
        this.viewList = true;
    }

    // Diamond List Data
    getDiamondList(params?, isInfinteScrollCalled?: boolean) {
        if (this.isApiLoading) {
            return;
        }
        this.isApiLoading = true;
        if (this.initialLoad) {
            this.loader.showSpinner();
        }
        this.subscription.add(
            this.whiteDiamondService.getDiamondList(params).subscribe(
                data => {
                    this.isApiLoading = false;
                    this.initialLoad = false;
                    if (data['code'] === 200 && !isInfinteScrollCalled) {
                        this.diamondList = data['data']['rows'];
                        this.totalRecords = data['data']['count'];
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']
                        this.displayPrice = data["data"]["display_price"];
                    }
                    if (data['code'] === 200 && isInfinteScrollCalled) {
                        this.diamondList = orderBy(union(this.diamondList, data['data']['rows']));
                        this.totalRecords = data['data']['count'];
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol'];
                        this.displayPrice = data["data"]["display_price"];
                    }
                    this.diamondList.forEach(element => {
                        // if (element.in_order_cart && !this.addToCartService.storedLotIds.includes(element.lot_id)) {
                        //     this.addToCartService.storedLotIds.push(element.lot_id)
                        // }

                        if (element.wishlist) {
                            element['checkWish'] = true;
                        }
                        else {
                            element['checkWish'] = false;
                        }
                    });
                    if (this.diamondComparisonArr.length == 0) {
                        var diamondCompareList = localStorage.getItem('DiamondCompareList');
                        var colordiamondCompareList = localStorage.getItem('colorDiamondCompareList');
                        var diamondComparisonList = JSON.parse(diamondCompareList);
                        var colordiamondComparisonList = JSON.parse(colordiamondCompareList);
                        if (diamondComparisonList) {
                            var compareLength = diamondComparisonList.length;
                            var compareLengthString = compareLength.toString();
                        }
                        if (colordiamondComparisonList) {
                            var colorCompareLength = colordiamondComparisonList.length;
                            var colorCompareLengthString = colorCompareLength.toString();
                        }
                        if (this.diamondType == 'w' && diamondComparisonList) {
                            for (let i = 0; i < this.diamondList.length; i++) {
                                if (this.diamondList[i]['checkWish'] == true) {
                                    for (let j = 0; j < diamondComparisonList.length; j++) {
                                        if (this.diamondList[i]['lot_id'] == diamondComparisonList[j]['lot_id']) {
                                            diamondComparisonList[j]['checkWish'] = true;
                                        }
                                    }
                                }
                            }
                            this.diamondComparisonArr = diamondComparisonList;

                        }
                        if (this.diamondType == 'c' && colordiamondComparisonList) {
                            for (let i = 0; i < this.diamondList.length; i++) {
                                if (this.diamondList[i]['checkWish'] == true) {
                                    for (let j = 0; j < colordiamondComparisonList.length; j++) {
                                        if (this.diamondList[i]['lot_id'] == colordiamondComparisonList[j]['lot_id']) {
                                            colordiamondComparisonList[j]['checkWish'] = true;
                                        }
                                    }
                                }
                            }
                            this.diamondComparisonArr = colordiamondComparisonList;
                        }
                    }
                    switch (compareLengthString) {
                        case "1": this.stateLength -= 1;
                            break;
                        case "2": this.stateLength -= 2;
                            break;
                        case "3": this.stateLength -= 3;
                            break;
                    }
                    switch (colorCompareLengthString) {
                        case "1": this.stateLengthTwo -= 1;
                            break;
                        case "2": this.stateLengthTwo -= 2;
                            break;
                        case "3": this.stateLengthTwo -= 3;
                            break;
                    }


                    // this.diamondColum.forEach((item, index) => {
                    //     if (item.key === 'Price' && this.currencyCode === 'USD') {
                    //         this.diamondColum.splice(index, 1);
                    //     }
                    // });

                    this.filterObj.page++
                    this.loader.hideSpinner();
                    // this.isAddToCart = this.cartFlag
                },
                error => {
                    this.isApiLoading = false;
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    // Paired Diamond List Data
    getpairedDiamondList(params?, isInfinteScrollCalled?: boolean) {
        if (this.isApiLoading) {
            return;
        }
        this.isApiLoading = true;
        if (this.initialLoad) {
            this.loader.showSpinner();
        }
        this.subscription.add(
            this.whiteDiamondService.getPairedDiamondList(params).subscribe(
                data => {
                    this.isApiLoading = false;
                    this.initialLoad = false;
                    if (data['code'] === 200 && !isInfinteScrollCalled) {
                        this.pairedDiamondList = data['data']['rows'];
                        this.totalPairedRecords = data['data']['count'];
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']
                        this.displayPrice = data["data"]["display_price"];
                    }
                    if (data['code'] === 200 && isInfinteScrollCalled) {
                        this.pairedDiamondList = orderBy(union(this.pairedDiamondList, data['data']['rows']));
                        this.totalPairedRecords = data['data']['count'];
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']
                        this.displayPrice = data["data"]["display_price"];
                    }
                    // this.diamondList.forEach(element => {
                    //     if (element.wishlist) {
                    //         element['checkWish'] = true;
                    //     }
                    //     else {
                    //         element['checkWish'] = false;
                    //     }
                    // });

                    // this.diamondColum.forEach((item, index) => {
                    //     if (item.key === 'Price' && this.currencyCode === 'USD') {
                    //         this.diamondColum.splice(index, 1);
                    //     }
                    // });

                    this.filterObj.page++
                    this.loader.hideSpinner();
                    // this.isAddToCart = this.cartFlag
                },
                error => {
                    this.isApiLoading = false;
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    get cartFlag() {
        // if (!this.diamondViewList || this.diamondViewList && this.diamondViewList.length === 0) {
        //     return false;
        // }
        return null as boolean
    }


    getDefaultDiamondList() {
        const params = {
            diamond_type: this.diamondType,
            availability: "All",
            // shape_id: this.filterData.shapecheckedIDs,
            page_no: this.filterObj.page,
            page_record: this.filterObj.pageRecord
        }
        this.loader.showSpinner();
        this.subscription.add(
            this.whiteDiamondService.getDiamondList(params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data['code'] === 200) {
                        this.diamondList = data['data']['rows'];
                        this.totalRecords = data['data']['count'];
                    }

                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    getRecentlyViewedList(params?, isInfinteScrollCalled?: boolean) {
        if (this.isApiLoading) {
            return;
        }
        this.isApiLoading = true;
        if (this.initialLoad) {
            this.loader.showSpinner();
        }
        this.subscription.add(
            this.whiteDiamondService.getrecentlyViewedList(params).subscribe(
                data => {
                    this.isApiLoading = false;
                    this.initialLoad = false;
                    this.loader.hideSpinner();
                    if (data['code'] === 200 && !isInfinteScrollCalled) {
                        this.recentDiamondList = data['data']['rows'];
                        this.totalRecentRecords = data['data']['count'];
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']
                        console.log(this.recentDiamondList);
                    }
                    if (data['code'] === 200 && isInfinteScrollCalled) {
                        this.recentDiamondList = orderBy(union(this.recentDiamondList, data['data']['rows']));
                        this.totalRecentRecords = data['data']['count'];
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']
                    }
                    this.recentDiamondList.forEach(element => {
                        if (element.lot.wishlist.lot_id) {
                            element['checkWish'] = true;
                        }
                        else {
                            element['checkWish'] = false;
                        }
                    });

                    // this.diamondColum.forEach((item, index) => {
                    //     if (item.key === 'Price' && this.currencyCode === 'USD') {
                    //         this.diamondColum.splice(index, 1);
                    //     }
                    // });
                    this.filterObj.page++;
                    // this.isAddToCart = this.cartFlag;
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    addDataToRecentlyViewed(diamond, diamondRowId) {
        console.log(diamond);
        const params = {
            "diamond_type": diamond.paired_lot_id ? 'p' : diamond.diamond_type,
            "lot_id": diamond.lot_id,
        }
        if (diamond.paired_lot_id) {
            params['paired_lot_id'] = diamond.paired_lot_id
        }
        this.subscription.add(
            this.whiteDiamondService.addRecentlyViewData(params).subscribe(
                data => {
                    if (data['code'] === 200) {
                        this.rowToScrollPosition = diamondRowId;
                        console.log(data)
                    }
                },
                error => {
                    console.log(error);
                }
            )
        );
    }

    // Recently view Data for Paired dimond
    getRecentlyViewedPairedList(params?, isInfinteScrollCalled?: boolean) {
        if (this.isApiLoading) {
            return;
        }
        this.isApiLoading = true;
        if (this.initialLoad) {
            this.loader.showSpinner();
        }
        this.subscription.add(
            this.whiteDiamondService.getRecentlyViewedPairedList(params).subscribe(
                data => {
                    this.isApiLoading = false;
                    this.initialLoad = false;
                    this.loader.hideSpinner();
                    if (data['code'] === 200 && !isInfinteScrollCalled) {
                        this.recentPairedDiamondList = data['data']['rows'];
                        this.totalpairedRecentRecords = data['data']['count'];
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']
                    }
                    if (data['code'] === 200 && isInfinteScrollCalled) {
                        this.recentPairedDiamondList = orderBy(union(this.recentPairedDiamondList, data['data']['rows']));
                        this.totalpairedRecentRecords = data['data']['count'];
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencyCode = data["data"]['conversion_currency_code']
                        this.currencySymbol = data["data"]['currency_symbol']
                    }

                    // this.diamondColum.forEach((item, index) => {
                    //     if (item.key === 'Price' && this.currencyCode === 'USD') {
                    //         this.diamondColum.splice(index, 1);
                    //     }
                    // });
                    this.filterObj.page++;
                    // this.isAddToCart = this.cartFlag;
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    // Location Data
    getLocation(params) {
        this.loader.showSpinner();
        this.subscription.add(
            this.whiteDiamondService.getLocation(params).subscribe(
                data => {
                    this.loader.hideSpinner();
                    if (data['code'] === 200) {
                        this.locationList = data['data'];
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    addtoWish(data, i) {
        console.log(data);
        data['checkWish'] = true;
        data['wishlist'] = true;
        let index = this.diamondComparisonArr.findIndex(function (item, i) {
            return item.lot_id === data.lot_id;
        });
        if (index != -1) {
            this.diamondComparisonArr[index]['checkWish'] = true;
        }
        this.loader.showSpinner();
        const params = {
            "lot_id": +data.lot_id
        };
        this.subscription.add(
            this.whiteDiamondService.addWishList(params).subscribe(
                data => {
                    if (data['code'] === 200) {
                        this.notifyService.showToast(data['message']);
                        this.wishlistServ.getWishListCount();
                        console.log(this.diamondList);
                        console.log(this.diamondComparisonArr);
                        setTimeout(() => {
                            this.loader.hideSpinner();
                        }, 200);
                    }
                    else {
                        this.notifyService.showToast(data['message']);
                        this.loader.hideSpinner();
                    }
                },
                error => {
                    this.loader.hideSpinner();
                    console.log(error);
                }
            )
        );
    }

    // Show diamond detail in left section
    showDiamondDetails(diamondData) {
        // this.isAddToCart = diamondData.in_order_cart;
        console.log(diamondData);
        this.showDiamondDetail = true;
        if (this.diamondType == 'w' || this.diamondType == 'c') {
            diamondData.measurement = diamondData.measurement ? diamondData.measurement.replaceAll("x", " x ") : '-';
            diamondData.measurement = diamondData.measurement ? diamondData.measurement.replaceAll("*", " x ") : '-';
        } else {
            diamondData.lot.measurement = diamondData.lot.measurement ? diamondData.lot.measurement.replaceAll("x", " x ") : '-';
            diamondData.lot.measurement = diamondData.lot.measurement ? diamondData.lot.measurement.replaceAll("*", " x ") : '-';
            diamondData.paired_diamond_lot.measurement = diamondData.paired_diamond_lot.measurement ? diamondData.paired_diamond_lot.measurement.replaceAll("x", " x ") : '-';
            diamondData.paired_diamond_lot.measurement = diamondData.paired_diamond_lot.measurement ? diamondData.paired_diamond_lot.measurement.replaceAll("*", " x ") : '-';
        }
        this.diamondViewList = diamondData;
        console.log(this.diamondViewList);
    }

    // Show diamond detail page
    diamondDetail(diamondData) {
        this.router.navigateByUrl('/diamondDetail');
    }

    // Availability Dropdown
    availableValueFunction(data) {
        this.availabilityValue = data;
    }

    availabilityChange(e) {
        this.availableValueFunction(e.value);
        this.onFilterChangeHandler();
    }

    preventDefaultAction(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    // Location Dropdown
    locationChange(e) {
        // console.log(this.locationList)
        // if (!e.value.includes("0")) {
        //     let index = this.selectedLocation.findIndex(x => x == "0");
        //     if (index > -1)
        //         this.selectedLocation.splice(index, 1);
        // } else {
        //     this.selectedLocation = ["0"];
        // }
        // this.locationValue = e.value;
        this.onFilterChangeHandler();
    }

    onScroll(e) {
        const currentValue = this.scrollposition;
        const element = e.target;
        let pos = (element.scrollTop || element.scrollTop) + element.offsetHeight;
        let max = element.scrollHeight;
        const difference = max - pos;
        if (difference >= 0 && difference <= 700 && currentValue < element.scrollTop) {
            // let params = {
            //     diamond_type: "w",
            //     // shape_id: this.filterData.shapecheckedIDs,
            //     availability: this.availabilityValue,
            //     Location: this.locationValue,
            //     page_no: this.filterObj.page += 1,
            //     page_record: this.filterObj.pageRecord
            // }
            if (this.filterObj.enableNext || this.comparisonTable == false) {
                this.paginationScroll = true;
                // this.getDiamondList(params, true);
                this.onFilterChangeHandler(true, this.filterParams ? true : false, this.filterParams);
                this.paginationScroll = false;
            }
        }
        this.scrollposition = element.scrollTop;
    }


    // Filter data changes
    onFilterChangeHandler(isInfinteScroll?: boolean, isSorting: boolean = false, sortingParams: object = {}) {
        let params = {
            diamond_type: this.diamondType,
            availability: this.availabilityValue,
            page_record: this.filterObj.pageRecord
        }
        if (!isInfinteScroll && !isSorting) {
            this.initialLoad = true;
        }
        if (this.filterData.resetClicked) {
            this.sortingArray = [];
            this.diamondColum.forEach(element => {
                element.sort = "ASC";
                element.is_asec = false;
                element.is_dsec = false;

            });
            this.diamondColumOne.forEach(element => {
                element.sort = "ASC";
                element.is_asec = false;
                element.is_dsec = false;

            });
            this.diamondColumTwo.forEach(element => {
                element.sort = "ASC";
                element.is_asec = false;
                element.is_dsec = false;

            });
            this.colorDiamondColum.forEach(element => {
                element.sort = "ASC";
                element.is_asec = false;
                element.is_dsec = false;

            });
            this.colorDiamondColumOne.forEach(element => {
                element.sort = "ASC";
                element.is_asec = false;
                element.is_dsec = false;

            });
            this.colorDiamondColumTwo.forEach(element => {
                element.sort = "ASC";
                element.is_asec = false;
                element.is_dsec = false;

            });
            this.pairedDiamondColumn.forEach(element => {
                element.sort = "ASC";
                element.is_asec = false;
                element.is_dsec = false;

            });
        }
        if (isSorting === true && sortingParams && this.sortingArray.length > 0) {
            params = {
                ...params,
                ...sortingParams,
            }
        }
        if (this.sortingArray.length > 0) {
            params = {
                ...params,
                ...this.filterParams,
            }
        }
        if (this.paginationScroll) {
            params['page_no'] = this.filterObj.page
        } else {
            params['page_no'] = 1
            this.filterObj.page = 1;
        }

        if (this.diamondType != 'c') {
            this.filterData.refineList.forEach(element => {
                if (element.name === 'Hearts & Arrows') {
                    params['heart_n_arrow'] = element.value
                } else if (element.name === 'AGS 000') {
                    params['ags_000'] = element.value
                } else if (element.name === '3X') {
                    params['3x'] = element.value
                } else if (element.name === 'GCAL 8X') {
                    params['gcal_8x'] = element.value
                } else if (element.name === 'As Grown') {
                    params['as_grown'] = element.value
                } else if (element.name === 'Superior Round' && element.value) {
                    params['superior_value'] = 'r'
                } else if (element.name === 'Superior Fancies' && element.value) {
                    params['superior_value'] = 'f'
                }

            });
        }


        if (this.selectedLocation.length > 0) {
            // params['Location'] = this.locationValue
            params['Location'] = this.selectedLocation;
        }
        if (this.filterData.shapecheckedIDs.length > 0) {
            params['shape_id'] = this.filterData.shapecheckedIDs
        }

        if (this.filterData.colorcheckedIDs.length > 0 && this.filterData.colorcheckedIDs.length < this.filterData.colorOptions.stepsArray.length)
            params['color_ids'] = this.filterData.colorcheckedIDs

        if (this.diamondType != 'c') {
            if (this.filterData.cutcheckedIDs.length > 0 && this.filterData.cutcheckedIDs.length < this.filterData.cutOptions.stepsArray.length)
                params['cut_ids'] = this.filterData.cutcheckedIDs
        }
        if (this.diamondType === 'c') {
            if (this.filterData.intensitycheckedIDs.length > 0 && this.filterData.intensitycheckedIDs.length < this.filterData.intensityOptions.stepsArray.length)
                params['intensity_ids'] = this.filterData.intensitycheckedIDs
        }
        if (this.filterData.claritycheckedIDs.length > 0 && this.filterData.claritycheckedIDs.length < this.filterData.clarityOptions.stepsArray.length)
            params['clarity_ids'] = this.filterData.claritycheckedIDs

        if (this.filterData.caratFromIDs) {
            params['carat_from'] = this.filterData.caratFromIDs
        } else {
            params['carat_from'] = 0;
        }
        if (this.filterData.caratToIDs) {
            params['carat_to'] = this.filterData.caratToIDs
        } else {
            params['carat_to'] = 20;
        }
        if (this.filterData.pricecheckedIDs.length > 0) {
            params['price_range'] = this.filterData.pricecheckedIDs
        }
        // else {
        //     params['price_range'] = [200, 100000];
        // }
        // if (this.filterData.sizeFromIDs || this.filterData.sizeFromIDs === 0) {
        //     params['size_from'] = this.filterData.sizeFromIDs
        // }
        // if (this.filterData.sizeToIDs) {
        //     params['size_to'] = this.filterData.sizeToIDs
        // }
        if (this.filterData.sizeFromIDs || this.filterData.sizeToIDs) {
            params['size_in_mm_range'] = [+this.filterData.sizeFromIDs, +this.filterData.sizeToIDs]
        } else {
            params['size_in_mm_range'] = [0, 25]
        }
        if (this.filterData.searchValue) {
            this.showDiamondDetail = false;
            params['Search'] = this.filterData.searchValue.split(/[\s,]+/);
        }
        if (this.filterData.labcheckedIDs.length > 0) {
            params['lab_ids'] = this.filterData.labcheckedIDs
        }
        if (this.filterData.polishcheckedIDs.length > 0) {
            params['polish_ids'] = this.filterData.polishcheckedIDs
        }
        if (this.filterData.symmetrycheckedIDs.length > 0) {
            params['symmetry_ids'] = this.filterData.symmetrycheckedIDs
        }
        if (this.filterData.tingecheckedIDs.length > 0) {
            params['tinge_ids'] = this.filterData.tingecheckedIDs
        }

        if (this.filterData.depthMinValue) {
            params['depth_min'] = + this.filterData.depthMinValue
        }
        if (this.filterData.depthMaxValue) {
            params['depth_max'] = + this.filterData.depthMaxValue
        }
        if (this.filterData.tableMinValue) {
            params['table_min'] = + this.filterData.tableMinValue
        }
        if (this.filterData.tableMaxValue) {
            params['table_max'] = + this.filterData.tableMaxValue
        }
        if (this.filterData.ratioMinValue) {
            params['ratio_min'] = + this.filterData.ratioMinValue
        }
        if (this.filterData.ratioMaxValue) {
            params['ratio_max'] = + this.filterData.ratioMaxValue
        }

        if (this.active === 2) {
            if (this.diamondType === 'p') {
                this.getRecentlyViewedPairedList(params, isInfinteScroll);
            } else {
                this.getRecentlyViewedList(params, isInfinteScroll);
            }
        }
        if (this.active === 1) {
            if (this.diamondType === 'p') {
                this.getpairedDiamondList(params, isInfinteScroll);
            } else {
                this.getDiamondList(params, isInfinteScroll);
            }
        }
        this.showDiamondDetail = false;

    }

    showAllDiamondsTab() {
        this.active = 1;
        this.allDiamondsFlag();
    }

    // openDialog(diamondData) {
    //     const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //         height: '230px',
    //         width: '400px',
    //         data: { headerName: 'Remove From Cart', text: 'Are you sure you want to remove this item from cart?' }
    //     });
    //     dialogRef.afterClosed().subscribe(result => {
    //         if (result) {
    //             this.addToCartService.removeFromCart(diamondData)
    //         }
    //     });
    // }
    //Add to cart
    AddToCart(diamondData) {
        if (this.getCartObject(diamondData)) {
            this.addToCartService.removeFromCart(diamondData);
        } else {
            this.addToCartService.addToCart(diamondData);
        }
    }

    getCartStatus(diamondData) {
        if (this.getCartObject(diamondData)) {
            return true;
        } else {
            return false;
        }
    }

    getCompareStatus(diamondData) {
        if (this.getCompareObject(diamondData)) {
            return true;
        } else {
            return false;
        }
    }

    getCompareObject(diamondData) {
        let compareObject;
        let compareList = JSON.parse(localStorage.getItem('DiamondCompareList'));
        let colorCompareList = JSON.parse(localStorage.getItem('colorDiamondCompareList'));
        if (compareList && diamondData.lot_id && this.diamondType == 'w')
            compareObject = compareList.find(x => x.lot_id === diamondData.lot_id)
        else if (colorCompareList && diamondData.lot_id && this.diamondType == 'c')
            compareObject = colorCompareList.find(x => x.lot_id === diamondData.lot_id)
        return compareObject ? true : false;
    }

    //Share to client modal
    showClientModal(data) {
        const modalRef = this.modalService.open(ModalComponent, { centered: true, scrollable: true });
        modalRef.componentInstance.shareClientData = data;
        modalRef.componentInstance.displayPrice = this.displayPrice;
    }

    getCartObject(diamondData) {
        let cartObject;
        if (diamondData) {
            if (diamondData.paired_diamond_lot && diamondData.paired_diamond_lot.lot_id)
                cartObject = this.addToCartService.orderCartListObject.rows.find(x => x.paired_lot_id == diamondData.paired_lot_id && x.lot_id == diamondData.lot_id);
            else
                if (this.addToCartService.orderCartListObject && this.addToCartService.orderCartListObject.rows)
                    cartObject = this.addToCartService.orderCartListObject.rows.find(x => x.lot_id == diamondData.lot_id && !x.paired_lot_id);
        }
        return cartObject ? true : false;
    }

    getLabelForCart(diamondData) {

        if (this.getCartObject(diamondData))
            return "Remove from Cart";
        else
            return "Add to Cart";
    }

    //Match 
    matchClick(data) {
        console.log(data);
        if (data.paired_lot_id) {
            this.router.navigate(['/pairedDiamondDetail' + '/' + data.lot_id], { queryParams: { pairedLotId: data.paired_lot_id } });
        } else {
            this.notifyService.showToast('No Match Found');
        }
    }

    goToDiamondDetail(diamondData) {
        let url = this.diamondType == 'p' ? '/pairedDiamondDetail/' + diamondData.lot_id + '?pairedLotId=' + diamondData.paired_lot_id
            : '/diamondDetail/' + diamondData.lot_id + '?diamondType=' + this.diamondType;
        window.open(`${environment.webUrl}` + url, '_blank');
    }
}
