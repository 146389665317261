<section class="maincontainer pb-0">
    <p class="mainLabel2 text-center">My Wishlist</p>
    <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
        <p class="loading">Loading...</p>
    </ngx-spinner>
    <div class="line1"></div>
    <div class="row" style="padding-left: 30px;">
        <div class="col-12 col-md-12 col-lg-3 diamond-inspect-section" style="padding-left: 0;">
            <div class="diamond-wrap mb-3">
                <div *ngIf="showDiamondDetail">
                    <div class="diamond">
                        <img *ngIf="diamondViewList.image_path" [src]="diamondViewList.image_path" style="margin: 20px 0px 0px 0px;">
                        <!-- <img *ngIf="!diamondViewList.image_path" src="./assets/img/defaultDiamond.png" style="padding: 25px;"> -->
                        <!-- <div><a href="#">View Certificate ></a></div> -->
                        <div (click)="showDetailImage(diamondContent, diamondViewList)"><a>View Image ></a></div>
                    </div>
                    <div class="links">
                        <a (click)="showDiamondVideo(diamondContent, diamondViewList)">View Video ></a>
                        <a (click)="showClientModal(diamondViewList)" class="pointerClass">Show Client ></a>
                        <!-- <a (click)="showClientModal(diamondViewList)" class="pointerClass">Show Client ></a> -->
                    </div>
                    <div class="values">
                        <div class="value-wrap">
                            <label>Stock ID :</label>
                            <!-- <p>{{diamondViewList.altr_stone_id}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <p>{{diamondViewList.altr_stock_id ? diamondViewList.altr_stock_id : '-'}}</p>
                            <!-- <label>Length :</label> -->
                            <!-- <p>{{diamondViewList.length}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <label>Measurement :</label>
                            <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name}}</p>
                                    <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.polish.polish_name}}</p> -->
                        </div>
                        <div class="value-wrap pr-0 pl-0">
                            <!-- <label>Width :</label> -->
                            <p>{{diamondViewList.measurement ? diamondViewList.measurement : '-'}}</p>
                            <!-- <p>{{diamondViewList.width}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <label>Cut :</label>
                            <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name}}</p>
                                    <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.polish.polish_name}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <!-- <label>Width :</label> -->
                            <p>{{diamondViewList.cut_name ? diamondViewList.cut_name : '-'}}</p>
                            <!-- <p>{{diamondViewList.width}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <label>Polish :</label>
                            <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.polish.polish_name}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <!-- <label>Width :</label> -->
                            <p>{{diamondViewList.polish_name ? diamondViewList.polish_name : '-'}}</p>
                            <!-- <p>{{diamondViewList.width}}</p> -->
                        </div>
                        <!-- <div class="value-wrap">
                            <label>Width :</label> -->
                        <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.polish?.polish_name}}</p>
                                    <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.polish.polish_name}}</p> -->
                        <!-- </div>
                        <div class="value-wrap"> -->
                        <!-- <label>Width :</label> -->
                        <!-- <p>{{diamondViewList.width ? diamondViewList.width : '-'}}</p>
                        </div> -->
                        <div class="value-wrap">
                            <label>Symmetry :</label>
                            <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.symmetry?.symmetry_name}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.symmetry.symmetry_name}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <p *ngIf="!recentlyViewedClick">{{diamondViewList.symmetry_name ?
                                diamondViewList.symmetry_name : '-'}}</p>
                            <!-- <label>Hearts & Arrow :</label> -->
                            <!-- <p>{{diamondViewList.heart_n_arrow === false ? 'No' : 'Yes'}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <label>Hearts & Arrow :</label>
                            <!-- <p>{{diamondViewList.depth_percentage ? diamondViewList.depth_percentage : '-'}}%</p> -->
                        </div>
                        <div class="value-wrap">
                            <p>{{diamondViewList.heart_n_arrow === false ? 'No' : 'Yes'}}</p>
                            <!-- <label>3X :</label> -->
                            <!-- <p>{{diamondViewList['3x'] === false ? 'No' : 'Yes'}}</p> -->
                        </div>
                        <!-- <div class="value-wrap">
                            <label>3X :</label> -->
                        <!-- <p>{{diamondViewList.depth_percentage ? diamondViewList.depth_percentage : '-'}}%</p> -->
                        <!-- </div> -->
                        <!-- <div class="value-wrap">
                            <p>{{diamondViewList['3x'] === false ? 'No' : 'Yes'}}</p> -->
                        <!-- <label>3X :</label> -->
                        <!-- <p>{{diamondViewList['3x'] === false ? 'No' : 'Yes'}}</p> -->
                        <!-- </div> -->
                        <div class="value-wrap">
                            <label>Depth :</label>
                            <!-- <p>{{diamondViewList.depth_percentage ? diamondViewList.depth_percentage : '-'}}%</p> -->
                        </div>
                        <div class="value-wrap">
                            <p>{{diamondViewList.depth_percentage ? diamondViewList.depth_percentage : '-'}}%</p>
                            <!-- <label>3X :</label> -->
                            <!-- <p>{{diamondViewList['3x'] === false ? 'No' : 'Yes'}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <label>Table :</label>
                            <!-- <p>{{diamondViewList.table_percentage ? diamondViewList.table_percentage : '-'}}%</p> -->
                        </div>
                        <div class="value-wrap">
                            <p>{{diamondViewList.table_percentage ? diamondViewList.table_percentage : '-'}}%</p>
                            <!-- <label>Tinge :</label> -->
                            <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.master_tinge?.tinge_name}}</p>
                                <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.master_tinge.tinge_name}}</p> -->
                        </div>
                        <!-- <div class="value-wrap">
                            <label>Tinge :</label> -->
                        <!-- <p>{{diamondViewList.table_percentage ? diamondViewList.table_percentage : '-'}}%</p> -->
                        <!-- </div> -->
                        <!-- <div class="value-wrap">
                            <p>{{diamondViewList.tinge_name ? diamondViewList.tinge_name : '-' }}</p> -->
                        <!-- <label>Tinge :</label> -->
                        <!-- <p *ngIf="!recentlyViewedClick">{{diamondViewList.master_tinge?.tinge_name}}</p>
                                    <p *ngIf="recentlyViewedClick">{{diamondViewList.lot?.master_tinge.tinge_name}}</p> -->
                        <!-- </div> -->
                        <!-- <div class="value-wrap">
                                <label>AGS000 :</label>
                                <p>{{diamondViewList.ags000 === false ? 'No' : 'Yes'}}</p>
                            </div> -->
                        <div class="value-wrap">
                            <label>Ratio :</label>
                            <!-- <p>{{diamondViewList.ratio ? diamondViewList.ratio : '-'}}</p> -->
                        </div>
                        <div class="value-wrap">
                            <p>{{diamondViewList.ratio ? diamondViewList.ratio : '-'}}</p>
                        </div>
                        <!-- <div class="value-wrap" *ngIf="!this.diamondViewList.ags000">
                            <label>GCAL :</label> -->
                        <!-- <p>{{diamondViewList.gcal_8x === false ? 'No' : 'Yes'}}</p> -->
                        <!-- </div> -->
                        <!-- <div class="value-wrap" *ngIf="!this.diamondViewList.ags000">
                            <p>{{diamondViewList.gcal_8x === false ? 'No' : 'Yes'}}</p>
                        </div> -->
                        <!-- <div class="value-wrap" *ngIf="this.diamondViewList.ags000">
                            <label>AGS :</label> -->
                        <!-- <p>{{diamondViewList.ags000 === false ? 'No' : 'Yes'}}</p> -->
                        <!-- </div>
                        <div class="value-wrap" *ngIf="this.diamondViewList.ags000">
                            <p>{{diamondViewList.ags000 === false ? 'No' : 'Yes'}}</p>
                        </div> -->
                    </div>
                    <div class="card">
                        <button type="button" class="btn btn-outline" (click)="AddToCart(diamondViewList)">{{
                            getLabelForCart(diamondViewList)}}</button>
                    </div>
                </div>
                <div class="default-diamond" *ngIf="!showDiamondDetail">
                    <img src="assets/img/defaultDiamond.png">
                    <p>Select a diamond in the table to view more information</p>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-12 col-lg-9" style="padding-left: 0;">
            <div class="formDiv">
                <div class="list-view">
                    <div class="table-responsive">
                        <table class="table table-striped table-fixed mb-0">
                            <thead>
                                <tr>
                                    <ng-container *ngIf="displayPrice === 'c'">
                                        <th class="commonWidth">Stock Id</th>
                                        <th *ngFor="let data of diamondColum" scope="col" (click)="diamondSort(data)"
                                            [ngClass]="[data.value == 'Price'? data.className :'', data.value == 'Intensity'? data.className :'', data.value != 'Price' ? 'commonWidth' : '']">
                                            {{data.key}}
                                            <span *ngIf="data.is_code">
                                                {{currencyCode}}
                                            </span>
                                            <span class="sort-wrap" *ngIf="diamondType != 'p' || data.is_price">
                                                <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                            </span>
                                        </th>
                                        <th scope="col" class="amountAction text-center commonWidth">Actions</th>
                                    </ng-container>
                                    <ng-container *ngIf="displayPrice === 's'">
                                        <th class="commonWidth">Stock Id</th>
                                        <th *ngFor="let data of diamondColumOne" scope="col" (click)="diamondSort(data)"
                                            [ngClass]="[data.value == 'Price'? data.className :'', data.value == 'Intensity'? data.className :'', data.value != 'Price' ? 'commonWidth' : '']">
                                            {{data.key}}
                                            <span *ngIf="data.is_code">
                                                {{currencyCode}}
                                            </span>
                                            <span class="sort-wrap" *ngIf="diamondType != 'p' || data.is_price">
                                                <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                            </span>
                                        </th>
                                        <th scope="col" class="amountAction text-center commonWidth">Actions</th>
                                    </ng-container>
                                    <ng-container *ngIf="displayPrice === 'b'">
                                        <th class="commonWidth">Stock Id</th>
                                        <th *ngFor="let data of diamondColumTwo" scope="col" (click)="diamondSort(data)"
                                            [ngClass]="[data.value == 'Price'? data.className :'', data.value == 'Intensity'? data.className :'', data.value != 'Price' ? 'commonWidth' : '']">
                                            {{data.key}}
                                            <span *ngIf="data.is_code">
                                                {{currencyCode}}
                                            </span>
                                            <span class="sort-wrap" *ngIf="diamondType != 'p' || data.is_price">
                                                <img [src]="data.is_asec ? './assets/img/svg/asce-dark.svg' : './assets/img/svg/asce.svg'">
                                                <img [src]="data.is_dsec ? './assets/img/svg/desce-dark.svg' : './assets/img/svg/desce.svg'">
                                            </span>
                                        </th>
                                        <th scope="col" class="actions text-center">Actions</th>
                                    </ng-container>
                                    <!-- <div> -->
                                    <!-- <th class="" style="width: 90px;">Stock Id</th>
                                    <th class="">Shape</th>
                                    <th class="">Carat</th>
                                    <th class="">Color</th>
                                    <th class="">Clarity</th>
                                    <th class="">Cut</th>
                                    <th class="">Intensity</th>
                                    <th class="">Lab</th> -->
                                    <!-- <th scope="col" class="col-1">Price USD</th> -->
                                    <!-- <th class="" style="width: 90px;">Price {{currencyCode}}</th>
                                    <th class="" style="text-align:center;width: 172px;padding-right: 47px;">Actions</th> -->
                                    <!-- </div> -->
                                </tr>
                            </thead>
                            <tbody (scroll)="onScroll($event)" class="thikScroll">
                                <a *ngFor="let data of wishListData; index as i" (click)="showDiamondDetails(data);">
                                    <tr [ngClass]="diamondViewList?.lot_id === data?.lot_id ? 'active': ''">
                                        <td class="commonWidth" style="padding-right: 0px;">{{ data.altr_stock_id ?
                                            data.altr_stock_id :
                                            '-'}}</td>
                                        <td class="commonWidth">{{ data.shape_name ? data.shape_name : '-' }}
                                            <span *ngIf="data.availability === 'next_day'" class="shape-active"></span>
                                        </td>
                                        <td class="commonWidth">{{ data.weight ? data.weight : '-'}}</td>
                                        <td class="commonWidth">{{ data.color_name ? data.color_name : '-'}}</td>
                                        <td class="intensity commonWidth">{{ data.intensity_name ?
                                            data.intensity_name : '-'}}
                                            <!-- <img *ngIf="data.heart_n_arrow" style="width:15px;position: absolute;top:-15%;"
                                                src="../../../assets/img/svg/heart&arrow.svg"></td> -->
                                        </td>
                                        <td class="commonWidth">{{ data.clarity_name ? data.clarity_name : '-'}}</td>
                                        <td class="d-flex justify-content-start commonWidth">{{ data.cut_name ?
                                            data.cut_name :
                                            '-'}}
                                            <span class="pl-1">
                                                <img *ngIf="data.heart_n_arrow" style="width: 13px;position: relative;bottom:2px;"
                                                    src="../../../assets/img/svg/heart&arrow.svg">
                                            </span>
                                        </td>
                                        <td class="d-flex align-items-center justify-content-start labPadding commonWidth">
                                            <div style="position:relative;top: 1px;">
                                                <div>
                                                    <span class="tooltip action">
                                                        <a [href]="data.grading_link ? data.grading_link : '#'" target="_blank"
                                                            class="gradingLink">{{
                                                            data.lab_name ? data.lab_name : '-'}}</a>
                                                        <span class="tooltiptext">View Certificate</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- <td class="col-1">${{ data.conversion_amount ? data.conversion_amount : '-'}}</td> -->
                                        <td *ngIf="displayPrice === 'c' || displayPrice === 'b'" class="price">{{currencySymbol}}{{
                                            data.cost_conversion_amount | number}}
                                        </td>
                                        <td *ngIf="displayPrice === 's' || displayPrice === 'b'" class="price">{{currencySymbol}}{{
                                            data.conversion_amount | number}}
                                        </td>
                                        <td class="justify-content-between commonWidth" style="padding-left:0px;padding-right:0px;"
                                            [ngStyle]="{'width' : displayPrice === 'c' ? '16%' : (displayPrice === 's' ? '16%' : null)}">
                                            <p *ngIf="!getCartStatus(data)" (click)="AddToCart(data)">
                                                <span class="tooltip action">
                                                    <img src="./assets/img/svg/bag.svg" class="iconSize">
                                                    <span class="tooltiptext">Add to Cart</span>
                                                </span>
                                            </p>
                                            <p *ngIf="getCartStatus(data)" (click)="AddToCart(data)">
                                                <span class="tooltip action">
                                                    <img src="./assets/img/svg/bag-fill.svg" class="iconSize" style="width: 16px;height: auto;">
                                                    <span class="tooltiptext">Remove from Cart</span>
                                                </span>
                                            </p>
                                            <p (click)="deleteWishList(data.lot_id)">
                                                <span class="tooltip action">
                                                    <img src="./assets/img/svg/delete.svg" class="iconSize">
                                                    <span class="tooltiptext">Delete from Wishlist</span>
                                                </span>
                                            </p>
                                            <p class="" (click)="goToDiamondDetail(data)">
                                                <span class="tooltip action">
                                                    <img src="../../../assets/img/svg/vision.svg">
                                                    <span class="tooltiptext">Diamond Detail</span>
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </a>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="paginationRow" *ngIf="wishListData.length > 0">
                            <ngb-pagination [(page)]="pageNo" (pageChange)="methodCall($event)" [pageSize]="pageRecord"
                                [collectionSize]="this.totalRecords">
                            </ngb-pagination>
                            <p class="showRowLabel">{{getRecord()}}</p>
                
                        </div> -->

                <div *ngIf="wishListData.length < 1" class="no-record-div">
                    <p style="font-size:13px"> No Records Found </p>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #diamondContent let-modal>
    <div class="modal-body pb-0 pt-0">
        <button type="button" class="close" style="outline: none !important;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div *ngIf="displayVideo">
            <div class="row px-3 pt-3 align-items-center justify-content-center">
                <div class="col-12">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;">Stock Id :
                        {{ diamindModalVideoData.altr_stock_id }}
                    </h4>
                </div>
            </div>
            <div class="row px-3 align-items-center justify-content-center">
                <!-- <div class="col-12 col-md-4" *ngFor="let data of dataForModal">
                            <iframe width="350" height="500" src={{data?.video_path}} frameborder="0" allowfullscreen></iframe>
                        </div> -->
                <div class="col-12">
                    <div class="d-flex justify-content-center">
                        <iframe class="videoIfrmae" width="350" height="290" scrolling="no" [src]="diamindModalVideoData.video_path | safe :'resourceUrl'"
                            frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div class="text-center">
                        <h4 class="mb-3" style="text-transform: capitalize;">
                            {{diamindModalVideoData.shape_name ?
                            diamindModalVideoData.shape_name :
                            '-'}}&nbsp;{{diamindModalVideoData.size ? diamindModalVideoData.size :
                            '-'}}&nbsp;{{diamindModalVideoData.color_name ?
                            diamindModalVideoData?.color_name
                            : '-'}}&nbsp;{{diamindModalVideoData.clarity_name ?
                            diamindModalVideoData.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="displayImage">
            <div class="row px-3 pt-3 justify-content-center">
                <div class="col-12">
                    <h4 class="text-center mb-3" style="text-transform: capitalize;">Stock Id :
                        {{diamindModalVideoData.altr_stock_id}}
                    </h4>
                </div>
            </div>
            <div class="row px-3">
                <div class="col-12 imgSliderBlock">
                    <div class="d-flex justify-content-between">
                        <div class="carouselContainer">
                            <ngb-carousel [showNavigationArrows]="diamindModalVideoData.slider_images_array.length > 1" [interval]="false">
                                <ng-template *ngFor="let images of diamindModalVideoData.slider_images_array" ngbSlide>
                                    <div class="picsum-img-wrapper">
                                        <img class="diamondImagePopUp" src="{{images.url}}" alt="Image Not Available">
                                        <div class="text-center imagesText">{{images.name}}</div>
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                    <div class="text-center pt-2">
                        <h4 class="mb-3 mt-4" style="text-transform: capitalize;">
                            {{diamindModalVideoData.shape_name ?
                            diamindModalVideoData.shape_name :
                            '-'}}&nbsp;{{diamindModalVideoData.weight ? this.diamindModalVideoData.weight :
                            '-'}}&nbsp;{{diamindModalVideoData.color_name ?
                            diamindModalVideoData?.color_name
                            : '-'}}&nbsp;{{diamindModalVideoData.clarity_name ?
                            diamindModalVideoData.clarity_name :
                            '-'}}&nbsp;
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>