import { RouterModule } from '@angular/router';
import { LoaderDirective } from './_directive/loader.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './_component/footer/footer.component';
import { HeaderComponent } from './_component/header/header.component';
import { FilterComponent } from './_component/filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatIconModule } from '@angular/material/icon';
import { ModalComponent } from './_component/modal/modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './_component/confirmation-dialog/confirmation-dialog.component';
import { CommonPipe } from './_pipes/common.pipe';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { floatValuePipe } from './_pipes/floatValue.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';


const BASE_MODULES = [CommonModule, FormsModule, RouterModule, ReactiveFormsModule, ScrollingModule, NgxSliderModule, NgbModule];

const ANGULAR_MODULES = [
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatStepperModule,
    MatButtonModule,
    MatCheckboxModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatMenuModule,
    NgxSpinnerModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatDialogModule,
    NgxImageZoomModule,
    MatAutocompleteModule,
    MatTooltipModule
];
const shared = [
    FooterComponent,
    HeaderComponent,
    FilterComponent,
    LoaderDirective,
    ModalComponent
];

const PIPES = [
    CommonPipe,
    floatValuePipe
];

@NgModule({
    declarations: [shared, ConfirmationDialogComponent, PIPES],
    imports: [
        ...BASE_MODULES,
        ...ANGULAR_MODULES,
    ],
    exports: [
        shared,
        ...BASE_MODULES,
        ...ANGULAR_MODULES,
        ...PIPES
    ],

})
export class CoreModule { }
