<div class="row refine-row">
    <div class="col-12 col-md-2 col-lg-1 refine-container" *ngIf="diamondType != 'c'">
        <label class="title-label refine-label">Refine By</label>
    </div>
    <div class="col-12 col-md-10 col-lg-11 d-flex justify-content-between refineContainer" *ngIf="diamondType != 'c'">
        <div class="refine-div">
            <label *ngFor="let refine of refineList" class="check-container">
                <input type="checkbox" [(ngModel)]="refine.value" (change)="changeRefineSelection(refine.name, $event.target.checked, refine, $event)"><span
                    class="refineName">{{refine.name}}</span>
                <span class="info" *ngIf="refine.is_info"><i class="fa fa-info"></i></span><span class="checkmark"></span>
            </label>
        </div>
        <div class="search-block">
            <input type="text" name="search" autocomplete="off" [(ngModel)]="search" placeholder="Search by Certificate/Stock ID."
                (keydown.enter)="searchData()" (keyup)="searchKeyData()">
            <i class="fa fa-search" (click)="searchData()"></i>
            <!-- <i *ngIf="this.search && searchActive" class="fa fa-times-circle-o clear" (click)="clearData()"></i> -->
        </div>
    </div>
    <div class="col-12 d-flex justify-content-end" *ngIf="diamondType === 'c'">
        <div class="search-block">
            <input type="text" name="search" autocomplete="off" [(ngModel)]="search" placeholder="Search by Certificate/Stock ID."
                (keydown.enter)="searchData()" (keyup)="searchKeyData()">
            <i class="fa fa-search" (click)="searchData()"></i>
            <!-- <i *ngIf="this.search && searchActive" class="fa fa-times-circle-o clear" (click)="clearData()"></i> -->
        </div>
    </div>
</div>
<div class="row shape-row">
    <div class="col-12 col-md-2 col-lg-1 d-flex common-container">
        <div>
            <label class="title-label">Shape</label>
        </div>
    </div>
    <div class="col-12 col-md-10 col-lg-11 shapeContainer">
        <div class="shape-wrap d-flex justify-content-start">
            <div *ngFor="let shape of shapeData" class="shape-div">
                <input type="checkbox" (change)="changeShapeSelection($event, shape.master_shape_id)" [(ngModel)]="shape.is_checked"
                    [id]="shape.master_shape_id">
                <label class="check-container shape-label" [for]="shape.master_shape_id" 
                [ngClass]="[shape.pointer ? 'cursorPointer':'cursorDisabled', 
                        shapecheckedIDs.length > 0 && shape.is_checked ? 'shapeDefault' : '', 
                        shapecheckedIDs.length > 0 && !shape.is_checked ? 'shapeUnselected' : '', 
                        shape.shape_name == 'Modified Cuts' ? 'modified-cuts' : '', 
                        shape.shape_name == 'Half Moon' ? 'half-moon' : '']">
                    <span><img [src]="shape.image_url"></span>
                    <span>{{shape.shape_name}}</span>
                </label>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-12 col-md-12 col-lg-6">
        <div class="row">
            <div class="col-12 col-md-2 d-flex common-container caratPriceSizeCommon">
                <div>
                    <label class="title-label" [ngClass]="diamondType === 'p' ? 'pairedLabel' : ''">{{diamondType ===
                        'p' ?
                        'Total Carat' : 'Carat'}}</label>
                </div>
            </div>
            <div class="col-12 col-md-10">
                <div class="custom-slider">
                    <div class="sliderInput">
                        <input type="number" step=".01" [(ngModel)]="caratMinValueCustom" class="pull-left" (blur)="caratMinValueInput($event)"
                            (keypress)="decimalCheck($event)">
                        <input type="number" step=".01" [(ngModel)]="caratMaxValueCustom" class="pull-right" (blur)="caratMaxValueInput($event)"
                            (keypress)="decimalCheck($event)">
                    </div>
                    <ngx-slider [(value)]="caratMinValue" [(highValue)]="caratMaxValue" [options]="caratOptions"
                        (userChangeEnd)="changeCaratSelection($event)" (valueChange)="changeCaratValue($event)"
                        (highValueChange)="changeCaratHighValue($event)"></ngx-slider>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-12 col-lg-6">
        <div class="row">
            <div class="col-12 col-md-2 pr-0 pl-0 d-flex common-container caratPriceSizeCommon">
                <div class="priceLabel">
                    <label class="title-label">{{diamondType === 'p' ? 'Total Price' : 'Price'}}</label>
                </div>
            </div>
            <div class="col-12 col-md-10">
                <div class="custom-slider custom-price-slider">
                    <div class="sliderInput priceInput">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">{{currency}}</span>
                            </div>
                            <input type="text" lang="en-150" [(ngModel)]="priceMinValueCustom" lang="en" class="pull-left"
                                (blur)="priceMinBlur($event)" (keypress)="isNumberKey($event)">
                        </div>
                        <div class="input-group mb-3 right-input">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon1">{{currency}}</span>
                            </div>
                            <input type="text" lang="en-150" [(ngModel)]="priceMaxValueCustom" lang="en" class="pull-right"
                                (blur)="priceMaxBlur($event)" (keypress)="isNumberKey($event)">
                        </div>
                    </div>
                    <ngx-slider [(value)]="priceMinValue" [(highValue)]="priceMaxValue" [options]="priceOptions"
                    (userChangeEnd)="changePriceSelection($event)" (valueChange)="changePriceValue($event)"
                    (highValueChange)="changePriceHighValue($event)"></ngx-slider>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row py-0">
    <div class="col-12 col-md-12 col-lg-6">
        <div class="row pb-0">
            <div class="col-12 col-md-2 d-flex common-container">
                <div>
                    <label class="title-label">Color</label>
                </div>
            </div>
            <div class="col-12 col-md-10">
                <div class="custom-slider hideLabel" *ngIf="diamondType == 'w' || diamondType == 'p'">
                    <ngx-slider [(value)]="colorMinValue" [(highValue)]="colorMaxValue" [options]="colorOptions"
                        (userChangeEnd)="changeColorSelection($event)"></ngx-slider>
                </div>
                <div class="btn-group btn-group-toggle" *ngIf="diamondType == 'c'">
                    <label class="btn-outline btn-outline-primary primaryCheckbox" ngbButtonLabel *ngFor="let color of colorData">
                      <input type="checkbox" ngbButton (change)="changeColorSelection($event)" [(ngModel)]="color.is_checked" id="color.master_color_id"> {{color.color_name}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-12 col-lg-6" *ngIf="diamondType != 'c'">
        <div class="row pb-0">
            <div class="col-12 col-md-2 pr-0 pl-0 d-flex common-container">
                <div class="cutLabel">
                    <label class="title-label">Cut</label>
                </div>
            </div>
            <div class="col-12 col-md-10">
                <div class="custom-slider hideLabel labelwhiteSpace">
                    <ngx-slider [(value)]="cutMinValue" [(highValue)]="cutMaxValue" [options]="cutOptions"
                        (userChangeEnd)="changeCutSelection($event)"></ngx-slider>
                </div>
                <!-- <div class="btn-group btn-group-toggle" *ngIf="diamondType != 'w'">
                    <label class="btn-outline btn-outline-primary primaryCheckbox" ngbButtonLabel *ngFor="let cut of cutData">
                      <input type="checkbox" ngbButton (change)="changeCutSelection($event)" [(ngModel)]="cut.is_checked" id="cut.master_cut_id"> {{cut.cut_name}}
                    </label>
                </div> -->
            </div>
        </div>
    </div>
    <div class="col-12 col-md-12 col-lg-6" *ngIf="diamondType === 'c'">
        <div class="row">
            <div class="col-12 col-md-2 pr-0 pl-0 d-flex common-container">
                <div class="intensityLabel">
                    <label class="title-label">Intensity</label>
                </div>
            </div>
            <div class="col-12 col-md-10">
                <!-- <div class="custom-slider hideLabel">
                    <ngx-slider [(value)]="intensityMinValue" [(highValue)]="intensityMaxValue" [options]="intensityOptions"
                        (userChangeEnd)="changeIntensitySelection($event)"></ngx-slider>
                </div> -->
                <div class="btn-group btn-group-toggle">
                    <label class="btn-outline btn-outline-primary primaryCheckbox" ngbButtonLabel *ngFor="let intensity of intensityData">
                      <input type="checkbox" ngbButton (change)="changeIntensitySelection($event)" [(ngModel)]="intensity.is_checked" id="intensity.intensity_id"> {{intensity.fancy_color_name}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row pb-0">
    <div class="col-12 col-md-12 col-lg-6">
        <div class="row">
            <div class="col-12 col-md-2 d-flex common-container">
                <div>
                    <label class="title-label">Clarity</label>
                </div>
            </div>
            <div class="col-12 col-md-10">
                <div class="custom-slider hideLabel">
                    <ngx-slider [(value)]="clarityMinValue" [(highValue)]="clarityMaxValue" [options]="clarityOptions"
                        (userChangeEnd)="changeClaritySelection($event)"></ngx-slider>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-12 col-lg-6">
        <div class="row">
            <div class="col-12 col-md-2 pr-0 pl-0 d-flex common-container caratPriceSizeCommon">
                <div class="sizeLabel">
                    <label class="title-label" style="white-space: nowrap;">Size In MM</label>
                </div>
            </div>
            <div class="col-12 col-md-10">
                <div class="custom-slider">
                    <div class="sliderInput">
                        <input type="number" step=".01" [(ngModel)]="sizeMinValueCustom" class="pull-left" (blur)="sizeMinValueInput($event)"
                            (keypress)="decimalCheck($event)">
                        <input type="number" step=".01" [(ngModel)]="sizeMaxValueCustom" class="pull-right" (blur)="sizeMaxValueInput($event)"
                            (keypress)="decimalCheck($event)">
                    </div>
                    <ngx-slider [(value)]="sizeMinValue" [(highValue)]="sizeMaxValue" [options]="sizeOptions"
                        (userChangeEnd)="changeSizeSelection($event)" (valueChange)="changeSizeValue($event)"
                        (highValueChange)="changeSizeHighValue($event)"></ngx-slider>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row filter-row">
    <div class="col-12" *ngIf="diamondType != 'p'">
        <button type="button" class="btn btn-outline-primary adv-btn" (click)="collapse.toggle();" [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
            Advanced Filter
        </button>
        <div class="reset">
            <button class="btn btn-outline" (click)="resetFilter()">Reset Filter</button>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="filter-card" id="filterCard" [ngClass]=" media.matches && this.isCollapsed == false ? 'filterCardOpen':'filterCardClosed'">
                <div class="card-body">
                    <div class="laboratory" style="padding-bottom: 10px;">
                        <button type="button" class="btn btn-outline-primary adv-btn-btn" [ngClass]="{'filterHighlight' : labcheckedIDs.length > 0}"
                            (click)="toggleDropdown(laboratoryCollapse, 'lab')" [attr.aria-expanded]="selectedDropdown == 'lab'"
                            aria-controls="collapseExample">
                            Laboratory
                        </button>
                        <div #laboratoryCollapse="ngbCollapse" [ngbCollapse]="selectedDropdown != 'lab'">
                            <div class="card">
                                <div class="card-body">
                                    <label class="check-container" *ngFor="let lab of labData">
                                        <input type="checkbox" (change)="changeLabSelection()" [(ngModel)]="lab.is_checked"
                                            id="lab.master_lab_id">{{lab.lab_name}}<span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="polish" style="padding-bottom: 10px;">
                        <button type="button" class="btn btn-outline-primary adv-btn-btn" [ngClass]="{'filterHighlight' : polishcheckedIDs.length > 0}"
                            (click)="toggleDropdown(polishCollapse, 'polish')" [attr.aria-expanded]="selectedDropdown == 'polish'"
                            aria-controls="collapseExample">
                            Polish
                        </button>
                        <div #polishCollapse="ngbCollapse" [ngbCollapse]="selectedDropdown != 'polish'">
                            <div class="card">
                                <div class="card-body">
                                    <label class="check-container" *ngFor="let polish of polishData">
                                        <input type="checkbox" (change)="changePolishSelection()" [(ngModel)]="polish.is_checked"
                                            id="polish.master_polish_id">{{polish.polish_name}}<span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="symmetry" style="padding-bottom: 10px;">
                        <button type="button" class="btn btn-outline-primary adv-btn-btn" [ngClass]="{'filterHighlight' : symmetrycheckedIDs.length > 0}"
                            (click)="toggleDropdown(symmetryCollapse, 'symmetry')" [attr.aria-expanded]="selectedDropdown == 'symmetry'"
                            aria-controls="collapseExample">
                            Symmetry
                        </button>
                        <div #symmetryCollapse="ngbCollapse" [ngbCollapse]="selectedDropdown != 'symmetry'">
                            <div class="card">
                                <div class="card-body">
                                    <label class="check-container" *ngFor="let symmetry of symmetryData">
                                        <input type="checkbox" (change)="changeSymmetrySelection()" [(ngModel)]="symmetry.is_checked"
                                            id="symmetry.master_symmetry_id">{{symmetry.symmetry_name}}<span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="depth" style="padding-bottom: 10px;">
                        <button type="button" class="btn btn-outline-primary adv-btn-btn" [ngClass]="{'filterHighlight' : depthMinValue || depthMaxValue}"
                            (click)="toggleDropdown(depthCollapse, 'depth')" [attr.aria-expanded]="selectedDropdown == 'depth'"
                            aria-controls="collapseExample">
                            Depth
                        </button>
                        <div #depthCollapse="ngbCollapse" [ngbCollapse]="selectedDropdown != 'depth'">
                            <div class="card">
                                <div class="card-body">
                                    <p class="minMaxError" [ngClass]="{'error': depthError}">Min value should be 46 &
                                        Max value should be 78</p>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-12">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Min</mat-label>
                                                <input matInput type="number" (change)="depthMinChange($event)" min="46"
                                                    max="78" name="min" placeholder="Enter Min" [(ngModel)]="depthMinValue">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Max</mat-label>
                                                <input matInput type="number" (change)="depthMaxChange($event)" min="46"
                                                    max="78" name="max" placeholder="Enter Max" [(ngModel)]="depthMaxValue">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="table-filter" style="padding-bottom: 10px;">
                        <button type="button" class="btn btn-outline-primary adv-btn-btn" [ngClass]="{'filterHighlight' : tableMinValue || tableMaxValue}"
                            (click)="toggleDropdown(tableCollapse, 'table')" [attr.aria-expanded]="selectedDropdown == 'table'"
                            aria-controls="collapseExample">
                            Table
                        </button>
                        <div #tableCollapse="ngbCollapse" [ngbCollapse]="selectedDropdown != 'table'">
                            <div class="card">
                                <div class="card-body">
                                    <p class="minMaxError" [ngClass]="{'error': tableError}">Min value should be 52 &
                                        Max value should be 80</p>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-12">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Min</mat-label>
                                                <input matInput type="number" (change)="tableMinChange($event)" min="52"
                                                    max="80" name="min" placeholder="Enter Min" [(ngModel)]="tableMinValue">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Max</mat-label>
                                                <input matInput type="number" (change)="tableMaxChange($event)" min="52"
                                                    max="80" name="min" placeholder="Enter Max" [(ngModel)]="tableMaxValue">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="tinge" style="padding-bottom: 10px;">
                        <button type="button" class="btn btn-outline-primary adv-btn-btn" [ngClass]="{'filterHighlight' : tingecheckedIDs.length > 0}"
                            (click)="toggleDropdown(tingeCollapse, 'tinge')" [attr.aria-expanded]="selectedDropdown == 'tinge'"
                            aria-controls="collapseExample">
                            Tinge
                        </button>
                        <div #tingeCollapse="ngbCollapse" [ngbCollapse]="selectedDropdown != 'tinge'">
                            <div class="card">
                                <div class="card-body">
                                    <label class="check-container" *ngFor="let tinge of tingeData">
                                        <input type="checkbox" (change)="changeTingeSelection()" [(ngModel)]="tinge.is_checked"
                                            id="tinge.master_tinge_id">{{tinge.tinge_name}}<span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div> -->
                    <div class="ratio">
                        <button type="button" class="btn btn-outline-primary adv-btn-btn" [ngClass]="{'filterHighlight' : ratioMinValue || ratioMaxValue}"
                            (click)="toggleDropdown(ratioCollapse, 'ratio')" [attr.aria-expanded]="selectedDropdown == 'ratio'"
                            aria-controls="collapseExample">
                            Ratio
                        </button>
                        <div #ratioCollapse="ngbCollapse" [ngbCollapse]="selectedDropdown != 'ratio'">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-12">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Min</mat-label>
                                                <input matInput type="number" (change)="ratioMinChange($event)" min="52"
                                                    max="80" name="min" placeholder="Enter Min" [(ngModel)]="ratioMinValue">
                                            </mat-form-field>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Max</mat-label>
                                                <input matInput type="number" (change)="ratioMaxChange($event)" min="52"
                                                    max="80" name="min" placeholder="Enter Max" [(ngModel)]="ratioMaxValue">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- <div class="reset">
                    <button class="btn btn-outline" (click)="resetFilter()">Reset Filter</button>
                </div> -->
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="diamondType === 'p'">
        <div class="resetPaired" style="position: relative;left: 3%;top: 0;">
            <button class="btn btn-outline" (click)="resetFilter()">Reset Filter</button>
        </div>
    </div>
</div>