import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { WhiteDiamondComponent } from './pages/white-diamond/white-diamond.component';
import { SignupComponent } from './pages/signup/signup.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/_guard/auth.guard';
import { PairedDiamondComponent } from './pages/paired-diamond/paired-diamond.component';
import { NotificationlistComponent } from './pages/notificationlist/notificationlist.component';
import { ManageUsersComponent } from './pages/manage-users/manage-users.component';
import { ManageSellPriceComponent } from './pages/manage-sell-price/manage-sell-price.component';
import { AddRoleComponent } from './pages/add-role/add-role.component';
import { AddUserComponent } from './pages/add-user/add-user.component';
import { OrderCartComponent } from './pages/order-cart/order-cart.component';
import { OrderHistoryComponent } from './pages/order-history/order-history.component';
import { DiamondDetailComponent } from './pages/diamond-detail/diamond-detail.component';
import { PairedDiamondDetailComponent } from './pages/paired-diamond-detail/paired-diamond-detail.component';
import { OrderDetailComponent } from './pages/order-detail/order-detail.component';
import { HomeComponent } from './pages/home/home.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { WishlistComponent } from './pages/wishlist/wishlist.component';

const routes: Routes = [

    {
        path: 'login',
        loadChildren: () => import('../app/pages/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('../app/pages/signup/signup.module').then(m => m.SignupModule),
    },
    {
        path: 'forgetPassword',
        loadChildren: () => import('../app/pages/forget-password/forget-password.module').then(m => m.ForgetPasswordModule),
    },
    {
        path: 'reset-password',
        loadChildren: () => import('../app/pages/forget-password/forget-password.module').then(m => m.ForgetPasswordModule),

    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'whiteDiamond',
                pathMatch: 'full',
            },

            {
                path: 'user-setting',
                loadChildren: () => import('./pages/user-setting/user-setting.module').then(m => m.UserSettingModule)
            },
            {
                path: 'whiteDiamond',
                component: WhiteDiamondComponent,
            },
            {
                path: 'coloredDiamond',
                component: WhiteDiamondComponent,
            },
            {
                path: 'pairedDiamond',
                component: WhiteDiamondComponent,
            },
            {
                path: 'notifications',
                component: NotificationlistComponent,
            },
            {
                path: 'manage-user',
                component: ManageUsersComponent,
            },
            {
                path: 'manage-sell-price',
                component: ManageSellPriceComponent,
            },
            {
                path: 'add-role',
                component: AddRoleComponent,
            },
            {
                path: 'add-user',
                component: AddUserComponent,
            },
            {
                path: 'order-cart',
                component: OrderCartComponent,
            },
            {
                path: 'order-history',
                component: OrderHistoryComponent,
            },
            {
                path: 'diamondDetail/:id',
                component: DiamondDetailComponent
            },
            // {
            //     path: 'diamondDetail',
            //     component: DiamondDetailComponent
            // },
            {
                path: 'pairedDiamondDetail/:id',
                component: PairedDiamondDetailComponent
            },
            // {
            //     path: 'pairedDiamondDetail',
            //     component: PairedDiamondDetailComponent
            // },
            {
                path: 'orderDetail',
                component: OrderDetailComponent,
            },
            {
                path: 'diamondDetail',
                component: DiamondDetailComponent
            },
            {
                path: 'pairedDiamondDetail',
                component: PairedDiamondDetailComponent
            },
            {
                path: 'volumeBuy',
                component: ComingSoonComponent
            },
            {
                path: 'bestValue',
                component: ComingSoonComponent
            },
            {
                path: 'Melee',
                component: ComingSoonComponent
            },
            {
                path: 'wishlist',
                component: WishlistComponent
            },
            {
                path: '**',
                component: PageNotFoundComponent
            }

        ]
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
