<section class="section first-section">
    <div class="container-fluid" *ngIf="detailsData">
        <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading...</p>
        </ngx-spinner>
        <div class="title d-flex">
            <div class="left-arrow d-flex align-items-center" (click)="backToOrderHistory();">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </div>
            <div class="order-no" [ngClass]="displayPrice === 'b' ? 'title-content' : 'single-price-title-content'">
                <div class="title-content-label">
                    <span>Order No.</span>
                </div>
                <div>
                    <a href="javascript:void(0)">
                        #{{detailsData.order_no}}</a>
                </div>
            </div>
            <div [ngClass]="displayPrice === 'b' ? 'title-content' : 'single-price-title-content'">
                <div class="title-content-label">
                    <span>Date</span>
                </div>
                <div>
                    <span>{{getDate(detailsData.date_of_order) | date:'MM-dd-yyyy'}}</span>
                </div>
            </div>
            <div [ngClass]="displayPrice === 'b' ? 'title-content' : 'single-price-title-content'">
                <div class="title-content-label">
                    <span>Status</span>
                </div>
                <div>
                    <span *ngIf="detailsData.status == 1" class="pending">Pending</span>
                    <span *ngIf="detailsData.status == 2" class="placed">Order Placed</span>
                    <span *ngIf="detailsData.status == 3" class="rejected">Rejected</span>
                    <span *ngIf="detailsData.status == 4" class="approve">Approved</span>
                    <span *ngIf="detailsData.status == 5" class="approve">Shipped</span>
                </div>
            </div>
            <div [ngClass]="displayPrice === 'b' ? 'title-content' : 'single-price-title-content'">
                <div class="title-content-label">
                    <span>Cts</span>
                </div>
                <div>
                    <span>{{detailsData.total_carat ? detailsData.total_carat : '-'}}</span>
                </div>
            </div>
            <div [ngClass]="displayPrice === 'b' ? 'title-content' : 'single-price-title-content'">
                <div class="title-content-label">
                    <span>Total Lots Placed</span>
                </div>
                <div>
                    <span>{{detailsData.no_of_lots}}</span>
                </div>
            </div>
            <div *ngIf="displayPrice === 'b' || displayPrice === 'c'" [ngClass]="displayPrice === 'b' ? 'title-content' : 'single-price-title-content'">
                <div class="title-content-label">
                    <span>Cost {{detailsData.conversion_currency_code}}</span>
                </div>
                <div>
                    <span>{{detailsData.currency_symbol}}{{detailsData.local_total_amount
                        | number}}</span>
                </div>
            </div>
            <div *ngIf="displayPrice === 'b' || displayPrice === 's'" [ngClass]="displayPrice === 'b' ? 'title-content' : 'single-price-title-content'">
                <div class="title-content-label">
                    <span>Retail {{detailsData.conversion_currency_code}}</span>
                </div>
                <div>
                    <span>{{detailsData.currency_symbol}}{{detailsData.local_total_sell_amount
                        | number}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 tableDiv">
                <!-- <div class="header-strip">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label>Order No :</label>
                            <span>{{detailsData.order_no}}</span>
                        </div>
                        <div class="col-12 col-md-6">
                            <label>Order Date :</label>
                            <span>{{getDate(detailsData.date_of_order) | date:'MM-dd-yyyy'}}</span>
                        </div>
                    </div>
                    <hr class="hr1">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label>Total Lots Placed :</label>
                            <span>{{detailsData.no_of_lots}}</span>
                        </div>
                        <div class="col-12 col-md-6">
                            <label>Status :</label>
                            <span *ngIf="detailsData.status == 1" class="pending">Pending</span>
                            <span *ngIf="detailsData.status == 2" class="placed">Order Placed</span>
                            <span *ngIf="detailsData.status == 3" class="rejected">Rejected</span>
                            <span *ngIf="detailsData.status == 4" class="approve">Approved</span>
                            <span *ngIf="detailsData.status == 5" class="approve">Shipped</span>
                        </div>
                    </div>
                </div> -->
                <div class="table-responsive">
                    <table class="table table-striped table-fixed mb-0">
                        <thead>
                            <tr style="padding-left: 15px;" [ngClass]="displayPrice === 's' || displayPrice === 'c' ? 'singlePrice' : ''">
                                <th>Stock Id</th>
                                <th>Shape</th>
                                <th>Carat</th>
                                <th>Color</th>
                                <th>Intensity</th>
                                <th>Clarity</th>
                                <th>Cut</th>
                                <th>Lab</th>
                                <th>Location</th>
                                <th>Availability</th>
                                <!-- <th scope="col" *ngIf="displayPrice === 'b' || displayPrice === 'c'">Cost Price USD</th> -->
                                <th *ngIf="displayPrice === 'b' || displayPrice === 'c'" style="width: 109px;">
                                    Cost {{detailsData.conversion_currency_code}}
                                </th>
                                <!-- <th scope="col" *ngIf="displayPrice === 'b' || displayPrice === 's'">Sell Price USD</th> -->
                                <th *ngIf="displayPrice === 'b' || displayPrice === 's'" style="width: 109px;">
                                    Retail {{detailsData.conversion_currency_code}}
                                </th>
                                <th class="text-center">Status</th>

                            </tr>
                        </thead>
                        <tbody class="thikScroll">
                            <a (click)="goToDiamondDetail(order);" *ngFor="let order of orderDetailList; index as i"
                                [ngClass]="order.paired_lot_id ? 'pairedDiamondA' : ''" target="_blank">
                                <tr style="padding-left: 15px;" [ngClass]="[order.paired_lot_id ? '' : 'DiamondA',displayPrice === 's' || displayPrice === 'c' ? 'singlePrice' : 'pairedData']">
                                    <td class="pairedData">{{ order.lot.altr_stock_id ? order.lot.altr_stock_id : "-"}}</td>
                                    <td class="pairedData">{{ order.lot.shape_name ? order.lot.shape_name : '-' }}</td>
                                    <td class="pairedData">{{ order.lot.weight ? order.lot.weight : '-' }}</td>
                                    <td class="pairedData">{{ order.lot.color_name ? order.lot.color_name : '-'}}</td>
                                    <td class="pairedData">{{ order.lot.intensity_name ? order.lot.intensity_name : '-'
                                        }}</td>
                                    <td class="pairedData">{{ order.lot.clarity_name ? order.lot.clarity_name : '-'}}</td>
                                    <td class="d-flex align-items-center pairedData">{{ order.lot.cut_name ?
                                        order.lot.cut_name :
                                        '-' }}
                                        <span class="pl-1">
                                            <img *ngIf="order.lot.heart_n_arrow" style="width: 13px;position: relative;bottom:-1px;"
                                                src="../../../assets/img/svg/heart&arrow.svg">
                                        </span>
                                    </td>
                                    <td class="pairedData">
                                        <div style="position:relative;top: 1px;">
                                            <span class="tooltip action">
                                                <a (click)="gradingLink($event, order.lot.grading_link)" target="_blank"
                                                    class="gradingLink">{{ order.lot.lab_name ?
                                                    order.lot.lab_name : '-' }}</a>
                                                <span class="tooltiptext">View Certificate</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td class="pairedData">{{ order.lot.lot_location_name ? order.lot.lot_location_name
                                        : '-' }}</td>
                                    <td>{{ order.lot.availability ? order.lot.availability : '-' }}</td>
                                    <!-- <td *ngIf="displayPrice === 'b' || displayPrice === 'c'">${{ order.customer_usd_price ? order.customer_usd_price : '-' }}
                                    </td> -->
                                    <td class="pairedData" *ngIf="displayPrice === 'b' || displayPrice === 'c'" style="width: 109px;">
                                        {{detailsData.currency_symbol}}{{ order.customer_local_currency_price |
                                        number}}</td>
                                    <!-- <td *ngIf="displayPrice === 'b' || displayPrice === 's'">${{ order.internal_client_usd_price ? order.internal_client_usd_price : '-' }}
                                    </td> -->
                                    <td class="pairedData" *ngIf="displayPrice === 'b' || displayPrice === 's'" style="width: 109px;">
                                        {{detailsData.currency_symbol}}{{ order.internal_client_local_currency_price |
                                        number}}</td>
                                    <!-- 1 : pending, 2 : approved, 3 : reject, 4: shipped -->
                                    <td class="pairedData" *ngIf="order.status == 1" class="pending justify-content-center">Pending</td>
                                    <td class="pairedData" *ngIf="order.status == 2" class="approve justify-content-center">Approved</td>
                                    <td class="pairedData" *ngIf="order.status == 3" class="rejected justify-content-center">Rejected</td>
                                    <td class="pairedData" *ngIf="order.status == 4" class="approve justify-content-center">
                                    <span class="tooltip action" style="text-decoration: none;">
                                        <a target="_blank" (click)="shipmentTracking($event, order.shipping_link)">
                                            Shipped
                                        </a>
                                        <span class="tooltiptext">Tracking link</span>
                                    </span>
                                    </td>
                                    <td class="pairedData" *ngIf="order.status == 5" class="approve justify-content-center">Delivered</td>
                                </tr>
                                <tr *ngIf="order.paired_lot_id" class="pairedTr" [ngClass]="displayPrice === 's' || displayPrice === 'c' ? 'singlePrice' : 'pairedTr'">
                                    <td>{{ order.paired_diamond_lot.altr_stock_id ?
                                        order.paired_diamond_lot.altr_stock_id : "-"}}</td>
                                    <td>{{ order.paired_diamond_lot.shape_name ? order.paired_diamond_lot.shape_name :
                                        '-' }}</td>
                                    <td>{{ order.paired_diamond_lot.weight ? order.paired_diamond_lot.weight : '-' }}</td>
                                    <td>{{ order.paired_diamond_lot.color_name ? order.paired_diamond_lot.color_name :
                                        '-'}}</td>
                                    <td>{{ order.paired_diamond_lot.intensity_name ?
                                        order.paired_diamond_lot.intensity_name : '-' }}</td>
                                    <td>{{ order.paired_diamond_lot.clarity_name ?
                                        order.paired_diamond_lot.clarity_name : '-'}}</td>
                                    <td>{{ order.paired_diamond_lot.cut_name ? order.paired_diamond_lot.cut_name : '-'
                                        }}
                                        <span class="pl-1">
                                            <img *ngIf="order.paired_diamond_lot.heart_n_arrow" style="width: 13px;position: relative;bottom:-2px;"
                                                src="../../../assets/img/svg/heart&arrow.svg">
                                        </span>
                                    </td>
                                    <td>
                                        <div style="position:relative;top: 1px;">
                                            <span class="tooltip action">
                                                <a [href]="order.paired_diamond_lot.grading_link ? order.paired_diamond_lot.grading_link : '#'"
                                                    target="_blank" class="gradingLink">{{
                                                    order.paired_diamond_lot.lab_name ?
                                                    order.paired_diamond_lot.lab_name : '-' }}</a>
                                                <span class="tooltiptext">View Certificate</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td>{{ order.paired_diamond_lot.lot_location_name ?
                                        order.paired_diamond_lot.lot_location_name : '-' }}</td>
                                    <td>{{ order.paired_diamond_lot.availability ?
                                        order.paired_diamond_lot.availability : '-' }}</td>
                                    <td *ngIf="displayPrice === 'b' || displayPrice === 'c'"></td>
                                    <td *ngIf="displayPrice === 'b' || displayPrice === 's'"></td>
                                    <td></td>
                                </tr>
                            </a>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row infoDiv mt-2 justify-content-end">
            <div class="col-12 col-md-5">
                <mat-form-field appearance="outline" *ngIf="detailsData.message">
                    <mat-label>Message</mat-label>
                    <textarea matInput placeholder="Message" readonly="true" value="{{detailsData.message}}" rows="5"></textarea>
                </mat-form-field>
                <!-- <div class="action">
                    <label *ngIf="detailsData.action_taken_by">Action Taken By :</label>
                    <span *ngIf="detailsData.action_taken_by">{{detailsData.action_taken_by ?
                        detailsData.action_taken_by : '-' }} <span>({{detailsData.action_role_name
                            ?
                            detailsData.action_role_name : "-"}})</span></span>
                    <br>
                    <label *ngIf="detailsData.action_taken_date">Action Time Stamp :</label>
                    <span *ngIf="detailsData.action_taken_date">{{getTime(detailsData.action_taken_date)}}</span>
                </div> -->
                <div class="d-flex justify-content-end mb-3" *ngIf="detailsData.is_order_approval  == true">
                    <button type="button" class="btn cancel mr-4" (click)="rejectOrder()">Reject</button>
                    <button type="button" class="btn req" (click)="approveOrder()">Approve</button>
                </div>
            </div>
            <!-- <div class="col-12 col-md-3"></div> -->
            <!-- <div class="col-12 col-md-4"> -->
            <!-- <ul>
                    <li *ngIf="displayPrice === 'b'">
                        <label>Sub Total CP:</label>
                        <span style="float: right;margin-right: 10px;">{{detailsData.currency_symbol}}{{detailsData.local_total_amount
                            | number}}
                        </span>  -->
            <!-- <span style="float: right;"> (${{detailsData.total_amount}})</span> -->
            <!-- </li>
                    <li *ngIf="displayPrice === 'b'">
                        <label>Sub Total SP:</label>
                        <span style="float: right;margin-right: 10px;">{{detailsData.currency_symbol}}{{detailsData.local_total_sell_amount
                            | number}}
                        </span> -->
            <!-- <span style="float: right;"> (${{detailsData.total_sell_amount}})</span> -->
            <!-- </li>
                    <li *ngIf="displayPrice === 's'">
                        <label>Sub Total :</label>
                        <span style="float: right;margin-right: 10px;">{{detailsData.currency_symbol}}{{detailsData.local_total_sell_amount
                            | number}}
                        </span> -->
            <!-- <span style="float: right;"> (${{detailsData.total_sell_amount}})</span> -->
            <!-- </li>
                    <li *ngIf="displayPrice === 'c'">
                        <label>Sub Total :</label>
                        <span style="float: right;margin-right: 10px;">{{detailsData.currency_symbol}}{{detailsData.local_total_amount
                            | number}}
                        </span> -->
            <!-- <span style="float: right;"> (${{detailsData.total_amount}})</span> -->
            <!-- </li> -->
            <!-- <li *ngIf="displayPrice === 's' || displayPrice === 'b'">
                        <label class="tbold">Total :</label>
                        <span class="tbold" style="float: right;margin-right: 10px;">{{detailsData.currency_symbol}}{{detailsData.local_total_sell_amount | number}}
                        </span>
                        <span class="tbold" style="float: right;"> (${{detailsData.total_sell_amount}})</span>
                    </li>
                    <li *ngIf="displayPrice === 'c'">
                        <label class="tbold">Total :</label>
                        <span class="tbold" style="float: right;margin-right: 10px;">{{detailsData.currency_symbol}}{{detailsData.local_total_amount | number}}
                        </span>
                        <span class="tbold" style="float: right;"> (${{detailsData.total_amount}})</span>
                    </li> -->
            <!-- </ul> -->
            <!-- </div> -->
        </div>
    </div>
</section>