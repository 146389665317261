import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../../services/data-service.service';
import { URLS } from '../../core/_constant/api.config';
import { CookieService } from 'ngx-cookie-service';
var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private subscription: Subscription = new Subscription();
  public userData: any;

  constructor(private dataService: DataService,
    public cookieService: CookieService) { }

  ngOnInit(): void {
    this.userData = this.cookieService.get('loginData') ? JSON.parse(this.cookieService.get("loginData")) : null;
    if (this.userData) {
      this.getChatUser().then((data: any) => {
        const dataIndex = Math.floor(Math.random() * data.data.data.length);
        this.intializeTawkChat(data.data.data[dataIndex]);
        if (window['Tawk_API']) {
          window['Tawk_API'].showWidget();
        }
      });
    }
  }

  getChatUser() {
    let country_id = this.userData.country_id;
    if (!country_id) {
      return null;
    }
    let params = {
      query: { country_id: country_id }
    }

    return new Promise((resolve, reject) => {
      this.subscription.add(this.dataService.post(URLS.listChatUsers, params).subscribe(data => {
        if (data['code'] == 200) {
          resolve(data);
        } else {
          reject(data);
        }
      }));
    });
  }

  intializeTawkChat(chat_details) {

    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = chat_details.widget_id;
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);

    s1.onload = () => {
      setTimeout(() => {
        window['Tawk_API'].setAttributes({
          name: this.userData.first_name,
          email: this.userData.email,
        }, function (error) {

        });
      }, 1200)
    }
    // })
  }
}
