import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../core/_common/loader.service';
import { NotifyService } from '../../core/_common/snackbar.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data-service.service';
import { URLS } from 'src/app/core/_constant/api.config';
import { WishListService } from 'src/app/services/wishlist.service';
import { AddToCartService } from 'src/app/services/addtocart.service';
import { orderBy, union } from 'lodash';
import { SharedHeaderService } from 'src/app/services/sharedHeader.service';
import { ConfirmationDialogComponent } from '../../core/_component/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment.prod';
import { NgbModal, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../core/_component/modal/modal.component';

@Component({
    selector: 'app-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent implements OnInit {
    wishListData = [];
    // pageRecord = 10;
    // pageNo = 1;
    totalRecords: any;
    search: any;
    currencySymbol: any;
    diamondType: any;
    diamondViewList: any;
    diamindModalVideoData: any;
    displayPrice: any;
    displayVideo = false;
    displayImage = false;
    sortingArray: any = [];
    public filterParams: any = {};
    private subscription: Subscription = new Subscription();

    diamondColum = [
        { key: 'Shape', value: 'Shape', is_two: true, className: '', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Intensity', value: 'Intensity', className: 'intensity', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, className: '', is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cut', value: 'Cut', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cost', value: 'Price', is_two: true, className: 'price', is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    diamondColumOne = [
        { key: 'Shape', value: 'Shape', is_two: true, className: '', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Intensity', value: 'Intensity', className: 'intensity', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, className: '', is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cut', value: 'Cut', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Retail', value: 'Price', is_two: true, className: 'price', is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    diamondColumTwo = [
        { key: 'Shape', value: 'Shape', is_two: true, className: '', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Carat', value: 'Carat', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Color', value: 'Color', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Intensity', value: 'Intensity', className: 'intensity', sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Clarity', value: 'Clarity', is_two: true, className: '', is_clarity: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cut', value: 'Cut', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        { key: 'Lab', value: 'Lab', sort: 'ASC', className: '', is_asec: false, is_dsec: false },
        // { key: 'Price USD', value: 'Price', is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Cost', value: 'Price', is_two: true, className: 'price', is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
        { key: 'Retail', value: 'Price', is_two: true, className: 'price', is_code: true, is_price: true, sort: 'ASC', is_asec: false, is_dsec: false },
    ];

    //Scroll pagination 
    scrollposition: any;
    paginationScroll: boolean = false;
    isApiLoading: boolean = false;
    showDiamondDetail: boolean = false;
    public filterObj = {
        enableNext: false,
        page: 1,
        pageRecord: 30
    };
    currencyCode: any;
    constructor(
        config: NgbCarouselConfig,
        public loader: LoaderService,
        public dataService: DataService,
        private notifyService: NotifyService,
        public wishlistServ: WishListService,
        public addToCartService: AddToCartService,
        public sharedHeaderService: SharedHeaderService,
        public dialog: MatDialog,
        private modalService: NgbModal
    ) {
        config.showNavigationArrows = true;
        config.showNavigationIndicators = false;
     }

    ngOnInit() {
        this.getWishListData({ page_no: 1 });

    }

    //Scroll pagination 
    ngAfterViewInit() {
        this.scrollposition = 0;
    }

    onScroll(e) {
        const currentValue = this.scrollposition;
        const element = e.target;
        let pos = (element.scrollTop || element.scrollTop) + element.offsetHeight;
        let max = element.scrollHeight;
        const difference = max - pos;
        if (difference >= 0 && difference <= 20 && currentValue < element.scrollTop) {
            if (this.filterObj.enableNext) {
                this.paginationScroll = true;
                // this.getDiamondList(params, true);
                console.log(this.filterParams);
                this.getWishListData(this.filterParams, this.filterObj.enableNext);
                this.paginationScroll = false;
            }
        }
        this.scrollposition = element.scrollTop;
    }

    getWishListData(params?, isInfinteScrollCalled?: boolean) {
        // this.isLoading = true;
        if (this.isApiLoading) {
            return;
        }
        this.isApiLoading = true;
        this.loader.showSpinner();
        if (this.paginationScroll) {
            params['page_no'] = this.filterObj.page
            params['page_record'] = this.filterObj.pageRecord
        } else {
            params['page_no'] = 1
            params['page_record'] = 30
        }
        this.subscription.add(
            this.dataService.post(URLS.getWishlist, params).subscribe(
                data => {
                    this.isApiLoading = false;
                    if (data["code"] == 200 && !isInfinteScrollCalled) {
                        this.wishListData = data["data"]['rows'];
                        console.log(this.wishListData);
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencySymbol = data["data"]['currency_symbol'];
                        this.currencyCode = data["data"]['conversion_currency_code'];
                        if (this.filterObj.page == 1) {
                            this.totalRecords = data["data"]['count'];
                        }
                        this.displayPrice = data["data"]["display_price"];
                        // this.wishListData = [...this.wishListData]
                    }
                    // Scroll pagination
                    else if (data["code"] == 200 && isInfinteScrollCalled) {
                        this.wishListData = orderBy(union(this.wishListData, data['data']['rows']));
                        this.filterObj.enableNext = data["data"]['enableNext']
                        this.currencySymbol = data["data"]['currency_symbol'];
                        this.currencyCode = data["data"]['conversion_currency_code'];
                        if (this.filterObj.page == 1) {
                            this.totalRecords = data["data"]['count'];
                        }
                        this.displayPrice = data["data"]["display_price"];
                    }
                    else {
                        this.notifyService.showToast(data["message"]);
                    }
                    this.wishListData.forEach(element => {
                        if (element.in_order_cart && !this.addToCartService.storedLotIds.includes(element.lot_id)) {
                            this.addToCartService.storedLotIds.push(element.lot_id)
                        }
                    });
                    this.filterObj.page++
                    this.loader.hideSpinner();
                },
                error => {
                    this.loader.hideSpinner();
                    this.isApiLoading = false;
                    console.log(error);
                }
            )
        );
    }

    openDialog(id) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            height: '230px',
            width: '400px',
            data: { headerName: 'Remove From Wishlist', text: 'Are you sure you want to remove this item from wishlist?' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loader.showSpinner();
                let params = {
                    "lot_id": +id,
                }
                this.subscription.add(
                    this.dataService.delete(URLS.wishListRemoveItem, { params: params }).subscribe(
                        data => {
                            if (data["code"] == 200) {
                                this.getWishListData(this.filterParams, this.filterObj.enableNext);
                                if (this.wishListData.length < 2) {
                                    this.showDiamondDetail = false;
                                }
                                this.wishlistServ.getWishListCount();
                            } else {
                                this.notifyService.showToast(data["message"]);
                            }
                        },
                        error => {
                            this.loader.hideSpinner();
                            console.log(error);
                        }
                    )
                );
            }
        });
    }

    columnsTosort(data: any) {
        if (this.sortingArray && this.sortingArray.length == 0) {
            this.sortingArray.push({
                sort_by: data.sort,
                sort_type: data.value
            })
        }
        else {
            let obj = this.sortingArray.find(e => e.sort_type == data.value)
            if (obj) {
                obj.sort_by = data.sort
            } else {
                this.sortingArray.push({
                    sort_by: data.sort,
                    sort_type: data.value
                })
            }
        }
    }

    diamondSort(data) {
        // if (!this.customerData || this.diamondType == 'p') {
        //     return
        // }
        if (data.sort === 'ASC') {
            data.is_asec = true;
            data.is_dsec = false;
            this.columnsTosort(data);
            this.filterParams = {
                sort_array: this.sortingArray,
            }
            this.getWishListData(this.filterParams);
            data.sort = 'DESC'
        } else if (data.sort === 'DESC') {
            data.is_asec = false;
            data.is_dsec = true;
            this.columnsTosort(data);
            this.filterParams = {
                sort_array: this.sortingArray,
            }
            this.getWishListData(this.filterParams);
            data.sort = '';
        } else {
            data.is_dsec = false;
            let index = this.sortingArray.findIndex(e => data.sort == '' && e.sort_type == data.value);
            this.sortingArray.splice(index, 1);
            this.filterParams = {
                sort_array: this.sortingArray,
            }
            this.getWishListData(this.filterParams);
            data.sort = 'ASC';
        }
    }

    goToDiamondDetail(diamondData) {
        let url = diamondData.diamond_type == 'p' ? '/pairedDiamondDetail/' + diamondData.lot_id + '?pairedLotId=' + diamondData.paired_lot_id
            : '/diamondDetail/' + diamondData.lot_id + '?diamondType=' + diamondData.diamond_type;
        window.open(`${environment.webUrl}` + url, '_blank');
    }

    deleteWishList(id) {
        this.openDialog(id);
    }

    methodCall(page: number) {
        console.log(page);
        this.filterObj.page = page;
        this.getWishListData();

    }

    getRecord() {
        let datalength = this.filterObj.page * this.filterObj.pageRecord;
        let startlength = (datalength - this.filterObj.pageRecord) + 1;
        if (datalength > this.totalRecords) {
            datalength = this.totalRecords
        }
        return startlength + " - " + datalength + " Items of " + this.totalRecords;
    }

    //Add to cart
    AddToCart(diamondData) {
        if (this.getCartObject(diamondData)) {
            this.addToCartService.removeFromCart(diamondData);
        } else {
            this.addToCartService.addToCart(diamondData);
        }

    }

    showDiamondDetails(diamondData) {
        // this.isAddToCart = diamondData.in_order_cart;
        this.showDiamondDetail = true;
        diamondData.measurement = diamondData.measurement ? diamondData.measurement.replaceAll("x", " x ") : '-';
        diamondData.measurement = diamondData.measurement ? diamondData.measurement.replaceAll("*", " x ") : '-';
        this.diamondViewList = diamondData;
    }

    showDetailImage(content, data) {
        this.diamindModalVideoData = data;
        this.modalService.open(content, { size: 'md', centered: true });
        this.displayVideo = false;
        this.displayImage = true;
    }

    showDiamondVideo(content, data) {
        this.diamindModalVideoData = data;
        this.modalService.open(content, { size: 'md', centered: true });
        this.displayVideo = true;
        this.displayImage = false;
    }

    showClientModal(data) {
        const modalRef = this.modalService.open(ModalComponent, { centered: true, scrollable: true });
        modalRef.componentInstance.shareClientData = data;
        modalRef.componentInstance.displayPrice = this.displayPrice;
    }

    getCartStatus(diamondData) {
        if (this.getCartObject(diamondData)) {
            return true;
        } else {
            return false;
        }
    }

    getCartObject(diamondData) {
        let cartObject;
        if (diamondData.paired_diamond_lot && diamondData.paired_diamond_lot.lot_id)
            cartObject = this.addToCartService.orderCartListObject.rows.find(x => x.paired_lot_id == diamondData.paired_lot_id && x.lot_id == diamondData.lot_id);
        else
            if (this.addToCartService.orderCartListObject && this.addToCartService.orderCartListObject.rows) {
                cartObject = this.addToCartService.orderCartListObject.rows.find(x => x.lot_id == diamondData.lot_id && !x.paired_lot_id);
            }
        return cartObject ? true : false;
    }

    getLabelForCart(diamondData) {

        if (this.getCartObject(diamondData))
            return "Remove from Cart";
        else
            return "Add to Cart";
    }
}
